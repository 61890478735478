interface SocialStatus {
  key: string
  value: string
  text: string
}

const data: SocialStatus[] = [
  {
    key: 'Пенсионер',
    value: 'Пенсионер',
    text: 'Пенсионер',
  },
  {
    key: 'Лицо с ограниченными возможностями',
    value: 'Лицо с ограниченными возможностями',
    text: 'Лицо с ограниченными возможностями',
  },
]

export const getSocialStatus = (): SocialStatus[] => data

export const getCurrentGender = (value: string): SocialStatus | undefined =>
  data.find((item) => item.value === value)
