import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useCounterpartiesStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import PhoneClear from '../../utils/phone-clear'

import CounterpartyForm from './CounterpartyForm'

interface RouteParams {
  orgId: string
  counterpartyId: string
}

function CounterpartyEdit({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const counterparty = useCounterpartiesStore()

  const { orgId, counterpartyId } = params as RouteParams

  useEffect(() => {
    if (
      counterparty.counterparties === null ||
      !counterparty.isCounterpartyByOrganizationId(Number(orgId))
    ) {
      counterparty.requestCounterparties({ orgId: Number(orgId) })
    }

    counterparty.fillCurrentCounterparty(Number(counterpartyId))

    return () => {
      counterparty.setErrors([])
      counterparty.clearCurrentCounterparty()
    }
  }, [orgId])

  if (!isEmpty(counterparty.errors)) {
    return <Error errors={counterparty.errors} />
  }

  if (isEmpty(counterparty.currentCounterparty) || counterparty.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    actualAddress: counterparty.currentCounterparty.actualAddress,
    bankBik: counterparty.currentCounterparty.bankBik,
    bankName: counterparty.currentCounterparty.bankName,
    bc: String(counterparty.currentCounterparty.bc),
    bin: counterparty.currentCounterparty.bin,
    email: counterparty.currentCounterparty.email,
    fullName: counterparty.currentCounterparty.fullName,
    shortName: counterparty.currentCounterparty.shortName,
    supervisor: counterparty.currentCounterparty.supervisor,
    iic: counterparty.currentCounterparty.iic,
    legalAddress: counterparty.currentCounterparty.legalAddress,
    orgId: String(counterparty.currentCounterparty.orgId),
    phone: PhoneClear(counterparty.currentCounterparty.phone),
    type: String(counterparty.currentCounterparty.type),
    okved: counterparty.currentCounterparty.okved,
    id: counterparty.currentCounterparty.id,
    duplication:
      counterparty.currentCounterparty.actualAddress ===
      counterparty.currentCounterparty.legalAddress,
  }

  const linkList = getLink(PrivatePaths.CounterpartyList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          {
            id: 1,
            title: t('CounterpartyList'),
            link: linkList,
            active: false,
          },
          {
            id: 2,
            title: t('CounterpartyEdit'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('CounterpartyEdit')}</Heading>

      <div className="container-wrapper">
        <CounterpartyForm
          initialValues={initialValues}
          handleService={counterparty.updateCounterparty}
          buttonlabel={t('Edit')}
          responseMessage={t('CounterpartyEditedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(CounterpartyEdit)))
