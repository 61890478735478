import { PositionAPI } from './ApiUrls'
import httpClient from './httpClient'
import { OrgId } from './OrganizationService'

export interface PositionId extends OrgId {
  id: number
}

export interface Position extends PositionId, OrgId {
  name: string
}

export class PositionService {
  list = async (model: OrgId): Promise<Response> => {
    const result = await httpClient.post(PositionAPI.List, model)

    return result
  }

  add = async (model: Position): Promise<Response> => {
    const result = await httpClient.post(PositionAPI.Add, model)

    return result
  }

  edit = async (model: Position): Promise<Response> => {
    const result = await httpClient.post(PositionAPI.Edit, model)

    return result
  }

  delete = async (model: PositionId): Promise<Response> => {
    const result = await httpClient.post(PositionAPI.Delete, model)

    return result
  }

  get = async (model: PositionId): Promise<Response> => {
    const result = await httpClient.post(PositionAPI.Get, model)

    return result
  }
}

export default new PositionService()
