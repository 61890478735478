import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import StorageService, { Storage, StorageId } from '../services/StorageService'

import { OrgId } from '../services/OrganizationService'

import { ReactSelectValues } from '../types'

import getErrorsMapping from '../utils/get-errors-mapping'
import isEmpty from '../utils/is-empty'

export class StoragesStore {
  storages: Storage[] = null as unknown as Storage[]

  currentStorage: Storage = {} as Storage

  errors: string[] = []

  isLoader = false

  statusError = 0

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      storages: observable,
      currentStorage: observable,
      errors: observable,
      isLoader: observable,
      statusError: observable,
      setErrors: action.bound,
      requestStorages: action.bound,
      createStorage: action.bound,
      getStorage: action.bound,
      updateStorage: action.bound,
      removeStorage: action.bound,
      requestOptionsStorage: action.bound,
      setStorages: action.bound,
      setStorage: action.bound,
      setCurrentStorage: action.bound,
      editStorage: action.bound,
      deleteStorage: action.bound,
      isStorageByOrganizationId: action.bound,
      clearCurrentStorage: action.bound,
      fillCurrentStorage: action.bound,
      isLoaderChanged: action.bound,
      setStatusError: action.bound,
    })

    this.rootStore = rootStore
  }

  setStorages(payload: Storage[]): void {
    this.storages = payload
  }

  setStorage(payload: Storage): void {
    this.storages = [...this.storages, payload]
  }

  setCurrentStorage(payload: Storage): void {
    this.currentStorage = payload
  }

  editStorage(payload: Storage): void {
    this.storages = this.storages.map((storage) =>
      storage.storeId === payload.storeId
        ? { ...storage, ...payload }
        : storage,
    )
  }

  deleteStorage(id: number): void {
    this.storages = this.storages.filter((storage) => storage.storeId !== id)
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  setStatusError(payload: number): void {
    this.statusError = payload
  }

  isStorageByOrganizationId(organizationId: number): boolean {
    const storage = this.storages.filter(
      ({ orgId }: Storage): boolean => orgId === organizationId,
    )
    return !isEmpty(storage)
  }

  clearCurrentStorage(): void {
    this.currentStorage = {} as Storage
  }

  fillCurrentStorage(storeId: number): void {
    if (!isEmpty(this.storages)) {
      const currentStorage = this.storages.filter(
        (item) => item.storeId === storeId,
      )[0]
      this.setCurrentStorage(currentStorage)
    } else {
      this.getStorage({ storeId })
    }
  }

  async requestStorages(payload: OrgId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await StorageService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setStatusError(500)
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({ orgId, name, address, type, storeId }: Storage): Storage => ({
            orgId,
            name,
            address,
            type,
            storeId,
          }),
        )
        this.setStorages(result)
      }
    } catch (e: any) {
      console.log(
        `Class StoragesStore, method requestStorages, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async getStorage(payload: StorageId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await StorageService.get(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setStatusError(500)
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const { orgId, name, address, type, storeId } = json
        this.setCurrentStorage({ orgId, name, address, type, storeId })
      }
    } catch (e: any) {
      console.log(
        `Class StoragesStore, method getStorage, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async createStorage(payload: Storage): Promise<null | string[]> {
    try {
      const response = await StorageService.add(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const { orgId, name, address, type, storeId } = json
        this.setStorage({ orgId, name, address, type, storeId })
      }
    } catch (e: any) {
      console.log(
        `Class StoragesStore, method createStorage, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async updateStorage(payload: Storage): Promise<null | string[]> {
    try {
      const response = await StorageService.edit(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const { orgId, name, address, type, storeId } = json
        this.setCurrentStorage({ orgId, name, address, type, storeId })
        this.editStorage({ orgId, name, address, type, storeId })
      }
    } catch (e: any) {
      console.log(
        `Class StoragesStore, method updateStorage, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async removeStorage(payload: StorageId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await StorageService.delete(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setStatusError(500)
      }

      if (response.status === 400) {
        const json = await response.json()
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.deleteStorage(payload.storeId)
      }
    } catch (e: any) {
      console.log(
        `Class StoragesStore, method removeStorage, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async requestOptionsStorage(payload: OrgId): Promise<ReactSelectValues[]> {
    try {
      const response = await StorageService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({ storeId, name }: Storage): ReactSelectValues => ({
            value: String(storeId),
            label: String(name),
          }),
        )
        return result
      }
    } catch (e: any) {
      console.log(
        `Class StoragesStore, method requestOptionsStorage, type error ${e.name}, Message ${e.message}`,
      )
    }

    return []
  }
}
