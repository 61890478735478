import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'

import { PrivatePaths, PrivatePathsRegexp } from '../../../router/Routes'

import { FilterType } from '../../../services/TmcService'

import {
  useStoragesStore,
  useTmcСancellationStore,
} from '../../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../../hoc/withAuthRedirect'

import { InitialValuesFilter } from './types'

import isEmpty from '../../../utils/is-empty'
import getLink from '../../../utils/get-link'

import { Heading } from '../../../components/ui/Typography/Typography'
import { Loader } from '../../../components/ui/Loader'
import { Error } from '../../../components/ui/Error'
import { Breadcrumb } from '../../../components/ui/Breadcrumb'

import СancellationListFilterForm from './СancellationListFilterForm'
import СancellationListData from './СancellationListData'

interface RouteParams {
  orgId: string
}

function СancellationList({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const storage = useStoragesStore()
  const Сancellation = useTmcСancellationStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      storage.storages === null ||
      !storage.isStorageByOrganizationId(Number(orgId))
    ) {
      storage.requestStorages({ orgId: Number(orgId) })
    }

    return () => {
      storage.setErrors([])
      Сancellation.setErrors([])
    }
  }, [orgId])

  useEffect(() => {
    if (!isEmpty(storage.storages) && isEmpty(Сancellation.Сancellations)) {
      const { storeId } = storage.storages[0]

      const payload = {
        operationType: 0,
        orgId: Number(orgId),
        storeId,
      }

      Сancellation.requestСancellations(payload, FilterType.receiving)
    }
  }, [storage.storages])

  useEffect(() => {
    if (Сancellation.filterType === FilterType.filtering) {
      const payload = {
        operationType: 0,
        orgId: Number(orgId),
        storeId: Сancellation.filters.storeId,
        ...(Сancellation.filters.tmcId && {
          tmcId: Сancellation.filters.tmcId,
        }),
        ...(Сancellation.filters.periodStart && {
          periodStart: Сancellation.filters.periodStart,
        }),
        ...(Сancellation.filters.periodEnd && {
          periodEnd: Сancellation.filters.periodEnd,
        }),
      }

      Сancellation.requestСancellations(payload, FilterType.filtering)
    }
  }, [Сancellation.filters])

  if (!isEmpty(storage.errors)) {
    return <Error errors={storage.errors} />
  }

  if (!isEmpty(Сancellation.errors)) {
    return <Error errors={Сancellation.errors} />
  }

  if (
    storage.storages === null ||
    storage.isLoader ||
    Сancellation.Сancellations === null ||
    Сancellation.isLoader
  ) {
    return <Loader />
  }

  const initialValues: InitialValuesFilter = {
    orgId: Number(orgId),
    storeId:
      Сancellation.filters.storeId !== 0
        ? Сancellation.filters.storeId.toString()
        : '',
    periodStart: Сancellation.filters.periodStart,
    periodEnd: Сancellation.filters.periodEnd,
  }

  const linkCreate = getLink(PrivatePaths.TmcСancellationCreate, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  const linkList = getLink(PrivatePaths.TmcList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('TmcList'), link: linkList, active: false },
          {
            id: 2,
            title: t('Сancellation list link'),
            link: '/',
            active: true,
          },
        ]}
      />

      <Heading>{t('Сancellation list link')}</Heading>

      <div className="text-center mb-3">
        <Link to={linkCreate} className="btn btn-primary text-white">
          {t('Сancellation link')}
        </Link>
      </div>

      <div className="container-wrapper mb-3">
        <СancellationListFilterForm
          initialValues={initialValues}
          setFilterValues={Сancellation.setFilterValues}
          loadOptionsStorage={storage.requestOptionsStorage}
        />
      </div>

      {Сancellation.isFilterLoader ? (
        <div className="d-flex justify-content-center py-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <СancellationListData Сancellations={Сancellation.Сancellations} />
      )}
    </>
  )
}

export default withRouter(withAuthRedirect(observer(СancellationList)))
