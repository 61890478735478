import { DepartmentAPI } from './ApiUrls'
import httpClient from './httpClient'
import { OrgId } from './OrganizationService'

export interface DepartmentId extends OrgId {
  id: number
}

export interface Department extends DepartmentId, OrgId {
  formationDate: string
  name: string
  parentId: number
  workScheduleId: number
}

export class EmployeeService {
  list = async (model: OrgId): Promise<Response> => {
    const result = await httpClient.post(DepartmentAPI.List, model)

    return result
  }

  add = async (model: Department): Promise<Response> => {
    const result = await httpClient.post(DepartmentAPI.Add, model)

    return result
  }

  edit = async (model: Department): Promise<Response> => {
    const result = await httpClient.post(DepartmentAPI.Edit, model)

    return result
  }

  delete = async (model: DepartmentId): Promise<Response> => {
    const result = await httpClient.post(DepartmentAPI.Delete, model)

    return result
  }

  get = async (model: DepartmentId): Promise<Response> => {
    const result = await httpClient.post(DepartmentAPI.Get, model)

    return result
  }
}

export default new EmployeeService()
