export enum UserAPI {
  SignIn = '/user/sign-in',
  SignUp = '/user/sign-up',
  Edit = '/user/edit',
  Get = '/user/get',
  ResetPassword = '/user/password-reset',
}

export enum OrganizationAPI {
  List = '/organization/list',
  Add = '/organization/add',
  Edit = '/organization/edit',
  Get = '/organization/get',
}

export enum StorageAPI {
  List = '/storage/list',
  Add = '/storage/add',
  Delete = '/storage/delete',
  Edit = '/storage/edit',
  Get = '/storage/get',
}

export enum CounterpartyAPI {
  List = '/counterparty/list',
  Add = '/counterparty/add',
  Delete = '/counterparty/delete',
  Edit = '/counterparty/edit',
  Get = '/counterparty/get',
}

export enum TmcAPI {
  List = '/tmc/list',
  Add = '/tmc/add',
  Move = '/tmc/move',
  MovementList = '/tmc/movement_list',
  WriteOff = '/tmc/delete',
  Get = '/nomenclature/get',
  Сancellation = '/tmc/movement_list',
}

export enum KkmAPI {
  List = '/kkm/list',
  Add = '/kkm/add',
  Delete = '/kkm/delete',
  Edit = '/kkm/edit',
  Link = '/kkm/link',
  Get = '/kkm/get',
}

export enum PersonAPI {
  List = '/person/list',
  Add = '/person/add',
  Delete = '/person/delete',
  Edit = '/person/edit',
  Get = '/person/get',
}

export enum EmployeeAPI {
  List = '/employee/list',
  Add = '/employee/add',
  Delete = '/employee/delete',
  Edit = '/employee/edit',
  Get = '/employee/get',
}

export enum DepartmentAPI {
  List = '/department/list',
  Add = '/department/add',
  Delete = '/department/delete',
  Edit = '/department/edit',
  Get = '/department/get',
}

export enum PositionAPI {
  List = '/position/list',
  Add = '/position/add',
  Delete = '/position/delete',
  Edit = '/position/edit',
  Get = '/position/get',
}

export enum StaffPositionAPI {
  List = '/staff-position/list',
  Add = '/staff-position/add',
  Delete = '/staff-position/delete',
  Open = '/staff-position/open',
  Edit = '/staff-position/edit',
  Get = '/staff-position/get',
}
