import React, { ReactElement } from 'react'
import { Alert } from 'react-bootstrap'

interface AlertProps {
  variant: string
  text: string
}

export function AlertComponent({ variant, text }: AlertProps): ReactElement {
  return <Alert variant={variant}>{text}</Alert>
}
