// const user = JSON.parse(localStorage.getItem('user') || '{}')
import guidGen from '../utils/guid-gen'

const BASE_URL = 'https://erp-ext.iberica.kz/backend'
// const BASE_URL = ''

class HttpClient {
  get = async <T>(url: string, urlParams: string): Promise<T> => {
    const guid = guidGen()
    const user = JSON.parse(localStorage.getItem('user') || '{}')

    const headers = new Headers()

    headers.append('Content-Type', 'application/json')
    headers.append('guid', guid)

    if (user?.token) {
      headers.append('Authorization', `Bearer ${user.token}`)
    }

    const options = {
      method: 'GET',
      headers,
    }

    // const request = new Request(`${BASE_URL}${url}?${urlParams}`, options)
    const response: any = await fetch(`${BASE_URL}${url}?${urlParams}`, options)
    return response
  }

  post = async (url: string, model: any): Promise<Response> => {
    const guid = guidGen()
    const user = JSON.parse(localStorage.getItem('user') || '{}')

    const headers = new Headers()

    headers.append('Content-Type', 'application/json')
    headers.append('guid', guid)

    if (user?.token) {
      headers.append('Authorization', `Bearer ${user.token}`)
    }

    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify(model),
    }

    // const request = new Request(`${BASE_URL}${url}`, options)
    const response: any = await fetch(`${BASE_URL}${url}`, options)
    return response
  }
}

export default new HttpClient()
