import { ReactElement, useEffect } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useStoragesStore } from '../../providers/RootStoreProvider'
import { Storage } from '../../services/StorageService'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import TableData from '../../components/ui/TableData'
import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Error } from '../../components/ui/Error'

interface RouteParams {
  orgId: string
}

function List({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const storage = useStoragesStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      storage.storages === null ||
      !storage.isStorageByOrganizationId(Number(orgId))
    ) {
      storage.requestStorages({ orgId: Number(orgId) })
    }

    return () => {
      storage.setErrors([])
    }
  }, [orgId])

  if (!isEmpty(storage.errors)) {
    return <Error errors={storage.errors} />
  }

  if (storage.storages === null || storage.isLoader) {
    return <Loader />
  }

  const linkReceipt = getLink(PrivatePaths.TmcReceive, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  const linkСancellation = getLink(PrivatePaths.TmcСancellationList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  const linkMove = getLink(PrivatePaths.TmcMove, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  const columns = [
    {
      name: 'storeId',
      label: '№',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: t('StorageName'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'address',
      label: t('StorageAddress'),
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'handle',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
  ]

  const data = storage.storages.map((item: Storage) => ({
    storeId: item.storeId,
    name: (
      <Link
        to={getLink(PrivatePaths.TmcPriceList, {
          [orgId]: PrivatePathsRegexp.orgId,
          [item.storeId]: PrivatePathsRegexp.storeId,
        })}
        className="text-success p-2 me-1"
      >
        {item.name}
      </Link>
    ),
    address: item.address,
  }))

  return (
    <>
      <Heading>{t('TmcList')}</Heading>
      <div className="d-flex justify-content-between mb-5">
        <Link to={linkReceipt} className="btn btn-primary text-white">
          {t('Tmc receipt link')}
        </Link>

        <Link to={linkСancellation} className="btn btn-primary text-white">
          {t('Сancellation list link')}
        </Link>

        <Link to={linkMove} className="btn btn-primary text-white">
          {t('Tmc move link')}
        </Link>
      </div>
      {!isEmpty(storage.storages) ? (
        <TableData data={data} columns={columns} />
      ) : (
        <p>{t('ListStoragesIsEmpty')}</p>
      )}
    </>
  )
}

export default withRouter(withAuthRedirect(observer(List)))
