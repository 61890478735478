import i18n from 'i18next'
import isEmpty from './is-empty'

export default (errors: string[]): string[] => {
  interface ErrorMaping {
    [x: string]: string
  }

  const mappingErrors: ErrorMaping = {
    DEFAULT_ERROR: i18n.t('DefaultError'),
    ERROR500: i18n.t('Error500'),
    PHONE_ALREADY_EXISTS: i18n.t('PhoneAlreadyExists'),
    EMAIL_ALREADY_EXISTS: i18n.t('EmailAlreadyExists'),
    LOGIN_ALREADY_EXISTS: i18n.t('LoginAlreadyExists'),
    BIN_REQUIRED: i18n.t('BinRequired'),
    GUID_REQUIRED: i18n.t('GuidRequired'),
    NAME_REQUIRED: i18n.t('NameRequired'),
    ORG_NAME_REQUIRED: i18n.t('OrgNameRequired'),
    EMAIL_REQUIRED: i18n.t('EmailRequired'),
    ADDRESS_REQUIRED: i18n.t('AddressRequired'),
    LEGAL_ADDRESS_REQUIRED: i18n.t('LegalAddressRequired'),
    ACTUAL_ADDRESS_REQUIRED: i18n.t('ActualAddressRequired'),
    IIK_REQUIRED: i18n.t('IikRequired'),
    IIC_REQUIRED: i18n.t('IicRequired'),
    BIK_REQUIRED: i18n.t('BikRequired'),
    BC_REQUIRED: i18n.t('BcRequired'),
    PHONE_REQUIRED: i18n.t('PhoneRequired'),
    ACTION_REQUIRED: i18n.t('ActionRequired'),
    SUPERVISOR_NAME_REQUIRED: i18n.t('SupervisorNameRequired'),
    OKVED_REQUIRED: i18n.t('OkvedRequired'),
    FIRST_NAME_REQUIRED: i18n.t('FirstNameRequired'),
    LAST_NAME_REQUIRED: i18n.t('LastNameRequired'),
    PAT_NAME_REQUIRED: i18n.t('PatNameRequired'),
    SHORT_NAME_REQUIRED: i18n.t('ShortNameRequired'),
    FULL_NAME_REQUIRED: i18n.t('FullNameRequired'),
    COUNTERPARTY_BIN_REQUIRED: i18n.t('CounterpartyBinRequired'),
    BANK_NAME_REQUIRED: i18n.t('BankNameRequired'),
    BANK_BIK_REQUIRED: i18n.t('BankBikRequired'),
    PERSON_IDN_REQUIRED: i18n.t('PersonIdnRequired'),
    ISSUING_AUTHORITY_REQUIRED: i18n.t('IssuingAuthorityRequired'),
    BIRTHPLACE_REQUIRED: i18n.t('BirthplaceRequired'),
    DOCTYPE_REQUIRED: i18n.t('DoctypeRequired'),
    CITIZENSHIP_REQUIRED: i18n.t('CitizenshipRequired'),
    GENDER_REQUIRED: i18n.t('GenderRequired'),
    IIN_REQUIRED: i18n.t('IinRequired'),
    INCORRECT_PHONE_NUMBER: i18n.t('IncorrectPhoneNumber'),
    INCORRECT_PHONE: i18n.t('IncorrectPhone'),
    WRONG_EMAIL: i18n.t('WrongEmail'),
    INCORRECT_ORG_NAME: i18n.t('IncorrectOrgName'),
    INCORRECT_ORG_BIN: i18n.t('IncorrectOrgBin'),
    INCORRECT_BIN: i18n.t('IncorrectBin'),
    INCORRECT_LEGAL_ADDRESS: i18n.t('IncorrectLegalAddress'),
    INCORRECT_ACTUAL_ADDRESS: i18n.t('IncorrectActualAddress'),
    INCORRECT_IIK: i18n.t('IncorrectIik'),
    INCORRECT_IIC: i18n.t('IncorrectIic'),
    INCORRECT_BIK: i18n.t('IncorrectBik'),
    INCORRECT_BANK_BIK: i18n.t('IncorrectBankBik'),
    INCORRECT_OKVED: i18n.t('IncorrectOkved'),
    INCORRECT_SUPERVISOR_NAME: i18n.t('IncorrectSupervisorName'),
    INCORRECT_TYPE: i18n.t('IncorrectType'),
    INCORRECT_ADDRESS: i18n.t('IncorrectAddress'),
    INCORRECT_NAME: i18n.t('IncorrectName'),
    INCORRECT_BC: i18n.t('IncorrectBc'),
    INCORRECT_COUNTERPARTY_BIN: i18n.t('IncorrectCounterpartyBin'),
    INCORRECT_BIRTHDAY_DATE: i18n.t('IncorrectBirthdayDate'),
    INCORRECT_DATE_OF_ISSUE: i18n.t('IncorrectDateOfIssue'),
    INCORRECT_BIRTHPLACE: i18n.t('IncorrectBirthplace'),
    INCORRECT_CONTRACT_START_DATE: i18n.t('IncorrectContractStartDate'),
    INCORRECT_CONTRACT_END_DATE: i18n.t('IncorrectContractEndDate'),
    CHECK_INPUT_INCORRECT: i18n.t('CheckInputCorrect'),
    INVALID_SRC_STORE_ID: i18n.t('InvalidSrcStoreId'),
    INVALID_VALUES: i18n.t('InvalidValues'),
    INVALID_BARCODE: i18n.t('InvalidBarcode'),
    INVALID_ORG_ID: i18n.t('InvalidOrgId'),
    INVALID_ORG_BIN: i18n.t('InvalidOrgId'),
    LEGAL_ADDRESS_BIRTHPLACE: i18n.t('InvalidOrgId'),
    ACTUAL_ADDRESS_BIRTHPLACE: i18n.t('InvalidOrgId'),
    RECEIVE_ERROR: i18n.t('ReceiveError'),
    counterparty_already_exists: i18n.t('counterparty_already_exists'),
    counterparty_bin_already_exists: i18n.t('counterparty_already_exists'),
    cert_expired: i18n.t('cert_expired'),
    cert_revoked: i18n.t('cert_revoked'),
    cert_invalid: i18n.t('cert_invalid'),
    invalid_cert_data: i18n.t('invalid_cert_data'),
  }

  return errors.map((error: string): string =>
    !isEmpty(mappingErrors[error]) ? mappingErrors[error] : error,
  )
}
