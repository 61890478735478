export enum StatusForm {
  success = 'success',
  warning = 'warning',
}

export enum TypesForm {
  creating = 'success',
  editing = 'editing',
}

export interface ReactSelectValues {
  value: string
  label: string
}

export interface TableDataDeleted {
  data: TableDataDeletedRow[]
}
export interface TableDataDeletedRow {
  dataIndex: number
  index: number
}

export const PhoneNumberMask = [
  '+',
  '7',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const IinMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
