import { StorageAPI } from './ApiUrls'
import httpClient from './httpClient'

import { OrgId } from './OrganizationService'

export interface StorageId {
  storeId: number
}

export interface Storage extends OrgId, StorageId {
  name: string
  address: string
  type: number
}

export class StorageService {
  list = async (model: OrgId): Promise<Response> => {
    const result = await httpClient.post(StorageAPI.List, model)

    return result
  }

  add = async (model: Storage): Promise<Response> => {
    const result = await httpClient.post(StorageAPI.Add, model)

    return result
  }

  edit = async (model: Storage): Promise<Response> => {
    const result = await httpClient.post(StorageAPI.Edit, model)

    return result
  }

  delete = async (model: StorageId): Promise<Response> => {
    const result = await httpClient.post(StorageAPI.Delete, model)

    return result
  }

  get = async (model: StorageId): Promise<Response> => {
    const result = await httpClient.post(StorageAPI.Get, model)

    return result
  }
}

export default new StorageService()
