import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Form, Toast } from 'react-bootstrap'

import { Product } from '../../../services/TmcService'

import { ProductСancellationDataProps } from './types'

import isEmpty from '../../../utils/is-empty'
import has from '../../../utils/has'

import TableData from '../../../components/ui/TableData'
import { Button } from '../../../components/ui/Forms'

function СancellationCreateData({
  products,
  values,
  inputValueCounts,
  onAmountChange,
  onProductСancellationChange,
  onShowModal,
}: ProductСancellationDataProps): ReactElement {
  const { t } = useTranslation()

  const columns = [
    {
      name: 'id',
      label: '№',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'nameRus',
      label: t('NameRus'),
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'barcode',
      label: t('Barcode'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'amount',
      label: t('HeaderAmount'),
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'purchasePrice',
      label: t('PurchasePrice'),
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'unit',
      label: t('UnitMeasurement'),
      options: {
        filter: true,
        sort: false,
        viewColumns: false,
      },
    },

    {
      name: 'countToWriteOff',
      label: t('HeaderCountToWriteOff'),
      options: {
        filter: true,
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: 'choose',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
  ]

  const data = products.map((item: Product) => {
    const countformInputCoiunt = has(inputValueCounts, item.id)
      ? inputValueCounts[item.id]
      : item.stockBalance

    const count = has(values, item.id) ? values[item.id] : countformInputCoiunt

    return {
      id: item.id,
      nameRus: item.nameRus,
      barcode: item.barcode,
      amount: item.stockBalance,
      purchasePrice: item.purchasePrice,
      unit: item.unit,
      countToWriteOff: (
        <div style={{ position: 'relative' }}>
          <Form.Control
            value={count}
            name={String(item.id)}
            type="number"
            placeholder={t('Amount')}
            onChange={onAmountChange}
            disabled={!has(values, item.id)}
          />

          {count > item.stockBalance && has(values, item.id) && (
            <>
              <Toast
                style={{
                  position: 'absolute',
                  marginTop: '10px',
                  zIndex: 9999,
                }}
              >
                <Toast.Header closeButton={false}>
                  <strong className="me-auto">{t('Warning')}!</strong>
                </Toast.Header>
                <Toast.Body style={{ background: '#F9F9F9' }}>
                  {t('QuantityWarning')}
                </Toast.Body>
              </Toast>
            </>
          )}
        </div>
      ),
      choose: (
        <Form.Check
          type="checkbox"
          name={String(item.id)}
          onChange={(e) => onProductСancellationChange(e, count)}
          checked={has(values, item.id)}
        />
      ),
    }
  })

  return (
    <>
      <TableData data={data} columns={columns} />
      <div className="text-center p-y-2">
        <Button
          variant="primary"
          className="mt-3"
          onClick={onShowModal}
          label={t('WriteOff')}
          disabledField={isEmpty(values)}
        />
      </div>
    </>
  )
}

export default observer(СancellationCreateData)
