import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Spinner, Button } from 'react-bootstrap'
import Select, { ActionMeta } from 'react-select'

import {
  InputFieldType,
  TextareaFieldType,
  CheckFieldType,
  ButtonFieldType,
  SelectFieldType,
  ReactSelectFieldType,
} from './types'

import { ReactSelectValues } from '../../../types'

import isEmpty from '../../../utils/is-empty'

const colourStyles = {
  control: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: '#F9F9F9',
    padding: '0.25rem 0',
    fontSize: '0.875rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: state.isFocused ? '#86c3f7' : '#ced4da',
    borderRadius: '0.375rem',
    boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 135 239 / 25%)' : 'none',
    ':hover': {
      borderColor: '#ced4da',
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    paddingLeft: '1.3125rem',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#6c757d',
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    background: state.isFocused ? '#0D87EF' : '#fff',
    color: state.isFocused ? '#fff' : '#363636',
  }),
  menu: (styles: any) => ({
    ...styles,
    zIndex: 9999,
  }),
}

function ReactSelectComponent({
  name,
  value,
  placeholder,
  label,
  defaultValue,
  isInvalid,
  typeFeedback,
  error,
  isDisabled = false,
  isClearable = false,
  onChange,
  onBlur,
  loadOptions,
}: ReactSelectFieldType): ReactElement {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<ReactSelectValues[]>(
    [] as ReactSelectValues[],
  )
  const [selectedOption, setSelectedOption] =
    useState<ReactSelectValues | null>(null)

  const requestData = async (): Promise<void> => {
    setLoading(true)
    const response = await loadOptions()

    if (response) {
      setOptions(response)
    }

    setLoading(false)
  }

  const handleChange = (
    v: ReactSelectValues | null,
    actionMeta: ActionMeta<ReactSelectValues>,
  ): void => {
    switch (actionMeta.action) {
      case 'select-option':
        if (v !== null) {
          setSelectedOption(v)
          onChange(name, v.value)
        }
        break
      case 'clear':
        if (selectedOption !== null) {
          setSelectedOption(null)
        }
        break

      default:
        break
    }
  }

  const handleBlur = (): void => {
    onBlur(name, true)
  }

  const setCurrentValue = (data: ReactSelectValues[], v: string): void => {
    const currentValue = data.find(
      (item: ReactSelectValues) => item.value === v,
    )

    if (currentValue) {
      setSelectedOption(currentValue)
    }
  }

  useEffect(() => {
    requestData()
  }, [])

  useEffect(() => {
    if (!isEmpty(options)) {
      setCurrentValue(options, value)

      if (defaultValue) {
        setCurrentValue(options, defaultValue)
      }
    }
  }, [options])

  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Select
        name={name}
        options={options}
        placeholder={placeholder}
        value={selectedOption}
        onChange={handleChange}
        onBlur={handleBlur}
        isDisabled={isDisabled}
        isLoading={loading}
        isClearable={isClearable}
        styles={colourStyles}
      />
      <Form.Control.Feedback
        type={typeFeedback}
        className="invalid-feedback-position"
        style={{ display: isInvalid ? 'block' : 'none' }}
      >
        {error}
      </Form.Control.Feedback>
    </>
  )
}

export const ReactSelectField = React.memo(ReactSelectComponent)

function TextareaComponent({
  name,
  placeholder,
  label,
  value,
  onChangeCallback,
  onBlurCallback,
  isInvalid,
  typeFeedback,
  classNameInputField,
  classNameFeedback,
  error,
  disabled = false,
  style,
}: TextareaFieldType): ReactElement {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        name={name}
        placeholder={placeholder}
        value={value}
        onBlur={onBlurCallback}
        onChange={onChangeCallback}
        isInvalid={isInvalid}
        className={classNameInputField}
        disabled={disabled}
        style={style}
      />
      <Form.Control.Feedback type={typeFeedback} className={classNameFeedback}>
        {error}
      </Form.Control.Feedback>
    </>
  )
}

export const TextareaField = React.memo(TextareaComponent)

function InputComponent({
  as = 'input',
  typeField,
  name,
  placeholder,
  label,
  value,
  onChangeCallback,
  onBlurCallback,
  isInvalid,
  typeFeedback,
  classNameInputField,
  classNameFeedback,
  error,
  showHide,
  disabled = false,
  style,
}: InputFieldType): ReactElement {
  const [type, setType] = useState(typeField)

  const toggleShowHide = () => {
    setType(type === 'password' ? 'text' : 'password')
  }

  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as={as}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onBlur={onBlurCallback}
        onChange={onChangeCallback}
        isInvalid={isInvalid}
        className={classNameInputField}
        disabled={disabled}
        style={style}
      />
      <Form.Control.Feedback type={typeFeedback} className={classNameFeedback}>
        {error}
      </Form.Control.Feedback>
      {showHide && (
        <i
          onClick={toggleShowHide}
          className={[
            'show-password-icon',
            `${type === 'password' ? 'bi bi-eye' : 'bi bi-eye-slash'}`,
          ].join(' ')}
        />
      )}
    </>
  )
}

export const InputField = React.memo(InputComponent)

function CheckComponent({
  id,
  required,
  name,
  label,
  isInvalid,
  error,
  onChangeCallback,
  onBlurCallback,
  classNameCheckField,
  checked,
}: CheckFieldType): ReactElement {
  return (
    <>
      <Form.Check
        type="checkbox"
        required={required}
        name={name}
        label={label}
        onBlur={onBlurCallback}
        onChange={onChangeCallback}
        isInvalid={isInvalid}
        feedback={error}
        id={id}
        className={classNameCheckField}
        checked={checked}
      />
    </>
  )
}

export const CheckField = React.memo(CheckComponent)

function ButtonComponent({
  typeField,
  label,
  className,
  classIconName,
  onClick,
  variant,
  disabledField = false,
  loadingField = false,
}: ButtonFieldType): ReactElement {
  const { t } = useTranslation()
  return (
    <>
      {!loadingField ? (
        <Button
          type={typeField}
          className={className}
          onClick={onClick}
          disabled={disabledField}
          variant={variant}
        >
          {classIconName && <i className={classIconName}></i>}
          {label}
        </Button>
      ) : (
        <Button disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="text-white me-2"
          />
          <span className="text-white">{t('Loading')}</span>
        </Button>
      )}
    </>
  )
}

export const ButtonField = React.memo(ButtonComponent)

function SelectComponent({
  label,
  children,
  name,
  value,
  onChangeCallback,
  isInvalid,
  typeFeedback,
  classNameSelectField,
  classNameFeedback,
  error,
  disabled = false,
}: SelectFieldType): ReactElement {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        name={name}
        value={value}
        onChange={onChangeCallback}
        isInvalid={isInvalid}
        className={classNameSelectField}
        disabled={disabled}
      >
        {children}
      </Form.Select>

      <Form.Control.Feedback type={typeFeedback} className={classNameFeedback}>
        {error}
      </Form.Control.Feedback>
    </>
  )
}

export const SelectField = React.memo(SelectComponent)
