import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { EmployeeTableDataProps } from './types'

import TableData from '../../components/ui/TableData'
import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'
import confirm from '../../utils/confirm-alert'

function EmployeeTableData({
  employees,
  onRemove,
  orgId,
}: EmployeeTableDataProps): ReactElement {
  const { t } = useTranslation()

  const columns = [
    {
      name: 'number',
      label: '№',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'personName',
      label: t('FIO'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'departmentName',
      label: t('Department'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'positionName',
      label: t('Position'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'handle',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
  ]

  const data = employees.map((item, index) => ({
    number: index + 1,
    personName: item.personName,
    departmentName: item.departmentName,
    positionName: item.positionName,
    handle: (
      <>
        <Link
          to={getLink(PrivatePaths.EmployeeEdit, {
            [orgId]: PrivatePathsRegexp.orgId,
            [item.id]: PrivatePathsRegexp.employeeId,
          })}
          className="text-success p-2 me-1"
          style={{ fontSize: '18px' }}
        >
          <i className="bi bi-pencil-fill"></i>
        </Link>

        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault()
            confirm(
              Number(item.id),
              t('AreYouSure'),
              t('AreYouSureDeleteEmployee'),
              t('Delete'),
              'lg',
              onRemove,
            )
          }}
          className="text-danger p-2 me-1"
          style={{ fontSize: '18px' }}
        >
          <i className="bi bi-trash"></i>
        </Link>
      </>
    ),
  }))

  return (
    <>
      {!isEmpty(employees) ? (
        <TableData data={data} columns={columns} title={t('EmployeeList')} />
      ) : (
        <p>{t('ListEmployeesIsEmpty')}</p>
      )}
    </>
  )
}

export default observer(EmployeeTableData)
