import { confirmAlert } from 'react-confirm-alert'
import i18n from 'i18next'
import { Modal } from 'react-bootstrap'
import { Button } from '../components/ui/Forms'

export default function confirm(
  id: number,
  title: string,
  text: string,
  buttonLabel: string,
  size: 'sm' | 'lg' | 'xl',
  handleRemove: (id: number) => void,
): any {
  return confirmAlert({
    customUI: ({ onClose }: any) => (
      <Modal.Dialog size={size}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="text-white"
            onClick={onClose}
            label={i18n.t('No')}
          />
          <Button
            variant="primary"
            className="text-white"
            onClick={() => {
              handleRemove(id)
              onClose()
            }}
            label={buttonLabel}
          />
        </Modal.Footer>
      </Modal.Dialog>
    ),
  })
}
