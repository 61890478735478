import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useCounterpartiesStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import CounterpartyForm from './CounterpartyForm'

interface RouteParams {
  orgId: string
}

function CounterpartyCreate({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const counterparty = useCounterpartiesStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      counterparty.counterparties === null ||
      !counterparty.isCounterpartyByOrganizationId(Number(orgId))
    ) {
      counterparty.requestCounterparties({ orgId: Number(orgId) })
    }

    return () => {
      counterparty.setErrors([])
    }
  }, [orgId])

  if (!isEmpty(counterparty.errors)) {
    return <Error errors={counterparty.errors} />
  }

  if (counterparty.counterparties === null || counterparty.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    orgId,
    actualAddress: '',
    bankBik: '',
    bankName: '',
    bc: '',
    bin: '',
    email: '',
    fullName: '',
    shortName: '',
    supervisor: '',
    iic: '',
    legalAddress: '',
    phone: '',
    type: '',
    okved: '',
    id: 0,
    duplication: false,
  }

  const linkList = getLink(PrivatePaths.CounterpartyList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          {
            id: 1,
            title: t('CounterpartyList'),
            link: linkList,
            active: false,
          },
          {
            id: 2,
            title: t('CounterpartyCreate'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('CounterpartyCreate')}</Heading>

      <div className="container-wrapper">
        <CounterpartyForm
          initialValues={initialValues}
          handleService={counterparty.createCounterparty}
          buttonlabel={t('Create')}
          link={linkList}
          responseMessage={t('CounterpartyAddedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(CounterpartyCreate)))
