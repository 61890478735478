import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { Form, Row, Col, Spinner } from 'react-bootstrap'
import * as Yup from 'yup'

import { PrivatePaths, PrivatePathsRegexp } from '../../../router/Routes'

import isEmpty from '../../../utils/is-empty'
import getLink from '../../../utils/get-link'
import { getUnitMeasurement } from '../../../utils/get-unit-measurement'

import { NomenclatureFormProps } from './types'

import {
  Input,
  Button,
  Select,
  ReactSelect,
} from '../../../components/ui/Forms'

import { Alert } from '../../../components/ui/Alert'

function NomenclatureForm({
  initialValues,
  onAddProductsReceived,
  onRequestNomenclature,
  loadOptionsStorage,
  loadOptionsCounterparty,
  nomenclatureIsLoader,
  nomenclatureErrors,
}: NomenclatureFormProps): ReactElement {
  const { t } = useTranslation()

  const schema = Yup.object().shape(
    {
      // Штрих код
      barcode: Yup.string()
        .max(16, ({ max }) => `Максимальное значение поля ${max} символов`)
        .required(t('InputRequiredValidate')),

      // Наименование на русском языке
      nameRus: Yup.string()
        .max(200, ({ max }) => `Максимальное значение поля ${max} символов`)
        .when('nameKaz', {
          is: (nameKaz: string) => !nameKaz,
          then: Yup.string().required(t('InputRequiredValidate')),
        }),

      // Наименование на казахском языке
      nameKaz: Yup.string()
        .max(200, ({ max }) => `Максимальное значение поля ${max} символов`)
        .when('nameRus', {
          is: (nameRus: string) => !nameRus,
          then: Yup.string().required(t('InputRequiredValidate')),
        }),

      // Единица измерения
      units: Yup.string()
        .max(5, ({ max }) => `Максимальное значение поля ${max} символов`)
        .required(t('InputRequiredValidate')),

      // Код товара (артикул)
      vendorCode: Yup.string().max(
        20,
        ({ max }) => `Максимальное значение поля ${max} символов`,
      ),

      // Количество/вес
      amount: Yup.string()
        .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
        .required(t('InputRequiredValidate')),

      // Закупочная цена тенге за единицу
      purchasePrice: Yup.string()
        .max(20, ({ max }) => `Максимальное значение поля ${max} символов`)
        .required(t('InputRequiredValidate')),

      // Список контрагентов
      counterpartyId: Yup.string().required(t('InputRequiredValidate')),

      // Список складов
      storeId: Yup.string().required(t('InputRequiredValidate')),
    },
    [['nameRus', 'nameKaz']],
  )

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onAddProductsReceived}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
        isSubmitting,
        errors,
      }) => (
        <>
          {!isEmpty(nomenclatureErrors)
            ? nomenclatureErrors.map((error: string) => (
                <Alert key={error} variant="danger" text={error} />
              ))
            : null}
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                xl="4"
                lg="12"
                controlId="barcode"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="barcode"
                  placeholder={t('Barcode')}
                  label={t('Barcode')}
                  value={values.barcode}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.barcode && !!errors.barcode}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.barcode}
                  disabled={nomenclatureIsLoader}
                />

                {nomenclatureIsLoader && (
                  <Spinner
                    variant="primary"
                    as="span"
                    animation="border"
                    size="sm"
                    style={{
                      position: 'absolute',
                      top: '2.625rem',
                      right: '4rem',
                      fontSize: '0.875rem',
                    }}
                  />
                )}

                {!isEmpty(values.barcode) && !nomenclatureIsLoader && (
                  <Link
                    to="/"
                    onClick={(e) => {
                      e.preventDefault()
                      onRequestNomenclature({ barcode: values.barcode })
                    }}
                    className="text-primary text-decoration-none me-1"
                    style={{
                      display: 'inline-block',
                      width: 'auto',
                      position: 'absolute',
                      top: '2.5rem',
                      right: '2.5rem',
                      fontSize: '0.875rem',
                    }}
                  >
                    <i className="bi bi-search"></i> Найти
                  </Link>
                )}
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="12"
                controlId="nameRus"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="nameRus"
                  placeholder={t('NameRus')}
                  label={t('NameRus')}
                  value={values.nameRus}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.nameRus && !!errors.nameRus}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.nameRus}
                  disabled
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="12"
                controlId="nameKaz"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="nameKaz"
                  placeholder={t('NameKaz')}
                  label={t('NameKaz')}
                  value={values.nameKaz}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.nameKaz && !!errors.nameKaz}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.nameKaz}
                  disabled
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="3"
                lg="12"
                controlId="vendorCode"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="vendorCode"
                  placeholder={t('VendorCode')}
                  label={t('VendorCode')}
                  value={values.vendorCode}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.vendorCode && !!errors.vendorCode}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.vendorCode}
                  disabled
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="3"
                lg="12"
                controlId="units"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('UnitMeasurement')}
                  name="units"
                  value={String(values.units)}
                  onChangeCallback={handleChange}
                  isInvalid={touched.units && !!errors.units}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.units}
                  disabled
                >
                  <option value="">{t('UnitMeasurement')}</option>
                  {getUnitMeasurement().map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group
                as={Col}
                xl="3"
                lg="12"
                controlId="amount"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="amount"
                  placeholder={t('Amount')}
                  label={t('Amount')}
                  value={values.amount}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.amount && !!errors.amount}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.amount}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="3"
                lg="12"
                controlId="purchasePrice"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="purchasePrice"
                  placeholder={t('PurchasePrice')}
                  label={t('PurchasePrice')}
                  value={values.purchasePrice}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.purchasePrice && !!errors.purchasePrice}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.purchasePrice}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="counterpartyId"
                className="pb-4 position-relative"
              >
                <Link
                  to={getLink(PrivatePaths.CounterpartyCreate, {
                    [values.orgId]: PrivatePathsRegexp.orgId,
                  })}
                  className="text-success counterparty-create"
                >
                  {t('Counterparty link')}
                </Link>
                <ReactSelect
                  name="counterpartyId"
                  value={String(values.counterpartyId)}
                  label={t('SelectCounterparty')}
                  placeholder={t('SelectCounterparty')}
                  isInvalid={touched.counterpartyId && !!errors.counterpartyId}
                  typeFeedback="invalid"
                  error={errors.counterpartyId}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  loadOptions={() =>
                    loadOptionsCounterparty({ orgId: values.orgId })
                  }
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="storeId"
                className="pb-4 position-relative"
              >
                <ReactSelect
                  name="storeId"
                  value={String(values.storeId)}
                  label={t('SelectStorage')}
                  placeholder={t('SelectStorage')}
                  isInvalid={touched.storeId && !!errors.storeId}
                  typeFeedback="invalid"
                  error={errors.storeId}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  loadOptions={() =>
                    loadOptionsStorage({ orgId: values.orgId })
                  }
                />
              </Form.Group>
            </Row>
            <div className="text-center">
              <Button
                typeField="submit"
                label={t('Add')}
                disabledField={isSubmitting}
                loadingField={isSubmitting}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default observer(NomenclatureForm)
