import { ReactElement, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Dropdown,
  Accordion,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, NonAuthPaths } from '../../../router/Routes'

import isEmpty from '../../../utils/is-empty'
import { UserData, UserOrganization } from '../../../services/UserService'

interface NavbarsProps {
  token: JSON
  user: UserData
  organizations: UserOrganization[]
  organization: UserOrganization
  logout: () => void
  setOrganization: (organization: UserOrganization) => void
}

function NavbarHeader({
  token,
  user,
  organizations,
  organization,
  setOrganization,
  logout,
}: NavbarsProps): ReactElement {
  const { t, i18n } = useTranslation()
  const currentLanguageCode = localStorage.getItem('i18nextLng') || 'ru'
  const [languageCode, settLanguageCode] = useState(currentLanguageCode)
  useEffect(() => {
    localStorage.setItem('i18nextLng', languageCode)
    i18n.changeLanguage(languageCode)
  }, [languageCode])

  const handleClick = (code: string): void => {
    settLanguageCode(code)
  }

  const handleChangeOrganization = (org: UserOrganization): void => {
    const accordionButton = document.querySelector(
      '.navbar .user-dropdown .accordion .accordion-button',
    ) as HTMLElement

    if (accordionButton) {
      accordionButton.click()
    }
    setOrganization(org)
  }

  return (
    <Navbar bg="primary" variant="dark" expand="sm">
      <Container>
        <div className="d-flex">
          <Navbar.Brand>ТИС</Navbar.Brand>
        </div>
        <div className="d-flex align-items-center">
          {!isEmpty(token) && <i className="icon user" />}
          <Nav className="ms-3 user-dropdown">
            {!isEmpty(token) && (
              <>
                {!isEmpty(user) && (
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title={`${user.firstName} ${user.lastName}`}
                    menuVariant="light"
                  >
                    {!isEmpty(organization) && (
                      <>
                        <Accordion className="mt-2">
                          <Accordion.Item eventKey="orgs">
                            <Accordion.Header>
                              {organization.orgName}
                            </Accordion.Header>
                            <Accordion.Body>
                              {organizations.map((org) => (
                                <>
                                  {org.orgId !== organization.orgId && (
                                    <NavLink
                                      to="#"
                                      className="nav-link"
                                      onClick={() => {
                                        handleChangeOrganization(org)
                                      }}
                                    >
                                      {org.orgName}
                                    </NavLink>
                                  )}
                                </>
                              ))}
                              <NavDropdown.Divider />
                              <NavLink
                                to={PrivatePaths.OrganizationList}
                                className="nav-link"
                              >
                                {t('Organization list link')}
                              </NavLink>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <NavDropdown.Divider />
                      </>
                    )}

                    {isEmpty(organization) && (
                      <>
                        <NavDropdown.Item
                          as={NavLink}
                          to={PrivatePaths.OrganizationList}
                        >
                          {t('Organization list link')}
                        </NavDropdown.Item>

                        <NavDropdown.Divider />
                      </>
                    )}

                    <NavDropdown.Item as={NavLink} to={PrivatePaths.SignEdit}>
                      {t('My data link')}
                    </NavDropdown.Item>

                    <NavDropdown.Divider />

                    <NavDropdown.Item
                      as={NavLink}
                      to={NonAuthPaths.Login}
                      onClick={logout}
                    >
                      Выход
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </>
            )}
          </Nav>

          <Dropdown id="dropdown-language">
            <Dropdown.Toggle>{languageCode}</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleClick('ru')}>
                Ru
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleClick('en')}>
                En
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleClick('kz')}>
                Kz
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>
    </Navbar>
  )
}

export default observer(NavbarHeader)
