import { FC } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { SignEditPage } from '../pages/User'

import { CertificatePage } from '../pages/Certificate'

import {
  OrganizationCreatePage,
  OrganizationEditPage,
  OrganizationListPage,
} from '../pages/Organization'

import {
  StorageCreatePage,
  StorageEditPage,
  StorageListPage,
} from '../pages/Storage'

import {
  ListPage,
  ReceivePage,
  СancellationListPage,
  MovePage,
  PriceListPage,
  СancellationDetailedPage,
  СancellationCreatePage,
} from '../pages/Tmc'

import {
  KkmCreatePage,
  KkmEditPage,
  KkmListPage,
  KkmAttachPage,
} from '../pages/Kkm'

import {
  CounterpartyCreatePage,
  CounterpartyEditPage,
  CounterpartyListPage,
} from '../pages/Counterparty'

import {
  PersonCreatePage,
  PersonEditPage,
  PersonListPage,
} from '../pages/Person'

import {
  EmployeeCreatePage,
  EmployeeEditPage,
  EmployeeListPage,
} from '../pages/Employee'

import {
  DepartmentCreatePage,
  DepartmentEditPage,
  DepartmentListPage,
} from '../pages/Department'

import {
  PositionCreatePage,
  PositionEditPage,
  PositionListPage,
} from '../pages/Position'

import {
  StaffPositionCreatePage,
  StaffPositionEditPage,
  StaffPositionListPage,
} from '../pages/StaffPosition'

import { PrivatePaths } from './Routes'

const routes = [
  {
    path: PrivatePaths.SignEdit,
    exact: true,
    component: SignEditPage,
  },

  {
    path: PrivatePaths.Certificate,
    exact: true,
    component: CertificatePage,
  },

  {
    path: PrivatePaths.OrganizationCreate,
    exact: true,
    component: OrganizationCreatePage,
  },
  {
    path: PrivatePaths.OrganizationEdit,
    exact: true,
    component: OrganizationEditPage,
  },
  {
    path: PrivatePaths.OrganizationList,
    exact: true,
    component: OrganizationListPage,
  },

  {
    path: PrivatePaths.StorageCreate,
    exact: true,
    component: StorageCreatePage,
  },
  {
    path: PrivatePaths.StorageEdit,
    exact: true,
    component: StorageEditPage,
  },
  {
    path: PrivatePaths.StorageList,
    exact: true,
    component: StorageListPage,
  },

  {
    path: PrivatePaths.TmcList,
    exact: true,
    component: ListPage,
  },
  {
    path: PrivatePaths.TmcReceive,
    exact: true,
    component: ReceivePage,
  },
  {
    path: PrivatePaths.TmcСancellationList,
    exact: true,
    component: СancellationListPage,
  },
  {
    path: PrivatePaths.TmcMove,
    exact: true,
    component: MovePage,
  },
  {
    path: PrivatePaths.TmcPriceList,
    exact: true,
    component: PriceListPage,
  },
  {
    path: PrivatePaths.TmcСancellationDetailed,
    exact: true,
    component: СancellationDetailedPage,
  },
  {
    path: PrivatePaths.TmcСancellationCreate,
    exact: true,
    component: СancellationCreatePage,
  },

  {
    path: PrivatePaths.CounterpartyCreate,
    exact: true,
    component: CounterpartyCreatePage,
  },
  {
    path: PrivatePaths.CounterpartyEdit,
    exact: true,
    component: CounterpartyEditPage,
  },
  {
    path: PrivatePaths.CounterpartyList,
    exact: true,
    component: CounterpartyListPage,
  },

  {
    path: PrivatePaths.KkmCreate,
    exact: true,
    component: KkmCreatePage,
  },
  {
    path: PrivatePaths.KkmEdit,
    exact: true,
    component: KkmEditPage,
  },
  {
    path: PrivatePaths.KkmList,
    exact: true,
    component: KkmListPage,
  },
  {
    path: PrivatePaths.KkmAttach,
    exact: true,
    component: KkmAttachPage,
  },

  {
    path: PrivatePaths.PersonCreate,
    exact: true,
    component: PersonCreatePage,
  },
  {
    path: PrivatePaths.PersonEdit,
    exact: true,
    component: PersonEditPage,
  },
  {
    path: PrivatePaths.PersonList,
    exact: true,
    component: PersonListPage,
  },

  {
    path: PrivatePaths.EmployeeCreate,
    exact: true,
    component: EmployeeCreatePage,
  },
  {
    path: PrivatePaths.EmployeeEdit,
    exact: true,
    component: EmployeeEditPage,
  },
  {
    path: PrivatePaths.EmployeeList,
    exact: true,
    component: EmployeeListPage,
  },

  {
    path: PrivatePaths.DepartmentCreate,
    exact: true,
    component: DepartmentCreatePage,
  },
  {
    path: PrivatePaths.DepartmentEdit,
    exact: true,
    component: DepartmentEditPage,
  },
  {
    path: PrivatePaths.DepartmentList,
    exact: true,
    component: DepartmentListPage,
  },

  {
    path: PrivatePaths.PositionCreate,
    exact: true,
    component: PositionCreatePage,
  },
  {
    path: PrivatePaths.PositionEdit,
    exact: true,
    component: PositionEditPage,
  },
  {
    path: PrivatePaths.PositionList,
    exact: true,
    component: PositionListPage,
  },

  {
    path: PrivatePaths.StaffPositionCreate,
    exact: true,
    component: StaffPositionCreatePage,
  },
  {
    path: PrivatePaths.StaffPositionEdit,
    exact: true,
    component: StaffPositionEditPage,
  },
  {
    path: PrivatePaths.StaffPositionList,
    exact: true,
    component: StaffPositionListPage,
  },
]

export const AppRouter: FC = () => (
  <Switch>
    {routes.map((route) => (
      <Route key={route.path} {...route} />
    ))}
    <Route path="*" render={() => <Redirect to="/404" />} />
  </Switch>
)
