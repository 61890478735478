import { ReactElement } from 'react'

import styles from './Loader.module.scss'

export function Loader(): ReactElement {
  return (
    <div className={styles['loader-container']}>
      <div className={styles.loader}></div>
    </div>
  )
}
