import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

import { useUserStore } from './providers/RootStoreProvider'
import UserService from './services/UserService'

import isEmpty from './utils/is-empty'

import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Sidenav from './components/layout/Sidenav/Sidenav'

import { PrivateRoute } from './router/PrivateRoute'
import { AppRouter } from './router/AppRouter'
import { NonAuthRouter } from './router/NonAuthRouter'
import Page404 from './pages/Page404'
import Page500 from './pages/Page500'

import styles from './App.module.scss'

function App() {
  const renderRootRedirect = () => <Redirect to="/non-auth" />
  const currentUser = UserService.getCurrentUser()
  const user = useUserStore()

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      user.getUser()
    }
  }, [])

  return (
    <>
      <div className="d-flex flex-column justify-content-between height-100vh">
        <Header />
        <div className="table main-layout">
          {!isEmpty(user.currentUser) && (
            <>
              <Sidenav />
              <div className="cell authorized-layout">
                <div className={styles.main}>
                  <Row className="justify-content-sm-center">
                    <Col md="12">
                      <Switch>
                        <Route exact path="/" component={renderRootRedirect} />
                        <PrivateRoute path="/app" component={AppRouter} />
                        <Route path="/non-auth" component={NonAuthRouter} />
                        <Route exact path="/404" render={() => <Page404 />} />
                        <Route exact path="/500" render={() => <Page500 />} />
                        <Route path="*" render={() => <Redirect to="/404" />} />
                      </Switch>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
          {isEmpty(user.currentUser) && (
            <>
              <div className="cell unauthorized-layout">
                <div className={styles.mainNotAuth}>
                  <Container>
                    <Row className="justify-content-sm-center">
                      <Col md="12">
                        <Switch>
                          <Route
                            exact
                            path="/"
                            component={renderRootRedirect}
                          />
                          <PrivateRoute path="/app" component={AppRouter} />
                          <Route path="/non-auth" component={NonAuthRouter} />
                          <Route exact path="/404" render={() => <Page404 />} />
                          <Route exact path="/500" render={() => <Page500 />} />
                          <Route
                            path="*"
                            render={() => <Redirect to="/404" />}
                          />
                        </Switch>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  )
}

export default observer(App)
