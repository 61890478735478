import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../../router/Routes'

import {
  useStoragesStore,
  useTmcPriceListStore,
} from '../../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../../hoc/withAuthRedirect'

import isEmpty from '../../../utils/is-empty'
import getLink from '../../../utils/get-link'

import { Heading } from '../../../components/ui/Typography/Typography'
import { Loader } from '../../../components/ui/Loader'
import { Breadcrumb } from '../../../components/ui/Breadcrumb'
import { Error } from '../../../components/ui/Error'

import PriceListData from './PriceListData'

interface RouteParams {
  orgId: string
  storeId: string
}

function PriceList({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const storage = useStoragesStore()
  const priceList = useTmcPriceListStore()

  const { orgId, storeId } = params as RouteParams

  useEffect(() => {
    storage.fillCurrentStorage(Number(storeId))
    priceList.requestPriceList({ storeId: Number(storeId) })

    return () => {
      storage.clearCurrentStorage()
      priceList.setErrors([])
    }
  }, [storeId])

  if (!isEmpty(storage.errors)) {
    return <Error errors={storage.errors} />
  }

  if (!isEmpty(priceList.errors)) {
    return <Error errors={priceList.errors} />
  }

  if (
    isEmpty(storage.currentStorage) ||
    priceList.priceList === null ||
    priceList.isLoader
  ) {
    return <Loader />
  }

  const linkList = getLink(PrivatePaths.TmcList, {
    [orgId]: PrivatePathsRegexp.orgId,
    [storeId]: PrivatePathsRegexp.storeId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          {
            id: 1,
            title: t('TmcList'),
            link: linkList,
            active: false,
          },
          {
            id: 2,
            title: storage.currentStorage.name,
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{storage.currentStorage.name}</Heading>
      <p className="text-center">{storage.currentStorage.address}</p>

      {!isEmpty(priceList.priceList) ? (
        <PriceListData
          products={priceList.priceList}
          title={`Прайс-лист (склад ${storage.currentStorage.name})`}
        />
      ) : (
        <p>{t('ListPriceListIsEmpty')}</p>
      )}
    </>
  )
}

export default withRouter(withAuthRedirect(observer(PriceList)))
