import { ReactElement, ReactNode } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '../Forms'

interface IModal {
  children: ReactNode
  title: string
  isButtonOk?: boolean
  isButtonClose?: boolean
  show: boolean
  size?: 'sm' | 'lg' | 'xl'
  labelButtonOk?: string
  labelButtonClose?: string
  handleModalOnHide?: () => void
  handleButtonOk?: () => void
  handleButtonClose?: () => void
}

export function ModalComponent({
  children,
  title,
  isButtonOk = false,
  isButtonClose = false,
  show,
  size,
  labelButtonOk,
  labelButtonClose,
  handleModalOnHide,
  handleButtonOk,
  handleButtonClose,
}: IModal): ReactElement {
  return (
    <Modal show={show} onHide={handleModalOnHide} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {!isButtonOk && !isButtonClose ? null : (
        <Modal.Footer>
          {isButtonClose && (
            <Button
              variant="secondary"
              onClick={handleButtonClose}
              label={labelButtonClose}
            />
          )}
          {isButtonOk && (
            <Button
              variant="primary"
              onClick={handleButtonOk}
              label={labelButtonOk}
            />
          )}
        </Modal.Footer>
      )}
    </Modal>
  )
}
