import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import TmcService, {
  Product,
  StoreIdsForMove,
  ProductMoved,
  ProductMoving,
  ProductValueCounts,
} from '../services/TmcService'

import { StorageId } from '../services/StorageService'

import getErrorsMapping from '../utils/get-errors-mapping'

import omit from '../utils/omit'

export class TmcMoveStore {
  errors: string[] = []

  isLoader = false

  inputValueCounts: ProductValueCounts = {}

  // Товары для перемещение
  productsForMove: Product[] = []

  // Перемещенные товары
  productsMoved: ProductMoved = {
    storeIds: {
      storeIdSrc: 0,
      storeIdDest: 0,
    },
    values: {},
  }

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      errors: observable,
      isLoader: observable,

      // Количество товара для id товара
      inputValueCounts: observable,

      // Товары для перемещения
      productsForMove: observable,

      // Перемещенные товары
      productsMoved: observable,

      setErrors: action.bound,

      isLoaderChanged: action.bound,

      // Создать количество товара для id товара
      createInputValueCounts: action.bound,
      // Добавить количество товара для id товара
      setInputValueCounts: action.bound,
      // Очистить количество товара
      clearInputValueCounts: action.bound,

      // Получить список товаров
      requestProducts: action.bound,
      // Переместить товар со склада на склад
      moveProducts: action.bound,

      // Добавление товаров для перемещения
      setProductsForMove: action.bound,
      // Добавление перемещенных товаров
      setProductsMoved: action.bound,
      // Добавление id складов
      setStoreIdsForMoved: action.bound,
      // Добавление id и количество перемещенных товаров
      setValueCountsForMoved: action.bound,
      // Удаление id и количество из перемещенных товаров
      deleteValueCountsForMoved: action.bound,
      // Очистить перемещенные товары
      clearProductsMoved: action.bound,
    })

    this.rootStore = rootStore
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  createInputValueCounts(payload: ProductValueCounts): void {
    this.inputValueCounts = payload
  }

  setInputValueCounts(productId: number, count: number): void {
    this.inputValueCounts = { ...this.inputValueCounts, [productId]: count }
  }

  clearInputValueCounts(): void {
    this.inputValueCounts = {}
  }

  // Добавить товары для перемещения
  setProductsForMove(payload: Product[]): void {
    this.productsForMove = payload
  }

  // Добавить перемещенные товары
  setProductsMoved(payload: ProductMoved): void {
    this.productsMoved = payload
  }

  // Добавить айдишники складов для перемещения товаров
  setStoreIdsForMoved(payload: StoreIdsForMove): void {
    this.productsMoved = {
      ...this.productsMoved,
      storeIds: {
        storeIdSrc: payload.storeIdSrc,
        storeIdDest: payload.storeIdDest,
      },
    }
  }

  // Добавить айдишники товаров с количеством для перемещения товаров
  setValueCountsForMoved(payload: ProductValueCounts): void {
    this.productsMoved = {
      ...this.productsMoved,
      values: { ...this.productsMoved.values, ...payload },
    }
  }

  // Удалить айдишники товаров с количеством для перемещения товаров
  deleteValueCountsForMoved(productId: number): void {
    this.productsMoved = {
      ...this.productsMoved,
      values: omit(this.productsMoved.values, [productId]),
    }
  }

  // Очистить id складов и id и количество перемещенных товаров
  clearProductsMoved(): void {
    this.productsMoved = {
      storeIds: {
        storeIdSrc: 0,
        storeIdDest: 0,
      },
      values: {},
    }
    this.productsForMove = []
  }

  // Получить список товаров
  async requestProducts(payload: StorageId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await TmcService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.setProductsForMove(json)
      }
    } catch (e: any) {
      console.log(
        `Class TmcMoveStore, method requestProducts, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  // Переместить товары со склада на склад
  async moveProducts(
    payload: ProductMoving,
    localStorageName: string,
  ): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await TmcService.move(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      if (response.status === 400) {
        const json = await response.json()
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.clearProductsMoved()

        this.clearInputValueCounts()

        TmcService.removeLocalMovedProducts(localStorageName)
      }
    } catch (e: any) {
      console.log(
        `Class TmcMoveStore, method moveProducts, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }
}
