import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useStoragesStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import StorageForm from './StorageForm'

interface RouteParams {
  orgId: string
}

function StorageCreate({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const storage = useStoragesStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      storage.storages === null ||
      !storage.isStorageByOrganizationId(Number(orgId))
    ) {
      storage.requestStorages({ orgId: Number(orgId) })
    }

    return () => {
      storage.setErrors([])
    }
  }, [orgId])

  if (!isEmpty(storage.errors)) {
    return <Error errors={storage.errors} />
  }

  if (storage.storages === null || storage.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    orgId: Number(orgId),
    name: '',
    address: '',
    type: '',
    storeId: 0,
  }

  const linkList = getLink(PrivatePaths.StorageList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('StorageList'), link: linkList, active: false },
          {
            id: 2,
            title: t('StorageCreate'),
            link: '/',
            active: true,
          },
        ]}
      />

      <div className="container-wrapper">
        <StorageForm
          initialValues={initialValues}
          handleService={storage.createStorage}
          buttonlabel={t('Create')}
          link={linkList}
          responseMessage={t('StorageAddedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(StorageCreate)))
