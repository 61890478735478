import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { ProductReceived } from '../../../services/TmcService'

import { ProductReceivedDataProps } from './types'
import { TableDataDeleted } from '../../../types'

import TableData from '../../../components/ui/TableData'
import { Button } from '../../../components/ui/Forms'

function ReceiveData({
  products,
  onUploadProductsReceived,
  onRemoveProductsReceived,
}: ProductReceivedDataProps): ReactElement {
  const { t } = useTranslation()

  const columns = [
    {
      name: 'nameRus',
      label: t('NameRus'),
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'nameKaz',
      label: t('NameKaz'),
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'barcode',
      label: t('Barcode'),
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'vendorCode',
      label: t('VendorCode'),
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'amount',
      label: t('Amount'),
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'purchasePrice',
      label: t('PurchasePrice'),
      options: {
        filter: false,
        sort: false,
      },
    },
  ]

  const options = {
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: false,
    onRowsDelete: (rowsDeleted: TableDataDeleted) => {
      if (onRemoveProductsReceived) {
        onRemoveProductsReceived(rowsDeleted.data)
      }
    },
  }

  const data = products.map((item: ProductReceived) => ({
    nameRus: item.nameRus,
    nameKaz: item.nameKaz,
    barcode: item.barcode,
    vendorCode: item.vendorCode,
    amount: item.amount,
    purchasePrice: item.purchasePrice,
  }))

  return (
    <>
      <TableData data={data} columns={columns} tableOptions={options} />
      <div className="text-center my-3">
        <Button
          variant="primary"
          onClick={onUploadProductsReceived}
          label={t('Save')}
        />
      </div>
    </>
  )
}

export default observer(ReceiveData)
