import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { Сancellation } from '../../../services/TmcService'

import { СancellationLisrDataProps } from './types'

import TableData from '../../../components/ui/TableData'
import { getDateTimeOfString } from '../../../utils/get-date'

function СancellationListData({
  Сancellations,
}: СancellationLisrDataProps): ReactElement {
  const { t } = useTranslation()

  const columns = [
    {
      name: 'operationId',
      label: t('OperationNumber'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'operationDate',
      label: t('Date'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'storeNameDest',
      label: t('StorageName'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'employee',
      label: t('EmployeeName'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'operationComment',
      label: 'Комментарий',
      options: {
        filter: false,
        sort: false,
      },
    },
  ]

  const data = Сancellations.map((item: Сancellation) => ({
    operationId: item.operationId,
    operationDate: getDateTimeOfString(item.operationDate),
    storeNameDest: item.storeNameDest,
    employee: `${item.userLastName} ${item.userFirstName} ${item.userPatName}`,
    operationComment: item.operationComment,
  }))

  return (
    <>
      <TableData data={data} columns={columns} />
    </>
  )
}

export default observer(СancellationListData)
