import { ReactSelectValues } from '../types'

interface BankData {
  id: string
  bik: string
  name: string
}

const banks: BankData[] = [
  {
    id: 'ATYNKZKA',
    bik: 'ATYNKZKA',
    name: 'Altyn Bank',
  },
  {
    id: 'KINCKZKA',
    bik: 'KINCKZKA',
    name: 'Bank RBK',
  },
  {
    id: 'TBKBKZKA',
    bik: 'TBKBKZKA',
    name: 'Capital Bank Kazakhstan',
  },
  {
    id: 'TSESKZKA',
    bik: 'TSESKZKA',
    name: 'First Heartland Jýsan Bank',
  },
  {
    id: 'IRTYKZKA',
    bik: 'IRTYKZKA',
    name: 'ForteBank',
  },
  {
    id: 'CASPKZKA',
    bik: 'CASPKZKA',
    name: 'Kaspi Bank',
  },
  {
    id: 'ALMNKZKA',
    bik: 'ALMNKZKA',
    name: 'АТФБанк',
  },
  {
    id: 'KSNVKZKA',
    bik: 'KSNVKZKA',
    name: 'Банк Kassa Nova',
  },
  {
    id: 'KCJBKZKX',
    bik: 'KCJBKZKX',
    name: 'Банк ЦентрКредит',
  },
  {
    id: 'ALFAKZKA',
    bik: 'ALFAKZKA',
    name: 'ДБ "Альфа-Банк"',
  },
  {
    id: 'BKCHKZKA',
    bik: 'BKCHKZKA',
    name: 'ДБ "Банк Китая в Казахстане"',
  },
  {
    id: 'KZIBKZKA',
    bik: 'KZIBKZKA',
    name: 'ДБ «КЗИ Банк»',
  },
  {
    id: 'NBPAKZKA',
    bik: 'NBPAKZKA',
    name: 'ДБ «Национальный Банк Пакистана»',
  },
  {
    id: 'INLMKZKA',
    bik: 'INLMKZKA',
    name: 'ДБ АО "Банк Хоум Кредит"',
  },
  {
    id: 'SABRKZKA',
    bik: 'SABRKZKA',
    name: 'ДБ АО «Сбербанк России»',
  },
  {
    id: 'VTBAKZKZ',
    bik: 'VTBAKZKZ',
    name: 'ДО АО Банк ВТБ (Казахстан)',
  },
  {
    id: 'EURIKZKA',
    bik: 'EURIKZKA',
    name: 'Евразийский Банк',
  },
  {
    id: 'ZAJSKZ22',
    bik: 'ZAJSKZ22',
    name: 'Заман-Банк',
  },
  {
    id: 'HLALKZKZ',
    bik: 'HLALKZKZ',
    name: 'Исламский Банк "Al-Hilal"',
  },
  {
    id: 'HSBKKZKX',
    bik: 'HSBKKZKX',
    name: 'Народный сберегательный банк Казахстана',
  },
  {
    id: 'NURSKZKX',
    bik: 'NURSKZKX',
    name: 'Нурбанк',
  },
  {
    id: 'HCSKKZKA',
    bik: 'HCSKKZKA',
    name: 'Отбасы банк',
  },
  {
    id: 'CITIKZKA',
    bik: 'CITIKZKA',
    name: 'Ситибанк Казахстан',
  },
  {
    id: 'ICBKKZKX',
    bik: 'ICBKKZKX',
    name: 'ТП Банк Китая в Алматы',
  },
  {
    id: 'SHBKKZKA',
    bik: 'SHBKKZKA',
    name: 'Шинхан Банк Казахстан',
  },
]

export const getBanksBiks = async (): Promise<ReactSelectValues[]> =>
  banks.map(
    ({ name }: BankData): ReactSelectValues => ({
      value: String(name),
      label: String(name),
    }),
  )

export const getBankName = (field: string): string => {
  const data: BankData | undefined = banks.find((item) => item.name === field)

  if (data) {
    return data.bik
  }

  return field
}
