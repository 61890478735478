import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { range } from 'lodash'

import { FilterFields } from '../../../services/TmcService'

import { СancellationFilterFormProps } from './types'

import { ReactSelect } from '../../../components/ui/Forms'
import { DatePicker } from '../../../components/ui/DatePicker'

function СancellationFilterForm({
  initialValues,
  setFilterValues,
  loadOptionsStorage,
}: СancellationFilterFormProps): ReactElement {
  const { t } = useTranslation()

  const date = new Date()
  const yearsArray = range(1970, date.getFullYear() + 11)

  const schema = Yup.object().shape({
    // Дата операции от
    periodStart: Yup.string(),

    // Дата операции до
    periodEnd: Yup.string(),

    // Список складов
    storeId: Yup.string(),
  })

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        console.log(values, actions)
      }}
      enableReinitialize
    >
      {({
        handleSubmit,
        touched,
        values,
        errors,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          <Form noValidate onSubmit={handleSubmit} className="mb-3">
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="4"
                controlId="storeId"
                className="pb-4 position-relative"
              >
                <ReactSelect
                  name="storeId"
                  value={String(values.storeId)}
                  label={t('StorageList')}
                  placeholder={t('StorageList')}
                  isInvalid={touched.storeId && !!errors.storeId}
                  typeFeedback="invalid"
                  error={errors.storeId}
                  onChange={(field: string, value: string) => {
                    setFieldValue(field, value)
                    setFilterValues(FilterFields.storeId, value)
                  }}
                  onBlur={setFieldTouched}
                  loadOptions={() =>
                    loadOptionsStorage({ orgId: values.orgId })
                  }
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md="4"
                controlId="periodStart"
                className="pb-4 position-relative"
              >
                <DatePicker
                  name="periodStart"
                  valueText={String(values.periodStart)}
                  placeholder={t('DateWriteOffFrom')}
                  onChange={(field: string, value: string) => {
                    setFieldValue(field, value)
                    setFilterValues(FilterFields.periodStart, value)
                  }}
                  years={yearsArray}
                  label={t('DateWriteOffFrom')}
                  isInvalid={touched.periodStart && !!errors.periodStart}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.periodStart}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md="4"
                controlId="periodEnd"
                className="pb-4 position-relative"
              >
                <DatePicker
                  name="periodEnd"
                  valueText={String(values.periodEnd)}
                  placeholder={t('DateWriteOffBefore')}
                  onChange={(field: string, value: string) => {
                    setFieldValue(field, value)
                    setFilterValues(FilterFields.periodEnd, value)
                  }}
                  years={yearsArray}
                  label={t('DateWriteOffBefore')}
                  isInvalid={touched.periodEnd && !!errors.periodEnd}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.periodEnd}
                />
              </Form.Group>
            </Row>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default observer(СancellationFilterForm)
