import { KkmAPI } from './ApiUrls'
import httpClient from './httpClient'

import { StorageId } from './StorageService'

export interface KkmId {
  id: number
}

export interface KkmLinkId extends KkmId, StorageId {}

export interface Kkm extends KkmId {
  name: string
  ofdId: number
  linked: boolean
}

export class KkmService {
  list = async (): Promise<Response> => {
    const result = await httpClient.post(KkmAPI.List, {})

    return result
  }

  add = async (model: Kkm): Promise<Response> => {
    const result = await httpClient.post(KkmAPI.Add, model)

    return result
  }

  edit = async (model: Kkm): Promise<Response> => {
    const result = await httpClient.post(KkmAPI.Edit, model)

    return result
  }

  delete = async (model: KkmId): Promise<Response> => {
    const result = await httpClient.post(KkmAPI.Delete, model)

    return result
  }

  link = async (model: KkmLinkId): Promise<Response> => {
    const result = await httpClient.post(KkmAPI.Link, model)

    return result
  }

  get = async (model: KkmId): Promise<Response> => {
    const result = await httpClient.post(KkmAPI.Get, model)

    return result
  }
}

export default new KkmService()
