import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import {
  useStaffPositionsStore,
  useDepartmentsStore,
  usePositionsStore,
} from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import StaffPositionForm from './StaffPositionForm'

interface RouteParams {
  orgId: string
  staffPositionId: string
}

function StaffPositionEdit({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const staffPosition = useStaffPositionsStore()
  const department = useDepartmentsStore()
  const position = usePositionsStore()

  const { orgId, staffPositionId } = params as RouteParams

  useEffect(() => {
    if (
      staffPosition.staffPositions === null ||
      !staffPosition.isStaffPositionByOrganizationId(Number(orgId))
    ) {
      staffPosition.requestStaffPositions({
        orgId: Number(orgId),
      })
    }

    staffPosition.fillCurrentStaffPosition({
      id: Number(staffPositionId),
    })

    return () => {
      staffPosition.setErrors([])
      staffPosition.clearCurrentStaffPosition()
    }
  }, [orgId])

  if (!isEmpty(staffPosition.errors)) {
    return <Error errors={staffPosition.errors} />
  }

  if (isEmpty(staffPosition.currentStaffPosition) || position.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    id: staffPosition.currentStaffPosition.id,
    orgId: staffPosition.currentStaffPosition.orgId,
    approvalDate: staffPosition.currentStaffPosition.approvalDate,
    departmentId: String(staffPosition.currentStaffPosition.departmentId),
    positionId: String(staffPosition.currentStaffPosition.positionId),
    positionCount: String(staffPosition.currentStaffPosition.positionCount),
    salary: String(staffPosition.currentStaffPosition.salary),
    salaryFund: String(staffPosition.currentStaffPosition.salaryFund),
    vacantUnitsCount: String(
      staffPosition.currentStaffPosition.vacantUnitsCount,
    ),
    workScheduleId: String(staffPosition.currentStaffPosition.workScheduleId),
  }

  const linkList = getLink(PrivatePaths.StaffPositionList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          {
            id: 1,
            title: t('StaffPositionList'),
            link: linkList,
            active: false,
          },
          {
            id: 2,
            title: t('StaffPositionEdit'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('StaffPositionEdit')}</Heading>

      <div className="container-wrapper">
        <StaffPositionForm
          initialValues={initialValues}
          handleService={staffPosition.updateStaffPosition}
          buttonlabel={t('Edit')}
          responseMessage={t('StaffPositionEditedSuccessfully')}
          loadOptionsPosition={position.requestOptionsPosition}
          loadOptionsDepartment={department.requestOptionsDepartment}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(StaffPositionEdit)))
