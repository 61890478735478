import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { Container, Row, Col } from 'react-bootstrap'

import styles from './Footer.module.scss'

function Footer(): ReactElement {
  const dateNow = new Date()

  return (
    <div className={styles.footer}>
      <Container>
        <Row>
          <Col md="6" lg="4">
            <div>
              <p>
                <span style={{ color: '#757575' }}>
                  По вопросам сотрудничества:
                </span>
                <br />
                Телефон: +7 (7172) 20 59 23
              </p>
              <p>Электронный адрес: info@ismet.kz</p>
              <p>
                <span style={{ color: '#757575' }}>По вопросам ОФД:</span>
                <br /> +7 (7172) 59 00 51
                <br />
                +7 (727) 259 00 51
                <br />8 8000 8000 51
              </p>
            </div>
          </Col>
          <Col md="6" lg="4">
            <p style={{ color: '#757575' }}>По вопросам маркировки товаров:</p>
            <p>
              Телефон: 8 800 080 6565
              <br />
              (Бесплатно с мобильных и городских номеров)
            </p>
            <p>
              График работы: 24/7
              <br />
              Электронный адрес: mark@ismet.kz
            </p>
          </Col>
          <Col md="12" lg="4">
            <p style={{ color: '#757575' }}>По вопросам работы с платформой:</p>
            <p>
              Телефон: 8 800 080 0807 (Бесплатно с мобильных и городских
              номеров)
            </p>
            <p>
              <span style={{ color: '#757575' }}>График работы:</span>
              <br />с 9:00 до 18:00 в будни
            </p>
            <p>
              <span style={{ color: '#757575' }}>Электронный адрес:</span>
              <br />
              support@ismet.kz
            </p>
          </Col>
        </Row>
        <Row className={styles.copyright}>
          <Col md="12">
            &copy; ISMET.KZ, {dateNow.getFullYear()}. Все права защищены.
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default observer(Footer)
