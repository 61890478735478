import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { Form, Row, Col } from 'react-bootstrap'
import * as Yup from 'yup'

import { CertificateStatus } from '../../services/CertificateService'
import { CertificateFormProps, InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import isValidCertificate from '../../utils/is-valid-certificate'

import { StatusForm } from '../../types'

import { Input, Button, Select } from '../../components/ui/Forms'
import { Alert } from '../../components/ui/Alert'

export default function CertificateForm({
  initialValues,
  handleService,
  buttonlabel,
  link,
  requestCertificate,
  certificateData,
  certificateStatus,
}: CertificateFormProps): ReactElement {
  const history = useHistory()
  const { t } = useTranslation()

  const schema = Yup.object().shape({
    // Вид сертификата
    storageSelect: Yup.string().required(t('InputRequiredValidate')),

    // Алиас ключа
    alias: Yup.string().required(t('InputRequiredValidate')),

    // Идентификатор ключа
    keyId: Yup.string().required(t('InputRequiredValidate')),

    // Алгоритм ключа
    algorithm: Yup.string().required(t('InputRequiredValidate')),

    // Общепринятое имя субъекта
    subjectCn: Yup.string().required(t('InputRequiredValidate')),

    // Уникальное имя субъекта
    subjectDn: Yup.string().required(t('InputRequiredValidate')),

    // Общепринятое имя издателя
    issuerCn: Yup.string().required(t('InputRequiredValidate')),

    // Уникальное имя издателя
    issuerDn: Yup.string().required(t('InputRequiredValidate')),

    // Серийный номер сертификата
    serialNumber: Yup.string().required(t('InputRequiredValidate')),

    // Дата начала действия сертификата
    certNotBefore: Yup.string().required(t('InputRequiredValidate')),

    // Дата окончания действия сертификата
    certNotAfter: Yup.string().required(t('InputRequiredValidate')),

    // Идентификатор ключа УЦ
    authorityKeyIdentifier: Yup.string().required(t('InputRequiredValidate')),

    // Сертификат в формате PEM
    pem: Yup.string().required(t('InputRequiredValidate')),
  })

  const handleSubmitForm = async (values: InitialValues): Promise<void> => {
    const payload = {
      storageSelect: values.storageSelect,
      alias: values.alias,
      keyId: values.keyId,
      algorithm: values.algorithm,
      subjectCn: values.subjectCn,
      subjectDn: values.subjectDn,
      issuerCn: values.issuerCn,
      issuerDn: values.issuerDn,
      serialNumber: values.serialNumber,
      certNotAfter: values.certNotAfter,
      certNotBefore: values.certNotBefore,
      authorityKeyIdentifier: values.authorityKeyIdentifier,
      pem: values.pem,
    }

    handleService(payload)

    if (link) {
      history.push(link)
    }
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        values,
        status,
        isSubmitting,
        errors,
      }) => (
        <>
          {certificateStatus === CertificateStatus.error ? (
            <Alert variant="danger" text={t('ErrorСonnectingNCALayer')} />
          ) : null}

          {certificateStatus === CertificateStatus.closed ? (
            <Alert variant="warning" text={t('ConnectionClosedNCALayer')} />
          ) : null}

          {!isEmpty(status) && status.type === StatusForm.warning
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="warning" text={error} />
              ))
            : null}
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="12"
                controlId="storageSelect"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('StorageSelect')}
                  name="storageSelect"
                  value={values.storageSelect}
                  onChangeCallback={handleChange}
                  isInvalid={touched.storageSelect && !!errors.storageSelect}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.storageSelect}
                >
                  <option key="PKCS12" value="PKCS12">
                    PKCS12
                  </option>
                </Select>
              </Form.Group>

              <div className="text-center">
                <Button
                  typeField="button"
                  label={t('GetKeyData')}
                  onClick={() => requestCertificate(values.storageSelect)}
                  disabledField={
                    certificateStatus === CertificateStatus.pending ||
                    certificateStatus === CertificateStatus.error
                  }
                  loadingField={certificateStatus === CertificateStatus.pending}
                />
              </div>
            </Row>
            {!isEmpty(certificateData) && (
              <>
                {!isValidCertificate(Number(certificateData.certNotAfter)) && (
                  <Alert variant="danger" text={t('CertificateExpired')} />
                )}

                {certificateData.serialNumber ===
                  '6724a08ccd56299de8c8896cd1f5d667af3807ee' && (
                  <Alert variant="danger" text={t('CertificateRevoked')} />
                )}
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="alias"
                    className="pb-4 position-relative"
                  >
                    <Input
                      typeField="text"
                      name="alias"
                      placeholder={t('Alias')}
                      label={t('Alias')}
                      value={values.alias}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={touched.alias && !!errors.alias}
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.alias}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="keyId"
                    className="pb-4 position-relative"
                  >
                    <Input
                      typeField="text"
                      name="keyId"
                      placeholder={t('KeyId')}
                      label={t('KeyId')}
                      value={values.keyId}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={touched.keyId && !!errors.keyId}
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.keyId}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="algorithm"
                    className="pb-4 position-relative"
                  >
                    <Input
                      typeField="text"
                      name="algorithm"
                      placeholder={t('Algorithm')}
                      label={t('Algorithm')}
                      value={values.algorithm}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={touched.algorithm && !!errors.algorithm}
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.algorithm}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="subjectCn"
                    className="pb-4 position-relative"
                  >
                    <Input
                      typeField="text"
                      name="subjectCn"
                      placeholder={t('SubjectCn')}
                      label={t('SubjectCn')}
                      value={values.subjectCn}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={touched.subjectCn && !!errors.subjectCn}
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.subjectCn}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="subjectDn"
                    className="pb-4 position-relative"
                  >
                    <Input
                      typeField="text"
                      name="subjectDn"
                      placeholder={t('SubjectDn')}
                      label={t('SubjectDn')}
                      value={values.subjectDn}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={touched.subjectDn && !!errors.subjectDn}
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.subjectDn}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="issuerCn"
                    className="pb-4 position-relative"
                  >
                    <Input
                      typeField="text"
                      name="issuerCn"
                      placeholder={t('IssuerCn')}
                      label={t('IssuerCn')}
                      value={values.issuerCn}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={touched.issuerCn && !!errors.issuerCn}
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.issuerCn}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="issuerDn"
                    className="pb-4 position-relative"
                  >
                    <Input
                      typeField="text"
                      name="issuerDn"
                      placeholder={t('IssuerDn')}
                      label={t('IssuerDn')}
                      value={values.issuerDn}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={touched.issuerDn && !!errors.issuerDn}
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.issuerDn}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="serialNumber"
                    className="pb-4 position-relative"
                  >
                    <Input
                      typeField="text"
                      name="serialNumber"
                      placeholder={t('SerialNumber')}
                      label={t('SerialNumber')}
                      value={values.serialNumber}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={touched.serialNumber && !!errors.serialNumber}
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.serialNumber}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="certNotBefore"
                    className="pb-4 position-relative"
                  >
                    <Input
                      typeField="text"
                      name="certNotBefore"
                      placeholder={t('CertNotBefore')}
                      label={t('CertNotBefore')}
                      value={values.certNotBefore}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={
                        touched.certNotBefore && !!errors.certNotBefore
                      }
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.certNotBefore}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="certNotAfter"
                    className="pb-4 position-relative"
                  >
                    <Input
                      typeField="text"
                      name="certNotAfter"
                      placeholder={t('CertNotAfter')}
                      label={t('CertNotAfter')}
                      value={values.certNotAfter}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={touched.certNotAfter && !!errors.certNotAfter}
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.certNotAfter}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="authorityKeyIdentifier"
                    className="pb-4 position-relative"
                  >
                    <Input
                      typeField="text"
                      name="authorityKeyIdentifier"
                      placeholder={t('AuthorityKeyIdentifier')}
                      label={t('AuthorityKeyIdentifier')}
                      value={values.authorityKeyIdentifier}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={
                        touched.authorityKeyIdentifier &&
                        !!errors.authorityKeyIdentifier
                      }
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.authorityKeyIdentifier}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="pem"
                    className="pb-4 position-relative"
                  >
                    <Input
                      as="textarea"
                      typeField="text"
                      name="pem"
                      placeholder={t('Pem')}
                      label={t('Pem')}
                      value={values.pem}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={touched.pem && !!errors.pem}
                      typeFeedback="invalid"
                      classNameFeedback="invalid-feedback-position"
                      error={errors.pem}
                      disabled
                      style={{ height: '200px' }}
                    />
                  </Form.Group>
                </Row>
                <div className="text-center">
                  <Button
                    typeField="submit"
                    label={buttonlabel}
                    disabledField={
                      isSubmitting ||
                      !isValidCertificate(
                        Number(certificateData.certNotAfter),
                      ) ||
                      certificateData.serialNumber ===
                        '6724a08ccd56299de8c8896cd1f5d667af3807ee'
                    }
                    loadingField={isSubmitting}
                  />
                </div>
              </>
            )}
          </Form>
        </>
      )}
    </Formik>
  )
}
