import { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { useOrganizationsStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { TypesForm } from '../../types'
import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'

import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import OrganizationForm from './OrganizationForm'
import PhoneClear from '../../utils/phone-clear'

interface RouteParams {
  orgId: string
}

function OrganizationEdit(): ReactElement {
  const { t } = useTranslation()

  const organization = useOrganizationsStore()

  const params = useParams<RouteParams>()
  const { orgId } = params as RouteParams

  useEffect(() => {
    if (organization.organizations === null) {
      organization.requestOrganizations()
    }

    organization.fillCurrentOrganization(Number(orgId))

    return () => {
      organization.setErrors([])
      organization.clearCurrentOrganization()
    }
  }, [])

  if (!isEmpty(organization.errors)) {
    return <Error errors={organization.errors} />
  }

  if (isEmpty(organization.currentOrganization) || organization.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    orgId: organization.currentOrganization.orgId,
    actualAddress: organization.currentOrganization.actualAddress,
    bik: organization.currentOrganization.bik,
    bankName: organization.currentOrganization.bankName,
    bc: String(organization.currentOrganization.bc),
    type: String(organization.currentOrganization.type),
    bin: organization.currentOrganization.bin,
    email: organization.currentOrganization.email,
    iik: organization.currentOrganization.iik,
    legalAddress: organization.currentOrganization.legalAddress,
    okved: organization.currentOrganization.okved,
    orgName: organization.currentOrganization.orgName,
    phone: PhoneClear(organization.currentOrganization.phone),
    supervisor: organization.currentOrganization.supervisor,
    duplication:
      organization.currentOrganization.actualAddress ===
      organization.currentOrganization.legalAddress,
  }

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('OrganizationList'), link: '/', active: false },
          {
            id: 2,
            title: t('OrganizationEdit'),
            link: '/',
            active: true,
          },
        ]}
      />

      <div className="container-wrapper">
        <OrganizationForm
          initialValues={initialValues}
          handleService={organization.updateOrganization}
          typeForm={TypesForm.editing}
          buttonlabel={t('Edit')}
          responseMessage={t('OrganizationEditedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withAuthRedirect(observer(OrganizationEdit))
