import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useDepartmentsStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import DepartmentForm from './DepartmentForm'

interface RouteParams {
  orgId: string
}

function DepartmentCreate({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const department = useDepartmentsStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      department.departments === null ||
      !department.isDepartmentByOrganizationId(Number(orgId))
    ) {
      department.requestDepartments({ orgId: Number(orgId) })
    }

    return () => {
      department.setErrors([])
    }
  }, [orgId])

  if (!isEmpty(department.errors)) {
    return <Error errors={department.errors} />
  }

  if (department.departments === null || department.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    orgId: Number(orgId),
    id: 0,
    formationDate: '',
    name: '',
    parentId: '',
    workScheduleId: '',
  }

  const linkList = getLink(PrivatePaths.DepartmentList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('DepartmentList'), link: linkList, active: false },
          {
            id: 2,
            title: t('DepartmentCreate'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('DepartmentCreate')}</Heading>

      <div className="container-wrapper">
        <DepartmentForm
          initialValues={initialValues}
          handleService={department.createDepartment}
          buttonlabel={t('Create')}
          link={linkList}
          responseMessage={t('DepartmentAddedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(DepartmentCreate)))
