import { UserAPI } from './ApiUrls'
import httpClient from './httpClient'

export interface UserCreds {
  login: string
  password: string
}

export interface ResetPasswordParams {
  login: string
}

export interface UserData {
  firstName: string
  lastName: string
  patName: string
  email: string
  phone: string
  password: string
  [x: string]: any
}

export interface UserOrganization {
  orgId: string
  orgName: string
}

export class UserService {
  signIn = async (model: UserCreds): Promise<Response> => {
    const response = await httpClient.post(UserAPI.SignIn, model)

    const json = await response.json()

    if (json?.token) {
      const user = {
        token: json?.token,
      }

      localStorage.setItem('user', JSON.stringify(user))
    }

    return response
  }

  signUp = async (model: UserData): Promise<Response> => {
    const response = await httpClient.post(UserAPI.SignUp, model)
    return response
  }

  edit = async (model: UserData): Promise<Response> => {
    const response = await httpClient.post(UserAPI.Edit, model)
    return response
  }

  get = async (urlParams = ''): Promise<UserData> => {
    const response = await httpClient.get<UserData>(UserAPI.Get, urlParams)
    return response
  }

  resetPassword = async (model: ResetPasswordParams): Promise<Response> => {
    const response = await httpClient.post(UserAPI.ResetPassword, model)
    return response
  }

  getCurrentUser = (): JSON => JSON.parse(localStorage.getItem('user') || '{}')

  signOut = (): void => localStorage.removeItem('user')
}

export default new UserService()
