import { StaffPositionAPI } from './ApiUrls'
import httpClient from './httpClient'
import { OrgId } from './OrganizationService'

export interface StaffPositionId {
  id: number
}

export interface StaffPosition extends StaffPositionId, OrgId {
  approvalDate: string
  departmentId: number
  positionCount: number
  positionId: number
  salary: number
  salaryFund: number
  vacantUnitsCount: number
  workScheduleId: number
}

export interface StaffPositionResponse extends StaffPosition {
  departmentName: string
  positionName: string
  closed: boolean
  closeDate: string
}

export interface StaffPositionOpenClose extends StaffPositionId {
  closed: boolean
  closeDate: string
}

export class StaffPositionService {
  list = async (model: OrgId): Promise<Response> => {
    const result = await httpClient.post(StaffPositionAPI.List, model)

    return result
  }

  add = async (model: StaffPosition): Promise<Response> => {
    const result = await httpClient.post(StaffPositionAPI.Add, model)

    return result
  }

  edit = async (model: StaffPosition): Promise<Response> => {
    const result = await httpClient.post(StaffPositionAPI.Edit, model)

    return result
  }

  delete = async (model: StaffPositionId): Promise<Response> => {
    const result = await httpClient.post(StaffPositionAPI.Delete, model)

    return result
  }

  open = async (model: StaffPositionId): Promise<Response> => {
    const result = await httpClient.post(StaffPositionAPI.Open, model)

    return result
  }

  get = async (model: StaffPositionId): Promise<Response> => {
    const result = await httpClient.post(StaffPositionAPI.Get, model)

    return result
  }
}

export default new StaffPositionService()
