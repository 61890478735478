interface OganizationalLegalForm {
  key: string
  value: string
  text: string
}

const data: OganizationalLegalForm[] = [
  {
    key: '0',
    value: '0',
    text: 'Индивидуальный предприниматель (ИП)',
  },
  {
    key: '1',
    value: '1',
    text: 'Частный нотариус (ЧН)',
  },
  {
    key: '2',
    value: '2',
    text: 'Частный судебный исполнитель (ЧСИ)',
  },
  {
    key: '3',
    value: '3',
    text: 'Адвокат',
  },
  {
    key: '4',
    value: '4',
    text: 'Профессиональный медиатор (ПМ)',
  },
  {
    key: '5',
    value: '5',
    text: 'ТОО ',
  },
  {
    key: '6',
    value: '6',
    text: 'АО',
  },
]

export const getOganizationalLegalForm = (): OganizationalLegalForm[] => data

export const getCurrentOganizationalLegalForm = (
  value: string,
): OganizationalLegalForm | undefined =>
  data.find((item) => item.value === value)
