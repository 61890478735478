import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import PositionService, {
  Position,
  PositionId,
} from '../services/PositionService'

import { OrgId } from '../services/OrganizationService'

import { ReactSelectValues } from '../types'

import getErrorsMapping from '../utils/get-errors-mapping'
import isEmpty from '../utils/is-empty'

export class PositionsStore {
  positions: Position[] = null as unknown as Position[]

  currentPosition: Position = {} as Position

  errors: string[] = []

  isLoader = false

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      positions: observable,
      currentPosition: observable,
      errors: observable,
      isLoader: observable,
      setErrors: action.bound,
      requestPositions: action.bound,
      createPosition: action.bound,
      getPosition: action.bound,
      updatePosition: action.bound,
      removePosition: action.bound,
      requestOptionsPosition: action.bound,
      setPositions: action.bound,
      setPosition: action.bound,
      setCurrentPosition: action.bound,
      editPosition: action.bound,
      deletePosition: action.bound,
      isPositionByOrganizationId: action.bound,
      clearCurrentPosition: action.bound,
      fillCurrentPosition: action.bound,
      isLoaderChanged: action.bound,
    })

    this.rootStore = rootStore
  }

  setPositions(payload: Position[]): void {
    this.positions = payload
  }

  setPosition(payload: Position): void {
    this.positions = [...this.positions, payload]
  }

  setCurrentPosition(payload: Position): void {
    this.currentPosition = payload
  }

  editPosition(payload: Position): void {
    this.positions = this.positions.map((position) =>
      position.id === payload.id ? { ...position, ...payload } : position,
    )
  }

  deletePosition(id: number): void {
    this.positions = this.positions.filter((position) => position.id !== id)
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  isPositionByOrganizationId(organizationId: number): boolean {
    const position = this.positions.filter(
      ({ orgId }: Position): boolean => orgId === organizationId,
    )
    return !isEmpty(position)
  }

  clearCurrentPosition(): void {
    this.currentPosition = {} as Position
  }

  fillCurrentPosition(positionId: PositionId): void {
    if (!isEmpty(this.positions)) {
      const currentPosition = this.positions.filter(
        (item) => item.id === positionId.id,
      )[0]
      this.setCurrentPosition(currentPosition)
    } else {
      this.getPosition(positionId)
    }
  }

  async requestPositions(payload: OrgId): Promise<void> {
    this.isLoaderChanged(true)
    try {
      const response = await PositionService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400 || response.status === 404) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({ id, orgId, name }: Position): Position => ({
            id,
            orgId,
            name,
          }),
        )
        this.setPositions(result)
      }
    } catch (e: any) {
      console.log(
        `Class PositionsStore, method requestPositions, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async getPosition(payload: PositionId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await PositionService.get(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const { id, orgId, name } = json
        this.setCurrentPosition({
          id,
          orgId,
          name,
        })
      }
    } catch (e: any) {
      console.log(
        `Class PositionsStore, method getPosition, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async createPosition(payload: Position): Promise<null | string[]> {
    try {
      const response = await PositionService.add(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const { id, orgId, name } = json
        this.setPosition({
          id,
          orgId,
          name,
        })
      }
    } catch (e: any) {
      console.log(
        `Class PositionsStore, method createPosition, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async updatePosition(payload: Position): Promise<null | string[]> {
    try {
      const response = await PositionService.edit(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const { id, orgId, name } = json
        this.setCurrentPosition({
          id,
          orgId,
          name,
        })
        this.editPosition({
          id,
          orgId,
          name,
        })
      }
    } catch (e: any) {
      console.log(
        `Class PositionsStore, method updatePosition, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async removePosition(payload: PositionId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await PositionService.delete(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.deletePosition(payload.id)
      }
    } catch (e: any) {
      console.log(
        `Class PositionsStore, method removePosition, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async requestOptionsPosition(payload: OrgId): Promise<ReactSelectValues[]> {
    try {
      const response = await PositionService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({ id, name }: Position): ReactSelectValues => ({
            value: String(id),
            label: String(name),
          }),
        )
        return result
      }
    } catch (e: any) {
      console.log(
        `Class PositionsStore, method requestOptionsPosition, type error ${e.name}, Message ${e.message}`,
      )
    }

    return []
  }
}
