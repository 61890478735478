import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'

import { useUserStore } from '../../../providers/RootStoreProvider'
import UserService, { UserOrganization } from '../../../services/UserService'
import Navbar from '../Navbar'

function Header(): ReactElement {
  const user = useUserStore()
  const currentUser = UserService.getCurrentUser()
  const setOrganization = (organization: UserOrganization) => {
    user.setDefaultOrganization(organization)
  }

  return (
    <>
      <Navbar
        token={currentUser}
        user={user.currentUser}
        organizations={user.organizations}
        organization={user.organization}
        setOrganization={setOrganization}
        logout={user.cleanUser}
      />
    </>
  )
}

export default observer(Header)
