import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form } from 'react-bootstrap'
import { Formik, FormikHelpers, Field } from 'formik'
import * as Yup from 'yup'

import MaskedInput from 'react-text-mask'
import PhoneClear from '../../utils/phone-clear'

import { useUserStore } from '../../providers/RootStoreProvider'
import { UserData } from '../../services/UserService'

import isEmpty from '../../utils/is-empty'

import { StatusForm, PhoneNumberMask } from '../../types'

import { Heading } from '../../components/ui/Typography'
import { Input, Button } from '../../components/ui/Forms'
import { Alert } from '../../components/ui/Alert'
import { Loader } from '../../components/ui/Loader'
import { Error } from '../../components/ui/Error'

import styles from './User.module.scss'

function SignEdit(): React.ReactElement {
  const { t } = useTranslation()
  const user = useUserStore()

  useEffect(() => {
    if (isEmpty(user.currentUser)) {
      user.getUser()
    }

    return () => {
      user.setErrors([])
    }
  }, [])

  if (!isEmpty(user.errors)) {
    return <Error errors={user.errors} />
  }

  if (isEmpty(user.currentUser) || user.isLoader) {
    return <Loader />
  }

  const initialValues: UserData = {
    firstName: user.currentUser.firstName,
    lastName: user.currentUser.lastName,
    patName: user.currentUser.patName,
    email: user.currentUser.email,
    phone: PhoneClear(user.currentUser.phone),
    password: user.currentUser.password ?? '',
  }

  const schema = Yup.object().shape({
    // Имя
    firstName: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .min(2, ({ min }) => `Максимальное значение поля ${min} символов`)
      .required(t('InputRequiredValidate')),

    // Фамилия
    lastName: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .min(2, ({ min }) => `Максимальное значение поля ${min} символов`)
      .required(t('InputRequiredValidate')),

    // Отчество
    patName: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .min(2, ({ min }) => `Максимальное значение поля ${min} символов`)
      .required(t('InputRequiredValidate')),

    // Email
    email: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .email(t('EmailRequiredValidate'))
      .required(t('InputRequiredValidate')),

    // Телефон
    phone: Yup.string()
      .matches(/^[0-9()+ ]+$/, {
        message: t('FieldPhoneIncorrect'),
        excludeEmptyString: true,
      })
      .required(t('InputRequiredValidate')),

    // Пароль
    password: Yup.string().min(
      6,
      ({ min }) => `Минимальное значение поля ${min} символов`,
    ),
  })

  const handleSubmitForm = async (
    values: UserData,
    action: FormikHelpers<UserData>,
  ): Promise<void> => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      patName: values.patName,
      email: values.email,
      phone: PhoneClear(values.phone),
      password: values.password,
    }

    const errors = await user.updateUser(payload)

    if (!isEmpty(errors)) {
      action.setStatus({
        type: StatusForm.warning,
        messages: errors,
      })
      return
    }

    action.setStatus({
      type: StatusForm.success,
      messages: [t('UserEditedSuccessfully')],
    })

    action.setSubmitting(false)
  }

  return (
    <>
      <Heading>{t('My data link')}</Heading>
      <div className="container-wrapper">
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
        >
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            touched,
            values,
            status,
            isSubmitting,
            errors,
          }) => (
            <>
              {!isEmpty(status) && status.type === StatusForm.warning
                ? status.messages.map((error: string) => (
                    <Alert key={error} variant="warning" text={error} />
                  ))
                : null}
              {!isEmpty(status) && status.type === StatusForm.success
                ? status.messages.map((error: string) => (
                    <Alert key={error} variant="success" text={error} />
                  ))
                : null}

              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col sm="12" md="4" lg="3">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="lastName"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="text"
                        name="lastName"
                        placeholder={t('LastName')}
                        label={t('LastName')}
                        value={values.lastName}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.lastName && !!errors.lastName}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.lastName}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm="12" md="4" lg="3">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="firstName"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="text"
                        name="firstName"
                        placeholder={t('FirstName')}
                        label={t('FirstName')}
                        value={values.firstName}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.firstName && !!errors.firstName}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.firstName}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm="12" md="4" lg="3">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="patName"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="text"
                        name="patName"
                        placeholder={t('PatName')}
                        label={t('PatName')}
                        value={values.patName}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.patName && !!errors.patName}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.patName}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="12" md="4" lg="3">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="email"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="email"
                        name="email"
                        placeholder={t('Email')}
                        label={t('Email')}
                        value={values.email}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.email && !!errors.email}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.email}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="2">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="phone"
                      className="pb-4 position-relative"
                    >
                      <Field
                        name="phone"
                        label={t('Telephone')}
                        render={({ ...field }) => (
                          <>
                            <label className="form-label" htmlFor="phone">
                              {t('Telephone')}
                            </label>
                            <MaskedInput
                              {...field}
                              mask={PhoneNumberMask}
                              id="phone"
                              value={values.phone}
                              placeholder={t('Telephone')}
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.phone && touched.phone
                                  ? 'form-control phone is-invalid'
                                  : 'form-control phone'
                              }
                            />
                            {errors.phone && touched.phone && (
                              <div className="User_invalid-feedback-position__23DZb invalid-feedback">
                                {errors.phone}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="4" lg="3">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="password"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="text"
                        name="password"
                        placeholder={t('Password')}
                        label={t('Password')}
                        value={values.password}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.password && !!errors.password}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.password}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="12" lg="9">
                    <div className="text-center">
                      <Button
                        typeField="submit"
                        label={t('Save')}
                        disabledField={isSubmitting}
                        loadingField={isSubmitting}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  )
}

export default observer(SignEdit)
