interface DocType {
  key: string
  value: string
  text: string
}

const data: DocType[] = [
  {
    key: 'Удостоверение личности РК',
    value: 'Удостоверение личности РК',
    text: 'Удостоверение личности РК',
  },
  {
    key: 'Паспорт РК',
    value: 'Паспорт РК',
    text: 'Паспорт РК',
  },
  {
    key: 'Вид на жительство РК',
    value: 'Вид на жительство РК',
    text: 'Вид на жительство РК',
  },
  {
    key: 'Паспорт иностранного гражданина',
    value: 'Паспорт иностранного гражданина',
    text: 'Паспорт иностранного гражданина',
  },
  {
    key: 'Другое',
    value: 'Другое',
    text: 'Другое',
  },
]

export const getDocType = (): DocType[] => data

export const getCurrentDocType = (value: string): DocType | undefined =>
  data.find((item) => item.value === value)
