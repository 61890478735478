import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { OrganizationTableDataProps } from './types'

import TableData from '../../components/ui/TableData'
import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

function OrganizationTableData({
  organizations,
}: OrganizationTableDataProps): ReactElement {
  const { t } = useTranslation()

  const columns = [
    {
      name: 'bin',
      label: t('OrganizationBin'),
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'orgName',
      label: t('OrganizationName'),
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'supervisor',
      label: t('SupervisorFullName'),
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'legalAddress',
      label: t('HeaderLegalAddress'),
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'phone',
      label: t('HeaderPhoneNumber'),
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'handle',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
  ]

  const data = organizations.map((item) => ({
    bin: item.bin,
    orgName: item.orgName,
    supervisor: item.supervisor,
    legalAddress: item.legalAddress,
    phone: item.phone,
    handle: (
      <>
        <Link
          to={getLink(PrivatePaths.OrganizationEdit, {
            [item.orgId]: PrivatePathsRegexp.orgId,
          })}
          className="text-primary p-2 me-1"
          style={{ fontSize: '18px' }}
        >
          <i className="bi bi-pencil-fill"></i>
        </Link>
      </>
    ),
  }))

  return (
    <>
      {!isEmpty(organizations) ? (
        <TableData data={data} columns={columns} />
      ) : (
        <p>{t('ListOrganizationIsEmpty')}</p>
      )}
    </>
  )
}

export default observer(OrganizationTableData)
