import { OrganizationAPI } from './ApiUrls'
import httpClient from './httpClient'

export interface OrgId {
  orgId: number
}

export interface Organization extends OrgId {
  bin: string
  orgName: string
  legalAddress: string
  actualAddress: string
  supervisor: string
  email: string
  phone: string
  okved: string
  bik: string
  bankName: string
  bc: number
  iik: string
  type: number
}

export interface OrganizationSelect extends OrgId {
  orgName: string
}

export class OrganizationService {
  list = async (urlParams = ''): Promise<Response> => {
    const result = await httpClient.get<Response>(
      OrganizationAPI.List,
      urlParams,
    )

    return result
  }

  add = async (model: Organization): Promise<Response> => {
    const result = await httpClient.post(OrganizationAPI.Add, model)

    return result
  }

  edit = async (model: Organization): Promise<Response> => {
    const result = await httpClient.post(OrganizationAPI.Edit, model)

    return result
  }

  get = async (model: OrgId): Promise<Response> => {
    const result = await httpClient.post(OrganizationAPI.Get, model)

    return result
  }
}

export default new OrganizationService()
