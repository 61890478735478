import { ReactElement, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Nav, Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { PrivatePaths } from '../../../router/Routes'
import { UserData, UserOrganization } from '../../../services/UserService'

import isEmpty from '../../../utils/is-empty'
import modalAlert from '../../../utils/alert'

interface MenuProps {
  token: UserData
  organization: UserOrganization | null
}

function Menu({ token, organization }: MenuProps): ReactElement {
  const { t } = useTranslation()
  const [active, setActive] = useState(false)

  const onNotWorkClick = () => {
    modalAlert(Number(1), t('InTestNotWork'), t('Ok'), 'lg')
    return false
  }

  const getUrl = (url: string) => {
    if (organization && organization.orgId) {
      return url.replace(':orgId', organization.orgId)
    }
    return PrivatePaths.OrganizationCreate
  }

  return (
    <>
      {!isEmpty(token) && (
        <div className={`cell main-menu${active ? ' active' : ''}`}>
          <div className="slider">
            <span onClick={() => setActive(!active)} />
          </div>
          <div className="menu-block">
            <Accordion>
              <Nav className="flex-column">
                <NavLink
                  to={getUrl(PrivatePaths.StorageList)}
                  className="nav-link table"
                >
                  <span className="icon storage"></span>
                  <span className="text">{t('Storage list link')}</span>
                </NavLink>

                <NavLink
                  to={getUrl(PrivatePaths.KkmList)}
                  className="nav-link table"
                >
                  <span className="icon cashbox" />
                  <span className="text">{t('Cash register list link')}</span>
                </NavLink>

                <NavLink
                  to="b1"
                  className="nav-link table"
                  onClick={onNotWorkClick}
                >
                  <span className="icon report" />
                  <span className="text">{t('Checkout reports')}</span>
                </NavLink>

                <NavLink
                  to={getUrl(PrivatePaths.CounterpartyList)}
                  className="nav-link table"
                >
                  <span className="icon supplier" />
                  <span className="text">{t('Counterparty list link')}</span>
                </NavLink>

                <NavLink
                  to="b2"
                  className="nav-link table"
                  onClick={onNotWorkClick}
                >
                  <span className="icon pricelist" />
                  <span className="text">{t('Price lists')}</span>
                </NavLink>

                <NavLink
                  to={getUrl(PrivatePaths.TmcReceive)}
                  className="nav-link table"
                >
                  <span className="icon calc" />
                  <span className="text">
                    {t('Commodity material values link')}
                  </span>
                </NavLink>

                <NavLink
                  to="b3"
                  className="nav-link table"
                  onClick={onNotWorkClick}
                >
                  <span className="icon indicator" />
                  <span className="text">{t('Sales figures')}</span>
                </NavLink>

                <NavLink
                  to="b4"
                  className="nav-link table"
                  onClick={onNotWorkClick}
                >
                  <span className="icon document" />
                  <span className="text">{t('Documentation')}</span>
                </NavLink>

                <NavLink
                  to="b5"
                  className="nav-link table"
                  onClick={onNotWorkClick}
                >
                  <span className="icon help" />
                  <span className="text">{t('Help')}</span>
                </NavLink>
              </Nav>
            </Accordion>
          </div>
        </div>
      )}
    </>
  )
}

export default observer(Menu)
