import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Formik, FormikHelpers } from 'formik'
import { Form, Row, Col } from 'react-bootstrap'
import * as Yup from 'yup'

import isEmpty from '../../utils/is-empty'

import { StatusForm } from '../../types'
import { PositionFormProps, InitialValues } from './types'

import { Input, Button } from '../../components/ui/Forms'
import { Alert } from '../../components/ui/Alert'

function PositionForm({
  initialValues,
  handleService,
  buttonlabel,
  link,
  responseMessage,
}: PositionFormProps): ReactElement {
  const history = useHistory()
  const { t } = useTranslation()

  const schema = Yup.object().shape({
    // Наименование подразделения
    name: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),
  })

  const handleSubmitForm = async (
    values: InitialValues,
    action: FormikHelpers<InitialValues>,
  ): Promise<void> => {
    const payload = {
      id: values.id,
      orgId: values.orgId,
      name: values.name,
    }

    const errors = await handleService(payload)

    if (!isEmpty(errors)) {
      action.setStatus({
        type: StatusForm.warning,
        messages: errors,
      })
      return
    }

    action.setStatus({
      type: StatusForm.success,
      messages: [responseMessage],
    })

    action.setSubmitting(false)

    if (link) {
      history.push(link)
    }
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        values,
        status,
        isSubmitting,
        errors,
      }) => (
        <>
          {!isEmpty(status) && status.type === StatusForm.warning
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="warning" text={error} />
              ))
            : null}
          {!isEmpty(status) && status.type === StatusForm.success
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="success" text={error} />
              ))
            : null}
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="12"
                controlId="positionName"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="name"
                  placeholder={t('PositionName')}
                  label={t('PositionName')}
                  value={values.name}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.name && !!errors.name}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.name}
                />
              </Form.Group>
            </Row>
            <div className="text-center">
              <Button
                typeField="submit"
                label={buttonlabel}
                disabledField={isSubmitting}
                loadingField={isSubmitting}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default observer(PositionForm)
