import React, { ReactElement, ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Field, Formik, FormikHelpers } from 'formik'
import { Form, Row, Col } from 'react-bootstrap'
import * as Yup from 'yup'

import MaskedInput from 'react-text-mask'
import { TypesForm, StatusForm, PhoneNumberMask } from '../../types'
import { OrganizationFormProps, InitialValues } from './types'

import PhoneClear from '../../utils/phone-clear'

import isEmpty from '../../utils/is-empty'
import { getOganizationalLegalForm } from '../../utils/get-organizational-legal-form'
import { getBanksBiks, getBankName } from '../../utils/get-banks'

import {
  Input,
  Button,
  Check,
  Select,
  ReactSelect,
} from '../../components/ui/Forms'
import { Alert } from '../../components/ui/Alert'

import CertificateService from '../../services/CertificateService'

function OrganizationForm({
  initialValues,
  handleService,
  typeForm,
  buttonlabel,
  link,
  responseMessage,
}: OrganizationFormProps): ReactElement {
  const history = useHistory()
  const { t } = useTranslation()

  const schema = Yup.object().shape({
    // ИИН/БИН организации
    bin: Yup.string()
      .length(12, ({ length }) => `Длина поля составляет ${length} символов`)
      .required(t('InputRequiredValidate')),

    // Наименование организации или ИП (Индивидуальный предприниматель)
    orgName: Yup.string()
      .max(1000, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Организационно правовая форма
    type: Yup.string().required(t('InputRequiredValidate')),

    // БИК (Банковский идентификационный код)
    bik: Yup.string()
      .length(8, ({ length }) => `Длина поля составляет ${length} символов`)
      .required(t('InputRequiredValidate')),

    // Наименование банка
    bankName: Yup.string()
      .max(1000, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // КБе (Код Бенефициара)
    bc: Yup.string()
      .matches(/^(1[1-9]{1,2}|2\d{1,2})$/, t('FieldOnlyDigitsBc'))
      .required(t('InputRequiredValidate')),

    // ИИК (Индивидуальный идентификационный код)
    iik: Yup.string()
      .max(20, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Юридический адрес
    legalAddress: Yup.string()
      .max(1000, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Фактический адрес
    actualAddress: Yup.string()
      .max(1000, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // ФИО руководителя
    supervisor: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Email
    email: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .email(t('EmailRequiredValidate'))
      .required(t('InputRequiredValidate')),

    // Телефон
    phone: Yup.string()
      .matches(/^[0-9()+ ]+$/, {
        message: t('FieldPhoneIncorrect'),
        excludeEmptyString: true,
      })
      .required(t('InputRequiredValidate')),

    // Вид деятельности (ОКЭД)
    okved: Yup.string().max(
      100,
      ({ max }) => `Максимальное значение поля ${max} символов`,
    ),

    // Совпадает с юридическим адресом
    duplication: Yup.bool(),
  })

  const handleSubmitForm = async (
    values: InitialValues,
    action: FormikHelpers<InitialValues>,
  ): Promise<void> => {
    const payload = {
      orgId: values.orgId,
      actualAddress: values.actualAddress,
      bik: values.bik,
      bin: values.bin,
      email: values.email,
      iik: values.iik,
      legalAddress: values.legalAddress,
      okved: values.okved,
      orgName: values.orgName,
      phone: PhoneClear(values.phone),
      supervisor: values.supervisor,
      type: Number(values.type),
      bc: Number(values.bc),
      bankName: values.bankName,
      cert_data: CertificateService.getLocalCertificate().pem,
    }

    const errors = await handleService(payload)

    if (!isEmpty(errors)) {
      action.setStatus({
        type: StatusForm.warning,
        messages: errors,
      })
      return
    }

    action.setStatus({
      type: StatusForm.success,
      messages: [responseMessage],
    })

    if (typeForm === TypesForm.creating) {
      CertificateService.removeLocalCertificate()
    }

    action.setSubmitting(false)

    if (link) {
      history.push(link)
    }
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
        status,
        isSubmitting,
        errors,
      }) => (
        <>
          {!isEmpty(status) && status.type === StatusForm.warning
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="warning" text={error} />
              ))
            : null}
          {!isEmpty(status) && status.type === StatusForm.success
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="success" text={error} />
              ))
            : null}

          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                xl="6"
                lg="4"
                controlId="bin"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="bin"
                  placeholder={t('OrganizationBin')}
                  label={t('OrganizationBin')}
                  value={values.bin}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.bin && !!errors.bin}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.bin}
                  disabled
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="8"
                controlId="orgName"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="orgName"
                  placeholder={t('OrganizationFullName')}
                  label={t('OrganizationFullName')}
                  value={values.orgName}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.orgName && !!errors.orgName}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.orgName}
                  disabled={typeForm === TypesForm.editing}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="legalAddress"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="legalAddress"
                  placeholder={t('LegalAddress')}
                  label={t('LegalAddress')}
                  value={values.legalAddress}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.legalAddress && !!errors.legalAddress}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.legalAddress}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="actualAddress"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="actualAddress"
                  placeholder={t('ActualAddress')}
                  label={t('ActualAddress')}
                  value={values.actualAddress}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.actualAddress && !!errors.actualAddress}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.actualAddress}
                  disabled={values.duplication}
                />
              </Form.Group>

              <Form.Group className="pb-4">
                <Check
                  required
                  id="duplication"
                  name="duplication"
                  label={t('DuplicationLegalAddress')}
                  onChangeCallback={(e: ChangeEvent<any>) => {
                    handleChange(e)
                    setFieldValue('actualAddress', values.legalAddress)
                  }}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.duplication && !!errors.duplication}
                  error={errors.duplication}
                  checked={values.duplication}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="supervisor"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="supervisor"
                  placeholder={t('SupervisorFullName')}
                  label={t('SupervisorFullName')}
                  value={values.supervisor}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.supervisor && !!errors.supervisor}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.supervisor}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="email"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="email"
                  name="email"
                  placeholder={t('Email')}
                  label={t('Email')}
                  value={values.email}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.email}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="phone"
                className="pb-4 position-relative"
              >
                <Field
                  name="phone"
                  label={t('Telephone')}
                  render={({ ...field }) => (
                    <>
                      <label className="form-label" htmlFor="phone">
                        {t('Telephone')}
                      </label>
                      <MaskedInput
                        {...field}
                        mask={PhoneNumberMask}
                        id="phone"
                        value={values.phone}
                        placeholder={t('Telephone')}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.phone && touched.phone
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                      {errors.phone && touched.phone && (
                        <div className="User_invalid-feedback-position__23DZb invalid-feedback">
                          {errors.phone}
                        </div>
                      )}
                    </>
                  )}
                />
              </Form.Group>

              <div className="my-3" style={{ fontWeight: 'bold' }}>
                {t('BankDetails')}
              </div>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="bik"
                className="pb-4 position-relative"
              >
                <ReactSelect
                  name="bik"
                  value={String(values.bik)}
                  label={t('Bik')}
                  placeholder={t('Bik')}
                  isInvalid={touched.bik && !!errors.bik}
                  typeFeedback="invalid"
                  error={errors.bik}
                  onChange={(name: string, value: string) => {
                    const bankname = getBankName(value)
                    setFieldValue(name, value)
                    setFieldValue('bankName', bankname)
                  }}
                  onBlur={setFieldTouched}
                  loadOptions={() => getBanksBiks()}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="bankName"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="bankName"
                  placeholder={t('BankName')}
                  label={t('BankName')}
                  value={values.bankName}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.bankName && !!errors.bankName}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.bankName}
                  disabled
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="bc"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="bc"
                  placeholder={t('RecipientCode')}
                  label={t('RecipientCode')}
                  value={String(values.bc)}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.bc && !!errors.bc}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.bc}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="type"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('OganizationalLegalForm')}
                  name="type"
                  value={String(values.type)}
                  onChangeCallback={handleChange}
                  isInvalid={touched.type && !!errors.type}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.type}
                >
                  <option value="">{t('OganizationalLegalForm')}</option>
                  {getOganizationalLegalForm().map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="iik"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="iik"
                  placeholder={t('Iic')}
                  label={t('Iic')}
                  value={values.iik}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.iik && !!errors.iik}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.iik}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="okved"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="okved"
                  placeholder={t('Okved')}
                  label={t('Okved')}
                  value={values.okved}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.okved && !!errors.okved}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.okved}
                />
              </Form.Group>
            </Row>
            <div className="text-center">
              <Button
                typeField="submit"
                label={buttonlabel}
                disabledField={isSubmitting}
                loadingField={isSubmitting}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default observer(OrganizationForm)
