import { ReactElement, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths } from '../../router/Routes'

import { useOrganizationsStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import isEmpty from '../../utils/is-empty'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Error } from '../../components/ui/Error'

import OrganizationTableData from './OrganizationTableData'

function OrganizationList(): ReactElement {
  const { t } = useTranslation()

  const organization = useOrganizationsStore()

  useEffect(() => {
    if (organization.organizations === null) {
      organization.requestOrganizations()
    }

    return () => {
      organization.setErrors([])
    }
  }, [])

  if (!isEmpty(organization.errors)) {
    return <Error errors={organization.errors} />
  }

  if (organization.organizations === null || organization.isLoader) {
    return <Loader />
  }

  return (
    <>
      <Heading>{t('OrganizationList')}</Heading>

      <div className="mb-3">
        <Link to={PrivatePaths.OrganizationCreate} className="btn btn-link">
          + {t('Organization link')}
        </Link>
      </div>

      <div className="organizationList">
        <OrganizationTableData organizations={organization.organizations} />
      </div>
    </>
  )
}

export default withAuthRedirect(observer(OrganizationList))
