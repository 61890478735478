import React, { ReactElement } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useUserStore } from '../providers/RootStoreProvider'

import UserService from '../services/UserService'

import { NonAuthPaths } from '../router/Routes'

import isEmpty from '../utils/is-empty'

export function withAuthRedirect<WCP>(
  WrappedComponent: React.ComponentType<WCP>,
): React.FC {
  const RedirectComponent: React.FC = (
    props: React.ComponentProps<typeof RedirectComponent>,
  ): ReactElement => {
    const currentUser = UserService.getCurrentUser()
    const user = useUserStore()

    if (isEmpty(user.currentUser) && isEmpty(currentUser)) {
      return <Redirect to={NonAuthPaths.Login} />
    }

    return <WrappedComponent {...(props as WCP)} />
  }

  return observer(RedirectComponent)
}
