import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useStoragesStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import StorageForm from './StorageForm'

interface RouteParams {
  orgId: string
  storeId: string
}

function StorageEdit({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const storage = useStoragesStore()

  const { orgId, storeId } = params as RouteParams

  useEffect(() => {
    if (
      storage.storages === null ||
      !storage.isStorageByOrganizationId(Number(orgId))
    ) {
      storage.requestStorages({ orgId: Number(orgId) })
    }

    storage.fillCurrentStorage(Number(storeId))

    return () => {
      storage.setErrors([])
      storage.clearCurrentStorage()
    }
  }, [orgId])

  if (!isEmpty(storage.errors)) {
    return <Error errors={storage.errors} />
  }

  if (isEmpty(storage.currentStorage) || storage.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    orgId: storage.currentStorage.orgId,
    name: storage.currentStorage.name,
    address: storage.currentStorage.address,
    type: String(storage.currentStorage.type),
    storeId: storage.currentStorage.storeId,
  }

  const linkList = getLink(PrivatePaths.StorageList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('StorageList'), link: linkList, active: false },
          {
            id: 2,
            title: t('StorageEdit'),
            link: '/',
            active: true,
          },
        ]}
      />

      <div className="container-wrapper">
        <StorageForm
          initialValues={initialValues}
          handleService={storage.updateStorage}
          buttonlabel={t('Edit')}
          responseMessage={t('StorageEditedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(StorageEdit)))
