import React, { ReactElement } from 'react'
import { Alert } from '../Alert'

interface ErrorProps {
  errors: string[]
}

export function ErrorComponent({ errors }: ErrorProps): ReactElement {
  return (
    <div>
      {errors.map((error: string) => (
        <Alert key={error} variant="danger" text={error} />
      ))}
    </div>
  )
}
