import { ReactElement, useEffect } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useKkmStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Error } from '../../components/ui/Error'

import KkmTableData from './KkmTableData'

interface RouteParams {
  orgId: string
}

function KkmList({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const kkm = useKkmStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (kkm.cashRegisters === null) {
      kkm.requestCashRegisters()
    }

    return () => {
      kkm.setErrors([])
    }
  }, [])

  const handleRemove = (id: number): void => {
    kkm.removeCashRegister({ id })
  }

  if (!isEmpty(kkm.errors)) {
    return <Error errors={kkm.errors} />
  }

  if (kkm.cashRegisters === null || kkm.isLoader) {
    return <Loader />
  }

  const linkCreate = getLink(PrivatePaths.KkmCreate, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Heading>{t('CashRegisterList')}</Heading>

      <div className="text-center mb-5">
        <Link to={linkCreate} className="btn btn-primary text-white">
          {t('Cash register link')}
        </Link>
      </div>

      <KkmTableData
        cashRegisters={kkm.cashRegisters}
        onRemove={handleRemove}
        orgId={orgId}
      />
    </>
  )
}

export default withRouter(withAuthRedirect(observer(KkmList)))
