import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form } from 'react-bootstrap'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import {
  useKkmStore,
  useStoragesStore,
} from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { StatusForm } from '../../types'
import { InitialValuesAttach } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Button, ReactSelect } from '../../components/ui/Forms'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Alert } from '../../components/ui/Alert'

interface RouteParams {
  kkmId: string
  orgId: string
}

function KkmAttach({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const kkm = useKkmStore()
  const storage = useStoragesStore()

  const { orgId, kkmId } = params as RouteParams

  useEffect(() => {
    if (kkm.cashRegisters === null) {
      kkm.requestCashRegisters()
    }

    kkm.fillCurrentCashRegister({ id: Number(kkmId) })
    return () => {
      kkm.clearCurrentCashRegister()
    }
  }, [orgId])

  if (kkm.cashRegisters === null || kkm.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValuesAttach = {
    orgId: Number(orgId),
    id: Number(kkmId),
    storeId: '',
  }

  const schema = Yup.object().shape({
    // Наименование складов
    storeId: Yup.string().required(t('InputRequiredValidate')),
  })

  const handleSubmitForm = async (
    values: InitialValuesAttach,
    action: FormikHelpers<InitialValuesAttach>,
  ): Promise<void> => {
    const payload = {
      id: values.id,
      storeId: Number(values.storeId),
    }

    const errors = await kkm.linkCashRegister(payload)

    if (!isEmpty(errors)) {
      action.setStatus({
        type: StatusForm.warning,
        messages: errors,
      })

      return
    }

    action.setStatus({
      type: StatusForm.success,
      messages: [t('CashRegistersAttachedSuccessfully')],
    })

    action.setSubmitting(false)
  }

  const linkList = getLink(PrivatePaths.KkmList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          {
            id: 1,
            title: t('CashRegisterList'),
            link: linkList,
            active: false,
          },
          {
            id: 2,
            title: t('CashRegisterAttach'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('CashRegisterAttach')}</Heading>

      <div className="container-wrapper">
        <b className="me-2">{kkm.currentCashRegister.name}</b>
        <span className="text-secondary">
          id: {kkm.currentCashRegister.ofdId}
        </span>

        <hr />

        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
        >
          {({
            handleSubmit,
            touched,
            values,
            status,
            isSubmitting,
            errors,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              {!isEmpty(status) && status.type === StatusForm.warning
                ? status.messages.map((error: string) => (
                    <Alert key={error} variant="warning" text={error} />
                  ))
                : null}
              {!isEmpty(status) && status.type === StatusForm.success
                ? status.messages.map((error: string) => (
                    <Alert key={error} variant="success" text={error} />
                  ))
                : null}
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="storeId"
                    className="pb-4 position-relative"
                  >
                    <ReactSelect
                      name="storeId"
                      value={String(values.storeId)}
                      label={t('StorageList')}
                      placeholder={t('StorageList')}
                      isInvalid={touched.storeId && !!errors.storeId}
                      typeFeedback="invalid"
                      error={errors.storeId}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      loadOptions={() =>
                        storage.requestOptionsStorage({ orgId: values.orgId })
                      }
                    />
                  </Form.Group>
                </Row>
                <div className="text-center">
                  <Button
                    typeField="submit"
                    label={t('Attach')}
                    disabledField={isSubmitting}
                    loadingField={isSubmitting}
                  />
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(KkmAttach)))
