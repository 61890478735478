import List from './List'
import Receive from './Receive/Receive'
import PriceList from './PriceList/PriceList'
import Move from './Move/Move'
import СancellationList from './Сancellation/СancellationList'
import СancellationDetailed from './Сancellation/СancellationDetailed'
import СancellationCreate from './Сancellation/СancellationCreate'

export const ListPage = List
export const ReceivePage = Receive
export const СancellationListPage = СancellationList
export const MovePage = Move
export const PriceListPage = PriceList
export const СancellationDetailedPage = СancellationDetailed
export const СancellationCreatePage = СancellationCreate
