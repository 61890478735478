import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { usePersonsStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import PersonForm from './PersonForm'
import PhoneClear from '../../utils/phone-clear'

interface RouteParams {
  orgId: string
  personId: string
}

function PersonEdit({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const person = usePersonsStore()

  const { orgId, personId } = params as RouteParams

  useEffect(() => {
    if (
      person.persons === null ||
      !person.isPersonByOrganizationId(Number(orgId))
    ) {
      person.requestPersons({ orgId: Number(orgId) })
    }

    person.fillCurrentPerson({ id: Number(personId) })

    return () => {
      person.setErrors([])
      person.clearCurrentPerson()
    }
  }, [orgId])

  if (!isEmpty(person.errors)) {
    return <Error errors={person.errors} />
  }

  if (isEmpty(person.currentPerson) || person.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    orgId: person.currentPerson.orgId,
    id: person.currentPerson.id,
    actualAddress: person.currentPerson.actualAddress,
    birthday: person.currentPerson.birthday,
    birthplace: person.currentPerson.birthplace,
    citizenship: person.currentPerson.citizenship,
    dateOfIssue: person.currentPerson.dateOfIssue,
    docType: person.currentPerson.docType,
    email: person.currentPerson.email,
    gender: String(person.currentPerson.gender),
    iin: person.currentPerson.iin,
    isForeign: person.currentPerson.isForeign,
    issuingAuthority: person.currentPerson.issuingAuthority,
    legalAddress: person.currentPerson.legalAddress,
    name: person.currentPerson.name,
    personIdn: person.currentPerson.personIdn,
    phone: PhoneClear(person.currentPerson.phone),
    socialStatus: person.currentPerson.socialStatus,
    bik: person.currentPerson.bik,
    bankName: person.currentPerson.bankName,
    iban: person.currentPerson.iban,
    bc: person.currentPerson.bc,
    duplication:
      person.currentPerson.actualAddress === person.currentPerson.legalAddress,
  }

  const linkList = getLink(PrivatePaths.PersonList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('PersonList'), link: linkList, active: false },
          {
            id: 2,
            title: t('PersonEdit'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('PersonEdit')}</Heading>

      <div className="container-wrapper">
        <PersonForm
          initialValues={initialValues}
          handleService={person.updatePerson}
          buttonlabel={t('Edit')}
          responseMessage={t('PersonEditedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(PersonEdit)))
