import { ReactElement, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { useUserStore } from '../../../providers/RootStoreProvider'
import UserService from '../../../services/UserService'

import isEmpty from '../../../utils/is-empty'
import Menu from './Menu'

function Sidenav(): ReactElement {
  const user = useUserStore()
  const currentUser = UserService.getCurrentUser()

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      user.getUser()
    }
  }, [])

  return (
    <>
      <Menu token={user.currentUser} organization={user.organization} />
    </>
  )
}

export default observer(Sidenav)
