import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: {
      // Nav Links label
      'Login link': 'Login',
      'Sign Up link': 'Sign Up',
      'Edit link': 'Edit',
      'Organization link': 'Organization create',
      'Organization list link': 'Organizations',
      'Storage link': 'Storage create',
      'Storage list link': 'Storages',
      'Counterparty link': 'Supplier create',
      'Counterparty list link': 'Suppliers',
      'Сancellation link': 'Create a write-off',
      'Сancellation list link': 'TMC write-off',
      'Tmc receipt link': 'TMC receipt',
      'Tmc move link': 'TMC move',
      'Cash register link': 'Cash register create',
      'Cash register list link': 'Cash registers',
      'Person link': 'Create a personal data card',
      'Person list link': 'Personal data',
      'Employee link': 'Hiring an employee',
      'Employee list link': 'Employee',
      'Department link': 'Department create',
      'Department list link': 'Department',
      'Position link': 'Position create',
      'Position list link': 'Position',
      'Staff Position link': 'Staff position create',
      'Staff Position list link': 'Staff position',
      'Nomenclature link': 'Nomenclature create',
      'Nomenclature list link': 'Nomenclatures',
      'My data link': 'My data',
      'Commodity material values link': 'Commodity material values',
      'Users list link': 'Users list',
      'Employee registration card link': 'Employee registration card',
      'Next Page': 'Next Page',
      'Previous Page': 'Previous page',
      'Rows per page': 'Rows per page:',
      'Show Columns': 'Show columns',
      'Show/Hide Table Columns': 'Show/Hide table columns',
      'Filter all': 'ALL',
      'Not found': 'Sorry, no matching records found',
      ToolTip: 'Sort',
      СolumnHeaderTooltip: 'Sort for',
      Filteres: 'FILTERS',
      Reset: 'RESET',
      DisplayRows: 'of',
      Opened: 'Opened',
      Closed: 'Closed',
      'Date closed': 'Date closed',
      'Toolbar search': 'Search',
      'Toolbar downloadCsv': 'Download CSV',
      'Toolbar print': 'Print',
      'Toolbar viewColumns': 'View columns',
      'Toolbar filterTable': 'Filter table',

      // Calendar
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',

      // Pages header
      Main: 'Trade and accounting system TIS',
      Authorization: 'Authorization',
      Registration: 'Registration',
      OrganizationCreate: 'Organization create',
      OrganizationEdit: 'Organization edit',
      OrganizationList: 'Organization list',
      EditUser: 'Edit user',
      StorageCreate: 'Storage create',
      StorageEdit: 'Storage edit',
      StorageList: 'Storage list',
      TmcList: 'TMC',
      TmcReceipt: 'TMC receipt',
      TmcMove: 'TMC move',
      TmcСancellation: 'Tmc Сancellation',
      TmcСancellationDetailed: 'Tmc Сancellation detailed',
      TmcСancellationCreate: 'Tmc Сancellation create',
      Сancellations: 'Сancellations',
      CounterpartyCreate: 'Creating supplier card',
      CounterpartyEdit: 'Editing supplier card',
      CounterpartyList: 'Supplier list',
      CashRegisterCreate: 'Cash register create',
      CashRegisterEdit: 'Cash register edit',
      CashRegisterList: 'Cash register list',
      CashRegisterAttach: 'Cash register attach',
      PersonCreate: 'Creating a personal data card',
      PersonEdit: 'Editing the personal data card',
      PersonList: 'Personal data',
      EmployeeCreate: 'Hiring an employee',
      EmployeeEdit: 'Employee edit',
      EmployeeList: 'Employee list',
      DepartmentCreate: 'Department create',
      DepartmentEdit: 'Department edit',
      DepartmentList: 'Department list',
      PositionCreate: 'Position create',
      PositionEdit: 'Position edit',
      PositionList: 'Position list',
      StaffPositionCreate: 'Staff position create',
      StaffPositionEdit: 'Staff position edit',
      StaffPositionList: 'Staff position list',
      NomenclatureCreate: 'Nomenclature create',
      NomenclatureEdit: 'Nomenclature edit',
      NomenclatureList: 'Nomenclature list',

      // Fields label
      SearchInput: 'Search',
      FIO: 'Full name (Last name First name Patronymic)',
      FirstName: 'First name',
      LastName: 'Last name',
      PatName: 'Patronymic',
      Login: 'Login',
      Email: 'Email',
      Telephone: 'Telephone',
      TelephonePlaceholder: '+7(___) ___-__-__',
      Password: 'Password',
      PasswordConfirm: 'Password confirmation',
      Terms: 'Agree to terms and conditions',
      DuplicationLegalAddress: 'Matches the legal address',
      DuplicationRegistrationAddress:
        'Matches the address of the residence permit',
      Date: 'Date',
      Bin: 'IIN/BIN (Individual Identification Number/Business identification number)',
      OrganizationBin: 'IIN/BIN organization',
      OrganizationFullName:
        'Name of the organization or sole proprietor(Individual entrepreneur)',
      SupervisorFullName: 'Supervisor full name',
      Guid: 'Guid',
      Supervisor: 'Supervisor',
      Okved: 'Type of activity (OKED)',
      OrganizationName: 'Organization name',
      StorageName: 'Storage name',
      StorageAddress: 'Storage address',
      StorageType: 'Storage type',
      NameRus: 'Name (in Russian)',
      NameKaz: 'Name (in Kazakh)',
      СustomInfo: 'Сustom info',
      NomenclatureType: 'Nomenclature type',
      Nomenclature: 'Nomenclature',
      UnitMeasurement: 'Unit measurement',
      AttributeWeightProduct: 'Attribute weight product',
      Barcode: 'Barcode',
      VendorCode: 'Vendor code',
      VendorCodeExt: 'Vendor code Magnum',
      Markup: 'Markup',
      MarkupType: 'Markup, units of measurement',
      MarkupPercentage: 'Markup per unit as percentage',
      MarkupValue: 'Markup per unit as tenge',
      VatValue: 'Vat value',
      Vat: 'Vat value %',
      Units: 'ТНВЭД code',
      VendorBin: 'ITN/BIN supplier',
      CustomInfo: 'Additional information (description)',
      VendorInfo: 'Information about manufacturer (country)',
      TypeOperation: 'Type operation',
      ActualAddress: 'Actual address',
      LegalAddress: 'Legal address',
      ResidentialAddress: 'Residential address',
      RegistrationAddress: 'Legal address',
      FullName: 'Full name',
      ShortName: 'Short name',
      BankName: 'Bank name',
      Iic: 'IIC (Individual Identification Code)',
      Iin: 'IIN (Individual identification number)',
      Bik: 'BIC (Bank Identification Code)',
      Gender: 'Gender',
      Citizenship: 'Citizenship',
      Birthplace: 'Birthplace',
      DocType: 'Identity document',
      PersonIdn: 'Document number',
      IssuingAuthority: 'Issuing authority',
      SocialStatus: 'Social status',
      IsForeign: 'Foreign citizen',
      Birthday: 'Birthday',
      DateOfIssue: 'Date of issue',
      Unit: 'Unit of measurement',
      Amount: 'Quantity / Weight',
      PurchasePrice: 'Purchase price tenge per unit',
      CostPrice: 'Cost price',
      SellPrice: 'Sale price',
      RecipientCode: 'Recipient`s code',
      CounterpartyBin: 'Supplier IIN/BIN',
      OganizationalLegalForm: 'Organizational legal form',
      CounterpartyId: 'Supplier id',
      CounterpartyFullName:
        'Name of the organization or sole proprietor(Individual entrepreneur)',
      CounterpartyShortName: 'Supervisor short name',
      CashRegistersName: 'Cash registers name',
      CashRegistersId: 'Cash registers id',
      CashRegisterLinked: 'Cash registers linked',
      Alias: 'Key alias',
      KeyId: 'Key id',
      Algorithm: 'Key algorithm',
      SubjectCn: 'Common name of the subject',
      SubjectDn: 'The unique name of the subject',
      IssuerCn: 'The common name of the publisher',
      IssuerDn: 'Unique name of the publisher',
      SerialNumber: 'Serial number of the certificate',
      CertNotBefore: 'Certificate start date',
      CertNotAfter: 'Certificate expiration date',
      AuthorityKeyIdentifier: 'ID of the UC key',
      Pem: 'Certificate in PEM format',
      StorageSelect: 'Certificate type',
      EmployeeName: 'Employee Name',
      Iban: 'Bank Account Number (IBAN)',
      NumberEmploymentOrder: 'Number of the employment order',
      OrderDate: 'Date of the employment order',
      ContractNumber: 'Contract number',
      Position: 'Position',
      Department: 'Department',
      ContractDate: 'Date of conclusion of the contract',
      СontractStartDate: 'Сontract start date',
      ContractEndDate: 'Сontract end date',
      ContractType: 'Contract type',
      EmploymentType: 'Employment type',
      Probation: 'Probation',
      PersonnelNumber: 'Personnel number',
      Salary: 'Salary',
      VacationDays: 'Vacation days',
      SalaryFund: 'Salary fund',
      DepartmentName: 'Department name',
      FormationDate: 'Date of formation of the department',
      HigherLevelDepartmentName: 'Name of the higher-level department',
      WorkSchedule: 'Work scheduleId',
      PositionName: 'Position name',
      PositionCount: 'Count of staff position',
      VacantUnitsCount: 'Vacant units count',
      DateWriteOffFrom: 'Date of write off from',
      DateWriteOffFromBefore: 'Date of write off before',
      OperationNumber: 'Operation number',
      Comment: 'Comment',
      Cause: 'Cause',
      Continue: 'Continue',

      // Validations label
      InputRequiredValidate: 'This field is required',
      EmailRequiredValidate: 'Invalid email address',
      ConditionsRequiredValidate: 'The conditions must be accepted',
      PasswordsMatch: 'Passwords must match',
      FieldOnlyDigits: 'The field should have digits only',
      FieldPhoneIncorrect: 'Filled in incorrectly',
      FieldOnlyDigitsBc: 'The field should only contain numbers from 11 to 29',

      // Buttons label
      Register: 'Register',
      Authenticate: 'Authenticate',
      Create: 'Creat',
      Add: 'Add',
      Edit: 'Edit',
      Attach: 'Attach',
      Move: 'Move',
      WriteOff: 'Write off',
      Save: 'Save',
      Cancel: 'Cancel',
      Complete: 'Complete',
      SearchButton: 'Find',
      Loading: 'Loading...',
      CheckCertificate: 'Check the certificate',
      GetKeyData: 'Get key data',
      No: 'No',
      Delete: 'Yes, Delete it!',
      Open: 'Yes, Open it!',
      Close: 'Yes, Close it!',

      // Modals Label
      ModalTmcReceiptAdded: 'Add product',
      ModalTmcMoveAdded: 'Added TMC move',
      CompleteWriteOff: 'Complete write-off',

      // Table headers
      HeaderName: 'Name',
      HeaderAmount: 'Amount',
      HeaderSellPrice: 'Sell price',
      HeaderSum: 'Sum',
      HeaderVat: 'Vat value %',
      HeaderVatValue: 'Vat value',
      HeaderCountToMove: 'Count to move',
      HeaderCountToWriteOff: 'Count To write off',
      HeaderChoose: 'Choose',
      HeaderStatus: 'Status',
      HeaderBin: 'IIN/BIN',
      HeaderPhoneNumber: 'Phone number',
      HeaderCounterpartyBin: 'Supplier IIN/BIN',
      HeaderFullNameDirector: 'Full name director',
      HeaderLegalAddress: 'Legal address',
      Warning: 'Warning',
      QuantityWarning:
        'The entered quantity must not exceed the current quantity',

      // Errors
      DefaultError: 'Error in filling in the fields',
      Error500: 'Something went wrong. Contact the support service.',
      CreatedError: 'Created',
      UnauthorizedError: 'Unauthorized',
      ForbiddenError: 'Forbidden',
      NotFound: 'Not Found',
      CheckInputCorrect: 'Check that the input is correct and try again',
      PhoneAlreadyExists: 'Phone already exists',
      EmailAlreadyExists: 'Email already exists',
      LoginAlreadyExists: 'Login already exists',
      BinRequired: 'BIN Required',
      GuidRequired: 'Guid Required',
      OrgNameRequired: 'Org name required',
      EmailRequired: 'Email required',
      NameRequired: 'Name required',
      AddressRequired: 'Address required',
      LegalAddressRequired: 'Legal address required',
      ActualAddressRequired: 'Actual address required',
      LegalAddressBirthplace: 'Legal address Birthplace',
      ActualAddressBirthplace: 'Actual address Birthplace',
      IikRequired: 'Individual identification code required',
      IicRequired: 'Individual identification code required',
      PhoneRequired: 'Phone required',
      ActionRequired: 'Action required',
      SupervisorNameRequired: 'Supervisor name required',
      OkvedRequired: 'Okved required',
      BikRequired: 'Bik required',
      BcRequired: 'Bc required',
      FirstNameRequired: 'First name required',
      LastNameRequired: 'Last name required',
      PatNameRequired: 'Pat name required',
      FullNameRequired: 'Full name required',
      ShortNameRequired: 'Short name required',
      CounterpartyBinRequired: 'Supplier BIN required',
      BankNameRequired: 'Bank Name required',
      BankBikRequired: 'Bank bik required',
      PersonIdnRequired: 'Person idn required',
      IssuingAuthorityRequired: 'Issuing authority required',
      BirthplaceRequired: 'Birthplace required',
      DoctypeRequired: 'Doctype required',
      CitizenshipRequired: 'Citizenship required',
      GenderRequired: 'Gender required',
      IinRequired: 'Iin required',
      IncorrectPhoneNumber: 'Incorrect phone number',
      IncorrectPhone: 'Incorrect phone number',
      WrongEmail: 'Wrong email',
      IncorrectOrgName: 'Incorrect organization name',
      IncorrectOrgBin: 'Incorrect organization BIN',
      IncorrectBik: 'Incorrect bank identification code',
      IncorrectBankBik: 'Incorrect bank identification code',
      IncorrectBin: 'Incorrect BIN',
      IncorrectIik: 'Incorrect individual identification code',
      IncorrectIic: 'Incorrect individual identification code',
      IncorrectLegalAddress: 'Incorrect legal address',
      IncorrectActualAddress: 'Incorrect actual address',
      IncorrectOkved: 'Type of activity (OKED)',
      IncorrectSupervisorName: 'Incorrect supervisor name',
      IncorrectType: 'Incorrect type',
      IncorrectAddress: 'Incorrect address',
      IncorrectName: 'Incorrect name',
      IncorrectBc: 'Incorrect code beneficiary',
      IncorrectCounterpartyBin: 'Incorrect supplier BIN',
      InvalidSrcStoreId: 'Invalid src store identification code',
      InvalidValues: 'Invalid products',
      InvalidBarcode: 'Invalid Barcode',
      InvalidOrgId: 'Requested organization does not exist',
      IncorrectBirthdayDate: 'Incorrect birthday date',
      IncorrectDateOfIssue: 'Incorrect date of issue',
      IncorrectBirthplace: 'Incorrect birthplace',
      IncorrectContractStartDate: 'Incorrect contract start date',
      IncorrectContractEndDate: 'Incorrect contract end date',
      ErrorСonnectingNCALayer:
        'Error connecting to NCALayer. Launch NCALayer and reload the page.',
      ConnectionClosedNCALayer: 'Connection with NCALayer has been closed',
      CertificateExpired: 'The certificate has expired',
      CertificateRevoked: 'Certificate revoked',

      // Select by Forms
      Creation: 'Creation',
      Editing: 'Editing',
      SelectCounterparty: 'Select supplier',
      SelectEmployee: 'Select employee',
      SelectStorage: 'Select storage',
      SelectStorageFrom: 'Move from storage',
      SelectStorageTo: 'Move to storage',

      // Page text
      ListOrganizationIsEmpty: 'The list of organizations is empty',
      ListStoragesIsEmpty: 'The list of storages is empty',
      ListCounterpartiesIsEmpty: 'The list of counterparties is empty',
      ListCashRegistersIsEmpty: 'The list of cash registers is empty',
      ListPersonsIsEmpty: 'The list of personal data is empty',
      ListEmployeesIsEmpty: 'The list of employees is empty',
      ListDepartmentsIsEmpty: 'The list of departments is empty',
      ListPositionsIsEmpty: 'The list of positions is empty',
      ListStaffPositionsIsEmpty: 'The list of staff positions is empty',
      ListPriceListIsEmpty: 'The list of price list is empty',
      ListСancellationsIsEmpty: 'The list of Сancellations list is empty',
      ListСancellationProductsIsEmpty:
        'The list of Сancellation products list is empty',
      StorageAddedSuccessfully: 'Storage added successfully',
      StorageEditedSuccessfully: 'Storage edited successfully',
      OrganizationAddedSuccessfully: 'Organization added successfully',
      OrganizationEditedSuccessfully: 'Organization edited successfully',
      UserEditedSuccessfully: 'User edited successfully',
      TmcAddedSuccessfully: 'TMC added successfully',
      PersonAddedSuccessfully:
        'The card with personal data has been successfully added',
      PersonEditedSuccessfully:
        'The card with personal data has been successfully edited',
      EmployeeAddedSuccessfully: 'Employee added successfully',
      EmployeeEditedSuccessfully: 'Employee edited successfully',
      DepartmentAddedSuccessfully: 'Department added successfully',
      DepartmentEditedSuccessfully: 'Department edited successfully',
      PositionAddedSuccessfully: 'Position added successfully',
      PositionEditedSuccessfully: 'Position edited successfully',
      StaffPositionAddedSuccessfully: 'Staff position added successfully',
      StaffPositionEditedSuccessfully: 'Staff position edited successfully',
      CounterpartyAddedSuccessfully: 'Supplier added successfully',
      CounterpartyEditedSuccessfully: 'Supplier edited successfully',
      CashRegistersAddedSuccessfully: 'Cash registers added successfully',
      CashRegistersEditedSuccessfully: 'Cash registers edited successfully',
      CashRegistersAttachedSuccessfully: 'Cash registers Attached successfully',
      AreYouSure: 'Are you sure?',
      AreYouSureDeleteStorage: 'You want to delete this storage?',
      AreYouSureDeleteCounterparty: 'You want to delete this supplier?',
      AreYouSureDeleteCashRegisters: 'You want to delete this cash registers?',
      AreYouSureDeletePerson:
        'Do you want to delete a card with personal data?',
      AreYouSureDeleteEmployee: 'You want to delete this employee?',
      AreYouSureDeletePosition: 'You want to delete this position?',
      AreYouSureDeleteDepartment: 'You want to delete this department?',
      AreYouSureDeleteStaffPosition: 'You want to delete this staff position?',
      AreYouSureCloseStaffPosition: 'You want to close this staff position?',
      AreYouSureOpenStaffPosition: 'You want to open this staff position?',
      BankDetails: 'Bank details',
      ReceiveError: 'Failed to complete goods receipt. Contact Support',
      RowsSelected: 'rows(s) selected',
      DeleteSelectedRows: 'Delete selected rows',
      NotItemsInStock: 'No items in stock',
      ForgotPassword: 'Forgot your password?',
      UserIsNotFound: 'User is not found',
      TemporaryPasswordWasSending:
        'A new temporary password has been sent to your e-mail to enter the system',
      TmcStoreIsNotEmpty:
        'Deleting a warehouse is not possible because there is a balance in the warehouse.',
      InvalidEmail: 'Enter a valid E-mail, for example: example@site.com',
      IncorrectIin: 'IIN / BIN must contain only numbers',
      CounterpartySupervisor: 'Supervisor',
      counterparty_already_exists: 'Counterparty already exist',
      cert_expired: 'The certificate has expired',
      cert_revoked: 'Certificate revoked',
      cert_invalid: 'Invalid certificate',
      invalid_cert_data: 'Invalid certificate',
      InTestNotWork: 'This feature does not work in the test version.',
      'Checkout mode': 'Checkout mode',
      'Checkout reports': 'Checkout reports',
      'Shift reports': 'Shift reports',
      'Cashier reports': 'Cashier reports',
      'Z-report report': 'Z-report report',
      'Price lists': 'Price lists',
      'Acceptance of goods': 'Acceptance of goods',
      'Movement of Goods': 'Movement of Goods',
      'Write-off of the Goods': 'Write-off of the Goods',
      'Return of goods to the Supplier': 'Return of goods to the Supplier',
      Inventory: 'Inventory',
      'Accounting for income and cash flow':
        'Accounting for income and cash flow',
      'Sales figures': 'Sales figures',
      'Money flow Bank': 'Money flow Bank',
      'Movement of money cash desk (cash desk)':
        'Movement of money cash desk (cash desk)',
      'Settlements with personnel': 'Settlements with personnel',
      'Profit and loss': 'Profit and loss',
      'Taxes and financial reporting': 'Taxes and financial reporting',
      'Extract from tax': 'Extract from tax',
      'Tax reporting form 910': 'Tax reporting form 910',
      'Tax registers': 'Tax registers',
      'Reference books': 'Reference books',
      'Warehouses/shops': 'Warehouses/shops',
      SNT: 'SNT',
      ESF: 'ESF',
      Documentation: 'Documentation',
      Settings: 'Settings',
      Help: 'Help (knowledge base)',
    },
  },
  ru: {
    translation: {
      // Nav Links label
      'Login link': 'Войти',
      'Sign Up link': 'Зарегистрироваться',
      'Edit link': 'Редактировать',
      'Organization link': 'Создать организацию',
      'Organization list link': 'Управление организациями',
      'Storage link': 'Добавить склад',
      'Storage list link': 'Склады',
      'Counterparty link': 'Добавить поставщика',
      'Counterparty list link': 'Поставщики',
      'Сancellation link': 'Создать списание',
      'Сancellation list link': 'Списание ТМЦ',
      'Tmc receipt link': 'Поступление ТМЦ',
      'Tmc move link': 'Перемещение ТМЦ',
      'Cash register link': 'Создать кассу',
      'Cash register list link': 'Кассы',
      'Person link': 'Создать карточку персональных данных',
      'Person list link': 'Персональные данные',
      'Employee link': 'Прием на работу сотрудника',
      'Employee list link': 'Сотрудники',
      'Department link': 'Создать подразделение',
      'Department list link': 'Подразделение',
      'Position link': 'Создать должность',
      'Position list link': 'Должность',
      'Staff Position link': 'Создать штатное расписание',
      'Staff Position list link': 'Штатное расписание',
      'Nomenclature link': 'Создать номенклатуру',
      'Nomenclature list link': 'Номенклатуры',
      'My data link': 'Мои данные',
      'Commodity material values link': 'Товаро-учетная система',
      'Users list link': 'Пользователи',
      'Employee registration card link':
        'Заработная плата и управление персоналом',
      'Next Page': 'Следующая страница',
      'Previous Page': 'Предыдущая страница',
      'Rows per page': 'Количество строк на странице:',
      'Show Columns': 'Показать столбцы',
      'Show/Hide Table Columns': 'Показывать/Скрывать столбцы таблицы',
      'Filter all': 'Все',
      'Not found': 'Извините, совпадающих записей не найдено',
      ToolTip: 'Сортировка',
      СolumnHeaderTooltip: 'Сортировать по',
      Filteres: 'ФИЛЬТРЫ',
      Reset: 'СБРОСИТЬ',
      DisplayRows: 'из',
      Opened: 'Открыто',
      Closed: 'Закрыто',
      'Date closed': 'Дата закрытия',
      'Toolbar search': 'Поиск',
      'Toolbar downloadCsv': 'Скачать CSV',
      'Toolbar print': 'Печать',
      'Toolbar viewColumns': 'Просмотр столбцов',
      'Toolbar filterTable': 'Фильтровать таблицу',

      // Calendar
      January: 'Январь',
      February: 'Февраль',
      March: 'Март',
      April: 'Апрель',
      May: 'Май',
      June: 'Июнь',
      July: 'Июль',
      August: 'Август',
      September: 'Сентябрь',
      October: 'Октябрь',
      November: 'Ноябрь',
      December: 'Декабрь',

      // Pages header
      Main: 'Торгово-учетная система ТИС',
      Authorization: 'Авторизация',
      Registration: 'Регистрация',
      EditUser: 'Редактирование пользователя',
      OrganizationCreate: 'Создание организации',
      OrganizationEdit: 'Редактирование организации',
      OrganizationList: 'Список организаций',
      StorageCreate: 'Создание склада',
      StorageEdit: 'Редактирование склада',
      StorageList: 'Список складов',
      TmcList: 'TMЦ',
      TmcReceipt: 'Поступление ТМЦ',
      TmcMove: 'Перемещение ТМЦ',
      TmcСancellation: 'Списание ТМЦ',
      TmcСancellationDetailed: 'Детали списания',
      TmcСancellationCreate: 'Создать списание',
      Сancellations: 'Списания',
      CounterpartyCreate: 'Создание карточки поставщика',
      CounterpartyEdit: 'Редактирование карточки поставщика',
      CounterpartyList: 'Список поставщиков',
      CashRegisterCreate: 'Создание кассы',
      CashRegisterEdit: 'Редактирование кассы',
      CashRegisterList: 'Список касс',
      CashRegisterAttach: 'Привязка кассы',
      PersonCreate: 'Создание карточки персональных данных',
      PersonEdit: 'Редактирование карточки персональных данных',
      PersonList: 'Персональные данные',
      EmployeeCreate: 'Прием на работу сотрудника',
      EmployeeEdit: 'Редактирование сотрудника',
      EmployeeList: 'Список сотрудников',
      DepartmentCreate: 'Создание подразделения',
      DepartmentEdit: 'Редактирование подразделения',
      DepartmentList: 'Список подразделений',
      PositionCreate: 'Создание должности',
      PositionEdit: 'Редактирование должности',
      PositionList: 'Список должностей',
      StaffPositionCreate: 'Создание штатного расписания',
      StaffPositionEdit: 'Редактирование штатного расписания',
      StaffPositionList: 'Список штатных расписаний',
      NomenclatureCreate: 'Создание номенклатуры',
      NomenclatureEdit: 'Редактирование номенклатуры',
      NomenclatureList: 'Список номенклатур',

      // Fields label
      SearchInput: 'Поиск',
      FIO: 'ФИО (Фамилия Имя Отчество)',
      FirstName: 'Имя',
      LastName: 'Фамилия',
      PatName: 'Отчество',
      Login: 'Логин',
      Email: 'E-mail',
      Telephone: 'Телефон',
      TelephonePlaceholder: '+7(___) ___-__-__',
      Password: 'Пароль',
      PasswordConfirm: 'Подтверждение пароля',
      Terms: 'Я согласен с условиями и положениями',
      DuplicationLegalAddress: 'Совпадает юридическим адресом',
      DuplicationRegistrationAddress: 'Совпадает адресом прописки',
      Date: 'Дата',
      Bin: 'ИИН/БИН (Индивидуальный идентификационный номер/бизнес-идентификационный номер)',
      OrganizationBin: 'ИИН/БИН организации',
      OrganizationFullName:
        'Наименование организации или ИП (Индивидуальный предприниматель)',
      SupervisorFullName: 'ФИО руководителя',
      Guid: 'Код',
      Supervisor: 'Руководитель',
      Okved: 'Вид деятельности  (ОКЭД)',
      OrganizationName: 'Наименование организации',
      StorageName: 'Наименование склада',
      StorageAddress: 'Адрес склада',
      StorageType: 'Тип склада',
      NameRus: 'Наименование (на русском)',
      ProductName: 'Наименование товара',
      NameKaz: 'Наименование (на казахском)',
      СustomInfo: 'Дополнительная иформация',
      NomenclatureType: 'Тип номенклатуры',
      Nomenclature: 'Номенклатура',
      UnitMeasurement: 'Единица измерения',
      AttributeWeightProduct: 'Признак весового товара',
      Barcode: 'Штрих код',
      VendorCode: 'Артикул',
      VendorCodeExt: 'Артикул Магнум',
      Markup: 'Наценка',
      MarkupType: 'Наценка, единицы измерения',
      MarkupPercentage: 'Наценка за единицу в %',
      MarkupValue: 'Наценка за единицу в Тг.',
      VatValue: 'НДС',
      Vat: 'НДС в %',
      Units: 'Код ТНВЭД',
      VendorBin: 'ИИН/БИН поставщика',
      CustomInfo: 'Дополнительная информация (описание)',
      VendorInfo: 'Информация о производителе (страна)',
      TypeOperation: 'Тип операции',
      ActualAddress: 'Фактический адрес',
      LegalAddress: 'Юридический адрес',
      ResidentialAddress: 'Адрес проживания',
      RegistrationAddress: 'Адрес прописки',
      FullName: 'Полное наименование',
      ShortName: 'Краткое наименование',
      BankName: 'Наименование банка',
      Iic: 'ИИК (Индивидуальный идентификационный код)',
      Iin: 'ИИН (Индивидуальный идентификационный номер)',
      Bik: ' БИК (Банковский идентификационный код)',
      Gender: 'Пол',
      Citizenship: 'Гражданство',
      Birthplace: 'Место рождения',
      DocType: 'Документ, удостоверяющий личность',
      PersonIdn: 'Номер документа',
      IssuingAuthority: 'Орган выдачи',
      SocialStatus: 'Социальный статус',
      IsForeign: 'Иностранный гражданин',
      Birthday: 'Дата рождения',
      DateOfIssue: 'Дата выдачи',
      Amount: 'Количество / вес',
      PurchasePrice: 'Закупочная цена тенге за единицу',
      CostPrice: 'Себестоимость',
      SellPrice: 'Цена продажи',
      RecipientCode: 'КБе (Код Бенефициара)',
      CounterpartyBin: 'ИИН/БИН поставщика',
      OganizationalLegalForm: 'Форма организации',
      CounterpartyId: 'Идентификатор поставщика',
      CounterpartyFullName:
        'Наименование организации или ИП (Индивидуальный предприниматель)',
      CounterpartyShortName: 'Краткое наименование',
      CashRegistersName: 'Наименование кассы',
      CashRegistersId: 'Идентификатор кассы',
      CashRegisterLinked: 'Касса привязана',
      Alias: 'Алиас ключа',
      KeyId: 'Идентификатор ключа',
      Algorithm: 'Алгоритм ключа',
      SubjectCn: 'Общепринятое имя субъекта',
      SubjectDn: 'Уникальное имя субъекта',
      IssuerCn: 'Общепринятое имя издателя',
      IssuerDn: 'Уникальное имя издателя',
      SerialNumber: 'Серийный номер сертификата',
      CertNotBefore: 'Дата начала действия сертификата',
      CertNotAfter: 'Дата окончания действия сертификата',
      AuthorityKeyIdentifier: 'Идентификатор ключа УЦ',
      Pem: 'Сертификат в формате PEM',
      StorageSelect: 'Тип сертификата',
      EmployeeName: 'Имя сотрудника',
      Iban: 'Номер банковского счета (IBAN)',
      NumberEmploymentOrder: 'Номер приказа о приеме на работу',
      OrderDate: 'Дата приказа о приеме на работу',
      ContractNumber: 'Номер договора',
      Position: 'Должность',
      Department: 'Подразделение',
      ContractDate: 'Дата заключения договора',
      СontractStartDate: 'Дата начала договора',
      ContractEndDate: 'Дата окончания договора',
      ContractType: 'Тип договора',
      EmploymentType: 'Вид занятости',
      Probation: 'Испытательный срок календарных дней',
      PersonnelNumber: 'Табельный номер',
      Salary: 'Оклад',
      VacationDays: 'Количество дней отпуска',
      SalaryFund: 'Фонд оплаты труда сотрудника',
      DepartmentName: 'Наименование подразделения',
      FormationDate: 'Дата формирования подразделения',
      HigherLevelDepartmentName: 'Наименование вышестоящего подразделения',
      WorkSchedule: 'График работы',
      PositionName: 'Наименование должности',
      PositionCount: 'Количество штатных единиц',
      VacantUnitsCount: 'Количество вакантных единиц',
      DateWriteOffFrom: 'Дата списания от',
      DateWriteOffBefore: 'Дата списания до',
      OperationNumber: 'Номер операции',
      Comment: 'Комментарий',
      Cause: 'Причина',
      Continue: 'Продолжить',

      // Validations label
      InputRequiredValidate: 'Поле обязательно для заполнения',
      EmailRequiredValidate: 'Невалидный адрес почты',
      ConditionsRequiredValidate: 'Условия должны быть приняты',
      PasswordsMatch: 'Пароли должны совпадать',
      FieldOnlyDigits: 'В поле должны быть только цифры',
      FieldPhoneIncorrect: 'Заполнено некорректно',
      FieldOnlyDigitsBc: 'В поле должны быть только цифры от 11 до 29',

      // Buttons label
      Register: 'Зарегистрироваться',
      Authenticate: 'Войти',
      Create: 'Создать',
      Add: 'Добавить',
      Edit: 'Редактировать',
      Attach: 'Привязать',
      Move: 'Переместить',
      WriteOff: 'Списать',
      Save: 'Сохранить',
      Cancel: 'Отменить',
      Complete: 'Завершить',
      SearchButton: 'Найти',
      Loading: 'Обработка...',
      CheckCertificate: 'Проверить сертификат',
      GetKeyData: 'Получить данные ключа',
      No: 'Нет',
      Delete: 'Да, Удалить его!',
      Open: 'Да, Открыть его!',
      Close: 'Да, Закрыть его!',

      // Modals Label
      ModalTmcReceiptAdded: 'Добавить товар',
      ModalTmcMoveAdded: 'Добавить перемещение ТМЦ',
      CompleteWriteOff: 'Завершить списание',

      // Table headers
      HeaderName: 'Наименование',
      HeaderAmount: 'Количество',
      HeaderSellPrice: 'Цена',
      HeaderSum: 'Сумма',
      HeaderVat: 'НДС %',
      HeaderVatValue: 'НДС сумма',
      HeaderCountToMove: 'Переместить',
      HeaderCountToWriteOff: 'Списать',
      HeaderChoose: 'Выбрать',
      HeaderStatus: 'Статус',
      HeaderBin: 'ИИН/БИН',
      HeaderPhoneNumber: 'Номер телефона',
      HeaderCounterpartyBin: 'ИИН/БИН поставщика',
      HeaderFullNameDirector: 'ФИО руководителя',
      HeaderLegalAddress: 'Юридический адрес',
      Warning: 'Предупреждение',
      QuantityWarning:
        'Введенное количество не должно превышать текущее количество',

      // Errors
      DefaultError: 'Ошибка в заполнении полей',
      Error500: 'Что-то пошло не так. Обратитесь в службу поддержки',
      CreatedError: 'Созданный',
      UnauthorizedError: 'Неавторизованный',
      ForbiddenError: 'Запрещенный',
      NotFound: 'Не найдено',
      CheckInputCorrect: 'Проверьте правильность ввода и повторите попытку',
      PhoneAlreadyExists: 'Телефон уже существует',
      EmailAlreadyExists: 'Email уже существует',
      LoginAlreadyExists: 'Логин уже существует',
      BinRequired: 'Поле "БИН" обязателен для заполнения',
      GuidRequired: 'Поле "Guid" обязателен для заполнения',
      OrgNameRequired:
        'Поле "Наименование организации" обязателен для заполнения',
      EmailRequired: 'Поле "Email" обязателен для заполнения',
      NameRequired: 'Поле "Наименование" обязателен для заполнения',
      AddressRequired: 'Поле "Адрес" обязателен для заполнения',
      LegalAddressRequired:
        'Поле "Юридический адрес" обязателен для заполнения',
      ActualAddressRequired:
        'Поле "Фактический адрес" обязателен для заполнения',
      LegalAddressBirthplace: 'Поле "Юридический адрес" некорректно заполнен',
      ActualAddressBirthplace: 'Поле "Фактический адрес" некорректно заполнен',
      IikRequired:
        'Поле "Индивидуальный идентификационный код" обязателен для заполнения',
      IicRequired:
        'Поле "Индивидуальный идентификационный код" обязателен для заполнения',
      PhoneRequired: 'Поле "Телефон" обязателен для заполнения',
      ActionRequired: 'Поле "Action" обязателен для заполнения',
      SupervisorNameRequired: 'Поле "Руководитель" обязателен для заполнения',
      OkvedRequired: 'Поле "Вид деятельности (ОКЭД)" обязателен для заполнения',
      BikRequired:
        'Поле "Банковский идентификационный код" обязателен для заполнения',
      BcRequired: 'Поле "Код бенефициара" обязателен для заполнения',
      FirstNameRequired: 'Поле "Имя" обязателен для заполнения',
      LastNameRequired: 'Поле "Фамилия" обязателен для заполнения',
      PatNameRequired: 'Поле "Отчество" обязателен для заполнения',
      FullNameRequired: 'Поле "Полное наименование" обязателен для заполнения',
      ShortNameRequired:
        'Поле "Краткое наименование" обязателен для заполнения',
      CounterpartyBinRequired:
        'Поле "ИИН/БИН поставщика" обязателен для заполнения',
      BankNameRequired: 'Поле "Наименование банка" обязателен для заполнения',
      BankBikRequired: 'Поле "БИК банка" обязателен для заполнения',
      PersonIdnRequired: 'Поле "Номер документа" обязателен для заполнения',
      IssuingAuthorityRequired: 'Поле "Орган выдачи" обязателен для заполнения',
      BirthplaceRequired: 'Поле "Место рождения" обязателен для заполнения',
      DoctypeRequired:
        'Поле "Документ, удостоверяющий личность" обязателен для заполнения',
      CitizenshipRequired: 'Поле "Гражданство" обязателен для заполнения',
      GenderRequired: 'Поле "Пол" обязателен для заполнения',
      IinRequired: 'Поле "ИИН" обязателен для заполнения',
      IncorrectPhoneNumber: 'Неверный номер телефона',
      IncorrectPhone: 'Неверный номер телефона',
      WrongEmail: 'Неверная электронная почта',
      IncorrectOrgName: 'Неверное имя организации',
      IncorrectOrgBin: 'Неверный БИН организации',
      IncorrectBik: 'Неверный банковский идентификационный код',
      IncorrectBankBik: 'Неверный банковский идентификационный код',
      IncorrectBin: 'Неверный БИН',
      IncorrectIik: 'Неверный индивидуальный идентификационный код',
      IncorrectIic: 'Неверный индивидуальный идентификационный код',
      IncorrectLegalAddress: 'Неверный юридический адрес',
      IncorrectActualAddress: 'Неверный фактический адрес',
      IncorrectOkved: 'Неверный вид деятельности  (ОКЭД)',
      IncorrectSupervisorName: 'Неверное имя руководителя',
      IncorrectType: 'Неверный тип',
      IncorrectAddress: 'Неверный адрес',
      IncorrectName: 'Неверное имя',
      IncorrectBc: 'Неверный код бенефициара',
      IncorrectCounterpartyBin: 'Неверный БИН поставщика',
      InvalidSrcStoreId: 'Неверный идентификатор склада',
      InvalidValues: 'Неверные товары',
      InvalidBarcode: 'Неверный штрихкод',
      InvalidOrgId: 'Запрашиваемой организации не существует',
      IncorrectBirthdayDate: 'Неверная дата рождения',
      IncorrectDateOfIssue: 'Неверные дата выдачи',
      IncorrectBirthplace: 'Неверное место рождения',
      IncorrectContractStartDate: 'Неверная дата начала договора',
      IncorrectContractEndDate: 'Неверная дата окончания договора',
      ErrorСonnectingNCALayer:
        'Ошибка при подключении к NCALayer. Запустите NCALayer и перезагрузите страницу.',
      ConnectionClosedNCALayer: 'Соединение с NCALayer было закрыто',
      CertificateExpired: 'Срок действия сертификата истек',
      CertificateRevoked: 'Сертификат отозван',

      // Select by Forms
      Creation: 'Создание',
      Editing: 'Редактирование',
      SelectCounterparty: 'Выберите поставщика',
      SelectEmployee: 'Выберите сотрудника',
      SelectStorage: 'Выберите склад',
      SelectStorageFrom: 'Переместить со склада',
      SelectStorageTo: 'Переместить на склад',

      // Page text
      ListOrganizationIsEmpty: 'Список организаций пуст',
      ListStoragesIsEmpty: 'Список складов пуст',
      ListCounterpartiesIsEmpty: 'Список поставщиков пуст',
      ListCashRegistersIsEmpty: 'Список касс пуст',
      ListPersonsIsEmpty: 'Список персональных данных пуст',
      ListEmployeesIsEmpty: 'Список сотрудников пуст',
      ListDepartmentsIsEmpty: 'Список подразделений пуст',
      ListPositionsIsEmpty: 'Список должностей пуст',
      ListStaffPositionsIsEmpty: 'Список штатного расписания пуст',
      ListPriceListIsEmpty: 'Список прайс листов пуст',
      ListСancellationsIsEmpty: 'Список списанных товаров пуст',
      ListСancellationProductsIsEmpty: 'Список списанных товаров пуст',
      StorageAddedSuccessfully: 'Склад успешно добавлен',
      StorageEditedSuccessfully: 'Склад успешно отредактирован',
      OrganizationAddedSuccessfully: 'Организация успешно добавлена',
      OrganizationEditedSuccessfully: 'Организация успешно отредактирована',
      UserEditedSuccessfully: 'Пользователь успешно отредактирован',
      TmcAddedSuccessfully: 'ТМЦ успешно добавлена',
      PersonAddedSuccessfully:
        'Карточка с персональными данными успешно добавлена',
      PersonEditedSuccessfully:
        'Карточка с персональными данными успешно отредактирована',
      EmployeeAddedSuccessfully: 'Сотрудник успешно добавлен',
      EmployeeEditedSuccessfully: 'Сотрудник успешно отредактирован',
      DepartmentAddedSuccessfully: 'Подразделение успешно добавлено',
      DepartmentEditedSuccessfully: 'Подразделение успешно отредактировано',
      PositionAddedSuccessfully: 'Должность успешно добавлена',
      PositionEditedSuccessfully: 'Должность успешно отредактирована',
      StaffPositionAddedSuccessfully: 'Штатное расписание успешно добавлено',
      StaffPositionEditedSuccessfully:
        'Штатное расписание успешно отредактирована',
      CounterpartyAddedSuccessfully: 'Поставщик успешно добавлен',
      CounterpartyEditedSuccessfully: 'Поставщик успешно отредактирован',
      CashRegistersAddedSuccessfully: 'Касса успешно добавлена',
      CashRegistersEditedSuccessfully: 'Касса успешно отредактирована',
      CashRegistersAttachedSuccessfully: 'Касса успешно привязана',
      AreYouSure: 'Вы уверены?',
      AreYouSureDeleteStorage: 'Вы хотите удалить этот склад?',
      AreYouSureDeleteCounterparty: 'Вы хотите удалить этого поставщика?',
      AreYouSureDeleteCashRegisters: 'Вы хотите удалить эту кассу?',
      AreYouSureDeletePerson:
        'Вы хотите удалить карточку с персональными данными?',
      AreYouSureDeleteEmployee: 'Вы хотите удалить этого сотрудника?',
      AreYouSureDeletePosition: 'Вы хотите удалить эту должность?',
      AreYouSureDeleteDepartment: 'Вы хотите удалить это подразделение?',
      AreYouSureDeleteStaffPosition:
        'Вы хотите удалить это штатное расписание?',
      AreYouSureCloseStaffPosition: 'Вы хотите закрыть это штатное расписание?',
      AreYouSureOpenStaffPosition: 'Вы хотите открыть это штатное расписание?',
      BankDetails: 'Банковские реквизиты',
      ReceiveError:
        'Не удалось завершить поступление товара. Обратитесь в службу поддержки',
      RowsSelected: 'строк выбрано',
      DeleteSelectedRows: 'Удалить выбранные строки',
      NotItemsInStock: 'Нет товаров на складе',
      ForgotPassword: 'Забыли пароль?',
      UserIsNotFound: 'Пользователь не найден',
      TemporaryPasswordWasSending:
        'Вам на указанный E-mail был отправлен временный пароль',
      TmcStoreIsNotEmpty:
        'Удаление склада невозможно, так как имеется остаток на складе',
      IncorrectIin: 'ИИН/БИН должен содержать только цифры',
      InvalidEmail: 'Введите корректный E-mail, например: example@site.com',
      CounterpartySupervisor: 'ФИО Руководителя',
      counterparty_already_exists: 'Поставщик уже добавлен',
      cert_expired: 'Срок действия сертификата истёк',
      cert_revoked: 'Сертификат отозван',
      cert_invalid: 'Некорректный сертификат',
      invalid_cert_data: 'Некорректный сертификат',
      InTestNotWork: 'В тестовой версии эта функция не работает',
      'Checkout mode': 'Режим работы кассы',
      'Checkout reports': 'Отчеты по кассам',
      'Shift reports': 'Отчеты по сменам',
      'Cashier reports': 'Отчеты по кассирам',
      'Z-report report': 'Отчет по Z-отчетам',
      'Price lists': 'Прайс-листы',
      'Acceptance of goods': 'Приемка Товара',
      'Movement of Goods': 'Перемещение Товара',
      'Write-off of the Goods': 'Списание Товара',
      'Return of goods to the Supplier': 'Возврат товара Поставщику',
      Inventory: 'Инвентаризация',
      'Accounting for income and cash flow': 'Учет доходов и движения денег',
      'Sales figures': 'Показатели продаж',
      'Money flow Bank': 'Движение денег Банк',
      'Movement of money cash desk (cash desk)': 'Движение денег касса (кассы)',
      'Settlements with personnel': 'Расчеты с персоналом',
      'Profit and loss': 'Прибыли и убытки',
      'Taxes and financial reporting': 'Налоги и финансовая отчетность',
      'Extract from tax': 'Выписка с налоговых',
      'Tax reporting form 910': 'Налоговая отчетность форма 910',
      'Tax registers': 'Налоговые регистры',
      'Reference books': 'Справочники',
      'Warehouses/shops': 'Склады/магазины',
      SNT: 'СНТ',
      ESF: 'ЭСФ',
      Documentation: 'Документы',
      Settings: 'Настройки',
      Help: 'Помощь (база знаний)',
    },
  },
  kz: {
    translation: {
      // Nav Links label
      'Login link': 'Кіру',
      'Sign Up link': 'Тіркелу',
      'Edit link': 'Редакциялау',
      'Organization link': 'Ұйым құру',
      'Organization list link': 'Ұйымдастыру',
      'Storage link': 'Қойма құру',
      'Storage list link': 'Қоймалар',
      'Counterparty link': 'Жеткізушіні қосу',
      'Counterparty list link': 'Жеткізушілер',
      'Сancellation link': 'Есептен шығаруды құру',
      'Сancellation list link': 'Есептен шығару ТМЦ',
      'Tmc receipt link': 'ТМЦ tүсімі',
      'Tmc move link': 'ТМЦ жылжыту',
      'Cash register link': 'Кассаны құру',
      'Cash register list link': 'Кассалар',
      'Person link': 'Дербес деректер карточкасын жасау',
      'Person list link': 'Дербес деректер',
      'Employee link': 'Жұмысқа қабылдау қызметкерді',
      'Employee list link': 'Қызметкерлер',
      'Department link': 'Бөлімшені құру',
      'Department list link': 'Бөлімшелер',
      'Position link': 'Лауазым құру',
      'Position list link': 'Лауазым',
      'Staff Position link': 'Штаттық кесте құру',
      'Staff Position list link': 'Штаттық кесте',
      'Nomenclature link': 'Номенклатураны құру',
      'Nomenclature list link': 'Номенклатуралар',
      'My data link': 'Менің',
      'Commodity material values link': 'ТМЦ',
      'Users list link': 'Пайдаланушылар',
      'Employee registration card link': 'Қызметкерді есепке алу карточкасы',
      'Next Page': 'Келесі бет',
      'Previous Page': 'Алдыңғы бет',
      'Rows per page': 'Беттегі жолдар саны:',
      'Show Columns': 'Көрсету бағандар',
      'Show/Hide Table Columns': 'Кесте бағандарын көрсету / жасыру',
      'Filter all': 'Барлық',
      'Not found': 'Кешіріңіз, сәйкес жазбалар табылған жоқ',
      ToolTip: 'Сұрыптау',
      СolumnHeaderTooltip: 'Бойынша сұрыптау',
      Filteres: 'Сүзгілер',
      Reset: 'Тастау',
      DisplayRows: 'бірі',
      Opened: 'Ашық',
      Closed: 'Жабық',
      'Date closed': 'Жабылу күні',
      'Toolbar search': 'Іздеу',
      'Toolbar downloadCsv': 'Жүктеу CSV',
      'Toolbar print': 'Басып шығару',
      'Toolbar viewColumns': 'Бағандарды қарау',
      'Toolbar filterTable': 'Кестені сүзу',

      // Calendar
      January: 'Қаңтар',
      February: 'Ақпан',
      March: 'Март',
      April: 'Наурыз',
      May: 'Мамыр',
      June: 'Маусым',
      July: 'Шілде',
      August: 'Тамыз',
      September: 'Қыркүйек',
      October: 'Қазан',
      November: 'Қараша',
      December: 'Желтоқсан',

      // Pages header
      Main: 'Сауда-есеп жүйесі ТИС',
      Authorization: 'Авторландыру',
      Registration: 'Тіркеу',
      EditUser: 'Пайдаланушыны өңдеу',
      OrganizationCreate: 'Ұйым құру',
      OrganizationEdit: 'Ұйымды редакциялау',
      OrganizationList: 'Ұйымдар тізімі',
      StorageCreate: 'Қойма құру',
      StorageEdit: 'Қойманы өңдеу',
      StorageList: 'Қоймалар тізімі',
      TmcList: 'TMЦ',
      TmcReceipt: 'TMЦ түсуі',
      TmcMove: 'TMЦ жылжыту',
      TmcСancellation: 'ТМЦ есептен шығару',
      TmcСancellationDetailed: 'Есептен шығару мәліметтері',
      TmcСancellationCreate: 'Есептен шығаруды құру',
      Сancellations: 'Есептен шығару',
      CounterpartyCreate: 'Жеткізушінің картасын жасау',
      CounterpartyEdit: 'Өнім берушінің карточкасын редакциялау',
      CounterpartyList: 'Жеткізушілер тізімі',
      CashRegisterCreate: 'Кассаны құру',
      CashRegisterEdit: 'Кассаны өңде',
      CashRegisterList: 'Кассалар тізімі',
      CashRegisterAttach: 'Кассаны байланыстыру',
      PersonCreate: 'Дербес деректер карточкасын құру',
      PersonEdit: 'Дербес деректер карточкасын редакциялау',
      PersonList: 'Дербес деректер',
      EmployeeCreate: 'Жұмысқа қабылдау қызметкерді',
      EmployeeEdit: 'Қызметкерді редакциялау',
      EmployeeList: 'Қызметкерлер тізімі',
      DepartmentCreate: 'Бөлімшені құру',
      DepartmentEdit: 'Бөлімшені редакциялау',
      DepartmentList: 'Бөлімшелер тізімі',
      PositionCreate: 'Лауазым құру',
      PositionEdit: 'Лауазымның редакциялануы',
      PositionList: 'Лауазымдар тізімі',
      StaffPositionCreate: 'Штаттық кестені құру',
      StaffPositionEdit: 'Штаттық кестені редакциялау',
      StaffPositionList: 'Штат кестелерінің тізімі',
      NomenclatureCreate: 'Номенклатураны құру',
      NomenclatureEdit: 'Номенклатураны редакциялау',
      NomenclatureList: 'Номенклатуралар тізімі',

      // Fields label
      SearchInput: 'Іздеу',
      FIO: 'Аты-жөні (Тегі, Аты, Әкесінің Аты)',
      FirstName: 'Аты',
      LastName: 'Тегі',
      PatName: 'Әкесінің',
      Login: 'Кіру',
      Email: 'Электрондық пошта',
      Telephone: 'Телефон',
      TelephonePlaceholder: '+7(___) ___-__-__',
      Password: 'Құпиясөзді',
      PasswordConfirm: 'Құпиясөзді растау',
      Terms: 'Шарттар мен ережелермен келісіңіз',
      DuplicationLegalAddress: 'Заңды мекен-жайы сәйкес келеді',
      DuplicationRegistrationAddress: 'Тіркеу мекен-жайына сәйкес келеді',
      Date: 'Күні',
      Bin: 'ИИН/БИН (Жеке сәйкестендіру нөмірі/бизнес сәйкестендіру нөмірі)',
      OrganizationBin: 'Ұйымның ЖСН / БСН',
      OrganizationFullName: 'Ұйымның немесе ЖК атауы (Жеке кәсіпкер)',
      SupervisorFullName: 'Басшының аты-жөні',
      Guid: 'Guid',
      Supervisor: 'Жетекшісі',
      Okved: 'Қызмет түрі (OKED)',
      OrganizationName: 'Ұйымның атауы',
      StorageName: 'Қойма атауы',
      StorageAddress: 'Қойма мекенжайы',
      StorageType: 'Қойма түрі',
      NameRus: 'Атауы (орыс тілінде)',
      NameKaz: 'Атауы (қазақ тілінде)',
      СustomInfo: 'Қосымша ақпарат',
      NomenclatureType: 'Номенклатура түрі',
      Nomenclature: 'Номенклатура',
      UnitMeasurement: 'Өлшем бірлігі',
      AttributeWeightProduct: 'Атрибуттық салмағы бар өнім',
      Barcode: 'Штрих код',
      VendorCode: 'Артикул',
      VendorCodeExt: 'Артикул Магнум',
      Markup: 'Үстеме',
      MarkupType: 'Үстеме баға үшін өлшем бірліктер',
      MarkupPercentage: 'Бірлік үшін үстеме баға пайызбен',
      MarkupValue: 'Бірлік үшін үстеме баға теңгемен',
      VatValue: 'НДС',
      Vat: 'Vat value %',
      Units: 'ТНВЭД код',
      VendorBin: 'ITN/BIN жеткізушінің',
      CustomInfo: 'Қосымша ақпарат (сипаттама)',
      VendorInfo: 'Өндіруші туралы ақпарат (ел)',
      TypeOperation: 'Операция түрі',
      ActualAddress: 'Нақты мекен-жайы',
      LegalAddress: 'Заңды мекен-жайы',
      ResidentialAddress: 'Тұратын мекен-жайы',
      RegistrationAddress: 'Тұратын мекен-жайы',
      FullName: 'Толық аты',
      ShortName: 'Қысқаша аты',
      BankName: 'Банктің атауы',
      Iic: 'ЖСК (Жеке сәйкестендіру коды)',
      Iin: 'ЖСН (Жеке сәйкестендіру нөмірі)',
      Bik: 'БСК (банктік сәйкестендіру коды)',
      Gender: 'Жынысы',
      Citizenship: 'Азаматтығы',
      Birthplace: 'Туған жері',
      DocType: 'Жеке басты куәландыратын құжат',
      PersonIdn: 'Құжат нөмірі',
      IssuingAuthority: 'Құжатты берген',
      SocialStatus: 'Әлеуметтік мәртебесі',
      IsForeign: 'Шетел азаматы',
      Birthday: 'Туған күні',
      DateOfIssue: 'Берілген күні',
      Unit: 'Unit of measurement',
      Amount: 'Quantity / Weight',
      PurchasePrice: 'Purchase price tenge per unit',
      CostPrice: 'Cost price',
      SellPrice: 'Sale price',
      RecipientCode: 'Алушының коды',
      CounterpartyBin: 'Жеткізушінің ЖСН / БСН',
      OganizationalLegalForm: 'Ұйымдастырушылық-құқықтық нысаны ',
      CounterpartyId: 'Жеткізушіні идентификаторы',
      CounterpartyFullName: 'Ұйымның немесе ЖК атауы (Жеке кәсіпкер)',
      CounterpartyShortName: 'Басшының аты-жөні',
      CashRegistersName: 'Кассаның атауы',
      CashRegistersId: 'Кассаның идентификаторы',
      CashRegisterLinked: 'Касса байланған',
      Alias: 'Кілт алиасы',
      KeyId: 'Кілт идентификаторы',
      Algorithm: 'Кілт алгоритмі',
      SubjectCn: 'Тақырыптың жалпы қабылданған атауы',
      SubjectDn: 'Тақырыптың ерекше атауы',
      IssuerCn: 'Жалпы қабылданған баспагердің аты',
      IssuerDn: 'Баспагердің ерекше аты',
      SerialNumber: 'Сертификаттың сериялық нөмірі',
      CertNotBefore: 'Сертификат әрекетінің басталу күні',
      CertNotAfter: 'Сертификаттың қолданылу мерзімінің аяқталу күні',
      AuthorityKeyIdentifier: 'УЦ кілтінің идентификаторы',
      Pem: 'PEM форматындағы сертификат',
      StorageSelect: 'Сертификат түрі',
      EmployeeName: 'Қызметкердің аты',
      Iban: 'Банк шотының нөмірі (IBAN)',
      NumberEmploymentOrder: 'Нөмірі жұмысқа қабылдау туралы бұйрықтың',
      OrderDate: 'Бұйрық күні жұмысқа қабылдау туралы',
      ContractNumber: 'Шарттың нөмірі',
      Position: 'Лауазымы',
      Department: 'Бөлімше',
      ContractDate: 'Шарттың жасалған күні',
      СontractStartDate: 'Шарттың басталу күні',
      ContractEndDate: 'Шарттың аяқталу күні',
      ContractType: 'Шарт түрі',
      EmploymentType: 'Жұмыспен қамту түрі',
      Probation: 'Күнтізбелік күндердің сынақ мерзімі',
      PersonnelNumber: 'Табельдік нөмір',
      Salary: 'Оклад',
      VacationDays: 'Демалыс күндерінің саны',
      SalaryFund: 'Қызметкерге еңбекақы төлеу қоры',
      DepartmentName: 'Бөлімшенің атауы',
      FormationDate: 'Бөлімшені қалыптастыру күні',
      HigherLevelDepartmentName: 'Жоғары тұрған бөлімшенің атауы',
      WorkSchedule: 'Жұмыс кестесі',
      PositionName: 'Лауазымның атауы',
      PositionCount: 'Штат бірліктерінің саны',
      VacantUnitsCount: 'Бос бірліктер саны',
      DateWriteOffFrom: 'Есептен шығарудың бастапқы күні',
      DateWriteOffBefore: 'Есептен шығарудың соңғы күні',
      OperationNumber: 'Операция нөмірі',
      Comment: 'Пікір',
      Cause: 'Себеп',
      Continue: 'Жалғастыру ',

      // Validations label
      InputRequiredValidate: 'Өрісті толтыру міндетті',
      EmailRequiredValidate: 'Жарамсыз пошта мекенжайы',
      ConditionsRequiredValidate: 'Шарттар қабылдануы керек',
      PasswordsMatch: 'Парольдер сәйкес келуі керек',
      FieldOnlyDigits: 'Өрісте тек сандар болуы кере',
      FieldPhoneIncorrect: 'Қате толтырылған',
      FieldOnlyDigitsBc: 'Өрісте тек 11-ден 29-ға дейінгі сандар болуы керек',

      // Buttons label
      Register: 'Тіркелу',
      Authenticate: 'Кіру',
      Create: 'Құру',
      Add: 'Қосу',
      Edit: 'Редакциялау',
      Attach: 'Байлау',
      Move: 'Жылжыту',
      WriteOff: 'Шығару',
      Save: 'Сақтау',
      Cancel: 'Болдырмау',
      Complete: 'Аяқтау',
      SearchButton: 'Табу',
      Loading: 'Өңдеу...',
      CheckCertificate: 'Сертификатты тексеру',
      GetKeyData: 'Кілт деректерін алыңыз',
      No: 'Жоқ',
      Delete: 'Ия, оны жойыңыз!',
      Open: 'Ия, оны ашыңыз!',
      Close: 'Ия, оны жойыңыз!',

      // Modals Label
      ModalTmcReceiptAdded: 'Өнімді қосу',
      ModalTmcMoveAdded: 'Жылжыту TMЦ қосу',
      CompleteWriteOff: 'Есептен шығаруды аяқтау',

      // Table headers
      HeaderName: 'Атауы',
      HeaderAmount: 'Саны',
      HeaderSellPrice: 'Бағасы',
      HeaderSum: 'Сомасы',
      HeaderVat: 'ҚҚС %',
      HeaderVatValue: 'ҚҚС сомасы',
      HeaderCountToMove: 'Жылжыту',
      HeaderCountToWriteOff: 'Есептен шығару үшін санаңыз',
      HeaderChoose: 'Таңдау',
      HeaderStatus: 'Мәртебесі',
      HeaderBin: 'ЖСН/БСН',
      HeaderPhoneNumber: 'Телефон нөмірі',
      HeaderCounterpartyBin: 'Жеткізушінің ЖСН / БСН',
      HeaderFullNameDirector: 'Басшының аты-жөні',
      HeaderLegalAddress: 'Заңды мекен-жайы',
      Warning: 'Ескерту',
      QuantityWarning: 'Енгізілген Сан ағымдағы саннан аспауы тиіс',

      // Errors
      DefaultError: 'Өрістерді толтыру қатесі',
      Error500: 'Бір нәрсе дұрыс болмады. Қолдау қызметіне хабарласыңыз',
      CreatedError: 'Құрылған',
      UnauthorizedError: 'Unauthorized',
      ForbiddenError: 'Forbidden',
      NotFound: 'Not Found',
      CheckInputCorrect: 'Енгізудің дұрыстығын тексеріп, қайталап көріңіз',
      PhoneAlreadyExists: 'Телефон қазірдің өзінде бар',
      EmailAlreadyExists: 'Электрондық пошта қазірдің өзінде бар',
      LoginAlreadyExists: 'Кіру қазірдің өзінде бар',
      BinRequired: '"БИН" деген жол толтыру үшін міндетті',
      GuidRequired: '"Guid" деген жол толтыру үшін міндетті',
      OrgNameRequired: '"Ұйымның атауы" деген жол толтыру үшін міндетті',
      EmailRequired: '"Email" деген жол толтыру үшін міндетті',
      NameRequired: '"Атауы" деген жол толтыру үшін міндетті',
      AddressRequired: '"Мекен жайы" деген жол толтыру үшін міндетті',
      LegalAddressRequired:
        '"Заңды мекен-жайы" деген жол толтыру үшін міндетті',
      ActualAddressRequired:
        '"Нақты мекен-жайы" деген жол толтыру үшін міндетті',
      LegalAddressBirthplace: '"Заңды мекен-жайы" жолы дұрыс толтырылмаған',
      ActualAddressBirthplace: '"Нақты мекен-жайы" жолы дұрыс толтырылмаған',
      IikRequired: '"Жеке сәйкестендіру коды" деген жол толтыру үшін міндетті',
      IicRequired: '"Жеке сәйкестендіру коды" деген жол толтыру үшін міндетті',
      PhoneRequired: '"Телефон" деген жол толтыру үшін міндетті',
      ActionRequired: '"Action" деген жол толтыру үшін міндетті',
      SupervisorNameRequired: '"Жетекшісі" деген жол толтыру үшін міндетті',
      OkvedRequired: '"Қызмет түрі (ОКЭД)" деген жол толтыру үшін міндетті',
      BikRequired:
        '"Банктік сәйкестендіру коды" деген жол толтыру үшін міндетті',
      BcRequired: '"Бенефициар коды" деген жол толтыру үшін міндетті',
      FirstNameRequired: '"Аты" деген жол толтыру үшін міндетті',
      LastNameRequired: '"Тегі" деген жол толтыру үшін міндетті',
      PatNameRequired: '"Әкесінің" деген жол толтыру үшін міндетті',
      FullNameRequired: '"Толық атауы" деген жол толтыру үшін міндетті',
      ShortNameRequired: '"Қысқаша атауы" деген жол толтыру үшін міндетті',
      CounterpartyBinRequired:
        '"Жеткізушіні БИН" деген жол толтыру үшін міндетті',
      BankNameRequired: '"Банктің атауы" деген жол толтыру үшін міндетті',
      BankBikRequired: '"Банктің БИК" деген жол толтыру үшін міндетті',
      PersonIdnRequired: '"Құжат нөмірі" деген жол толтыру үшін міндетті',
      IssuingAuthorityRequired: '"Беру органы" деген жол толтыру үшін міндетті',
      BirthplaceRequired: '"Туған жері" деген жол толтыру үшін міндетті',
      DoctypeRequired: '"Құжат" деген жол толтыру үшін міндетті',
      CitizenshipRequired: '"Азаматтығы" деген жол толтыру үшін міндетті',
      GenderRequired: '"Жынысы" деген жол толтыру үшін міндетті',
      IinRequired: '"ИИН" деген жол толтыру үшін міндетті',
      IncorrectPhoneNumber: 'Телефон нөмірі дұрыс емес',
      IncorrectPhone: 'Телефон нөмірі дұрыс емес',
      WrongEmail: 'Қате электрондық пошта',
      IncorrectOrgName: 'Ұйымның аты дұрыс емес',
      IncorrectOrgBin: 'Ұйымның БИН дұрыс емес',
      IncorrectBik: 'Қате банктік сәйкестендіру коды',
      IncorrectBankBik: 'Қате банктік сәйкестендіру коды',
      IncorrectBin: 'Қате БИН',
      IncorrectIik: 'Жеке сәйкестендіру коды дұрыс емес',
      IncorrectIic: 'Жеке сәйкестендіру коды дұрыс емес',
      IncorrectLegalAddress: 'Қате мекен-жайы',
      IncorrectActualAddress: 'Нақты мекен-жайы дұрыс емес',
      IncorrectOkved: 'Қызметтің дұрыс емес түрі (ОКЭД)',
      IncorrectSupervisorName: 'Басшының аты-жөні дұрыс емес',
      IncorrectType: 'Қате Түрі',
      IncorrectAddress: 'Адрес қате',
      IncorrectName: 'Аты дұрыс емес',
      IncorrectBc: 'Бенефициар коды дұр',
      IncorrectCounterpartyBin: 'Жеткізушіні қате БИН',
      InvalidSrcStoreId: 'Жарамсыз қойма идентификаторы',
      InvalidValues: 'Жарамсыз тауарлар',
      InvalidBarcode: 'Қате штрихкод',
      InvalidOrgId: 'Сұрау салынған ұйым жоқ',
      IncorrectBirthdayDate: 'Туған күні дұрыс емес',
      IncorrectDateOfIssue: 'Берілген күні дұрыс емес',
      IncorrectBirthplace: 'Дұрыс емес туған жер',
      IncorrectContractStartDate: 'Шарттың басталу күні дұрыс емес',
      IncorrectContractEndDate: 'Шарттың аяқталу күні дұрыс емес',
      ErrorСonnectingNCALayer: 'NCALayer-ге қосылу кезінде қате',
      ConnectionClosedNCALayer: 'NCALayer байланысы жабылды',
      CertificateExpired: 'Сертификаттың қолданылу мерзімі аяқталды',
      CertificateRevoked: 'Сертификат кері қайтарып алынды',

      // Select by Forms
      Creation: 'Құру',
      Editing: 'Редакциялау',
      SelectCounterparty: 'Жеткізушіні таңдаңыз',
      SelectEmployee: 'Қызметкерді таңдаңыз',
      SelectStorage: 'Қойманы таңдаңыз',
      SelectStorageFrom: 'Қоймадан жылжыту',
      SelectStorageTo: 'Қоймаға жылжыту',

      // Page text
      ListOrganizationIsEmpty: 'Ұйымдар тізімі бос',
      ListStoragesIsEmpty: 'Қоймалар тізімі бос',
      ListCounterpartiesIsEmpty: 'Жеткізуші тізімі бос',
      ListCashRegistersIsEmpty: 'Кассалар тізімі бос',
      ListPersonsIsEmpty: 'Жеке мәліметтер тізімі бос',
      ListEmployeesIsEmpty: 'Қызметкерлер тізімі бос',
      ListDepartmentsIsEmpty: 'Бөлімшелер тізімі бос',
      ListPositionsIsEmpty: 'Лауазымдар тізімі бос',
      ListStaffPositionsIsEmpty: 'Штаттық кесте тізімі бос',
      ListPriceListIsEmpty: 'Бағалар тізімі бос',
      ListСancellationsIsEmpty: 'Есептен шығарылған тауарлардың тізімі бос',
      ListСancellationProductsIsEmpty:
        'Есептен шығарылған тауарлардың тізімі бос',
      StorageAddedSuccessfully: 'Қойма сәтті қосылды',
      StorageEditedSuccessfully: 'Қойма сәтті өңделген',
      OrganizationAddedSuccessfully: 'Ұйым сәтті қосылды',
      OrganizationEditedSuccessfully: 'Ұйым сәтті өңделген',
      UserEditedSuccessfully: 'Пайдаланушы сәтті өңделген',
      TmcAddedSuccessfully: 'ТМЦ сәтті қосылды',
      PersonAddedSuccessfully: 'Жеке деректер картасы сәтті қосылды',
      PersonEditedSuccessfully:
        'Жеке деректері бар карточка сәтті редакцияланған',
      EmployeeAddedSuccessfully: 'Қызметкер сәтті қосылды',
      EmployeeEditedSuccessfully: 'Қызметкер сәтті редакцияланған',
      DepartmentAddedSuccessfully: 'Бөлім сәтті қосылды',
      DepartmentEditedSuccessfully: 'Бөлім сәтті өңделген',
      PositionAddedSuccessfully: 'Лауазым сәтті қосылды',
      PositionEditedSuccessfully: 'Лауазым сәтті редакцияланған',
      StaffPositionAddedSuccessfully: 'Штаттық кесте сәтті қосылды',
      StaffPositionEditedSuccessfully: 'Штаттық кесте сәтті редакцияланды',
      CounterpartyAddedSuccessfully: 'Жеткізуші  сәтті қосылды',
      CounterpartyEditedSuccessfully: 'Жеткізуші  сәтті өңделген',
      CashRegistersAddedSuccessfully: 'Касса сәтті қосылды',
      CashRegistersEditedSuccessfully: 'Касса сәтті редакцияланған',
      CashRegistersAttachedSuccessfully: 'Касса сәтті байланған',
      AreYouSure: 'Сіз сенімдіміз?',
      AreYouSureDeleteStorage: 'Сіз бұл қойманы жойғыңыз келе ме?',
      AreYouSureDeleteCounterparty: 'Сіз бұл Жеткізушіні жойғыңыз келе ме?',
      AreYouSureDeleteCashRegisters: 'Сіз бұл кассаны жойғыңыз келе ме?',
      AreYouSureDeletePerson:
        'Сіз жеке деректері бар карточканы жойғыңыз келе ме?',
      AreYouSureDeleteEmployee: 'Сіз бұл қызметкерді жойғыңыз келе ме?',
      AreYouSureDeletePosition: 'Сіз бұл позицияны жойғыңыз келе ме?',
      AreYouSureDeleteDepartment: 'Сіз бұл бөлімді жойғыңыз келе ме?',
      AreYouSureDeleteStaffPosition:
        'Сіз бұл штаттық кестені жойғыңыз келе ме?',
      AreYouSureCloseStaffPosition: 'Сіз бұл штаттық кестені жабғыңыз келе ме?',
      AreYouSureOpenStaffPosition: 'Сіз осы штаттық кестені ашқыңыз келе ме?',
      BankDetails: 'Банк деректемелері',
      ReceiveError: 'Тауар түбіртегі аяқталмады. Қолдау қызметіне хабарласыңыз',
      RowsSelected: 'жолдар таңдалды',
      DeleteSelectedRows: 'Таңдалған жолдарды жою',
      NotItemsInStock: 'Қоймада тауар жоқ ',
      ForgotPassword: 'Құпия сөзіңізді ұмыттыңыз ба?',
      UserIsNotFound: 'Пайдаланушы табылмады',
      TemporaryPasswordWasSending:
        'Жүйеге кіру үшін электрондық поштаңызға жаңа уақытша құпия сөз жіберілді ',
      TmcStoreIsNotEmpty:
        'Қойманы жою мүмкін емес, өйткені қоймада баланс бар.',
      IncorrectIin: 'ЖСН/БСН тек сандардан тұруы керек ',
      InvalidEmail:
        'Жарамды электрондық поштаны енгізіңіз, мысалы: example@site.com',
      CounterpartySupervisor: 'Жетекші',
      counterparty_already_exists: 'Провайдер әлдеқашан қосылған',
      cert_expired: 'Сертификат мерзімі бітті',
      cert_revoked: 'Сертификат кері қайтарылды',
      cert_invalid: 'Жарамсыз сертификат',
      invalid_cert_data: 'Жарамсыз сертификат',
      InTestNotWork: 'Бұл мүмкіндік сынақ нұсқасында жұмыс істемейді.',
      'Checkout mode': 'Төлем режимі',
      'Checkout reports': 'Есептерді шығару',
      'Shift reports': 'Shift есептер',
      'Cashier reports': 'Кассир есеп береді',
      'Z-report report': 'Z-есеп',
      'Price lists': 'Баға тізімдері',
      'Acceptance of goods': 'Тауарларды қабылдау',
      'Movement of Goods': 'Тауарлардың қозғалысы',
      'Write-off of the Goods': 'Тауарларды есептен шығару',
      'Return of goods to the Supplier': 'Тауарды Жеткізушіге қайтару',
      Inventory: 'Түгендеу',
      'Accounting for income and cash flow':
        'Табыс пен ақша қозғалысын есепке алу',
      'Sales figures': 'Сату көрсеткіштері',
      'Money flow Bank': 'Ақша айналымы банкі',
      'Movement of money cash desk (cash desk)':
        'Ақша кассасының қозғалысы (касса)',
      'Settlements with personnel': 'Персоналмен есеп айырысу',
      'Profit and loss': 'Пайда және шығын',
      'Taxes and financial reporting': 'Салық және қаржылық есеп беру',
      'Extract from tax': 'Салықтан үзінді',
      'Tax reporting form 910': 'Салық есептілігі 910 нысаны',
      'Tax registers': 'Салық регистрлері',
      'Reference books': 'Анықтамалық әдебиеттер',
      'Warehouses/shops': 'Қоймалар/дүкендер',
      SNT: 'СНТ',
      ESF: 'ЭСФ',
      Documentation: 'Құжаттама',
      Settings: 'Параметрлер',
      Help: 'Анықтама (білім базасы)',
    },
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: 'ru', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    supportedLngs: ['ru', 'en', 'kz'],
    fallbackLng: 'ru',
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
