import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import TmcService, {
  Nomenclature,
  NomenclatureBarcode,
  ProductReceived,
  ProductReceivedRequest,
} from '../services/TmcService'

import getErrorsMapping from '../utils/get-errors-mapping'

export class TmcReceiveStore {
  errors: string[] = []

  isLoader = false

  // Номенклатура
  nomenclature: Nomenclature = {} as Nomenclature

  nomenclatureIsLoader = false

  // Поступившие товары
  productsReceived: ProductReceived[] = []

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      errors: observable,
      isLoader: observable,

      // Номенклатура
      nomenclature: observable,
      nomenclatureIsLoader: observable,

      // Поступившие товары
      productsReceived: observable,

      setErrors: action.bound,

      isLoaderChanged: action.bound,

      // Получение номенклатуры (по штрихкоду)
      requestNomenclature: action.bound,
      // Создание товаров
      createProducts: action.bound,

      // Добавление номенклатуры
      setNomenclature: action.bound,
      // Управление лоадером номенклатуры
      nomenclatureIsLoaderChanged: action.bound,
      // Очистка номенклатуры
      clearNomenclature: action.bound,
      // Добавление поступившего товара
      setProductReceived: action.bound,
      // Удаление поступившего товара по индексу
      removeProductReceivedByIndex: action.bound,
      // Добавление поступивших товаров
      setProductsReceived: action.bound,
      // Очистка поступивших товаров
      clearProductsReceived: action.bound,
    })

    this.rootStore = rootStore
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  // Добавить номенклатуру
  setNomenclature(payload: Nomenclature): void {
    this.nomenclature = payload
  }

  nomenclatureIsLoaderChanged(payload: boolean): void {
    this.nomenclatureIsLoader = payload
  }

  clearNomenclature(): void {
    this.nomenclature = {} as Nomenclature
  }

  // Добавить поступившие товары
  setProductsReceived(payload: ProductReceived[]): void {
    this.productsReceived = payload
  }

  // Добавить поступивший товар
  setProductReceived(payload: ProductReceived): void {
    this.productsReceived = [...this.productsReceived, payload]
  }

  // Удаление поступившего товара по индексу
  removeProductReceivedByIndex(index: number): ProductReceived[] {
    if (this.productsReceived[index]) {
      this.productsReceived.splice(index, 1)
    }
    return this.productsReceived
  }

  // Очистить поступившие товары
  clearProductsReceived(): void {
    this.productsReceived = [] as ProductReceived[]
  }

  // Получение номенклатуры (по штрихкоду)
  async requestNomenclature(payload: NomenclatureBarcode): Promise<void> {
    this.nomenclatureIsLoaderChanged(true)

    try {
      const response = await TmcService.get(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400 || response.status === 404) {
        const errors = getErrorsMapping(json.responseDescription)
        this.setErrors(errors)
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          nameKaz,
          nameRus,
          customInfo,
          unit,
          barcode,
          vendorBin,
          vendorCode,
          vendorInfo,
          vendorCodeExt,
          cnfea,
        } = json
        this.setNomenclature({
          id,
          nameKaz,
          nameRus,
          customInfo,
          units: String(unit),
          barcode,
          vendorBin,
          vendorCode,
          vendorInfo,
          vendorCodeExt,
          cnfea,
        })

        this.setErrors([])
      }
    } catch (e: any) {
      console.log(
        `Class TmcReceiveStore, method requestNomenclature, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.nomenclatureIsLoaderChanged(false)
  }

  async createProducts(
    payload: ProductReceivedRequest[],
    localStorageName: string,
  ): Promise<null | string[]> {
    this.isLoaderChanged(true)

    try {
      const response = await TmcService.add(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['RECEIVE_ERROR']))
      }

      if (response.status === 404) {
        this.setErrors(getErrorsMapping(['RECEIVE_ERROR']))
      }

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(['DEFAULT_ERROR']))
      }

      if (response.status >= 200 && response.status < 300) {
        this.clearProductsReceived()
        this.clearNomenclature()
        TmcService.removeLocalProductsReceived(localStorageName)
      }
    } catch (e: any) {
      console.log(
        `Class TmcReceiveStore, method createProducts, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    this.isLoaderChanged(false)

    return null
  }
}
