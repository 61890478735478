import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import TmcService, {
  Id,
  Сancellation,
  Product,
  DataForСancellation,
  ProductСancellationed,
  ProductСancellationing,
  ProductValueCounts,
  ProductDetailСancellation,
  СancellationRequest,
  FilterType,
  FilterValues,
  FilterFields,
} from '../services/TmcService'
import { StorageId } from '../services/StorageService'

import getErrorsMapping from '../utils/get-errors-mapping'

import omit from '../utils/omit'

export class TmcСancellationStore {
  errors: string[] = []

  isLoader = false

  isFilterLoader = false

  filterType: FilterType = FilterType.receiving

  filters: FilterValues = {
    storeId: 0,
    tmcId: 0,
    periodStart: '',
    periodEnd: '',
  }

  inputValueCounts: ProductValueCounts = {}

  // Список списанний
  Сancellations: Сancellation[] = null as unknown as Сancellation[]

  // Товары для списания
  productsForСancellation: Product[] = []

  // Списанные товары
  productsСancellationed: ProductСancellationed = {
    data: {
      storeIdSrc: 0,
    },
    values: {},
  }

  productsDetailСancellation: ProductDetailСancellation[] =
    null as unknown as ProductDetailСancellation[]

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      errors: observable,
      isLoader: observable,
      isFilterLoader: observable,
      filters: observable,

      setErrors: action.bound,
      isLoaderChanged: action.bound,
      isFilterLoaderChanged: action.bound,
      setFilterType: action.bound,
      setFilterValues: action.bound,

      // Количество товара для id товара
      inputValueCounts: observable,

      // Список списаний
      Сancellations: observable,
      // Товары для списания
      productsForСancellation: observable,
      // Списанные товары
      productsСancellationed: observable,
      // Детальное списание
      productsDetailСancellation: observable,

      // Получить список списаний
      requestСancellations: action.bound,
      // Получить список товаров
      requestProducts: action.bound,
      // Списать товар со склада
      writeOffProducts: action.bound,

      // Создать количество товара для id товара
      createInputValueCounts: action.bound,
      // Добавить количество товара для id товара
      setInputValueCounts: action.bound,
      // Очистить количество товара
      clearInputValueCounts: action.bound,

      // Добавить списания
      setСancellations: action.bound,
      // Добавить списание
      setСancellation: action.bound,

      // Добавить товары для списания
      setProductsForСancellation: action.bound,
      // Добавить списанные товары
      setProductsСancellationed: action.bound,
      // Добавление склада
      setDataForСancellationed: action.bound,
      // Добавление id и количество списанных товаров
      setValueCountsForСancellationed: action.bound,
      // Удаление id и количество из списанных товаров
      deleteValueCountsForСancellationed: action.bound,
      // Очистить списанные товары
      clearProductsСancellationed: action.bound,
      // Добавить детальное списание
      setProductsDetailСancellation: action.bound,
    })

    this.rootStore = rootStore
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  setError(payload: string[]): void {
    this.errors = [...this.errors, ...payload]
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  isFilterLoaderChanged(payload: boolean): void {
    this.isFilterLoader = payload
  }

  setFilterType(type: FilterType): void {
    this.filterType = type
  }

  setFilterValues(field: FilterFields, value: string | number): void {
    this.filters = { ...this.filters, [field]: value }
  }

  createInputValueCounts(payload: ProductValueCounts): void {
    this.inputValueCounts = payload
  }

  // Добавить списания
  setСancellations(payload: Сancellation[]): void {
    this.Сancellations = payload
  }

  // Добавить списание
  setСancellation(payload: Сancellation): void {
    this.Сancellations = [...this.Сancellations, payload]
  }

  setInputValueCounts(productId: number, count: number): void {
    this.inputValueCounts = { ...this.inputValueCounts, [productId]: count }
  }

  clearInputValueCounts(): void {
    this.inputValueCounts = {}
  }

  // Добавить товары для списания
  setProductsForСancellation(payload: Product[]): void {
    this.productsForСancellation = payload
  }

  // Добавить списанные товары
  setProductsСancellationed(payload: ProductСancellationed): void {
    this.productsСancellationed = payload
  }

  // Добавить айдишник склада, с которого списывается товар
  setDataForСancellationed(payload: DataForСancellation): void {
    this.productsСancellationed = {
      ...this.productsСancellationed,
      data: {
        storeIdSrc: payload.storeIdSrc,
      },
    }
  }

  // Добавить айдишники товаров с количеством для списания товаров
  setValueCountsForСancellationed(payload: ProductValueCounts): void {
    this.productsСancellationed = {
      ...this.productsСancellationed,
      values: { ...this.productsСancellationed.values, ...payload },
    }
  }

  // Удалить айдишники товаров с количеством для перемещения товаров
  deleteValueCountsForСancellationed(productId: number): void {
    this.productsСancellationed = {
      ...this.productsСancellationed,
      values: omit(this.productsСancellationed.values, [productId]),
    }
  }

  // Очистить id складов и id и количество перемещенных товаров
  clearProductsСancellationed(): void {
    this.productsСancellationed = {
      data: {
        storeIdSrc: 0,
      },
      values: {},
    }
    this.productsForСancellation = []
  }

  // Добавить товары в детальное спсиание
  setProductsDetailСancellation(payload: ProductDetailСancellation[]): void {
    this.productsDetailСancellation = payload
  }

  // Запрос списка списаний
  async requestСancellations(
    payload: СancellationRequest,
    type: FilterType,
  ): Promise<void> {
    if (type === FilterType.receiving) {
      this.isLoaderChanged(true)
      this.setFilterValues(FilterFields.storeId, payload.storeId)
    } else {
      this.isFilterLoaderChanged(true)
    }

    try {
      const response = await TmcService.Сancellation(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({
            operationId,
            operationDate,
            operationComment,
            storeIdSrc,
            storeIdDest,
            storeNameDest,
            storeNameSrc,
            userId,
            userLastName,
            userFirstName,
            userPatName,
          }: Сancellation): Сancellation => ({
            operationId,
            operationDate,
            operationComment,
            storeIdSrc,
            storeIdDest,
            storeNameDest,
            storeNameSrc,
            userId,
            userLastName,
            userFirstName,
            userPatName,
          }),
        )

        this.setСancellations(result)
      }
    } catch (e: any) {
      console.log(
        `Class TmcСancellationStore, method requestСancellations, type error ${e.name}, Message ${e.message}`,
      )
    }

    if (type === FilterType.receiving) {
      this.isLoaderChanged(false)
      this.setFilterType(FilterType.filtering)
    } else {
      this.isFilterLoaderChanged(false)
    }
  }

  // Запрос товаров для списаний
  async requestProducts(payload: StorageId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await TmcService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.setProductsForСancellation(json)
      }
    } catch (e: any) {
      console.log(
        `Class TmcСancellationStore, method requestProducts, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  // Списать товар со склада
  async writeOffProducts(
    payload: ProductСancellationing,
    localStorageName: string,
  ): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await TmcService.writeOff(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      if (response.status === 400) {
        const json = await response.json()
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.clearProductsСancellationed()

        this.clearInputValueCounts()

        TmcService.removeLocalСancellationedProducts(localStorageName)
      }
    } catch (e: any) {
      console.log(
        `Class TmcСancellationStore, method writeOffProducts, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  // Запрос списка детального списания
  async requestDetailСancellation(payload: Id): Promise<void> {
    this.isLoaderChanged(true)
    console.log('test payload', payload)
    try {
      const response = {
        status: 200,
        responseDescription: ['Errors'],
        data: [
          {
            id: 1,
            productName: 'Батарейки',
            amount: 24,
          },
          {
            id: 2,
            productName: 'Адаптер электрический',
            amount: 8,
          },
          {
            id: 3,
            productName: 'Бутылка д/воды',
            amount: 15,
          },
          {
            id: 4,
            productName: 'Сникерс',
            amount: 10,
          },
        ],
      }

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500) {
        this.setError(getErrorsMapping(['ERROR500']))
      }

      // const json = await response.json()
      const json = await response.data

      if (response.status === 400 || response.status === 404) {
        // const errors = getErrorsMapping(json.responseDescription)
        const errors = getErrorsMapping(response.responseDescription)
        this.setError(errors)
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({
            id,
            productName,
            amount,
          }: ProductDetailСancellation): ProductDetailСancellation => ({
            id,
            productName,
            amount,
          }),
        )
        this.setProductsDetailСancellation(result)
      }
    } catch (e: any) {
      console.log(
        `Class TmcСancellationStore, method requestDetailСancellation, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }
}
