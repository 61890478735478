import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useKkmStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import KkmForm from './KkmForm'

interface RouteParams {
  orgId: string
  kkmId: string
}

function KkmEdit({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const kkm = useKkmStore()

  const { orgId, kkmId } = params as RouteParams

  useEffect(() => {
    if (kkm.cashRegisters === null) {
      kkm.requestCashRegisters()
    }

    kkm.fillCurrentCashRegister({ id: Number(kkmId) })

    return () => {
      kkm.setErrors([])
      kkm.clearCurrentCashRegister()
    }
  }, [])

  if (!isEmpty(kkm.errors)) {
    return <Error errors={kkm.errors} />
  }

  if (isEmpty(kkm.currentCashRegister) || kkm.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    name: kkm.currentCashRegister.name,
    ofdId: String(kkm.currentCashRegister.ofdId),
    id: kkm.currentCashRegister.id,
    linked: kkm.currentCashRegister.linked,
  }

  const linkList = getLink(PrivatePaths.KkmList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          {
            id: 1,
            title: t('CashRegisterList'),
            link: linkList,
            active: false,
          },
          {
            id: 2,
            title: t('CashRegisterEdit'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('CashRegisterEdit')}</Heading>

      <div className="container-wrapper">
        <KkmForm
          initialValues={initialValues}
          handleService={kkm.updateCashRegister}
          buttonlabel={t('Edit')}
          responseMessage={t('CashRegistersEditedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(KkmEdit)))
