import { AppStore } from './appStore'
import { UserStore } from './userStore'
import { OrganizationsStore } from './organizationStore'
import { StoragesStore } from './storageStore'
import { PersonsStore } from './personStore'
import { EmployeesStore } from './employeeStore'
import { DepartmentsStore } from './departmentStore'
import { PositionsStore } from './positionStore'
import { StaffPositionsStore } from './staffPositionStore'
import { TmcСancellationStore } from './tmcСancellationStore'
import { TmcPriceListStore } from './tmcPriceListStore'
import { TmcMoveStore } from './tmcMoveStore'
import { TmcReceiveStore } from './tmcReceiveStore'
import { CounterpartiesStore } from './counterpartyStore'
import { KkmStore } from './kkmStore'
import { CertificateStore } from './certificateStore'

class RootStore {
  appStore: AppStore

  userStore: UserStore

  organizationsStore: OrganizationsStore

  storagesStore: StoragesStore

  tmcPriceListStore: TmcPriceListStore

  tmcСancellationStore: TmcСancellationStore

  tmcMoveStore: TmcMoveStore

  tmcReceiveStore: TmcReceiveStore

  counterpartiesStore: CounterpartiesStore

  kkmStore: KkmStore

  certificateStore: CertificateStore

  personsStore: PersonsStore

  employeesStore: EmployeesStore

  departmentsStore: DepartmentsStore

  positionsStore: PositionsStore

  staffPositionsStore: StaffPositionsStore

  constructor() {
    this.appStore = new AppStore(this)
    this.userStore = new UserStore(this)
    this.organizationsStore = new OrganizationsStore(this)
    this.storagesStore = new StoragesStore(this)
    this.tmcPriceListStore = new TmcPriceListStore(this)
    this.tmcСancellationStore = new TmcСancellationStore(this)
    this.tmcMoveStore = new TmcMoveStore(this)
    this.tmcReceiveStore = new TmcReceiveStore(this)
    this.counterpartiesStore = new CounterpartiesStore(this)
    this.kkmStore = new KkmStore(this)
    this.certificateStore = new CertificateStore(this)
    this.personsStore = new PersonsStore(this)
    this.employeesStore = new EmployeesStore(this)
    this.departmentsStore = new DepartmentsStore(this)
    this.positionsStore = new PositionsStore(this)
    this.staffPositionsStore = new StaffPositionsStore(this)
  }
}

export default new RootStore()
