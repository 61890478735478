import { ReactElement } from 'react'
import i18n from 'i18next'
import DatePicker from 'react-datepicker'
import { Form, Button } from 'react-bootstrap'

import {
  formatDateToString,
  getDate,
  formatDateForValue,
} from '../../../utils/get-date'

import { DatePickerProps } from './types'

export function DatePickerComponent({
  name,
  valueText,
  placeholder,
  label,
  onChange,
  isInvalid,
  typeFeedback,
  classNameFeedback,
  error,
  disabled = false,
  years,
}: DatePickerProps): ReactElement {
  const months = [
    i18n.t('January'),
    i18n.t('February'),
    i18n.t('March'),
    i18n.t('April'),
    i18n.t('May'),
    i18n.t('June'),
    i18n.t('July'),
    i18n.t('August'),
    i18n.t('September'),
    i18n.t('October'),
    i18n.t('November'),
    i18n.t('December'),
  ]

  const handleChange = (date: Date) => {
    const formattedDate = formatDateToString(date)
    onChange(name, formattedDate)
  }

  const valueDate = getDate(valueText)

  return (
    <>
      <Form.Label>{label}</Form.Label>
      <DatePicker
        name={name}
        placeholderText={placeholder}
        customInput={<Form.Control isInvalid={isInvalid} />}
        value={formatDateForValue(valueDate)}
        // dateFormat="yyyy-MM-dd"
        selected={valueDate}
        onChange={handleChange}
        disabled={disabled}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="d-flex flex-column m-2">
            <Form.Select
              className="form-select form-select-sm mb-2"
              value={date.getFullYear()}
              onChange={(e: any) => changeYear(Number(e.target.value))}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
            <Form.Select
              className="form-select form-select-sm  mb-2"
              value={months[date.getMonth()]}
              onChange={(e: any) => changeMonth(months.indexOf(e.target.value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-primary"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                className="border-0 bg-transparent"
              >
                <i className="bi bi-chevron-left"></i>
              </Button>

              <Button
                variant="outline-primary"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                className="border-0 bg-transparent"
              >
                <i className="bi bi-chevron-right"></i>
              </Button>
            </div>
          </div>
        )}
      />

      <Form.Control.Feedback
        type={typeFeedback}
        className={classNameFeedback}
        style={{ display: isInvalid ? 'block' : 'none' }}
      >
        {error}
      </Form.Control.Feedback>
    </>
  )
}
