import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form } from 'react-bootstrap'
import { Field, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import MaskedInput from 'react-text-mask'
import { NonAuthPaths } from '../../router/Routes'

import { useUserStore } from '../../providers/RootStoreProvider'
import { UserData } from '../../services/UserService'

import isEmpty from '../../utils/is-empty'

import { PhoneNumberMask, StatusForm } from '../../types'

import { Input, Check, Button } from '../../components/ui/Forms'
import { Alert } from '../../components/ui/Alert'

import PhoneClear from '../../utils/phone-clear'

import styles from './User.module.scss'
import { HeadingSecond } from '../../components/ui/Typography/Typography'

interface SignUpData extends UserData {
  passwordConfirm: string
  terms: boolean
}

export default function SignUp(): React.ReactElement {
  const { t } = useTranslation()
  const history = useHistory()
  const user = useUserStore()

  useEffect(() => {
    if (!isEmpty(user.currentUser)) {
      user.cleanUser()
    }
  }, [])

  const initialValues: SignUpData = {
    firstName: '',
    lastName: '',
    patName: '',
    email: '',
    phone: '',
    password: '',
    passwordConfirm: '',
    terms: false,
  }

  const schema = Yup.object().shape({
    // Имя
    firstName: Yup.string()
      .max(10, ({ max }) => `Максимальное значение поля ${max} символов`)
      .min(2, ({ min }) => `Максимальное значение поля ${min} символов`)
      .required(t('InputRequiredValidate')),

    // Фамилия
    lastName: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .min(2, ({ min }) => `Максимальное значение поля ${min} символов`)
      .required(t('InputRequiredValidate')),

    // Отчество
    patName: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .min(2, ({ min }) => `Максимальное значение поля ${min} символов`)
      .required(t('InputRequiredValidate')),

    // Email
    email: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .email(t('EmailRequiredValidate'))
      .required(t('InputRequiredValidate')),

    // Телефон
    phone: Yup.string()
      .matches(/^[0-9()+ ]+$/, {
        message: t('FieldPhoneIncorrect'),
        excludeEmptyString: true,
      })
      .required(t('InputRequiredValidate')),

    // Пароль
    password: Yup.string()
      .min(6, ({ min }) => `Минимальное значение поля ${min} символов`)
      .max(50, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Подтверждение пароля
    passwordConfirm: Yup.string()
      .min(6, ({ min }) => `Минимальное значение поля ${min} символов`)
      .max(50, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate'))
      .oneOf([Yup.ref('password'), null], t('PasswordsMatch')),

    // Согласия на обработку данных
    terms: Yup.bool()
      .required(t('InputRequiredValidate'))
      .oneOf([true], t('ConditionsRequiredValidate')),
  })

  const handleSubmitForm = async (
    values: SignUpData,
    action: FormikHelpers<SignUpData>,
  ): Promise<void> => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      patName: values.patName,
      email: values.email,
      phone: PhoneClear(values.phone),
      password: values.password,
    }

    const errors = await user.signUp(payload)

    if (!isEmpty(errors)) {
      action.setStatus({
        type: StatusForm.warning,
        messages: errors,
      })
      return
    }

    history.push(NonAuthPaths.Login)
  }

  return (
    <>
      <HeadingSecond>{t('Registration')}</HeadingSecond>

      <div className="container-wrapper">
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
        >
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            touched,
            values,
            status,
            isSubmitting,
            errors,
          }) => (
            <>
              {!isEmpty(status) && status.type === StatusForm.warning
                ? status.messages.map((error: string) => (
                    <Alert key={error} variant="warning" text={error} />
                  ))
                : null}
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="lastName"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="text"
                        name="lastName"
                        placeholder={t('LastName')}
                        label={t('LastName')}
                        value={values.lastName}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.lastName && !!errors.lastName}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.lastName}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="firstName"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="text"
                        name="firstName"
                        placeholder={t('FirstName')}
                        label={t('FirstName')}
                        value={values.firstName}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.firstName && !!errors.firstName}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.firstName}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="patName"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="text"
                        name="patName"
                        placeholder={t('PatName')}
                        label={t('PatName')}
                        value={values.patName}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.patName && !!errors.patName}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.patName}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="email"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="email"
                        name="email"
                        placeholder={t('Email')}
                        label={t('Email')}
                        value={values.email}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.email && !!errors.email}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.email}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="phone"
                      className="pb-4 position-relative"
                    >
                      <Field
                        name="phone"
                        label={t('Telephone')}
                        render={({ ...field }) => (
                          <>
                            <label className="form-label" htmlFor="phone">
                              {t('Telephone')}
                            </label>
                            <MaskedInput
                              {...field}
                              mask={PhoneNumberMask}
                              id="phone"
                              value={values.phone}
                              placeholder={t('TelephonePlaceholder')}
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.phone && touched.phone
                                  ? 'form-control phone is-invalid'
                                  : 'form-control phone'
                              }
                            />
                            {errors.phone && touched.phone && (
                              <div className="User_invalid-feedback-position__23DZb invalid-feedback">
                                {errors.phone}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>

                  <Col></Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="password"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="password"
                        name="password"
                        placeholder={t('Password')}
                        label={t('Password')}
                        value={values.password}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.password && !!errors.password}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.password}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="passwordConfirm"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="password"
                        name="passwordConfirm"
                        placeholder={t('PasswordConfirm')}
                        label={t('PasswordConfirm')}
                        value={values.passwordConfirm}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={
                          touched.passwordConfirm && !!errors.passwordConfirm
                        }
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.passwordConfirm}
                      />
                    </Form.Group>
                  </Col>

                  <Col></Col>
                </Row>

                <Row className="mb-3">
                  <Form.Group className="mb-5">
                    <Check
                      required
                      id="terms"
                      name="terms"
                      label={t('Terms')}
                      onChangeCallback={handleChange}
                      onBlurCallback={handleBlur}
                      isInvalid={touched.terms && !!errors.terms}
                      error={errors.terms}
                    />
                  </Form.Group>
                </Row>

                <div className="text-center">
                  <Button
                    typeField="submit"
                    label={t('Register')}
                    disabledField={isSubmitting}
                    loadingField={isSubmitting}
                  />
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  )
}
