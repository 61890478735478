export enum NonAuthPaths {
  Main = '/non-auth/',
  Login = '/non-auth/login',
  SignUp = '/non-auth/signup',
}

export enum PrivatePaths {
  SignEdit = '/app/signedit',

  Certificate = '/app/certificate',

  OrganizationList = '/app/organization/list',
  OrganizationCreate = '/app/organization/create',
  OrganizationEdit = '/app/organization/:orgId/edit',

  StorageList = '/app/organization/:orgId/storage/list',
  StorageCreate = '/app/organization/:orgId/storage/create',
  StorageEdit = '/app/organization/:orgId/storage/:storeId/edit',

  CounterpartyList = '/app/organization/:orgId/counterparty/list',
  CounterpartyCreate = '/app/organization/:orgId/counterparty/create',
  CounterpartyEdit = '/app/organization/:orgId/counterparty/:counterpartyId/edit',

  TmcList = '/app/organization/:orgId/tmc/list',
  TmcReceive = '/app/organization/:orgId/tmc/receive',
  TmcСancellationList = '/app/organization/:orgId/tmc/Сancellation/list',
  TmcСancellationCreate = '/app/organization/:orgId/tmc/Сancellation/create',
  TmcСancellationDetailed = '/app/organization/:orgId/tmc/Сancellation/:СancellationId/detailed',
  TmcMove = '/app/organization/:orgId/tmc/move',
  TmcPriceList = '/app/organization/:orgId/storage/:storeId/tmc/price',

  KkmList = '/app/organization/:orgId/kkm/list',
  KkmCreate = '/app/organization/:orgId/kkm/create',
  KkmEdit = '/app/organization/:orgId/kkm/:kkmId/edit',
  KkmAttach = '/app/organization/:orgId/kkm/:kkmId/attach',

  PersonList = '/app/organization/:orgId/person/list',
  PersonCreate = '/app/organization/:orgId/person/create',
  PersonEdit = '/app/organization/:orgId/person/:personId/edit',

  EmployeeList = '/app/organization/:orgId/employee/list',
  EmployeeCreate = '/app/organization/:orgId/employee/create',
  EmployeeEdit = '/app/organization/:orgId/employee/:employeeId/edit',

  DepartmentList = '/app/organization/:orgId/department/list',
  DepartmentCreate = '/app/organization/:orgId/department/create',
  DepartmentEdit = '/app/organization/:orgId/department/:departmentId/edit',

  PositionList = '/app/organization/:orgId/position/list',
  PositionCreate = '/app/organization/:orgId/position/create',
  PositionEdit = '/app/organization/:orgId/position/:positionId/edit',

  StaffPositionList = '/app/organization/:orgId/staff-position/list',
  StaffPositionCreate = '/app/organization/:orgId/staff-position/create',
  StaffPositionEdit = '/app/organization/:orgId/staff-position/:staffPositionId/edit',
}

export enum PrivatePathsRegexp {
  orgId = ':orgId',
  storeId = ':storeId',
  counterpartyId = ':counterpartyId',
  kkmId = ':kkmId',
  personId = ':personId',
  employeeId = ':employeeId',
  departmentId = ':departmentId',
  positionId = ':positionId',
  staffPositionId = ':staffPositionId',
}
