import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Formik, FormikHelpers } from 'formik'
import { Form, Row, Col } from 'react-bootstrap'
import * as Yup from 'yup'

import { range } from 'lodash'

import isEmpty from '../../utils/is-empty'
import { getWorkSchedule } from '../../utils/get-work-schedule'

import { StatusForm } from '../../types'
import { StaffPositionFormProps, InitialValues } from './types'

import { Input, Button, Select, ReactSelect } from '../../components/ui/Forms'
import { Alert } from '../../components/ui/Alert'
import { DatePicker } from '../../components/ui/DatePicker'

function StaffPositionForm({
  initialValues,
  handleService,
  buttonlabel,
  link,
  responseMessage,
  loadOptionsPosition,
  loadOptionsDepartment,
}: StaffPositionFormProps): ReactElement {
  const history = useHistory()
  const { t } = useTranslation()

  const date = new Date()
  const yearsArray = range(1990, date.getFullYear() + 1)

  const schema = Yup.object().shape({
    // Наименование подразделения
    departmentId: Yup.string().required(t('InputRequiredValidate')),

    // Наименование должности
    positionId: Yup.string().required(t('InputRequiredValidate')),

    // Количество штатных единиц
    positionCount: Yup.string()
      .max(10, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Количество вакантных единиц
    vacantUnitsCount: Yup.string()
      .max(10, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Должностной оклад
    salary: Yup.string()
      .max(10, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Фонд заработной платы
    salaryFund: Yup.string()
      .max(10, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // График работы
    workScheduleId: Yup.string().required(t('InputRequiredValidate')),

    // Дата
    approvalDate: Yup.string().required(t('InputRequiredValidate')),
  })

  const handleSubmitForm = async (
    values: InitialValues,
    action: FormikHelpers<InitialValues>,
  ): Promise<void> => {
    const payload = {
      id: values.id,
      orgId: values.orgId,
      approvalDate: values.approvalDate,
      departmentId: Number(values.departmentId),
      positionId: Number(values.positionId),
      positionCount: Number(values.positionCount),
      salary: Number(values.salary),
      salaryFund: Number(values.salaryFund),
      vacantUnitsCount: Number(values.vacantUnitsCount),
      workScheduleId: Number(values.workScheduleId),
    }

    const errors = await handleService(payload)

    if (!isEmpty(errors)) {
      action.setStatus({
        type: StatusForm.warning,
        messages: errors,
      })
      return
    }

    action.setStatus({
      type: StatusForm.success,
      messages: [responseMessage],
    })

    action.setSubmitting(false)

    if (link) {
      history.push(link)
    }
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        values,
        status,
        isSubmitting,
        errors,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          {!isEmpty(status) && status.type === StatusForm.warning
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="warning" text={error} />
              ))
            : null}
          {!isEmpty(status) && status.type === StatusForm.success
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="success" text={error} />
              ))
            : null}
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="departmentId"
                className="pb-4 position-relative"
              >
                <ReactSelect
                  name="departmentId"
                  value={String(values.departmentId)}
                  label={t('Department')}
                  placeholder={t('Department')}
                  isInvalid={touched.departmentId && !!errors.departmentId}
                  typeFeedback="invalid"
                  error={errors.departmentId}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  loadOptions={() =>
                    loadOptionsDepartment({ orgId: values.orgId })
                  }
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="positionId"
                className="pb-4 position-relative"
              >
                <ReactSelect
                  name="positionId"
                  value={String(values.positionId)}
                  label={t('Position')}
                  placeholder={t('Position')}
                  isInvalid={touched.positionId && !!errors.positionId}
                  typeFeedback="invalid"
                  error={errors.positionId}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  loadOptions={() =>
                    loadOptionsPosition({ orgId: values.orgId })
                  }
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="positionCount"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="positionCount"
                  placeholder={t('PositionCount')}
                  label={t('PositionCount')}
                  value={values.positionCount}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.positionCount && !!errors.positionCount}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.positionCount}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="vacantUnitsCount"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="vacantUnitsCount"
                  placeholder={t('VacantUnitsCount')}
                  label={t('VacantUnitsCount')}
                  value={values.vacantUnitsCount}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={
                    touched.vacantUnitsCount && !!errors.vacantUnitsCount
                  }
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.vacantUnitsCount}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="salary"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="salary"
                  placeholder={t('Salary')}
                  label={t('Salary')}
                  value={values.salary}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.salary && !!errors.salary}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.salary}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="salaryFund"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="salaryFund"
                  placeholder={t('SalaryFund')}
                  label={t('SalaryFund')}
                  value={values.salaryFund}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.salaryFund && !!errors.salaryFund}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.salaryFund}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="workScheduleId"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('WorkSchedule')}
                  name="workScheduleId"
                  value={String(values.workScheduleId)}
                  onChangeCallback={handleChange}
                  isInvalid={touched.workScheduleId && !!errors.workScheduleId}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.workScheduleId}
                >
                  <option value="">{t('WorkSchedule')}</option>
                  {getWorkSchedule().map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="approvalDate"
                className="pb-4 position-relative"
              >
                <DatePicker
                  name="approvalDate"
                  valueText={String(values.approvalDate)}
                  placeholder={t('FormationDate')}
                  onChange={setFieldValue}
                  years={yearsArray}
                  label={t('FormationDate')}
                  isInvalid={touched.approvalDate && !!errors.approvalDate}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.approvalDate}
                />
              </Form.Group>
            </Row>
            <div className="text-center">
              <Button
                typeField="submit"
                label={buttonlabel}
                disabledField={isSubmitting}
                loadingField={isSubmitting}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default observer(StaffPositionForm)
