interface EmploymentType {
  key: string
  value: string
  text: string
}

const data: EmploymentType[] = [
  {
    key: '0',
    value: '0',
    text: 'Основное место работы',
  },
  {
    key: '1',
    value: '1',
    text: 'Внутренне совместительство',
  },
]

export const getEmploymentTypes = (): EmploymentType[] => data

export const getCurrentEmploymentType = (
  value: string,
): EmploymentType | undefined => data.find((item) => item.value === value)
