import { CounterpartyAPI } from './ApiUrls'
import httpClient from './httpClient'
import { OrgId } from './OrganizationService'

export interface CounterpartyId {
  id: number
}

export interface Counterparty extends OrgId, CounterpartyId {
  id: number
  actualAddress: string
  bankBik: string
  bankName: string
  bc: number
  bin: string
  email: string
  shortName: string
  supervisor: string
  fullName: string
  iic: string
  legalAddress: string
  phone: string
  type: number
  okved: string
}

class CounterpartyService {
  list = async (model: OrgId): Promise<Response> => {
    const result = await httpClient.post(CounterpartyAPI.List, model)

    return result
  }

  add = async (model: Counterparty): Promise<Response> => {
    const result = await httpClient.post(CounterpartyAPI.Add, model)

    return result
  }

  edit = async (model: Counterparty): Promise<Response> => {
    const result = await httpClient.post(CounterpartyAPI.Edit, model)

    return result
  }

  delete = async (model: CounterpartyId): Promise<Response> => {
    const result = await httpClient.post(CounterpartyAPI.Delete, model)

    return result
  }

  get = async (model: CounterpartyId): Promise<Response> => {
    const result = await httpClient.post(CounterpartyAPI.Get, model)

    return result
  }
}

export default new CounterpartyService()
