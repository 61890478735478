import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { Product } from '../../../services/TmcService'

import { PriceListDataProps } from './types'

import TableData from '../../../components/ui/TableData'

function PriceListData({ products, title }: PriceListDataProps): ReactElement {
  const { t } = useTranslation()

  const columns = [
    {
      name: 'id',
      label: '№',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'nameRus',
      label: t('NameRus'),
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'nameKaz',
      label: t('NameKaz'),
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'barcode',
      label: t('Barcode'),
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'vendorCode',
      label: t('VendorCode'),
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'amount',
      label: t('Amount'),
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'purchasePrice',
      label: t('PurchasePrice'),
      options: {
        filter: false,
        sort: false,
      },
    },
  ]

  const data = products.map((item: Product) => ({
    id: item.id,
    nameRus: item.nameRus,
    nameKaz: item.nameKaz,
    barcode: item.barcode,
    vendorCode: item.vendorCode,
    amount: item.stockBalance,
    purchasePrice: item.purchasePrice,
  }))

  return <TableData data={data} columns={columns} title={title} />
}

export default observer(PriceListData)
