import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import {
  useEmployeesStore,
  useDepartmentsStore,
  usePersonsStore,
  usePositionsStore,
} from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import EmployeeForm from './EmployeeForm'

interface RouteParams {
  orgId: string
  employeeId: string
}

function EmployeeEdit({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const employee = useEmployeesStore()
  const person = usePersonsStore()
  const department = useDepartmentsStore()
  const position = usePositionsStore()

  const { orgId, employeeId } = params as RouteParams

  useEffect(() => {
    if (
      employee.employees === null ||
      !employee.isEmployeeByOrganizationId(Number(orgId))
    ) {
      employee.requestEmployees({ orgId: Number(orgId) })
    }

    employee.fillCurrentEmployee({
      id: Number(employeeId),
      orgId: Number(orgId),
    })

    return () => {
      employee.setErrors([])
      employee.clearCurrentEmployee()
    }
  }, [orgId])

  if (!isEmpty(employee.errors)) {
    return <Error errors={employee.errors} />
  }

  if (isEmpty(employee.currentEmployee) || employee.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    id: employee.currentEmployee.id,
    orgId: employee.currentEmployee.orgId,
    contractDate: employee.currentEmployee.contractDate,
    contractEndDate: employee.currentEmployee.contractEndDate,
    contractNumber: employee.currentEmployee.contractNumber,
    contractStartDate: employee.currentEmployee.contractStartDate,
    contractType: employee.currentEmployee.contractType,
    departmentId: String(employee.currentEmployee.departmentId),
    employeeWorkScheduleId: String(
      employee.currentEmployee.employeeWorkScheduleId,
    ),
    employmentType: String(employee.currentEmployee.employmentType),
    orderDate: employee.currentEmployee.orderDate,
    orderNumber: employee.currentEmployee.orderNumber,
    personId: String(employee.currentEmployee.personId),
    personnelNumber: employee.currentEmployee.personnelNumber,
    positionId: String(employee.currentEmployee.positionId),
    probation: employee.currentEmployee.probation,
    salary: String(employee.currentEmployee.salary),
    salaryFund: String(employee.currentEmployee.salaryFund),
    vacationDays: String(employee.currentEmployee.vacationDays),
  }

  const linkList = getLink(PrivatePaths.EmployeeList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('EmployeeList'), link: linkList, active: false },
          {
            id: 2,
            title: t('EmployeeEdit'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('EmployeeEdit')}</Heading>

      <div className="container-wrapper">
        <EmployeeForm
          initialValues={initialValues}
          handleService={employee.updateEmployee}
          buttonlabel={t('Edit')}
          responseMessage={t('EmployeeEditedSuccessfully')}
          loadOptionsDepartment={department.requestOptionsDepartment}
          loadOptionsPosition={position.requestOptionsPosition}
          loadOptionsPerson={person.requestOptionsPerson}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(EmployeeEdit)))
