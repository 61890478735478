import { ReactElement } from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import Image404 from '../assets/images/08_404 1.png'

export default function Page500(): ReactElement {
  return (
    <>
      <Row>
        <Col
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image src={Image404} rounded />
        </Col>
        <Col
          md={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p>
            <b>Извините...</b>
          </p>
          <p>К сожалениею на ismet.kz технические неполадки!</p>
          <p>
            Мы работаем над тем, чтобы исправить все как можно быстрее! Все
            сервисы будут доступны в ближайшее время.
          </p>
          <p className="text-secondary">Мы скоро врнемся...!</p>
        </Col>
      </Row>
    </>
  )
}
