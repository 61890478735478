interface WorkSchedule {
  key: string
  value: string
  text: string
}

const data: WorkSchedule[] = [
  {
    key: '0',
    value: '0',
    text: '5-дневная',
  },
  {
    key: '1',
    value: '1',
    text: '6-дневная',
  },
  {
    key: '2',
    value: '2',
    text: '2х1 смену',
  },
  {
    key: '3',
    value: '3',
    text: '2х2 смены',
  },
]

export const getWorkSchedule = (): WorkSchedule[] => data

export const getCurrentWorkSchedule = (
  value: string,
): WorkSchedule | undefined => data.find((item) => item.value === value)
