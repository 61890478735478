import React, { ReactElement, useEffect, useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../../router/Routes'

import {
  useStoragesStore,
  useEmployeesStore,
  useTmcСancellationStore,
} from '../../../providers/RootStoreProvider'

import TmcService, {
  LocalStorageName,
  ProductСancellationed,
} from '../../../services/TmcService'

import { withAuthRedirect } from '../../../hoc/withAuthRedirect'

import { InitialValuesCreateForm, InitialValuesCommentForm } from './types'

import isEmpty from '../../../utils/is-empty'
import getLink from '../../../utils/get-link'
import has from '../../../utils/has'

import { Heading } from '../../../components/ui/Typography/Typography'
import { Loader } from '../../../components/ui/Loader'
import { Modal } from '../../../components/ui/Modal'
import { Error } from '../../../components/ui/Error'
import { Breadcrumb } from '../../../components/ui/Breadcrumb'

import СancellationCreateForm from './СancellationCreateForm'
import СancellationCreateData from './СancellationCreateData'
import СancellationCreateCommentForm from './СancellationCreateCommentForm'

interface RouteParams {
  orgId: string
}

function СancellationCreate({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const storage = useStoragesStore()
  const employee = useEmployeesStore()
  const Сancellation = useTmcСancellationStore()

  const { orgId } = params as RouteParams

  const localStorageName = `${LocalStorageName.productsСancellation}-${orgId}`
  const localСancellationProducts: ProductСancellationed =
    TmcService.getLocalСancellationProducts(localStorageName)

  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  useEffect(() => {
    if (!isEmpty(localСancellationProducts)) {
      const model: ProductСancellationed = {
        data: localСancellationProducts.data,
        values: localСancellationProducts.values,
      }

      Сancellation.setProductsСancellationed(model)

      Сancellation.createInputValueCounts(localСancellationProducts.values)

      Сancellation.requestProducts({
        storeId: localСancellationProducts.data.storeIdSrc,
      })
    }

    return () => {
      Сancellation.setErrors([])
    }
  }, [orgId])

  const handleRequestProductsForСancellation = (
    values: InitialValuesCreateForm,
  ): void => {
    Сancellation.clearProductsСancellationed()

    Сancellation.clearInputValueCounts()

    const model: ProductСancellationed = {
      data: {
        storeIdSrc: Number(values.storeIdSrc),
      },
      values: {},
    }

    TmcService.setLocalСancellationProducts(localStorageName, model)

    Сancellation.setDataForСancellationed({
      storeIdSrc: Number(values.storeIdSrc),
    })

    Сancellation.requestProducts({
      storeId: Number(values.storeIdSrc),
    })
  }

  const handleProductСancellationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    count: number,
  ): void => {
    const productId = Number(e.target.name)
    const countFromInputValueCounts = has(
      Сancellation.inputValueCounts,
      productId,
    )
      ? Сancellation.inputValueCounts[productId]
      : count

    if (has(Сancellation.productsСancellationed.values, productId)) {
      Сancellation.deleteValueCountsForСancellationed(productId)
    } else {
      Сancellation.setValueCountsForСancellationed({
        [productId]: countFromInputValueCounts,
      })
    }

    TmcService.setLocalСancellationProduct(
      localStorageName,
      productId,
      countFromInputValueCounts,
    )
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const productId = Number(e.target.name)
    const count = Number(e.target.value)

    if (has(Сancellation.productsСancellationed.values, productId)) {
      Сancellation.setInputValueCounts(productId, count)

      Сancellation.setValueCountsForСancellationed({ [productId]: count })

      TmcService.updateLocalСancellationProduct(
        localStorageName,
        productId,
        count,
      )
    }
  }

  const handleProductsСancellationed = (comment: string): void => {
    const { data, values } = Сancellation.productsСancellationed

    if (isEmpty(Сancellation.errors)) {
      const payload = {
        operationType: 0,
        comment,
        storeId: data.storeIdSrc,
        values: Object.entries(values).map(([key, value]) => ({
          tmcId: Number(key),
          count: value,
        })),
      }

      Сancellation.writeOffProducts(payload, localStorageName)
    }
  }

  if (!isEmpty(Сancellation.errors)) {
    return <Error errors={Сancellation.errors} />
  }

  if (Сancellation.isLoader) {
    return <Loader />
  }

  const { storeIdSrc } = Сancellation.productsСancellationed.data

  const initialValuesCreateForm: InitialValuesCreateForm = {
    orgId: Number(orgId),
    storeIdSrc: storeIdSrc !== 0 ? String(storeIdSrc) : '',
  }

  const initialValuesCommentForm: InitialValuesCommentForm = {
    comment: '',
  }

  const linkList = getLink(PrivatePaths.TmcList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  const linkСancellation = getLink(PrivatePaths.TmcСancellationList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('TmcList'), link: linkList, active: false },
          {
            id: 2,
            title: t('Сancellation list link'),
            link: linkСancellation,
            active: false,
          },
          {
            id: 3,
            title: t('TmcСancellationCreate'),
            link: '/',
            active: true,
          },
        ]}
      />

      <Heading>{t('TmcСancellationCreate')}</Heading>

      {!isEmpty(Сancellation.errors) &&
        Сancellation.errors.map((error) => <div key={error}>{error}</div>)}

      <div className="container-wrapper mb-3">
        <СancellationCreateForm
          initialValues={initialValuesCreateForm}
          handleSubmitForm={handleRequestProductsForСancellation}
          loadOptionsStorage={storage.requestOptionsStorage}
          loadOptionsEmployee={employee.requestOptionsEmployee}
        />
      </div>

      {!isEmpty(Сancellation.productsForСancellation) && (
        <СancellationCreateData
          products={Сancellation.productsForСancellation}
          values={Сancellation.productsСancellationed.values}
          inputValueCounts={Сancellation.inputValueCounts}
          onAmountChange={handleAmountChange}
          onProductСancellationChange={handleProductСancellationChange}
          onShowModal={handleShowModal}
        />
      )}

      <Modal
        title={t('CompleteWriteOff')}
        show={showModal}
        size="lg"
        handleModalOnHide={handleCloseModal}
      >
        <СancellationCreateCommentForm
          initialValues={initialValuesCommentForm}
          onProductsСancellationed={handleProductsСancellationed}
          onCloseModal={handleCloseModal}
        />
      </Modal>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(СancellationCreate)))
