import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import KkmService, { KkmId, Kkm, KkmLinkId } from '../services/KkmService'

import getErrorsMapping from '../utils/get-errors-mapping'
import isEmpty from '../utils/is-empty'

export class KkmStore {
  cashRegisters: Kkm[] = null as unknown as Kkm[]

  currentCashRegister: Kkm = {} as Kkm

  errors: string[] = []

  isLoader = false

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      cashRegisters: observable,
      currentCashRegister: observable,
      errors: observable,
      isLoader: observable,
      setErrors: action.bound,
      requestCashRegisters: action.bound,
      createCashRegister: action.bound,
      getCashRegister: action.bound,
      updateCashRegister: action.bound,
      removeCashRegister: action.bound,
      linkCashRegister: action.bound,
      setCashRegisters: action.bound,
      setCashRegister: action.bound,
      setCurrentCashRegister: action.bound,
      editCashRegister: action.bound,
      deleteCashRegister: action.bound,
      setLinkCashRegister: action.bound,
      clearCurrentCashRegister: action.bound,
      fillCurrentCashRegister: action.bound,
      isLoaderChanged: action.bound,
    })

    this.rootStore = rootStore
  }

  setCashRegisters(payload: Kkm[]): void {
    this.cashRegisters = payload
  }

  setCashRegister(payload: Kkm): void {
    this.cashRegisters = [...this.cashRegisters, payload]
  }

  setCurrentCashRegister(payload: Kkm): void {
    this.currentCashRegister = payload
  }

  editCashRegister(payload: Kkm): void {
    this.cashRegisters = this.cashRegisters.map((cashRegister) =>
      cashRegister.id === payload.id
        ? { ...cashRegister, ...payload }
        : cashRegister,
    )
  }

  deleteCashRegister(id: number): void {
    this.cashRegisters = this.cashRegisters.filter(
      (cashRegister) => cashRegister.id !== id,
    )
  }

  setLinkCashRegister(payload: KkmLinkId): void {
    this.cashRegisters = this.cashRegisters.map((cashRegister) =>
      cashRegister.id === payload.id
        ? { ...cashRegister, linked: !cashRegister.linked }
        : cashRegister,
    )
  }

  fillCurrentCashRegister(payload: KkmId): void {
    if (!isEmpty(this.cashRegisters)) {
      const currentCashRegisterse = this.cashRegisters.filter(
        (item) => item.id === payload.id,
      )[0]
      this.setCurrentCashRegister(currentCashRegisterse)
    } else {
      this.getCashRegister({ id: payload.id })
    }
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  clearCurrentCashRegister(): void {
    this.currentCashRegister = {} as Kkm
  }

  async requestCashRegisters(): Promise<void> {
    this.isLoaderChanged(true)
    try {
      const response = await KkmService.list()

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({ id, ofdId, name, linked }: Kkm): Kkm => ({
            id,
            ofdId,
            name,
            linked,
          }),
        )
        this.setCashRegisters(result)
      }
    } catch (e: any) {
      console.log(
        `Class KkmStore, method requestCashRegisters, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async getCashRegister(payload: KkmId): Promise<void> {
    this.isLoaderChanged(true)
    try {
      const response = await KkmService.get(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const { id, ofdId, name, linked } = json
        this.setCurrentCashRegister({ id, ofdId, name, linked })
      }
    } catch (e: any) {
      console.log(
        `Class KkmStore, method getCashRegister, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async createCashRegister(payload: Kkm): Promise<null | string[]> {
    try {
      const response = await KkmService.add(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const { id, ofdId, name, linked } = json
        this.setCashRegister({ id, ofdId, name, linked })
      }
    } catch (e: any) {
      console.log(
        `Class KkmStore, method createCashRegister, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async updateCashRegister(payload: Kkm): Promise<null | string[]> {
    try {
      const response = await KkmService.edit(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const { id, ofdId, name, linked } = json
        this.setCurrentCashRegister({ id, ofdId, name, linked })
        this.editCashRegister({ id, ofdId, name, linked })
      }
    } catch (e: any) {
      console.log(
        `Class KkmStore, method updateCashRegister, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async removeCashRegister(payload: KkmId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await KkmService.delete(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      if (response.status === 400) {
        const json = await response.json()
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.deleteCashRegister(payload.id)
      }
    } catch (e: any) {
      console.log(
        `Class KkmStore, method removeCashRegister, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async linkCashRegister(payload: KkmLinkId): Promise<null | string[]> {
    try {
      const response = await KkmService.link(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      if (response.status === 400) {
        const errors = getErrorsMapping(['DEFAULT_ERROR'])
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        this.setLinkCashRegister(payload)
      }
    } catch (e: any) {
      console.log(
        `Class KkmStore, method linkCashRegister, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }
}
