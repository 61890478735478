import React, { ReactElement } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface Ibreadcrumb {
  id: number
  link: string
  title: string
  active: boolean
}

interface BreadcrumbProps {
  breadcrumbs: Ibreadcrumb[]
}

export function BreadcrumbComponent({
  breadcrumbs,
}: BreadcrumbProps): ReactElement {
  return (
    <Breadcrumb>
      {breadcrumbs.map(
        (breadcrumb: Ibreadcrumb): ReactElement => (
          <li className="breadcrumb-item" key={breadcrumb.id}>
            {!breadcrumb.active ? (
              <Link key={breadcrumb.title} to={breadcrumb.link}>
                {breadcrumb.title}
              </Link>
            ) : (
              breadcrumb.title
            )}
          </li>
        ),
      )}
    </Breadcrumb>
  )
}
