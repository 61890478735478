import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { StorageTableDataProps } from './types'

import TableData from '../../components/ui/TableData'
import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'
import confirm from '../../utils/confirm-alert'
import modalAlert from '../../utils/alert'

function StorageTableData({
  storages,
  onRemove,
  checkIsStoreEmpty,
  orgId,
}: StorageTableDataProps): ReactElement {
  const { t } = useTranslation()

  const columns = [
    {
      name: 'name',
      label: t('StorageName'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'address',
      label: t('StorageAddress'),
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'handle',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
  ]

  const data = storages.map((item) => ({
    storeId: item.storeId,
    name: item.name,
    address: item.address,
    handle: (
      <>
        <Link
          to={getLink(PrivatePaths.StorageEdit, {
            [orgId]: PrivatePathsRegexp.orgId,
            [item.storeId]: PrivatePathsRegexp.storeId,
          })}
          className="p-2 me-1"
          style={{ fontSize: '18px' }}
        >
          <i className="bi bi-pencil-fill"></i>
        </Link>

        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault()
            checkIsStoreEmpty(Number(item.storeId), (result) => {
              if (!result) {
                confirm(
                  Number(item.storeId),
                  t('AreYouSure'),
                  t('AreYouSureDeleteStorage'),
                  t('Delete'),
                  'lg',
                  onRemove,
                )
              } else {
                modalAlert(
                  Number(item.storeId),
                  t('TmcStoreIsNotEmpty'),
                  t('Ok'),
                  'lg',
                )
              }
            })
          }}
          className="p-2 me-1"
          style={{ fontSize: '18px' }}
        >
          <i className="bi bi-trash"></i>
        </Link>
      </>
    ),
  }))

  return (
    <>
      {!isEmpty(storages) ? (
        <TableData data={data} columns={columns} title={t('StorageList')} />
      ) : (
        <p>{t('ListStoragesIsEmpty')}</p>
      )}
    </>
  )
}

export default observer(StorageTableData)
