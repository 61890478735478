import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import {
  useStaffPositionsStore,
  useDepartmentsStore,
  usePositionsStore,
} from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import StaffPositionForm from './StaffPositionForm'

interface RouteParams {
  orgId: string
}

function StaffPositionCreate({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const staffPosition = useStaffPositionsStore()
  const department = useDepartmentsStore()
  const position = usePositionsStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      staffPosition.staffPositions === null ||
      !staffPosition.isStaffPositionByOrganizationId(Number(orgId))
    ) {
      staffPosition.requestStaffPositions({
        orgId: Number(orgId),
      })
    }

    return () => {
      staffPosition.setErrors([])
    }
  }, [orgId])

  if (!isEmpty(staffPosition.errors)) {
    return <Error errors={staffPosition.errors} />
  }

  if (staffPosition.staffPositions === null || position.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    orgId: Number(orgId),
    id: 0,
    approvalDate: '',
    departmentId: '',
    positionCount: '',
    positionId: '',
    salary: '',
    salaryFund: '',
    vacantUnitsCount: '',
    workScheduleId: '',
  }

  const linkList = getLink(PrivatePaths.StaffPositionList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          {
            id: 1,
            title: t('StaffPositionList'),
            link: linkList,
            active: false,
          },
          {
            id: 2,
            title: t('StaffPositionCreate'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('StaffPositionCreate')}</Heading>

      <div className="container-wrapper">
        <StaffPositionForm
          initialValues={initialValues}
          handleService={staffPosition.createStaffPosition}
          buttonlabel={t('Create')}
          link={linkList}
          responseMessage={t('StaffPositionAddedSuccessfully')}
          loadOptionsPosition={position.requestOptionsPosition}
          loadOptionsDepartment={department.requestOptionsDepartment}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(StaffPositionCreate)))
