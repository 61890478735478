import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import OrganizationService, {
  Organization,
  OrgId,
} from '../services/OrganizationService'

import getErrorsMapping from '../utils/get-errors-mapping'
import isEmpty from '../utils/is-empty'
import has from '../utils/has'

export class OrganizationsStore {
  organizations: Organization[] = null as unknown as Organization[]

  currentOrganization: Organization = {} as Organization

  errors: string[] = []

  isLoader = false

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      organizations: observable,
      currentOrganization: observable,
      errors: observable,
      isLoader: observable,
      setErrors: action.bound,
      requestOrganizations: action.bound,
      createOrganization: action.bound,
      getSOrganization: action.bound,
      updateOrganization: action.bound,
      setOrganizations: action.bound,
      setOrganization: action.bound,
      setCurrentOrganization: action.bound,
      editOrganization: action.bound,
      clearCurrentOrganization: action.bound,
      fillCurrentOrganization: action.bound,
      isLoaderChanged: action.bound,
    })

    this.rootStore = rootStore
  }

  setOrganizations(payload: Organization[]): void {
    this.organizations = payload
  }

  setOrganization(payload: Organization): void {
    this.organizations = [...this.organizations, payload]
  }

  setCurrentOrganization(payload: Organization): void {
    this.currentOrganization = payload
  }

  editOrganization(payload: Organization): void {
    this.organizations = this.organizations.map((organization) =>
      organization.orgId === payload.orgId
        ? { ...organization, ...payload }
        : organization,
    )
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  clearCurrentOrganization(): void {
    this.currentOrganization = {} as Organization
  }

  fillCurrentOrganization(organizationId: number): void {
    if (!isEmpty(this.organizations)) {
      const currentOrganization = this.organizations.filter(
        (item) => item.orgId === organizationId,
      )[0]
      this.setCurrentOrganization(currentOrganization)
    } else {
      this.getSOrganization({ orgId: organizationId })
    }
  }

  async requestOrganizations(): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await OrganizationService.list()

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({
            orgId,
            orgName,
            bin,
            okved,
            supervisor,
            legalAddress,
            actualAddress,
            iik,
            bik,
            bankName,
            bc,
            type,
            phone,
            email,
          }: Organization): Organization => ({
            orgId,
            orgName,
            bin,
            okved,
            supervisor,
            legalAddress,
            actualAddress,
            iik,
            bik,
            bankName,
            bc,
            type,
            phone,
            email,
          }),
        )
        this.setOrganizations(result)
      }
    } catch (e: any) {
      console.log(
        `Class OrganizationsStore, method requestOrganizations, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async getSOrganization(payload: OrgId): Promise<void> {
    this.isLoaderChanged(true)
    try {
      const response = await OrganizationService.get(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          orgId,
          orgName,
          bin,
          okved,
          supervisor,
          legalAddress,
          actualAddress,
          iik,
          bik,
          bankName,
          bc,
          type,
          phone,
          email,
        } = json
        this.setCurrentOrganization({
          orgId,
          orgName,
          bin,
          okved,
          supervisor,
          legalAddress,
          actualAddress,
          iik,
          bik,
          bankName,
          bc,
          type,
          phone,
          email,
        })
      }
    } catch (e: any) {
      console.log(
        `Class OrganizationsStore, method getSOrganization, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async createOrganization(payload: Organization): Promise<null | string[]> {
    try {
      const response = await OrganizationService.add(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        if (has(json, 'resultCode') && Number(json.resultCode) !== 0) {
          const errors = getErrorsMapping([json.resultStr])
          return errors
        }

        if (
          has(json, 'responseDescription') &&
          Number(json.responseDescription) !== 0
        ) {
          const errors = getErrorsMapping(json.responseDescription)
          return errors
        }

        if (has(json, 'messages') && Number(json.messages) !== 0) {
          const errors = getErrorsMapping(json.messages)
          return errors
        }
      }
      if (response.status >= 200 && response.status < 300) {
        const {
          orgId,
          orgName,
          bin,
          okved,
          supervisor,
          legalAddress,
          actualAddress,
          iik,
          bik,
          bankName,
          bc,
          type,
          phone,
          email,
        } = json
        this.setOrganization({
          orgId,
          orgName,
          bin,
          okved,
          supervisor,
          legalAddress,
          actualAddress,
          iik,
          bik,
          bankName,
          bc,
          type,
          phone,
          email,
        })
        this.rootStore.userStore.setOrganization({ orgId, orgName })
      }
    } catch (e: any) {
      console.log(
        `Class OrganizationsStore, method createOrganization, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async updateOrganization(payload: Organization): Promise<null | string[]> {
    try {
      const response = await OrganizationService.edit(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
        const errors = getErrorsMapping(['DEFAULT_ERROR'])
        return errors
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          orgId,
          orgName,
          bin,
          okved,
          supervisor,
          legalAddress,
          actualAddress,
          iik,
          bik,
          bankName,
          bc,
          type,
          phone,
          email,
        } = json

        this.setCurrentOrganization({
          orgId,
          orgName,
          bin,
          okved,
          supervisor,
          legalAddress,
          actualAddress,
          iik,
          bik,
          bankName,
          bc,
          type,
          phone,
          email,
        })

        this.editOrganization({
          orgId,
          orgName,
          bin,
          okved,
          supervisor,
          legalAddress,
          actualAddress,
          iik,
          bik,
          bankName,
          bc,
          type,
          phone,
          email,
        })
      }
    } catch (e: any) {
      console.log(
        `Class OrganizationsStore, method updateOrganization, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }
}
