import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Formik, FormikHelpers } from 'formik'
import { Form, Row, Col } from 'react-bootstrap'
import * as Yup from 'yup'

import isEmpty from '../../../utils/is-empty'

import { StatusForm } from '../../../types'
import { InitialValuesCommentForm, CommentFormProps } from './types'

import { Textarea, Button } from '../../../components/ui/Forms'
import { Alert } from '../../../components/ui/Alert'

function СancellationCreateCommentForm({
  initialValues,
  onProductsСancellationed,
  onCloseModal,
}: CommentFormProps): ReactElement {
  const { t } = useTranslation()

  const schema = Yup.object().shape({
    // Комментарий
    comment: Yup.string()
      .max(1000, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),
  })

  const handleSubmitForm = (
    values: InitialValuesCommentForm,
    action: FormikHelpers<InitialValuesCommentForm>,
  ): void => {
    onProductsСancellationed(values.comment)

    action.setSubmitting(false)

    onCloseModal()
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        values,
        status,
        isSubmitting,
        errors,
      }) => (
        <>
          {!isEmpty(status) && status.type === StatusForm.warning
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="warning" text={error} />
              ))
            : null}
          {!isEmpty(status) && status.type === StatusForm.success
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="success" text={error} />
              ))
            : null}
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="12"
                controlId="comment"
                className="pb-4 position-relative"
              >
                <Textarea
                  name="comment"
                  placeholder={t('Comment')}
                  label={t('Comment')}
                  value={values.comment}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.comment && !!errors.comment}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.comment}
                />
              </Form.Group>
            </Row>
            <div className="text-center">
              <Button
                typeField="button"
                label={t('Cancel')}
                className="me-3"
                onClick={onCloseModal}
              />
              <Button
                typeField="submit"
                label={t('Complete')}
                disabledField={isSubmitting}
                loadingField={isSubmitting}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default observer(СancellationCreateCommentForm)
