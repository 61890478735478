import { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'

import UserService from '../services/UserService'

import { NonAuthPaths } from './Routes'

import isEmpty from '../utils/is-empty'

export const PrivateRoute: FC<any> = ({
  component: Component,
  ...rest
}: any) => {
  const currentUser = UserService.getCurrentUser()

  return (
    <Route
      {...rest}
      render={(props) =>
        !isEmpty(currentUser) ? (
          <Component {...props} />
        ) : (
          <Redirect to={NonAuthPaths.Login} />
        )
      }
    />
  )
}
