import React, { ReactElement, ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Field, Formik, FormikHelpers } from 'formik'
import { Form, Row, Col } from 'react-bootstrap'
import * as Yup from 'yup'

import { range } from 'lodash'

import MaskedInput from 'react-text-mask'

import isEmpty from '../../utils/is-empty'
import { getGender } from '../../utils/get-gender'
import { getDocType } from '../../utils/get-doc-type'
import { getSocialStatus } from '../../utils/get-social-status'
import { getIssuingAuthority } from '../../utils/get-issuing-authority'
import { getBanksBiks, getBankName } from '../../utils/get-banks'

import { StatusForm, PhoneNumberMask, IinMask } from '../../types'
import { PersonFormProps, InitialValues } from './types'

import PhoneClear from '../../utils/phone-clear'

import {
  Input,
  Button,
  Select,
  Check,
  ReactSelect,
} from '../../components/ui/Forms'
import { Alert } from '../../components/ui/Alert'
import { DatePicker } from '../../components/ui/DatePicker'

function PersonForm({
  initialValues,
  handleService,
  buttonlabel,
  link,
  responseMessage,
}: PersonFormProps): ReactElement {
  const history = useHistory()
  const { t } = useTranslation()

  const date = new Date()
  const yearsArray = range(1900, date.getFullYear() + 1)

  const schema = Yup.object().shape({
    // Наименование физического лица
    name: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Дата рождения физического лица
    birthday: Yup.string().required(t('InputRequiredValidate')),

    // Пол физического лица
    gender: Yup.string().required(t('InputRequiredValidate')),

    // Гражданство физического лица
    citizenship: Yup.string()
      .max(40, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Место рождения физического лица
    birthplace: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // ИИН физического лица
    iin: Yup.string()
      .max(12, ({ max }) => `Максимальное значение поля ${max} символов`)
      .min(12, ({ min }) => `Минимальное значение поля ${min} символов`)
      .matches(/^[0-9]+$/, {
        message: t('IncorrectIin'),
        excludeEmptyString: true,
      })
      .required(t('InputRequiredValidate')),

    // Документ, удостоверяющий личность
    docType: Yup.string().required(t('InputRequiredValidate')),

    // Номер документа
    personIdn: Yup.string()
      .length(9, ({ length }) => `Длина поля составляет ${length} символов`)
      .required(t('InputRequiredValidate')),

    // Орган выдачи
    issuingAuthority: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Дата выдачи
    dateOfIssue: Yup.string().required(t('InputRequiredValidate')),

    // Адрес прописки
    legalAddress: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Адрес проживания
    actualAddress: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Email
    email: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .email(t('EmailRequiredValidate'))
      .required(t('InputRequiredValidate')),

    // Телефон
    phone: Yup.string()
      .matches(/^[0-9()+ ]+$/, {
        message: t('FieldPhoneIncorrect'),
        excludeEmptyString: true,
      })
      .required(t('InputRequiredValidate')),

    // Социальный статус
    // socialStatus: Yup.bool().oneOf([true], t('ConditionsRequiredValidate')),
    socialStatus: Yup.string().max(
      100,
      ({ max }) => `Максимальное значение поля ${max} символов`,
    ),

    // Иностранный гражданин
    isForeign: Yup.bool(),

    // БИК сотрудника
    bik: Yup.string()
      .length(8, ({ length }) => `Длина поля составляет ${length} символов`)
      .required(t('InputRequiredValidate')),

    // Наименование банка
    bankName: Yup.string()
      .max(1000, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Номер банковского счета (IBAN)
    iban: Yup.string()
      .max(20, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // КБе (Код Бенефициара)
    bc: Yup.string()
      .matches(/^(1[1-9]{1,2}|2\d{1,2})$/, t('FieldOnlyDigitsBc'))
      .required(t('InputRequiredValidate')),

    // Совпадает с юридическим адресом
    duplication: Yup.bool(),
  })

  const handleSubmitForm = async (
    values: InitialValues,
    action: FormikHelpers<InitialValues>,
  ): Promise<void> => {
    const payload = {
      id: values.id,
      orgId: values.orgId,
      actualAddress: values.actualAddress,
      birthday: values.birthday,
      birthplace: values.birthplace,
      citizenship: values.citizenship,
      dateOfIssue: values.dateOfIssue,
      docType: values.docType,
      email: values.email,
      gender: Number(values.gender),
      iin: values.iin,
      isForeign: Boolean(values.isForeign),
      issuingAuthority: values.issuingAuthority,
      legalAddress: values.legalAddress,
      name: values.name,
      personIdn: values.personIdn,
      phone: PhoneClear(values.phone),
      socialStatus: values.socialStatus,
      bik: values.bik,
      bankName: values.bankName,
      bc: values.bc,
      iban: values.iban,
    }

    const errors = await handleService(payload)

    if (!isEmpty(errors)) {
      action.setStatus({
        type: StatusForm.warning,
        messages: errors,
      })
      return
    }

    action.setStatus({
      type: StatusForm.success,
      messages: [responseMessage],
    })

    action.setSubmitting(false)

    if (link) {
      history.push(link)
    }
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
        status,
        isSubmitting,
        errors,
      }) => (
        <>
          {!isEmpty(status) && status.type === StatusForm.warning
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="warning" text={error} />
              ))
            : null}
          {!isEmpty(status) && status.type === StatusForm.success
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="success" text={error} />
              ))
            : null}
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="iin"
                className="pb-4 position-relative"
              >
                <Field
                  name="iin"
                  label={t('Iin')}
                  render={({ ...field }) => (
                    <>
                      <label className="form-label" htmlFor="iin">
                        {t('Iin')}
                      </label>
                      <MaskedInput
                        {...field}
                        mask={IinMask}
                        id="iin"
                        value={values.iin}
                        placeholder={t('Iin')}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.iin && touched.iin
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                      {errors.iin && touched.iin && (
                        <div className="User_invalid-feedback-position__23DZb invalid-feedback">
                          {errors.iin}
                        </div>
                      )}
                    </>
                  )}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="3"
                lg="6"
                controlId="name"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="name"
                  placeholder={t('FIO')}
                  label={t('FIO')}
                  value={values.name}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.name && !!errors.name}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.name}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="3"
                lg="6"
                controlId="birthday"
                className="pb-4 position-relative"
              >
                <DatePicker
                  name="birthday"
                  valueText={String(values.birthday)}
                  placeholder={t('Birthday')}
                  onChange={setFieldValue}
                  years={yearsArray}
                  label={t('Birthday')}
                  isInvalid={touched.birthday && !!errors.birthday}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.birthday}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="2"
                lg="6"
                controlId="gender"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('Gender')}
                  name="gender"
                  value={String(values.gender)}
                  onChangeCallback={handleChange}
                  isInvalid={touched.gender && !!errors.gender}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.gender}
                >
                  <option value="">{t('Gender')}</option>
                  {getGender().map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group
                as={Col}
                xl="3"
                lg="6"
                controlId="citizenship"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="citizenship"
                  placeholder={t('Citizenship')}
                  label={t('Citizenship')}
                  value={values.citizenship}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.citizenship && !!errors.citizenship}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.citizenship}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="docType"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('DocType')}
                  name="docType"
                  value={String(values.docType)}
                  onChangeCallback={handleChange}
                  isInvalid={touched.docType && !!errors.docType}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.docType}
                >
                  <option value="">{t('DocType')}</option>
                  {getDocType().map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group
                as={Col}
                xl="3"
                lg="6"
                controlId="personIdn"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="personIdn"
                  placeholder={t('PersonIdn')}
                  label={t('PersonIdn')}
                  value={values.personIdn}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.personIdn && !!errors.personIdn}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.personIdn}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="2"
                lg="6"
                controlId="dateOfIssue"
                className="pb-4 position-relative"
              >
                <DatePicker
                  name="dateOfIssue"
                  valueText={String(values.dateOfIssue)}
                  placeholder={t('DateOfIssue')}
                  onChange={setFieldValue}
                  years={yearsArray}
                  label={t('DateOfIssue')}
                  isInvalid={touched.dateOfIssue && !!errors.dateOfIssue}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.dateOfIssue}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Check
                  required
                  id="isForeign"
                  name="isForeign"
                  label={t('IsForeign')}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.isForeign && !!errors.isForeign}
                  error={errors.isForeign}
                  checked={values.isForeign}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="issuingAuthority"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('IssuingAuthority')}
                  name="issuingAuthority"
                  value={String(values.issuingAuthority)}
                  onChangeCallback={handleChange}
                  isInvalid={
                    touched.issuingAuthority && !!errors.issuingAuthority
                  }
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.issuingAuthority}
                >
                  <option value="">{t('IssuingAuthority')}</option>
                  {getIssuingAuthority().map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group
                as={Col}
                xl="8"
                lg="6"
                controlId="birthplace"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="birthplace"
                  placeholder={t('Birthplace')}
                  label={t('Birthplace')}
                  value={values.birthplace}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.birthplace && !!errors.birthplace}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.birthplace}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="legalAddress"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="legalAddress"
                  placeholder={t('RegistrationAddress')}
                  label={t('RegistrationAddress')}
                  value={values.legalAddress}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.legalAddress && !!errors.legalAddress}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.legalAddress}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="actualAddress"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="actualAddress"
                  placeholder={t('ResidentialAddress')}
                  label={t('ResidentialAddress')}
                  value={values.actualAddress}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.actualAddress && !!errors.actualAddress}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.actualAddress}
                  disabled={values.duplication}
                />
              </Form.Group>

              <Form.Group className="pb-4">
                <Check
                  required
                  id="duplication"
                  name="duplication"
                  label={t('DuplicationRegistrationAddress')}
                  onChangeCallback={(e: ChangeEvent<any>) => {
                    handleChange(e)
                    setFieldValue('actualAddress', values.legalAddress)
                  }}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.duplication && !!errors.duplication}
                  error={errors.duplication}
                  checked={values.duplication}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="email"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="email"
                  name="email"
                  placeholder={t('Email')}
                  label={t('Email')}
                  value={values.email}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.email}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="phone"
                className="pb-4 position-relative"
              >
                <Field
                  name="phone"
                  label={t('Telephone')}
                  render={({ ...field }) => (
                    <>
                      <label className="form-label" htmlFor="phone">
                        {t('Telephone')}
                      </label>
                      <MaskedInput
                        {...field}
                        mask={PhoneNumberMask}
                        id="phone"
                        value={values.phone}
                        placeholder={t('Telephone')}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.phone && touched.phone
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                      {errors.phone && touched.phone && (
                        <div className="User_invalid-feedback-position__23DZb invalid-feedback">
                          {errors.phone}
                        </div>
                      )}
                    </>
                  )}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="4"
                controlId="socialStatus"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('SocialStatus')}
                  name="socialStatus"
                  value={String(values.socialStatus)}
                  onChangeCallback={handleChange}
                  isInvalid={touched.socialStatus && !!errors.socialStatus}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.socialStatus}
                >
                  <option value="">{t('SocialStatus')}</option>
                  {getSocialStatus().map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Form.Group>

              <div className="my-3" style={{ fontWeight: 'bold' }}>
                {t('BankDetails')}
              </div>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="bik"
                className="pb-4 position-relative"
              >
                <ReactSelect
                  name="bankName"
                  value={String(values.bankName)}
                  label={t('BankName')}
                  placeholder={t('BankName')}
                  isInvalid={touched.bankName && !!errors.bankName}
                  typeFeedback="invalid"
                  error={errors.bankName}
                  onChange={(name: string, value: string) => {
                    const bankname = getBankName(value)
                    setFieldValue(name, value)
                    setFieldValue('bik', bankname)
                  }}
                  onBlur={setFieldTouched}
                  loadOptions={() => getBanksBiks()}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="bankName"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="bik"
                  placeholder={t('Bik')}
                  label={t('Bik')}
                  value={values.bik}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.bik && !!errors.bik}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.bik}
                  disabled
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="iban"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="iban"
                  placeholder={t('Iban')}
                  label={t('Iban')}
                  value={values.iban}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.iban && !!errors.iban}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.iban}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="bc"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="bc"
                  placeholder={t('RecipientCode')}
                  label={t('RecipientCode')}
                  value={String(values.bc)}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.bc && !!errors.bc}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.bc}
                />
              </Form.Group>
            </Row>
            <div className="text-center">
              <Button
                typeField="submit"
                label={buttonlabel}
                disabledField={isSubmitting}
                loadingField={isSubmitting}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default observer(PersonForm)
