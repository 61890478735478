import { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form, Popover, OverlayTrigger } from 'react-bootstrap'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { useUserStore } from '../../providers/RootStoreProvider'
import { UserCreds } from '../../services/UserService'
import { PrivatePaths } from '../../router/Routes'

import isEmpty from '../../utils/is-empty'

import { StatusForm } from '../../types'

import { HeadingSecond } from '../../components/ui/Typography/Typography'
import { Input, Button } from '../../components/ui/Forms'
import { Alert } from '../../components/ui/Alert'

import styles from './User.module.scss'

export default function Login(): ReactElement {
  const { t } = useTranslation()
  const history = useHistory()
  const user = useUserStore()
  const [disabledReset, setDisabledReset] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [message, setTooltipMessage] = useState(null)

  useEffect(() => {
    if (!isEmpty(user.currentUser)) {
      user.cleanUser()
    } else {
      localStorage.clear()
    }
  }, [])

  const initialValues: UserCreds = {
    login: '',
    password: '',
  }

  const schema = Yup.object().shape({
    // Логин пользователя
    login: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),
    // Пароль пользователя
    password: Yup.string().required(t('InputRequiredValidate')),
  })

  const handleSubmitForm = async (
    values: UserCreds,
    action: FormikHelpers<UserCreds>,
  ): Promise<void> => {
    const payload = {
      login: values.login,
      password: values.password,
    }

    const errors = await user.signIn(payload)

    if (!isEmpty(errors)) {
      action.setStatus({
        type: StatusForm.warning,
        messages: errors,
      })
      return
    }

    if (!user.organizations || !user.organizations.length) {
      history.push(PrivatePaths.OrganizationCreate)
    } else if (user.organizations && user.organizations.length === 1) {
      history.push(
        PrivatePaths.TmcList.replace(':orgId', user.organizations[0].orgId),
      )
    } else {
      user.setIsShowMenu(true)
      history.push(PrivatePaths.OrganizationList)
    }
  }

  const handleResetLink = async (e: any, login: string): Promise<void> => {
    e.preventDefault()
    if (login) {
      const payload = {
        login,
      }

      const result = await user.resetPassword(payload)

      setDisabledReset(false)

      if (result === 'success') {
        setTooltipMessage(t('TemporaryPasswordWasSending'))
      }

      if (result === 'user_error') {
        setTooltipMessage(t('UserIsNotFound'))
      }

      setShowTooltip(true)
    } else {
      setDisabledReset(false)
    }
  }

  return (
    <>
      <HeadingSecond>{t('Authorization')}</HeadingSecond>

      <div className="container-wrapper">
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
        >
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            touched,
            values,
            status,
            isSubmitting,
            errors,
          }) => (
            <>
              {!isEmpty(status) && status.type === StatusForm.warning
                ? status.messages.map((error: string) => (
                    <Alert key={error} variant="warning" text={error} />
                  ))
                : null}
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="email"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="login"
                        name="login"
                        placeholder={`${t('Email')}`}
                        label={`${t('Login')} *`}
                        value={values.login}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.login && !!errors.login}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.login}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="password"
                      className="pb-4 position-relative"
                    >
                      <Input
                        typeField="password"
                        name="password"
                        placeholder={`${t('Password')}`}
                        label={`${t('Password')} *`}
                        value={values.password}
                        onChangeCallback={handleChange}
                        onBlurCallback={handleBlur}
                        isInvalid={touched.password && !!errors.password}
                        typeFeedback="invalid"
                        classNameFeedback={styles['invalid-feedback-position']}
                        error={errors.password}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="d-flex justify-content-center align-items-center">
                    <OverlayTrigger
                      show={showTooltip}
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Popover id="popover-positioned-bottom">
                          <Popover.Body>{message}</Popover.Body>
                        </Popover>
                      }
                    >
                      <div>
                        {!disabledReset && (
                          <a
                            onClick={(e) => {
                              setDisabledReset(true)
                              handleResetLink(e, values.login)
                            }}
                            href="!#"
                          >
                            {t('ForgotPassword')}
                          </a>
                        )}
                        {disabledReset && <span>{t('ForgotPassword')}</span>}
                      </div>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    typeField="submit"
                    label={t('Authenticate')}
                    disabledField={isSubmitting}
                    loadingField={isSubmitting}
                  />
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  )
}
