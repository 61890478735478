interface StorageType {
  key: string
  value: string
  text: string
}

const data: StorageType[] = [
  {
    key: '1',
    value: '1',
    text: 'Оптовый',
  },
  {
    key: '2',
    value: '2',
    text: 'Розничный',
  },
]

export const getStorageType = (): StorageType[] => data

export const getCurrentStorageType = (value: string): StorageType | undefined =>
  data.find((item) => item.value === value)
