import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useKkmStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import KkmForm from './KkmForm'

interface RouteParams {
  orgId: string
}

function KkmCreate({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const kkm = useKkmStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (kkm.cashRegisters === null) {
      kkm.requestCashRegisters()
    }

    return () => {
      kkm.setErrors([])
    }
  }, [])

  if (!isEmpty(kkm.errors)) {
    return <Error errors={kkm.errors} />
  }

  if (kkm.cashRegisters === null || kkm.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    name: '',
    ofdId: '',
    id: 0,
    linked: false,
  }

  const linkList = getLink(PrivatePaths.KkmList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          {
            id: 1,
            title: t('CashRegisterList'),
            link: linkList,
            active: false,
          },
          {
            id: 2,
            title: t('CashRegisterCreate'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('CashRegisterCreate')}</Heading>

      <div className="container-wrapper">
        <KkmForm
          initialValues={initialValues}
          handleService={kkm.createCashRegister}
          buttonlabel={t('Create')}
          link={linkList}
          responseMessage={t('CashRegistersAddedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(KkmCreate)))
