import { ReactElement, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths } from '../../router/Routes'

import { useOrganizationsStore } from '../../providers/RootStoreProvider'

import CertificateService from '../../services/CertificateService'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { TypesForm } from '../../types'
import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'

import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import OrganizationForm from './OrganizationForm'

// const bin = subjectDn.match(/SERIALNUMBER=[^,]*/g)?.toString().split('=')[1].slice(3)

function OrganizationCreate(): ReactElement {
  const { t } = useTranslation()

  const organization = useOrganizationsStore()

  useEffect(() => {
    if (organization.organizations === null) {
      organization.requestOrganizations()
    }

    return () => {
      organization.setErrors([])
    }
  }, [])

  if (!isEmpty(organization.errors)) {
    return <Error errors={organization.errors} />
  }

  if (organization.organizations === null || organization.isLoader) {
    return <Loader />
  }

  if (isEmpty(CertificateService.getLocalCertificate())) {
    return <Redirect to={PrivatePaths.Certificate} />
  }

  const { subjectDn } = CertificateService.getLocalCertificate()
  const [, , binSubjectDn, , , emailSubjectDn] = subjectDn.split(',')

  const initialValues: InitialValues = {
    actualAddress: '',
    bik: '',
    bankName: '',
    bc: '',
    bin: !isEmpty(binSubjectDn) ? binSubjectDn.split('=')[1].slice(3) : '',
    email: !isEmpty(emailSubjectDn)
      ? emailSubjectDn.split('=')[1].toLowerCase()
      : '',
    iik: '',
    legalAddress: '',
    okved: '',
    orgName: '',
    phone: '',
    supervisor: '',
    type: '',
    orgId: 0,
    duplication: false,
  }

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('OrganizationList'), link: '/', active: false },
          {
            id: 2,
            title: t('OrganizationCreate'),
            link: '/',
            active: true,
          },
        ]}
      />
      <div className="container-wrapper">
        <OrganizationForm
          initialValues={initialValues}
          handleService={organization.createOrganization}
          typeForm={TypesForm.creating}
          buttonlabel={t('Create')}
          link={PrivatePaths.OrganizationList}
          responseMessage={t('OrganizationAddedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withAuthRedirect(observer(OrganizationCreate))
