import { ReactElement, useEffect } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useStaffPositionsStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Error } from '../../components/ui/Error'

import StaffPositionTableData from './StaffPositionTableData'

interface RouteParams {
  orgId: string
}

function StaffPositionList({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const staffPosition = useStaffPositionsStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      staffPosition.staffPositions === null ||
      !staffPosition.isStaffPositionByOrganizationId(Number(orgId))
    ) {
      staffPosition.requestStaffPositions({
        orgId: Number(orgId),
      })
    }

    return () => {
      staffPosition.setErrors([])
    }
  }, [orgId])

  const handleClose = (id: number): void => {
    staffPosition.closeStaffPosition({ id })
  }

  const handleOpen = (id: number): void => {
    staffPosition.openStaffPosition({ id })
  }

  if (!isEmpty(staffPosition.errors)) {
    return <Error errors={staffPosition.errors} />
  }

  if (staffPosition.staffPositions === null || staffPosition.isLoader) {
    return <Loader />
  }

  const linkCreate = getLink(PrivatePaths.StaffPositionCreate, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Heading>{t('StaffPositionList')}</Heading>

      <div className="text-center mb-5">
        <Link to={linkCreate} className="btn btn-primary text-white">
          {t('Staff Position link')}
        </Link>
      </div>

      <StaffPositionTableData
        staffPositions={staffPosition.staffPositions}
        onClose={handleClose}
        onOpen={handleOpen}
        orgId={orgId}
      />
    </>
  )
}

export default withRouter(withAuthRedirect(observer(StaffPositionList)))
