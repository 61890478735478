import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'react-bootstrap'

import { useTmcСancellationStore } from '../../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../../hoc/withAuthRedirect'

import isEmpty from '../../../utils/is-empty'

import TableData from '../../../components/ui/TableData'
import { Heading } from '../../../components/ui/Typography/Typography'
import { Loader } from '../../../components/ui/Loader'
import { Error } from '../../../components/ui/Error'

interface RouteParams {
  orgId: string
  СancellationId: string
}

function СancellationDetailed({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const Сancellations = useTmcСancellationStore()

  const { orgId, СancellationId } = params as RouteParams

  useEffect(() => {
    if (Сancellations.productsDetailСancellation === null) {
      Сancellations.requestDetailСancellation({ id: Number(СancellationId) })
    }

    return () => {
      Сancellations.setErrors([])
    }
  }, [orgId, СancellationId])

  if (!isEmpty(Сancellations.errors)) {
    return <Error errors={Сancellations.errors} />
  }

  if (
    Сancellations.productsDetailСancellation === null ||
    Сancellations.isLoader
  ) {
    return <Loader />
  }

  const columns = [
    {
      name: 'id',
      label: '№',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'productName',
      label: t('ProductName'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'amount',
      label: t('Amount'),
      options: {
        filter: true,
        sort: false,
      },
    },
  ]

  const data = Сancellations.productsDetailСancellation.map((item) => ({
    id: item.id,
    productName: item.productName,
    amount: item.amount,
  }))

  return (
    <>
      <Heading>{t('TmcСancellation')}</Heading>
      <div className="container-wrapper">
        <h2
          className="mb-3"
          style={{ fontSize: 16, textTransform: 'uppercase', fontWeight: 700 }}
        >
          {t('TmcСancellationDetailed')}
        </h2>

        <hr />

        <Row>
          <Col md={6} lg={3}>
            <span className="text-secondary">Дата:</span> <b>28.08.2021</b>
          </Col>
          <Col md={6} lg={3}>
            <span className="text-secondary">Склад:</span> <b>Основной склад</b>
          </Col>
          <Col md={6} lg={3}>
            <span className="text-secondary">Сотрудник:</span> <b>Алмат</b>
          </Col>
          <Col md={6} lg={3}>
            <span className="text-secondary">Комментарий:</span> <b>Проверка</b>
          </Col>
        </Row>

        <hr />

        {!isEmpty(Сancellations.productsDetailСancellation) ? (
          <TableData data={data} columns={columns} />
        ) : (
          <p>{t('ListСancellationProductsIsEmpty')}</p>
        )}
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(СancellationDetailed)))
