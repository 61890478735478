import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import DepartmentService, {
  Department,
  DepartmentId,
} from '../services/DepartmentService'

import { OrgId } from '../services/OrganizationService'

import { ReactSelectValues } from '../types'

import getErrorsMapping from '../utils/get-errors-mapping'
import isEmpty from '../utils/is-empty'

export class DepartmentsStore {
  departments: Department[] = null as unknown as Department[]

  currentDepartment: Department = {} as Department

  errors: string[] = []

  isLoader = false

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      departments: observable,
      currentDepartment: observable,
      errors: observable,
      isLoader: observable,
      setErrors: action.bound,
      requestDepartments: action.bound,
      createDepartment: action.bound,
      getDepartment: action.bound,
      updateDepartment: action.bound,
      removeDepartment: action.bound,
      requestOptionsDepartment: action.bound,
      setDepartments: action.bound,
      setDepartment: action.bound,
      setCurrentDepartment: action.bound,
      editDepartment: action.bound,
      deleteDepartment: action.bound,
      isDepartmentByOrganizationId: action.bound,
      clearCurrentDepartment: action.bound,
      fillCurrentDepartment: action.bound,
      isLoaderChanged: action.bound,
    })

    this.rootStore = rootStore
  }

  setDepartments(payload: Department[]): void {
    this.departments = payload
  }

  setDepartment(payload: Department): void {
    this.departments = [...this.departments, payload]
  }

  setCurrentDepartment(payload: Department): void {
    this.currentDepartment = payload
  }

  editDepartment(payload: Department): void {
    this.departments = this.departments.map((department) =>
      department.id === payload.id ? { ...department, ...payload } : department,
    )
  }

  deleteDepartment(id: number): void {
    this.departments = this.departments.filter(
      (department) => department.id !== id,
    )
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  isDepartmentByOrganizationId(organizationId: number): boolean {
    const department = this.departments.filter(
      ({ orgId }: Department): boolean => orgId === organizationId,
    )
    return !isEmpty(department)
  }

  clearCurrentDepartment(): void {
    this.currentDepartment = {} as Department
  }

  fillCurrentDepartment(departmentId: DepartmentId): void {
    if (!isEmpty(this.departments)) {
      const currentDepartment = this.departments.filter(
        (item) => item.id === departmentId.id,
      )[0]
      this.setCurrentDepartment(currentDepartment)
    } else {
      this.getDepartment(departmentId)
    }
  }

  async requestDepartments(payload: OrgId): Promise<void> {
    this.isLoaderChanged(true)
    try {
      const response = await DepartmentService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({
            id,
            orgId,
            formationDate,
            name,
            parentId,
            workScheduleId,
          }: Department): Department => ({
            id,
            orgId,
            formationDate,
            name,
            parentId,
            workScheduleId,
          }),
        )
        this.setDepartments(result)
      }
    } catch (e: any) {
      console.log(
        `Class DepartmentsStore, method requestDepartments, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async getDepartment(payload: DepartmentId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await DepartmentService.get(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const { id, orgId, formationDate, name, parentId, workScheduleId } =
          json
        this.setCurrentDepartment({
          id,
          orgId,
          formationDate,
          name,
          parentId,
          workScheduleId,
        })
      }
    } catch (e: any) {
      console.log(
        `Class DepartmentsStore, method getDepartment, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async createDepartment(payload: Department): Promise<null | string[]> {
    try {
      const response = await DepartmentService.add(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const { id, orgId, formationDate, name, parentId, workScheduleId } =
          json
        this.setDepartment({
          id,
          orgId,
          formationDate,
          name,
          parentId,
          workScheduleId,
        })
      }
    } catch (e: any) {
      console.log(
        `Class DepartmentsStore, method createDepartment, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async updateDepartment(payload: Department): Promise<null | string[]> {
    try {
      const response = await DepartmentService.edit(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400 || response.status === 404) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const { id, orgId, formationDate, name, parentId, workScheduleId } =
          json
        this.setCurrentDepartment({
          id,
          orgId,
          formationDate,
          name,
          parentId,
          workScheduleId,
        })
        this.editDepartment({
          id,
          orgId,
          formationDate,
          name,
          parentId,
          workScheduleId,
        })
      }
    } catch (e: any) {
      console.log(
        `Class DepartmentsStore, method updateDepartment, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async removeDepartment(payload: DepartmentId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await DepartmentService.delete(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 400) {
        const json = await response.json()
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.deleteDepartment(payload.id)
      }
    } catch (e: any) {
      console.log(
        `Class DepartmentsStore, method removeDepartment, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async requestOptionsDepartment(payload: OrgId): Promise<ReactSelectValues[]> {
    try {
      const response = await DepartmentService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({ id, name }: Department): ReactSelectValues => ({
            value: String(id),
            label: String(name),
          }),
        )
        return result
      }
    } catch (e: any) {
      console.log(
        `Class DepartmentsStore, method requestOptionsDepartment, type error ${e.name}, Message ${e.message}`,
      )
    }

    return []
  }
}
