import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { usePersonsStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import PersonForm from './PersonForm'

interface RouteParams {
  orgId: string
}

function PersonCreate({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const person = usePersonsStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      person.persons === null ||
      !person.isPersonByOrganizationId(Number(orgId))
    ) {
      person.requestPersons({ orgId: Number(orgId) })
    }

    return () => {
      person.setErrors([])
    }
  }, [orgId])

  if (!isEmpty(person.errors)) {
    return <Error errors={person.errors} />
  }

  if (person.persons === null || person.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    orgId: Number(orgId),
    id: 0,
    actualAddress: '',
    birthday: '',
    birthplace: '',
    citizenship: '',
    dateOfIssue: '',
    docType: '',
    email: '',
    gender: '',
    iin: '',
    isForeign: false,
    issuingAuthority: '',
    legalAddress: '',
    name: '',
    personIdn: '',
    phone: '',
    socialStatus: '',
    bik: '',
    bankName: '',
    iban: '',
    bc: '',
    duplication: false,
  }

  const linkList = getLink(PrivatePaths.PersonList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('PersonList'), link: linkList, active: false },
          {
            id: 2,
            title: t('PersonCreate'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('PersonCreate')}</Heading>

      <div className="container-wrapper">
        <PersonForm
          initialValues={initialValues}
          handleService={person.createPerson}
          buttonlabel={t('Create')}
          link={linkList}
          responseMessage={t('PersonAddedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(PersonCreate)))
