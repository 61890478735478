import isEmpty from './is-empty'

export function formatDateToString(date: Date | null): string {
  if (!date) {
    return ''
  }

  const [sDate, sTime] = date.toLocaleString().split(', ')

  const nDate = sDate.split('.').reverse().join('-')

  return `${nDate}T${sTime}`
}

export function formatDateForValue(date: Date | null): string {
  if (!date) {
    return ''
  }

  const [sDate] = date.toLocaleString().split(', ')

  const nDate = sDate.split('.').join('-')

  return nDate
}

export function getDate(valueText: string): Date | null {
  if (isEmpty(valueText)) {
    return null
  }

  const [sDate, sTime] = valueText.split('T')
  const [year, month, day] = sDate.split('-')
  const [hour, minute, second] = sTime.split(':')

  return new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    Number(hour),
    Number(minute),
    Number(second),
  )
}

export function getDateTimeOfString(
  date: string,
  dateLabel = 'Дата',
  timeLabel = 'Время',
): string {
  const [sDate, sTime] = date.split('T')
  const nDate = sDate.split('-').reverse().join('-')
  const [nTime] = sTime.split('.')

  return `${dateLabel}: ${nDate} / ${timeLabel}: ${nTime}`
}

export function getDateToHunanString(date: Date | null): string {
  if (!date) {
    return ''
  }

  const [sDate] = date.toLocaleString().split(', ')

  return sDate
}
