import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import {
  useEmployeesStore,
  useDepartmentsStore,
  usePersonsStore,
  usePositionsStore,
} from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import EmployeeForm from './EmployeeForm'

interface RouteParams {
  orgId: string
}

function EmployeeCreate({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const employee = useEmployeesStore()
  const person = usePersonsStore()
  const department = useDepartmentsStore()
  const position = usePositionsStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      employee.employees === null ||
      !employee.isEmployeeByOrganizationId(Number(orgId))
    ) {
      employee.requestEmployees({ orgId: Number(orgId) })
    }

    return () => {
      employee.setErrors([])
    }
  }, [orgId])

  if (!isEmpty(employee.errors)) {
    return <Error errors={employee.errors} />
  }

  if (employee.employees === null || employee.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    orgId: Number(orgId),
    id: 0,
    contractDate: '',
    contractEndDate: '',
    contractNumber: '',
    contractStartDate: '',
    contractType: '',
    employeeWorkScheduleId: '',
    employmentType: '',
    orderDate: '',
    orderNumber: '',
    personnelNumber: '',
    probation: '',
    salary: '',
    salaryFund: '',
    vacationDays: '',
    personId: '',
    departmentId: '',
    positionId: '',
  }

  const linkList = getLink(PrivatePaths.EmployeeList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('EmployeeList'), link: linkList, active: false },
          {
            id: 2,
            title: t('EmployeeCreate'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('EmployeeCreate')}</Heading>

      <div className="container-wrapper">
        <EmployeeForm
          initialValues={initialValues}
          handleService={employee.createEmployee}
          buttonlabel={t('Create')}
          link={linkList}
          loadOptionsDepartment={department.requestOptionsDepartment}
          loadOptionsPosition={position.requestOptionsPosition}
          loadOptionsPerson={person.requestOptionsPerson}
          responseMessage={t('EmployeeAddedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(EmployeeCreate)))
