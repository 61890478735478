import { ReactElement, useEffect } from 'react'
import {
  Link,
  withRouter,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import {
  useStoragesStore,
  useTmcPriceListStore,
} from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography'
import { Loader } from '../../components/ui/Loader'
import { Error } from '../../components/ui/Error'

import StorageTableData from './StorageTableData'
import { Product } from '../../services/TmcService'

interface RouteParams {
  orgId: string
}

function StorageList({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const storage = useStoragesStore()
  const tmc = useTmcPriceListStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      storage.storages === null ||
      !storage.isStorageByOrganizationId(Number(orgId))
    ) {
      storage.requestStorages({ orgId: Number(orgId) })
    }

    return () => {
      storage.setErrors([])
      storage.setStatusError(0)
    }
  }, [orgId])

  const handleRemove = (storeId: number): void => {
    storage.removeStorage({ storeId })
  }

  const checkIsStoreEmpty = (
    storeId: number,
    success: (result: boolean) => void,
  ): Promise<void> =>
    tmc.requestPriceList({ storeId: Number(storeId) }).then((e) => {
      let total = 0
      e.priceList.map((item: Product) => {
        total += item.stockBalance
        return item
      })
      success(total > 0)
    })

  if (storage.statusError === 500) {
    return <Redirect to="/500" />
  }

  if (!isEmpty(storage.errors)) {
    return <Error errors={storage.errors} />
  }

  if (storage.storages === null || storage.isLoader) {
    return <Loader />
  }

  const linkCreate = getLink(PrivatePaths.StorageCreate, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Heading>{t('StorageList')}</Heading>

      <div className="mb-3">
        <Link to={linkCreate} className="btn btn-link">
          + {t('Storage link')}
        </Link>
      </div>

      <div className="storageList">
        <StorageTableData
          storages={storage.storages}
          onRemove={handleRemove}
          checkIsStoreEmpty={checkIsStoreEmpty}
          orgId={orgId}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(StorageList)))
