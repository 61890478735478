import { confirmAlert } from 'react-confirm-alert'
import { Modal } from 'react-bootstrap'
import { Button } from '../components/ui/Forms'

export default function modalAlert(
  id: number,
  text: string,
  buttonLabel: string,
  size: 'sm' | 'lg' | 'xl',
): any {
  return confirmAlert({
    customUI: ({ onClose }: any) => (
      <Modal.Dialog size={size}>
        <Modal.Body>
          <p>{text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="text-white"
            onClick={onClose}
            label={buttonLabel}
          />
        </Modal.Footer>
      </Modal.Dialog>
    ),
  })
}
