import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'

import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables'

import isEmpty from '../../../utils/is-empty'

interface Data {
  [x: string]: any
}

interface TableDataType {
  title?: string
  data: Data[]
  columns: MUIDataTableColumn[]
  tableOptions?: any
}

function TableData({
  title,
  data,
  columns,
  tableOptions,
}: TableDataType): ReactElement {
  const { t } = useTranslation()

  const getMuiTheme = () => {
    const theme = createTheme({
      typography: {
        fontFamily: ['Roboto', 'Aria', 'sans- serif'].join(','),
      },
      palette: {
        primary: {
          light: '#1E93F8',
          main: '#1E93F8',
          dark: '#1E93F8',
          contrastText: '#fff',
        },
      },
      overrides: {
        MuiPaper: {
          elevation4: {
            boxShadow: 'none',
            border: '1px solid #DFDFDF',
            boxSizing: 'border-box',
          },
        },
        MuiToolbar: {
          root: { display: 'none' },
          gutters: {
            '@media (min-width: 600px)': {
              paddingLeft: 26,
              paddingRight: 0,
            },
          },
        },
        MuiTableCell: {
          head: {
            backgroundColor: 'rgba(217, 217, 217, 0.3) !important',
            fontSize: '0.8125rem',
            fontWeight: 500,
            paddingLeft: 26,
            paddingRight: 26,
          },
          body: {
            paddingLeft: 26,
            paddingRight: 26,
          },
        },
        MUIDataTableHeadCell: {
          toolButton: {
            fontWeight: 700,
          },
        },
        MuiTypography: {
          h6: {
            fontSize: '1rem',
          },
        },
        MuiButton: {
          text: {
            fontSize: '0.8125rem',
          },
        },
      },
    })
    return theme
  }

  let options = {
    filter: false,
    search: false,
    pagination: false,
    expandableRowsHeader: false,
    print: false,
    download: false,
    rowsPerPageOptions: [10, 25, 50],
    rowsPerPage: 10,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    filterArrayFullMatch: false,
    fixedSelectColumn: false,
    sortFilterList: false,
    sort: false,
    viewColumns: false,
    searchPlaceholder: 'Поиск',
    textLabels: {
      body: {
        noMatch: t('Not found'),
        toolTip: t('ToolTip'),
        columnHeaderTooltip: (column: any) =>
          `${t('СolumnHeaderTooltip')} "${column.label}"`,
      },
      toolbar: {
        search: t('Toolbar search'),
        downloadCsv: t('Toolbar downloadCsv'),
        print: t('Toolbar print'),
        viewColumns: t('Toolbar viewColumns'),
        filterTable: t('Toolbar filterTable'),
      },
      pagination: {
        next: t('Next Page'),
        previous: t('Previous Page'),
        rowsPerPage: t('Rows per page'),
        displayRows: t('DisplayRows'),
      },
      filter: {
        all: t('Filter all'),
        title: t('Filteres'),
        reset: t('Reset'),
      },
      viewColumns: {
        title: t('Show Columns'),
        titleAria: t('Show/Hide Table Columns'),
      },
      selectedRows: {
        text: t('RowsSelected'),
        delete: t('Delete'),
        deleteAria: t('DeleteSelectedRows'),
      },
    },
  }

  if (tableOptions) {
    options = {
      ...options,
      ...tableOptions,
    }
  }

  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={!isEmpty(title) ? title : ' '}
          data={data}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </>
  )
}

export default observer(TableData)
