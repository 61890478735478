import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'

import './assets/styles/index.scss'

import reportWebVitals from './reportWebVitals'
import { RootStoreProvider } from './providers/RootStoreProvider'
import './i18n'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <RootStoreProvider>
        <App />
      </RootStoreProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
