interface UnitMeasurement {
  key: string
  value: string
  text: string
}

const data: UnitMeasurement[] = [
  {
    key: '1',
    value: '1',
    text: 'шт',
  },
  {
    key: '2',
    value: '2',
    text: 'кг',
  },
  {
    key: '3',
    value: '3',
    text: 'л',
  },
]

export const getUnitMeasurement = (): UnitMeasurement[] => data

export const getCurrentUnitMeasurement = (
  value: string,
): UnitMeasurement | undefined => data.find((item) => item.value === value)
