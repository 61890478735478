interface IssuingAuthority {
  key: string
  value: string
  text: string
}

const data: IssuingAuthority[] = [
  {
    key: 'МВД РК',
    value: 'МВД РК',
    text: 'МВД РК',
  },
  {
    key: 'МЮ РК',
    value: 'МЮ РК',
    text: 'МЮ РК',
  },
  {
    key: 'Другое',
    value: 'Другое',
    text: 'Другое',
  },
]

export const getIssuingAuthority = (): IssuingAuthority[] => data

export const getCurrentDocType = (
  value: string,
): IssuingAuthority | undefined => data.find((item) => item.value === value)
