import { FC } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { Main as MainPage } from '../pages/Main'
import { LoginPage, SignUpPage } from '../pages/User'

import UserService from '../services/UserService'

import { NonAuthPaths } from './Routes'

import isEmpty from '../utils/is-empty'

const routes = [
  {
    path: NonAuthPaths.Main,
    exact: true,
    component: MainPage,
  },
  {
    path: NonAuthPaths.Login,
    exact: true,
    component: LoginPage,
  },
  {
    path: NonAuthPaths.SignUp,
    exact: true,
    component: SignUpPage,
  },
]

export const NonAuthRouter: FC = () => {
  const currentUser = UserService.getCurrentUser()

  return !isEmpty(currentUser) ? (
    <Redirect to="/app/organization/list" />
  ) : (
    <Switch>
      {routes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
    </Switch>
  )
}
