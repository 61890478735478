import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import UserService, {
  UserData,
  UserCreds,
  UserOrganization,
  ResetPasswordParams,
} from '../services/UserService'

import getErrorsMapping from '../utils/get-errors-mapping'
import isEmpty from '../utils/is-empty'
import has from '../utils/has'

export class UserStore {
  currentUser: UserData = {} as UserData

  organizations: UserOrganization[] = []

  organization: UserOrganization = {} as UserOrganization

  errors: string[] = []

  isLoader = false

  isShowMenu = false

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      currentUser: observable,
      organization: observable,
      errors: observable,
      isLoader: observable,
      setErrors: action.bound,
      signIn: action.bound,
      signUp: action.bound,
      getUser: action.bound,
      updateUser: action.bound,
      setUser: action.bound,
      setOrganizations: action.bound,
      setOrganization: action.bound,
      setDefaultOrganization: action.bound,
      cleanUser: action.bound,
      removeUser: action.bound,
      removeOrganizations: action.bound,
      isLoaderChanged: action.bound,
    })

    this.rootStore = rootStore
  }

  setIsShowMenu(payload: boolean): void {
    this.isShowMenu = payload
  }

  setUser(payload: UserData): void {
    this.currentUser = payload
  }

  setOrganizations(payload: UserOrganization[]): void {
    this.organizations = payload
  }

  setDefaultOrganization(payload: UserOrganization): void {
    this.organization = payload
  }

  setOrganization(payload: UserOrganization): void {
    this.organizations = [...this.organizations, payload]
  }

  removeUser(): void {
    this.currentUser = {} as UserData
  }

  removeOrganizations(): void {
    this.organizations = []
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  cleanUser(): void {
    UserService.signOut()
    document.location.href = '/non-auth/login'
  }

  async signIn(payload: UserCreds): Promise<null | string[]> {
    this.isLoaderChanged(true)
    try {
      const response = await UserService.signIn(payload)

      if (response.status !== 200) {
        if (has(response, 'statusText')) {
          const errors = getErrorsMapping([response.statusText])
          return errors
        }
        return [`Class ${UserStore}, method ${'signIn'} Errors Unauthorized`]
      }

      if (!isEmpty(UserService.getCurrentUser())) {
        await this.getUser()
      }
    } catch (e: any) {
      console.log(
        `Class UserStore, method signIn, type error ${e.name}, Message ${e.message}`,
      )
      const errors = getErrorsMapping(['CHECK_INPUT_INCORRECT'])
      return errors
    }

    this.isLoaderChanged(false)

    return null
  }

  async resetPassword(payload: ResetPasswordParams): Promise<null | string> {
    this.isLoaderChanged(true)
    try {
      const response = await UserService.resetPassword(payload)

      this.isLoaderChanged(false)
      if (response.status === 200) {
        return 'success'
      }

      const json = await response.json()
      if (
        json &&
        json.responseDescription &&
        json.responseDescription.indexOf('unknown_user') !== -1
      ) {
        return 'user_error'
      }

      return 'error'
    } catch (e: any) {
      console.log(
        `Class UserStore, method signIn, type error ${e.name}, Message ${e.message}`,
      )
      console.log(e)
      return 'error'
    }
  }

  async signUp(payload: UserData): Promise<null | string[]> {
    this.isLoaderChanged(true)

    try {
      const response = await UserService.signUp(payload)

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400 || response.status === 403) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }
    } catch (e: any) {
      return [e.message]
    }

    this.isLoaderChanged(false)

    return null
  }

  async getUser(): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await UserService.get()

      if (response.status === 403) {
        this.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(['DEFAULT_ERROR']))
      }

      const json = await response.json()

      if (response.status >= 200 && response.status < 300) {
        const { firstName, lastName, patName, email, phone, organizations } =
          json

        this.setUser({
          firstName,
          lastName,
          patName,
          email,
          phone,
          password: '',
        })

        this.setOrganizations(organizations)

        if (organizations && organizations.length) {
          this.setDefaultOrganization(organizations[0])
        }
      }
    } catch (e: any) {
      console.log(
        `Class UserStore, method getUser, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async updateUser(payload: UserData): Promise<null | string[]> {
    try {
      const response = await UserService.edit(payload)

      if (response.status === 403) {
        this.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        this.setUser(payload)
      }
    } catch (e: any) {
      return [e.message]
    }

    return null
  }
}
