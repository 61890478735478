import { PersonAPI } from './ApiUrls'
import httpClient from './httpClient'
import { OrgId } from './OrganizationService'

export interface PersonId {
  id: number
}

export interface Person extends PersonId, OrgId {
  actualAddress: string
  birthday: string
  birthplace: string
  citizenship: string
  dateOfIssue: string
  docType: string
  email: string
  gender: number
  iin: string
  isForeign: boolean
  issuingAuthority: string
  legalAddress: string
  name: string
  personIdn: string
  phone: string
  socialStatus: string
  bik: string
  iban: string
  bankName: string
  bc: string
}

export class PersonService {
  list = async (model: OrgId): Promise<Response> => {
    const result = await httpClient.post(PersonAPI.List, model)

    return result
  }

  add = async (model: Person): Promise<Response> => {
    const result = await httpClient.post(PersonAPI.Add, model)

    return result
  }

  edit = async (model: Person): Promise<Response> => {
    const result = await httpClient.post(PersonAPI.Edit, model)

    return result
  }

  delete = async (model: PersonId): Promise<Response> => {
    const result = await httpClient.post(PersonAPI.Delete, model)

    return result
  }

  get = async (model: PersonId): Promise<Response> => {
    const result = await httpClient.post(PersonAPI.Get, model)

    return result
  }
}

export default new PersonService()
