import { ReactElement, useState, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../../router/Routes'

import {
  useStoragesStore,
  useCounterpartiesStore,
  useTmcReceiveStore,
} from '../../../providers/RootStoreProvider'
import TmcService, {
  ProductReceived,
  LocalStorageName,
} from '../../../services/TmcService'

import { withAuthRedirect } from '../../../hoc/withAuthRedirect'

import isEmpty from '../../../utils/is-empty'
import getLink from '../../../utils/get-link'

import { InitialValues } from './types'

import { Heading } from '../../../components/ui/Typography/Typography'
import { Loader } from '../../../components/ui/Loader'
import { Modal } from '../../../components/ui/Modal'
import { Breadcrumb } from '../../../components/ui/Breadcrumb'
import { Error } from '../../../components/ui/Error'

import { Button } from '../../../components/ui/Forms'

import NomenclatureForm from './NomenclatureForm'
import ReceiveData from './ReceiveData'
import { TableDataDeletedRow } from '../../../types'

interface RouteParams {
  orgId: string
}

function Receive({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const storage = useStoragesStore()
  const counterparty = useCounterpartiesStore()
  const tmc = useTmcReceiveStore()

  const { orgId } = params as RouteParams

  const localStorageName = `${LocalStorageName.productsReceived}-${orgId}`
  const localReceivedProducts: ProductReceived[] =
    TmcService.getLocalProductsReceived(localStorageName)

  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  useEffect(() => {
    if (!isEmpty(localReceivedProducts)) {
      tmc.setProductsReceived(localReceivedProducts)
    }

    return () => {
      storage.setErrors([])
      counterparty.setErrors([])
      tmc.setErrors([])
    }
  }, [orgId])

  const handleAddProductsReceived = (values: InitialValues): void => {
    const payload = {
      id: values.id,
      orgId: values.orgId,
      counterpartyId: Number(values.counterpartyId),
      storeId: Number(values.storeId),
      barcode: values.barcode,
      nameRus: values.nameRus,
      nameKaz: values.nameKaz,
      vendorCode: values.vendorCode,
      units: values.units,
      amount: Number(values.amount),
      purchasePrice: Number(values.purchasePrice),
    }

    tmc.setProductReceived(payload)

    TmcService.setLocalProductReceived(localStorageName, payload)

    tmc.clearNomenclature()

    handleCloseModal()
  }

  const handleRemoveProductsReceived = (rows: TableDataDeletedRow[]) => {
    let products: ProductReceived[] = []

    for (let i = 0; i < rows.length; i += 1) {
      const row = rows[i]
      products = tmc.removeProductReceivedByIndex(row.index)
      console.log(products)
    }

    TmcService.setLocalProductsReceived(localStorageName, products)
  }

  const handleUploadProductsReceived = () => {
    const payload = tmc.productsReceived.map((product) => ({
      orgId: product.orgId,
      counterpartyId: product.counterpartyId,
      storeId: product.storeId,
      nomenclatureId: product.id,
      amount: product.amount,
      purchasePrice: product.purchasePrice,
    }))

    tmc.createProducts(payload, localStorageName)
  }

  if (!isEmpty(storage.errors)) {
    return <Error errors={storage.errors} />
  }

  if (!isEmpty(counterparty.errors)) {
    return <Error errors={counterparty.errors} />
  }

  if (tmc.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    orgId: Number(orgId),
    counterpartyId: '',
    storeId: '',
    id: !isEmpty(tmc.nomenclature) ? tmc.nomenclature.id : 0,
    barcode: !isEmpty(tmc.nomenclature) ? tmc.nomenclature.barcode : '',
    nameRus: !isEmpty(tmc.nomenclature) ? tmc.nomenclature.nameRus : '',
    nameKaz: !isEmpty(tmc.nomenclature) ? tmc.nomenclature.nameKaz : '',
    vendorCode: !isEmpty(tmc.nomenclature) ? tmc.nomenclature.vendorCode : '',
    units: !isEmpty(tmc.nomenclature) ? String(tmc.nomenclature.units) : '',
    amount: '',
    purchasePrice: '',
  }

  const linkList = getLink(PrivatePaths.TmcList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('TmcList'), link: linkList, active: false },
          {
            id: 2,
            title: t('TmcReceipt'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('TmcReceipt')}</Heading>

      <div className="text-center mb-5">
        <Button label={t('Add')} onClick={handleShowModal} />
      </div>

      <Modal
        title={t('ModalTmcReceiptAdded')}
        show={showModal}
        size="xl"
        handleModalOnHide={handleCloseModal}
      >
        <NomenclatureForm
          initialValues={initialValues}
          onAddProductsReceived={handleAddProductsReceived}
          onRequestNomenclature={tmc.requestNomenclature}
          loadOptionsStorage={storage.requestOptionsStorage}
          loadOptionsCounterparty={counterparty.requestOptionsCounterparty}
          nomenclatureIsLoader={tmc.nomenclatureIsLoader}
          nomenclatureErrors={tmc.errors}
        />
      </Modal>

      {!isEmpty(tmc.errors) && <Error errors={tmc.errors} />}

      {!isEmpty(tmc.productsReceived) && (
        <ReceiveData
          products={tmc.productsReceived}
          onUploadProductsReceived={handleUploadProductsReceived}
          onRemoveProductsReceived={handleRemoveProductsReceived}
        />
      )}
    </>
  )
}

export default withRouter(withAuthRedirect(observer(Receive)))
