import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import PersonService, { Person, PersonId } from '../services/PersonService'

import { OrgId } from '../services/OrganizationService'

import { ReactSelectValues } from '../types'

import getErrorsMapping from '../utils/get-errors-mapping'
import isEmpty from '../utils/is-empty'

export class PersonsStore {
  persons: Person[] = null as unknown as Person[]

  currentPerson: Person = {} as Person

  errors: string[] = []

  isLoader = false

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      persons: observable,
      currentPerson: observable,
      errors: observable,
      isLoader: observable,
      setErrors: action.bound,
      requestPersons: action.bound,
      createPerson: action.bound,
      getPerson: action.bound,
      updatePerson: action.bound,
      removePerson: action.bound,
      requestOptionsPerson: action.bound,
      setPersons: action.bound,
      setPerson: action.bound,
      setCurrentPerson: action.bound,
      editPerson: action.bound,
      deletePerson: action.bound,
      isPersonByOrganizationId: action.bound,
      clearCurrentPerson: action.bound,
      fillCurrentPerson: action.bound,
      isLoaderChanged: action.bound,
    })

    this.rootStore = rootStore
  }

  setPersons(payload: Person[]): void {
    this.persons = payload
  }

  setPerson(payload: Person): void {
    this.persons = [...this.persons, payload]
  }

  setCurrentPerson(payload: Person): void {
    this.currentPerson = payload
  }

  editPerson(payload: Person): void {
    this.persons = this.persons.map((person) =>
      person.id === payload.id ? { ...person, ...payload } : person,
    )
  }

  deletePerson(id: number): void {
    this.persons = this.persons.filter((person) => person.id !== id)
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  isPersonByOrganizationId(organizationId: number): boolean {
    const person = this.persons.filter(
      ({ orgId }: Person): boolean => orgId === organizationId,
    )
    return !isEmpty(person)
  }

  clearCurrentPerson(): void {
    this.currentPerson = {} as Person
  }

  fillCurrentPerson(personId: PersonId): void {
    if (!isEmpty(this.persons)) {
      const currentPerson = this.persons.filter(
        (item) => item.id === personId.id,
      )[0]
      this.setCurrentPerson(currentPerson)
    } else {
      this.getPerson(personId)
    }
  }

  async requestPersons(payload: OrgId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await PersonService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({
            id,
            orgId,
            actualAddress,
            birthday,
            birthplace,
            citizenship,
            dateOfIssue,
            docType,
            email,
            gender,
            iin,
            isForeign,
            issuingAuthority,
            legalAddress,
            name,
            personIdn,
            phone,
            socialStatus,
            iban,
            bik,
            bankName,
            bc,
          }: Person): Person => ({
            id,
            orgId,
            actualAddress,
            birthday,
            birthplace,
            citizenship,
            dateOfIssue,
            docType,
            email,
            gender,
            iin,
            isForeign,
            issuingAuthority,
            legalAddress,
            name,
            personIdn,
            phone,
            socialStatus,
            iban,
            bik,
            bankName,
            bc,
          }),
        )
        this.setPersons(result)
      }
    } catch (e: any) {
      console.log(
        `Class PersonsStore, method requestPersons, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async getPerson(payload: PersonId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await PersonService.get(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          orgId,
          actualAddress,
          birthday,
          birthplace,
          citizenship,
          dateOfIssue,
          docType,
          email,
          gender,
          iin,
          isForeign,
          issuingAuthority,
          legalAddress,
          name,
          personIdn,
          phone,
          socialStatus,
          iban,
          bik,
          bankName,
          bc,
        } = json
        this.setCurrentPerson({
          id,
          orgId,
          actualAddress,
          birthday,
          birthplace,
          citizenship,
          dateOfIssue,
          docType,
          email,
          gender,
          iin,
          isForeign,
          issuingAuthority,
          legalAddress,
          name,
          personIdn,
          phone,
          socialStatus,
          iban,
          bik,
          bankName,
          bc,
        })
      }
    } catch (e: any) {
      console.log(
        `Class PersonsStore, method getPerson, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async createPerson(payload: Person): Promise<null | string[]> {
    try {
      const response = await PersonService.add(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          orgId,
          actualAddress,
          birthday,
          birthplace,
          citizenship,
          dateOfIssue,
          docType,
          email,
          gender,
          iin,
          isForeign,
          issuingAuthority,
          legalAddress,
          name,
          personIdn,
          phone,
          socialStatus,
          iban,
          bik,
          bankName,
          bc,
        } = json
        this.setPerson({
          id,
          orgId,
          actualAddress,
          birthday,
          birthplace,
          citizenship,
          dateOfIssue,
          docType,
          email,
          gender,
          iin,
          isForeign,
          issuingAuthority,
          legalAddress,
          name,
          personIdn,
          phone,
          socialStatus,
          iban,
          bik,
          bankName,
          bc,
        })
      }
    } catch (e: any) {
      console.log(
        `Class PersonsStore, method createPerson, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async updatePerson(payload: Person): Promise<null | string[]> {
    try {
      const response = await PersonService.edit(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          actualAddress,
          birthday,
          birthplace,
          citizenship,
          dateOfIssue,
          docType,
          email,
          gender,
          iin,
          isForeign,
          issuingAuthority,
          legalAddress,
          name,
          orgId,
          personIdn,
          phone,
          socialStatus,
          iban,
          bik,
          bankName,
          bc,
        } = json
        this.setCurrentPerson({
          id,
          orgId,
          actualAddress,
          birthday,
          birthplace,
          citizenship,
          dateOfIssue,
          docType,
          email,
          gender,
          iin,
          isForeign,
          issuingAuthority,
          legalAddress,
          name,
          personIdn,
          phone,
          socialStatus,
          iban,
          bik,
          bankName,
          bc,
        })
        this.editPerson({
          id,
          orgId,
          actualAddress,
          birthday,
          birthplace,
          citizenship,
          dateOfIssue,
          docType,
          email,
          gender,
          iin,
          isForeign,
          issuingAuthority,
          legalAddress,
          name,
          personIdn,
          phone,
          socialStatus,
          iban,
          bik,
          bankName,
          bc,
        })
      }
    } catch (e: any) {
      console.log(
        `Class PersonsStore, method updatePerson, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async removePerson(payload: PersonId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await PersonService.delete(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      if (response.status === 400) {
        const json = await response.json()
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.deletePerson(payload.id)
      }
    } catch (e: any) {
      console.log(
        `Class PersonsStore, method removePerson, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async requestOptionsPerson(payload: OrgId): Promise<ReactSelectValues[]> {
    try {
      const response = await PersonService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({ id, name }: Person): ReactSelectValues => ({
            value: String(id),
            label: String(name),
          }),
        )
        return result
      }
    } catch (e: any) {
      console.log(
        `Class PersonsStore, method requestOptionsPerson, type error ${e.name}, Message ${e.message}`,
      )
    }

    return []
  }
}
