import { action, makeObservable, observable } from 'mobx'

import RootStore from './rootStore'

export class AppStore {
  language = ''

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      language: observable,
      changeLanguage: action.bound,
    })

    this.rootStore = rootStore
  }

  changeLanguage(payload: string): void {
    this.language = payload
  }
}
