import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import CounterpartyService, {
  Counterparty,
  CounterpartyId,
} from '../services/CounterpartyService'

import { OrgId } from '../services/OrganizationService'

import { ReactSelectValues } from '../types'

import getErrorsMapping from '../utils/get-errors-mapping'
import isEmpty from '../utils/is-empty'

export class CounterpartiesStore {
  counterparties: Counterparty[] = null as unknown as Counterparty[]

  currentCounterparty: Counterparty = {} as Counterparty

  errors: string[] = []

  isLoader = false

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      counterparties: observable,
      currentCounterparty: observable,
      errors: observable,
      isLoader: observable,
      setErrors: action.bound,
      requestCounterparties: action.bound,
      createCounterparty: action.bound,
      getCounterparty: action.bound,
      updateCounterparty: action.bound,
      removeCounterparty: action.bound,
      requestOptionsCounterparty: action.bound,
      setCounterparties: action.bound,
      setCounterparty: action.bound,
      setCurrentCounterparty: action.bound,
      editCounterparty: action.bound,
      deleteCounterparty: action.bound,
      isCounterpartyByOrganizationId: action.bound,
      clearCurrentCounterparty: action.bound,
      fillCurrentCounterparty: action.bound,
      isLoaderChanged: action.bound,
    })

    this.rootStore = rootStore
  }

  setCounterparties(payload: Counterparty[]): void {
    this.counterparties = payload
  }

  setCounterparty(payload: Counterparty): void {
    this.counterparties = [...this.counterparties, payload]
  }

  setCurrentCounterparty(payload: Counterparty): void {
    this.currentCounterparty = payload
  }

  editCounterparty(payload: Counterparty): void {
    this.counterparties = this.counterparties.map((counterparty) =>
      counterparty.id === payload.id
        ? { ...counterparty, ...payload }
        : counterparty,
    )
  }

  deleteCounterparty(id: number): void {
    this.counterparties = this.counterparties.filter(
      (counterparty) => counterparty.id !== id,
    )
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  isCounterpartyByOrganizationId(organizationId: number): boolean {
    const counterparty = this.counterparties.filter(
      ({ orgId }: Counterparty): boolean => orgId === organizationId,
    )
    return !isEmpty(counterparty)
  }

  clearCurrentCounterparty(): void {
    this.currentCounterparty = {} as Counterparty
  }

  fillCurrentCounterparty(id: number): void {
    if (!isEmpty(this.counterparties)) {
      const currentCounterparty = this.counterparties.filter(
        (item) => item.id === id,
      )[0]
      this.setCurrentCounterparty(currentCounterparty)
    } else {
      this.getCounterparty({ id })
    }
  }

  async requestCounterparties(payload: OrgId): Promise<void> {
    this.isLoaderChanged(true)
    try {
      const response = await CounterpartyService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({
            id,
            orgId,
            fullName,
            shortName,
            supervisor,
            bin,
            type,
            bankName,
            bankBik,
            bc,
            iic,
            legalAddress,
            actualAddress,
            email,
            phone,
            okved,
          }: Counterparty): Counterparty => ({
            id,
            orgId,
            fullName,
            shortName,
            supervisor,
            bin,
            type,
            bankName,
            bankBik,
            bc,
            iic,
            legalAddress,
            actualAddress,
            email,
            phone,
            okved,
          }),
        )

        this.setCounterparties(result)
      }
    } catch (e: any) {
      console.log(
        `Class CounterpartiesStore, method requestCounterparties, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async getCounterparty(payload: CounterpartyId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await CounterpartyService.get(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          orgId,
          fullName,
          shortName,
          supervisor,
          bin,
          type,
          bankName,
          bankBik,
          bc,
          iic,
          legalAddress,
          actualAddress,
          email,
          phone,
          okved,
        } = json
        this.setCurrentCounterparty({
          id,
          orgId,
          fullName,
          shortName,
          supervisor,
          bin,
          type,
          bankName,
          bankBik,
          bc,
          iic,
          legalAddress,
          actualAddress,
          email,
          phone,
          okved,
        })
      }
    } catch (e: any) {
      console.log(
        `Class CounterpartiesStore, method getCounterparty, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async createCounterparty(payload: Counterparty): Promise<null | string[]> {
    try {
      const response = await CounterpartyService.add(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          orgId,
          fullName,
          shortName,
          supervisor,
          bin,
          type,
          bankName,
          bankBik,
          bc,
          iic,
          legalAddress,
          actualAddress,
          email,
          phone,
          okved,
        } = json
        this.setCounterparty({
          id,
          orgId,
          fullName,
          shortName,
          supervisor,
          bin,
          type,
          bankName,
          bankBik,
          bc,
          iic,
          legalAddress,
          actualAddress,
          email,
          phone,
          okved,
        })
      }
    } catch (e: any) {
      console.log(
        `Class CounterpartiesStore, method createCounterparty, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async updateCounterparty(payload: Counterparty): Promise<null | string[]> {
    try {
      const response = await CounterpartyService.edit(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          orgId,
          fullName,
          shortName,
          supervisor,
          bin,
          type,
          bankName,
          bankBik,
          bc,
          iic,
          legalAddress,
          actualAddress,
          email,
          phone,
          okved,
        } = json

        this.setCurrentCounterparty({
          id,
          orgId,
          fullName,
          shortName,
          supervisor,
          bin,
          type,
          bankName,
          bankBik,
          bc,
          iic,
          legalAddress,
          actualAddress,
          email,
          phone,
          okved,
        })
        this.editCounterparty({
          id,
          orgId,
          fullName,
          shortName,
          supervisor,
          bin,
          type,
          bankName,
          bankBik,
          bc,
          iic,
          legalAddress,
          actualAddress,
          email,
          phone,
          okved,
        })
      }
    } catch (e: any) {
      console.log(
        `Class CounterpartiesStore, method updateCounterparty, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async removeCounterparty(payload: CounterpartyId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await CounterpartyService.delete(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.deleteCounterparty(payload.id)
      }
    } catch (e: any) {
      console.log(
        `Class CounterpartiesStore, method removeCounterparty, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async requestOptionsCounterparty(
    payload: OrgId,
  ): Promise<ReactSelectValues[]> {
    try {
      const response = await CounterpartyService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({ id, fullName }: Counterparty): ReactSelectValues => ({
            value: String(id),
            label: String(fullName),
          }),
        )
        return result
      }
    } catch (e: any) {
      console.log(
        `Class CounterpartiesStore, method requestOptionsCounterparty, type error ${e.name}, Message ${e.message}`,
      )
    }

    return []
  }
}
