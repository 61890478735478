import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Formik, FormikHelpers } from 'formik'
import { Form, Row, Col } from 'react-bootstrap'
import * as Yup from 'yup'

import { range } from 'lodash'

import isEmpty from '../../utils/is-empty'
import { getContractType } from '../../utils/get-contract-type'
import { getEmploymentTypes } from '../../utils/get-employment-types'
import { getWorkSchedule } from '../../utils/get-work-schedule'

import { StatusForm } from '../../types'
import { EmployeeFormProps, InitialValues } from './types'

import { Input, Button, Select, ReactSelect } from '../../components/ui/Forms'
import { Alert } from '../../components/ui/Alert'
import { DatePicker } from '../../components/ui/DatePicker'

function EmployeeForm({
  initialValues,
  handleService,
  buttonlabel,
  link,
  responseMessage,
  loadOptionsDepartment,
  loadOptionsPosition,
  loadOptionsPerson,
}: EmployeeFormProps): ReactElement {
  const history = useHistory()
  const { t } = useTranslation()

  const date = new Date()
  const yearsArray = range(1970, date.getFullYear() + 11)

  const schema = Yup.object().shape({
    // Физическое лицо
    personId: Yup.string().required(t('InputRequiredValidate')),

    // Номер приказа о приеме на работу
    orderNumber: Yup.string()
      .max(10, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Дата приказа
    orderDate: Yup.string().required(t('InputRequiredValidate')),

    // Номер договора
    contractNumber: Yup.string()
      .max(20, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Должность
    positionId: Yup.string().required(t('InputRequiredValidate')),

    // Подразделение
    departmentId: Yup.string().required(t('InputRequiredValidate')),

    // Дата договора
    contractDate: Yup.string().required(t('InputRequiredValidate')),

    // Дата начала договора
    contractStartDate: Yup.string().required(t('InputRequiredValidate')),

    // Дата окончания договора
    contractEndDate: Yup.string().required(t('InputRequiredValidate')),

    // Тип договора
    contractType: Yup.string().required(t('InputRequiredValidate')),

    // Вид занятости
    employmentType: Yup.string().required(t('InputRequiredValidate')),

    // График работы
    employeeWorkScheduleId: Yup.string().required(t('InputRequiredValidate')),

    // Испытательный срок календарных дней
    probation: Yup.string()
      .max(10, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Табельный номер
    personnelNumber: Yup.string()
      .max(20, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Оклад
    salary: Yup.string()
      .max(10, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Количество дней отпуска
    vacationDays: Yup.string()
      .max(10, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Фонд оплаты труда сотрудника
    salaryFund: Yup.string()
      .max(10, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),
  })

  const handleSubmitForm = async (
    values: InitialValues,
    action: FormikHelpers<InitialValues>,
  ): Promise<void> => {
    const payload = {
      id: values.id,
      orgId: values.orgId,
      contractDate: values.contractDate,
      contractEndDate: values.contractEndDate,
      contractNumber: values.contractNumber,
      contractStartDate: values.contractStartDate,
      contractType: values.contractType,
      departmentId: Number(values.departmentId),
      employeeWorkScheduleId: Number(values.employeeWorkScheduleId),
      employmentType: Number(values.employmentType),
      orderDate: values.orderDate,
      orderNumber: values.orderNumber,
      personId: Number(values.personId),
      personnelNumber: values.personnelNumber,
      positionId: Number(values.positionId),
      probation: values.probation,
      salary: Number(values.salary),
      salaryFund: Number(values.salaryFund),
      vacationDays: Number(values.vacationDays),
    }

    const errors = await handleService(payload)

    if (!isEmpty(errors)) {
      action.setStatus({
        type: StatusForm.warning,
        messages: errors,
      })
      return
    }

    action.setStatus({
      type: StatusForm.success,
      messages: [responseMessage],
    })

    action.setSubmitting(false)

    if (link) {
      history.push(link)
    }
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
        status,
        isSubmitting,
        errors,
      }) => (
        <>
          {!isEmpty(status) && status.type === StatusForm.warning
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="warning" text={error} />
              ))
            : null}
          {!isEmpty(status) && status.type === StatusForm.success
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="success" text={error} />
              ))
            : null}
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="personId"
                className="pb-4 position-relative"
              >
                <ReactSelect
                  name="personId"
                  value={String(values.personId)}
                  label={t('FIO')}
                  placeholder={t('FIO')}
                  isInvalid={touched.personId && !!errors.personId}
                  typeFeedback="invalid"
                  error={errors.personId}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  loadOptions={() => loadOptionsPerson({ orgId: values.orgId })}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="orderNumber"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="orderNumber"
                  placeholder={t('NumberEmploymentOrder')}
                  label={t('NumberEmploymentOrder')}
                  value={values.orderNumber}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.orderNumber && !!errors.orderNumber}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.orderNumber}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="orderDate"
                className="pb-4 position-relative"
              >
                <DatePicker
                  name="orderDate"
                  valueText={String(values.orderDate)}
                  placeholder={t('OrderDate')}
                  onChange={setFieldValue}
                  years={yearsArray}
                  label={t('OrderDate')}
                  isInvalid={touched.orderDate && !!errors.orderDate}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.orderDate}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="positionId"
                className="pb-4 position-relative"
              >
                <ReactSelect
                  name="positionId"
                  value={String(values.positionId)}
                  label={t('Position')}
                  placeholder={t('Position')}
                  isInvalid={touched.positionId && !!errors.positionId}
                  typeFeedback="invalid"
                  error={errors.positionId}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  loadOptions={() =>
                    loadOptionsPosition({ orgId: values.orgId })
                  }
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="departmentId"
                className="pb-4 position-relative"
              >
                <ReactSelect
                  name="departmentId"
                  value={String(values.departmentId)}
                  label={t('Department')}
                  placeholder={t('Department')}
                  isInvalid={touched.departmentId && !!errors.departmentId}
                  typeFeedback="invalid"
                  error={errors.departmentId}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  loadOptions={() =>
                    loadOptionsDepartment({ orgId: values.orgId })
                  }
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="salary"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="salary"
                  placeholder={t('Salary')}
                  label={t('Salary')}
                  value={values.salary}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.salary && !!errors.salary}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.salary}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="contractNumber"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="contractNumber"
                  placeholder={t('ContractNumber')}
                  label={t('ContractNumber')}
                  value={values.contractNumber}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.contractNumber && !!errors.contractNumber}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.contractNumber}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="contractDate"
                className="pb-4 position-relative"
              >
                <DatePicker
                  name="contractDate"
                  valueText={String(values.contractDate)}
                  placeholder={t('ContractDate')}
                  onChange={setFieldValue}
                  years={yearsArray}
                  label={t('ContractDate')}
                  isInvalid={touched.contractDate && !!errors.contractDate}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.contractDate}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="contractType"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('ContractType')}
                  name="contractType"
                  value={String(values.contractType)}
                  onChangeCallback={handleChange}
                  isInvalid={touched.contractType && !!errors.contractType}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.contractType}
                >
                  <option value="">{t('ContractType')}</option>
                  {getContractType().map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="contractStartDate"
                className="pb-4 position-relative"
              >
                <DatePicker
                  name="contractStartDate"
                  valueText={String(values.contractStartDate)}
                  placeholder={t('СontractStartDate')}
                  onChange={setFieldValue}
                  years={yearsArray}
                  label={t('СontractStartDate')}
                  isInvalid={
                    touched.contractStartDate && !!errors.contractStartDate
                  }
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.contractStartDate}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="contractEndDate"
                className="pb-4 position-relative"
              >
                <DatePicker
                  name="contractEndDate"
                  valueText={String(values.contractEndDate)}
                  placeholder={t('ContractEndDate')}
                  onChange={setFieldValue}
                  years={yearsArray}
                  label={t('ContractEndDate')}
                  isInvalid={
                    touched.contractEndDate && !!errors.contractEndDate
                  }
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.contractEndDate}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="employeeWorkScheduleId"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('WorkSchedule')}
                  name="employeeWorkScheduleId"
                  value={String(values.employeeWorkScheduleId)}
                  onChangeCallback={handleChange}
                  isInvalid={
                    touched.employeeWorkScheduleId &&
                    !!errors.employeeWorkScheduleId
                  }
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.employeeWorkScheduleId}
                >
                  <option value="">{t('WorkSchedule')}</option>
                  {getWorkSchedule().map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="employmentType"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('EmploymentType')}
                  name="employmentType"
                  value={String(values.employmentType)}
                  onChangeCallback={handleChange}
                  isInvalid={touched.employmentType && !!errors.employmentType}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.employmentType}
                >
                  <option value="">{t('EmploymentType')}</option>
                  {getEmploymentTypes().map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="vacationDays"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="vacationDays"
                  placeholder={t('VacationDays')}
                  label={t('VacationDays')}
                  value={values.vacationDays}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.vacationDays && !!errors.vacationDays}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.vacationDays}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="probation"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="probation"
                  placeholder={t('Probation')}
                  label={t('Probation')}
                  value={values.probation}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.probation && !!errors.probation}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.probation}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="personnelNumber"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="personnelNumber"
                  placeholder={t('PersonnelNumber')}
                  label={t('PersonnelNumber')}
                  value={values.personnelNumber}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={
                    touched.personnelNumber && !!errors.personnelNumber
                  }
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.personnelNumber}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="4"
                lg="6"
                controlId="salaryFund"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="salaryFund"
                  placeholder={t('SalaryFund')}
                  label={t('SalaryFund')}
                  value={values.salaryFund}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.salaryFund && !!errors.salaryFund}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.salaryFund}
                />
              </Form.Group>
            </Row>
            <div className="text-center">
              <Button
                typeField="submit"
                label={buttonlabel}
                disabledField={isSubmitting}
                loadingField={isSubmitting}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default observer(EmployeeForm)
