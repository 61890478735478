import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { CounterpartyTableDataProps } from './types'

import TableData from '../../components/ui/TableData'
import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'
import confirm from '../../utils/confirm-alert'

function CounterpartyTableData({
  counterparties,
  onRemove,
  orgId,
}: CounterpartyTableDataProps): ReactElement {
  const { t } = useTranslation()

  const columns = [
    {
      name: 'id',
      label: '№',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'bin',
      label: t('HeaderCounterpartyBin'),
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'fullName',
      label: t('HeaderName'),
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'handle',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
  ]

  const data = counterparties.map((item) => ({
    id: item.id,
    bin: item.bin,
    fullName: item.fullName,
    handle: (
      <>
        <Link
          to={getLink(PrivatePaths.CounterpartyEdit, {
            [orgId]: PrivatePathsRegexp.orgId,
            [item.id]: PrivatePathsRegexp.counterpartyId,
          })}
          className="text-success p-2 me-1"
          style={{ fontSize: '18px' }}
        >
          <i className="bi bi-pencil-fill"></i>
        </Link>

        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault()
            confirm(
              Number(item.id),
              t('AreYouSure'),
              t('AreYouSureDeleteCounterparty'),
              t('Delete'),
              'lg',
              onRemove,
            )
          }}
          className="text-danger p-2 me-1"
          style={{ fontSize: '18px' }}
        >
          <i className="bi bi-trash"></i>
        </Link>
      </>
    ),
  }))

  return (
    <>
      {!isEmpty(counterparties) ? (
        <TableData
          data={data}
          columns={columns}
          title={t('CounterpartyList')}
        />
      ) : (
        <p>{t('ListCounterpartiesIsEmpty')}</p>
      )}
    </>
  )
}

export default observer(CounterpartyTableData)
