import { ReactElement, useEffect } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { usePositionsStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Error } from '../../components/ui/Error'

import PositionTableData from './PositionTableData'

interface RouteParams {
  orgId: string
}

function PositionList({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const position = usePositionsStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      position.positions === null ||
      !position.isPositionByOrganizationId(Number(orgId))
    ) {
      position.requestPositions({ orgId: Number(orgId) })
    }

    return () => {
      position.setErrors([])
    }
  }, [orgId])

  const handleRemove = (id: number): void => {
    position.removePosition({
      id,
      orgId: Number(orgId),
    })
  }

  if (!isEmpty(position.errors)) {
    return <Error errors={position.errors} />
  }

  if (position.positions === null || position.isLoader) {
    return <Loader />
  }

  const linkCreate = getLink(PrivatePaths.PositionCreate, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Heading>{t('PositionList')}</Heading>

      <div className="text-center mb-5">
        <Link to={linkCreate} className="btn btn-primary text-white">
          {t('Position link')}
        </Link>
      </div>

      <PositionTableData
        positions={position.positions}
        onRemove={handleRemove}
        orgId={orgId}
      />
    </>
  )
}

export default withRouter(withAuthRedirect(observer(PositionList)))
