import { ReactElement, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths } from '../../router/Routes'

import { InitialValues } from './types'
import { useCertificateStore } from '../../providers/RootStoreProvider'

import CertificateForm from './CertificateForm'
import CertificateService from '../../services/CertificateService'

function Certificate(): ReactElement {
  const { t } = useTranslation()
  const certificate = useCertificateStore()

  useEffect(() => {
    console.log('useEffect mount Certificate')
    certificate.startCertificateListening()

    return () => {
      console.log('useEffect unmount Certificate')
      certificate.stopCertificateListening()
    }
  }, [])

  const certNotAfterDate = certificate.certificate.certNotAfter
    ? new Date(Number(certificate.certificate.certNotAfter)).toISOString()
    : ''
  const certNotBeforeDate = certificate.certificate.certNotBefore
    ? new Date(Number(certificate.certificate.certNotBefore)).toISOString()
    : ''

  const initialValues: InitialValues = {
    algorithm: certificate.certificate.algorithm ?? '',
    alias: certificate.certificate.alias ?? '',
    authorityKeyIdentifier:
      certificate.certificate.authorityKeyIdentifier ?? '',
    certNotAfter: certNotAfterDate,
    certNotBefore: certNotBeforeDate,
    issuerCn: certificate.certificate.issuerCn ?? '',
    issuerDn: certificate.certificate.issuerDn ?? '',
    keyId: certificate.certificate.keyId ?? '',
    pem: certificate.certificate.pem ?? '',
    serialNumber: certificate.certificate.serialNumber ?? '',
    subjectCn: certificate.certificate.subjectCn ?? '',
    subjectDn: certificate.certificate.subjectDn ?? '',
    storageSelect: 'PKCS12',
  }

  return (
    <div className="container-wrapper">
      <CertificateForm
        initialValues={initialValues}
        handleService={CertificateService.setLocalCertificate}
        buttonlabel={t('CheckCertificate')}
        link={PrivatePaths.OrganizationCreate}
        requestCertificate={certificate.requestCertificate}
        certificateData={certificate.certificate}
        certificateStatus={certificate.certificateStatus}
      />
    </div>
  )
}

export default observer(Certificate)
