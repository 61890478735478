interface Gender {
  key: string
  value: string
  text: string
}

const data: Gender[] = [
  {
    key: '0',
    value: '0',
    text: 'Мужской',
  },
  {
    key: '1',
    value: '1',
    text: 'Женский',
  },
]

export const getGender = (): Gender[] => data

export const getCurrentGender = (value: string): Gender | undefined =>
  data.find((item) => item.value === value)
