export default function PhoneClear(phone: string): string {
  let newPhone: string = phone
  if (newPhone.indexOf('+8') === 0) {
    newPhone = newPhone.replace('+8', '')
  }
  if (newPhone.indexOf('+7') === 0) {
    newPhone = newPhone.replace('+7', '')
  }
  newPhone = newPhone.replace(/\D/g, '')
  return newPhone
}
