import { ReactNode, ReactElement } from 'react'

import styles from './Typography.module.scss'

export function HeadingSecond({
  children,
}: {
  children: ReactNode
}): ReactElement {
  return <h1 className={styles.heading}>{children}</h1>
}

export function Heading({ children }: { children: ReactNode }): ReactElement {
  return <h1 className={styles.headingBorder}>{children}</h1>
}
