import { createContext, ReactNode, useContext, ReactElement } from 'react'
import { AppStore } from '../store/appStore'
import { UserStore } from '../store/userStore'
import { OrganizationsStore } from '../store/organizationStore'
import { StoragesStore } from '../store/storageStore'
import { PersonsStore } from '../store/personStore'
import { EmployeesStore } from '../store/employeeStore'
import { DepartmentsStore } from '../store/departmentStore'
import { PositionsStore } from '../store/positionStore'
import { StaffPositionsStore } from '../store/staffPositionStore'
import { TmcСancellationStore } from '../store/tmcСancellationStore'
import { TmcPriceListStore } from '../store/tmcPriceListStore'
import { TmcMoveStore } from '../store/tmcMoveStore'
import { TmcReceiveStore } from '../store/tmcReceiveStore'
import { CounterpartiesStore } from '../store/counterpartyStore'
import { KkmStore } from '../store/kkmStore'
import { CertificateStore } from '../store/certificateStore'

import RootStore from '../store/rootStore'

const StoreContext = createContext<typeof RootStore | undefined>(undefined)

export function useRootStore(): typeof RootStore {
  const context = useContext(StoreContext)

  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider')
  }

  return context
}

export function useAppStore(): AppStore {
  const { appStore } = useRootStore()

  return appStore
}

export function useUserStore(): UserStore {
  const { userStore } = useRootStore()

  return userStore
}

export function useOrganizationsStore(): OrganizationsStore {
  const { organizationsStore } = useRootStore()

  return organizationsStore
}

export function useCertificateStore(): CertificateStore {
  const { certificateStore } = useRootStore()

  return certificateStore
}

export function useStoragesStore(): StoragesStore {
  const { storagesStore } = useRootStore()

  return storagesStore
}

export function useTmcСancellationStore(): TmcСancellationStore {
  const { tmcСancellationStore } = useRootStore()

  return tmcСancellationStore
}

export function useTmcPriceListStore(): TmcPriceListStore {
  const { tmcPriceListStore } = useRootStore()

  return tmcPriceListStore
}

export function useTmcMoveStore(): TmcMoveStore {
  const { tmcMoveStore } = useRootStore()

  return tmcMoveStore
}

export function useTmcReceiveStore(): TmcReceiveStore {
  const { tmcReceiveStore } = useRootStore()

  return tmcReceiveStore
}

export function useKkmStore(): KkmStore {
  const { kkmStore } = useRootStore()

  return kkmStore
}

export function useCounterpartiesStore(): CounterpartiesStore {
  const { counterpartiesStore } = useRootStore()

  return counterpartiesStore
}

export function usePersonsStore(): PersonsStore {
  const { personsStore } = useRootStore()

  return personsStore
}

export function useEmployeesStore(): EmployeesStore {
  const { employeesStore } = useRootStore()

  return employeesStore
}

export function useDepartmentsStore(): DepartmentsStore {
  const { departmentsStore } = useRootStore()

  return departmentsStore
}

export function usePositionsStore(): PositionsStore {
  const { positionsStore } = useRootStore()

  return positionsStore
}

export function useStaffPositionsStore(): StaffPositionsStore {
  const { staffPositionsStore } = useRootStore()

  return staffPositionsStore
}

export function RootStoreProvider({
  children,
}: {
  children: ReactNode
}): ReactElement {
  return (
    <StoreContext.Provider value={RootStore}>{children}</StoreContext.Provider>
  )
}
