import { ReactElement } from 'react'
import { Row, Col } from 'react-bootstrap'

export default function Page404(): ReactElement {
  return (
    <>
      <Row>
        <Col
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontSize: 150,
              color: '#0D87EF',
              textShadow: '-3px 0px 1px #dee2e6',
            }}
          >
            404
          </div>
        </Col>
        <Col
          md={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p style={{ fontSize: 32 }}>
            К сожалениею запрашиваемая Вами страница не найдена
          </p>
        </Col>
      </Row>
    </>
  )
}
