import { ReactElement, useEffect } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useEmployeesStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Error } from '../../components/ui/Error'

import EmployeeTableData from './EmployeeTableData'

interface RouteParams {
  orgId: string
}

function EmployeeList({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const employee = useEmployeesStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      employee.employees === null ||
      !employee.isEmployeeByOrganizationId(Number(orgId))
    ) {
      employee.requestEmployees({ orgId: Number(orgId) })
    }

    return () => {
      employee.setErrors([])
    }
  }, [orgId])

  const handleRemove = (id: number): void => {
    employee.removeEmployee({
      id,
      orgId: Number(orgId),
    })
  }

  if (!isEmpty(employee.errors)) {
    return <Error errors={employee.errors} />
  }

  if (employee.employees === null || employee.isLoader) {
    return <Loader />
  }

  const linkCreate = getLink(PrivatePaths.EmployeeCreate, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Heading>{t('EmployeeList')}</Heading>

      <div className="text-center mb-5">
        <Link to={linkCreate} className="btn btn-primary text-white">
          {t('Employee link')}
        </Link>
      </div>

      <EmployeeTableData
        employees={employee.employees}
        onRemove={handleRemove}
        orgId={orgId}
      />
    </>
  )
}

export default withRouter(withAuthRedirect(observer(EmployeeList)))
