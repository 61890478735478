import { action, makeObservable, observable } from 'mobx'

import RootStore from './rootStore'
import CertificateService, {
  Certificate,
  CertificateResponse,
  CertificateStatus,
  EventsNames,
} from '../services/CertificateService'

export class CertificateStore {
  certificate: Certificate = {} as Certificate

  certificateStatus: CertificateStatus = CertificateStatus.pending

  errors: string[] = []

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      certificate: observable,
      certificateStatus: observable,
      errors: observable,
      setCertificate: action.bound,
      certificateStatusChange: action.bound,
      requestCertificate: action.bound,
      certificateHandlerCreator: action.bound,
      setErrors: action.bound,
    })

    this.rootStore = rootStore
  }

  setCertificate(payload: Certificate): void {
    this.certificate = payload
  }

  certificateStatusChange(payload: CertificateStatus): void {
    this.certificateStatus = payload
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  certificateHandlerCreator(payload: CertificateResponse): void {
    try {
      if (payload.getCode() === '500') {
        console.log(
          'class CertificateStore method certificateHandlerCreator error',
          payload.getMessage(),
        )
      }

      if (payload.getCode() === '200') {
        console.log('payload.getResponseObject()', payload.getResponseObject())
        this.setCertificate(payload.getResponseObject())
      }
    } catch (e: any) {
      console.log(e.message)
    }
  }

  async startCertificateListening(): Promise<void> {
    console.log('startCertificateListening')
    CertificateService.start()
    CertificateService.subscribe(
      EventsNames.certificateReceived,
      this.certificateHandlerCreator,
    )
    CertificateService.subscribe(
      EventsNames.statusChanged,
      this.certificateStatusChange,
    )
  }

  async stopCertificateListening(): Promise<void> {
    console.log('stopCertificateListening')
    CertificateService.stop()
    CertificateService.unsubscribe(
      EventsNames.certificateReceived,
      this.certificateHandlerCreator,
    )
    CertificateService.unsubscribe(
      EventsNames.statusChanged,
      this.certificateStatusChange,
    )
  }

  requestCertificate(selectedStorage: string): void {
    CertificateService.getCertificateInfo(selectedStorage)
  }
}
