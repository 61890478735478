/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export interface Certificate {
  algorithm: string
  alias: string
  authorityKeyIdentifier: string
  certNotAfter: string
  certNotBefore: string
  issuerCn: string
  issuerDn: string
  keyId: string
  pem: string
  serialNumber: string
  subjectCn: string
  subjectDn: string
}

export interface CertificateResponse {
  code: string
  message: string
  responseObject: Certificate
  getResult: () => CertificateResponse
  getMessage: () => string
  getCode: () => string
  getResponseObject: () => Certificate
}

export enum CertificateStatus {
  pending = 'pending',
  ready = 'ready',
  error = 'error',
  closed = 'closed',
}

export enum EventsNames {
  certificateReceived = 'certificate-received',
  statusChanged = 'status-changed',
}

export type CertificateReceivedSubscriber = (
  result: CertificateResponse,
) => void

export type StatusChangedSubscriber = (status: CertificateStatus) => void

let ws: WebSocket | null = null

const subcribers = {
  [EventsNames.certificateReceived]: [] as CertificateReceivedSubscriber[],
  [EventsNames.statusChanged]: [] as StatusChangedSubscriber[],
}

export class CertificateService {
  messageHandler(e: MessageEvent): void {
    const result = JSON.parse(e.data)
    if (result != null) {
      const payload: CertificateResponse = {
        code: result.code,
        message: result.message,
        responseObject: result.responseObject,

        getResult() {
          return this
        },

        getMessage() {
          return this.message
        },

        getResponseObject() {
          return this.responseObject
        },

        getCode() {
          return this.code
        },
      }

      subcribers[EventsNames.certificateReceived].forEach((s) => s(payload))
    }
  }

  openHandler(): void {
    console.log('CertificateService openHandler: connection opened')

    subcribers[EventsNames.statusChanged].forEach((s) =>
      s(CertificateStatus.ready),
    )
  }

  closeHandler(event: CloseEvent): void {
    if (event.wasClean) {
      console.log('CertificateService closeHandler: connection closed')
      subcribers[EventsNames.statusChanged].forEach((s) =>
        s(CertificateStatus.closed),
      )
      setTimeout(this.createChannel, 5000)
    } else {
      console.log(
        'CertificateService errorHandler: connection error. REFRESH PAGE',
      )
      subcribers[EventsNames.statusChanged].forEach((s) =>
        s(CertificateStatus.error),
      )
    }
  }

  errorHandler(): void {
    console.log(
      'CertificateService errorHandler: connection error. REFRESH PAGE',
    )

    subcribers[EventsNames.statusChanged].forEach((s) =>
      s(CertificateStatus.error),
    )
  }

  createChannel(): void {
    if (ws === null || ws.CLOSED) {
      ws = new WebSocket('wss://127.0.0.1:13579/')
      ws.addEventListener('close', this.closeHandler)
      ws.addEventListener('message', this.messageHandler)
      ws.addEventListener('open', this.openHandler)
      ws.addEventListener('error', this.errorHandler)
      console.log('CertificateService createChannel:  connection opened')
    }
  }

  cleanUp(): void {
    console.log('CertificateService cleanUp: connection closed')
    if (ws !== null) {
      ws.removeEventListener('close', this.closeHandler)
      ws.removeEventListener('message', this.messageHandler)
      ws.removeEventListener('open', this.openHandler)
      ws.removeEventListener('error', this.errorHandler)
      ws.close()
    }
  }

  start(): void {
    this.createChannel()
  }

  stop(): void {
    subcribers[EventsNames.certificateReceived] = []
    subcribers[EventsNames.statusChanged] = []
    this.cleanUp()
  }

  subscribe(
    eventName: EventsNames,
    callback: CertificateReceivedSubscriber | StatusChangedSubscriber,
  ): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    subcribers[eventName] = [...subcribers[eventName], callback]
  }

  unsubscribe(
    eventName: EventsNames,
    callback: CertificateReceivedSubscriber | StatusChangedSubscriber,
  ): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    subcribers[eventName] = subcribers[eventName].filter(
      (s: CertificateReceivedSubscriber | StatusChangedSubscriber) =>
        s !== callback,
    )
  }

  getCertificateInfo(storageName: string) {
    if (ws !== null) {
      const getKeyInfoRequest = {
        module: 'kz.gov.pki.knca.commonUtils',
        method: 'getKeyInfo',
        args: [storageName],
      }
      ws.send(JSON.stringify(getKeyInfoRequest))
    } else {
      console.error('getCertificateInfo REFRESH PAGE')
    }
  }

  getLocalCertificate = (): Certificate =>
    JSON.parse(localStorage.getItem('certificate') || '{}')

  setLocalCertificate = (payload: Certificate): void =>
    localStorage.setItem('certificate', JSON.stringify(payload))

  removeLocalCertificate = (): void => localStorage.removeItem('certificate')
}

export default new CertificateService()
