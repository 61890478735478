import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Formik, FormikHelpers } from 'formik'
import { Form, Row, Col } from 'react-bootstrap'
import * as Yup from 'yup'

import { range } from 'lodash'

import isEmpty from '../../utils/is-empty'
import { getWorkSchedule } from '../../utils/get-work-schedule'

import { StatusForm } from '../../types'
import { DepartmentFormProps, InitialValues } from './types'

import { Input, Button, Select } from '../../components/ui/Forms'
import { Alert } from '../../components/ui/Alert'
import { DatePicker } from '../../components/ui/DatePicker'

function DepartmentForm({
  initialValues,
  handleService,
  buttonlabel,
  link,
  responseMessage,
}: DepartmentFormProps): ReactElement {
  const history = useHistory()
  const { t } = useTranslation()

  const date = new Date()
  const yearsArray = range(1990, date.getFullYear() + 1)

  const schema = Yup.object().shape({
    // Наименование подразделения
    name: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .required(t('InputRequiredValidate')),

    // Дата формирования подразделения
    formationDate: Yup.string().required(t('InputRequiredValidate')),

    // Наименование вышестоящего подразделения
    parentId: Yup.string().required(t('InputRequiredValidate')),

    // График работы
    workScheduleId: Yup.string(),
  })

  const handleSubmitForm = async (
    values: InitialValues,
    action: FormikHelpers<InitialValues>,
  ): Promise<void> => {
    const payload = {
      id: values.id,
      orgId: values.orgId,
      name: values.name,
      formationDate: values.formationDate,
      parentId: Number(values.parentId),
      workScheduleId: Number(values.workScheduleId),
    }

    const errors = await handleService(payload)

    if (!isEmpty(errors)) {
      action.setStatus({
        type: StatusForm.warning,
        messages: errors,
      })
      return
    }

    action.setStatus({
      type: StatusForm.success,
      messages: [responseMessage],
    })

    action.setSubmitting(false)

    if (link) {
      history.push(link)
    }
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
    >
      {({
        handleSubmit,
        setFieldValue,
        handleBlur,
        handleChange,
        touched,
        values,
        status,
        isSubmitting,
        errors,
      }) => (
        <>
          {!isEmpty(status) && status.type === StatusForm.warning
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="warning" text={error} />
              ))
            : null}
          {!isEmpty(status) && status.type === StatusForm.success
            ? status.messages.map((error: string) => (
                <Alert key={error} variant="success" text={error} />
              ))
            : null}
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="name"
                className="pb-4 position-relative"
              >
                <Input
                  typeField="text"
                  name="name"
                  placeholder={t('DepartmentName')}
                  label={t('DepartmentName')}
                  value={values.name}
                  onChangeCallback={handleChange}
                  onBlurCallback={handleBlur}
                  isInvalid={touched.name && !!errors.name}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.name}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="parentId"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('HigherLevelDepartmentName')}
                  name="parentId"
                  value={String(values.parentId)}
                  onChangeCallback={handleChange}
                  isInvalid={touched.parentId && !!errors.parentId}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.parentId}
                >
                  <option value="">{t('HigherLevelDepartmentName')}</option>
                  <option key="0" value="0">
                    Высшее подразделение 1
                  </option>
                </Select>
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="workScheduleId"
                className="pb-4 position-relative"
              >
                <Select
                  label={t('WorkSchedule')}
                  name="workScheduleId"
                  value={String(values.workScheduleId)}
                  onChangeCallback={handleChange}
                  isInvalid={touched.workScheduleId && !!errors.workScheduleId}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.workScheduleId}
                >
                  <option value="">{t('WorkSchedule')}</option>
                  {getWorkSchedule().map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group
                as={Col}
                xl="6"
                lg="6"
                controlId="formationDate"
                className="pb-4 position-relative"
              >
                <DatePicker
                  name="formationDate"
                  valueText={String(values.formationDate)}
                  placeholder={t('FormationDate')}
                  onChange={setFieldValue}
                  years={yearsArray}
                  label={t('FormationDate')}
                  isInvalid={touched.formationDate && !!errors.formationDate}
                  typeFeedback="invalid"
                  classNameFeedback="invalid-feedback-position"
                  error={errors.formationDate}
                />
              </Form.Group>
            </Row>
            <div className="text-center">
              <Button
                typeField="submit"
                label={buttonlabel}
                disabledField={isSubmitting}
                loadingField={isSubmitting}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default observer(DepartmentForm)
