import { TmcAPI } from './ApiUrls'
import httpClient from './httpClient'
import { StorageId } from './StorageService'
import { OrgId } from './OrganizationService'
import has from '../utils/has'
import omit from '../utils/omit'

export interface NomenclatureBarcode {
  barcode: string
}

export interface Id {
  id: number
}

export interface ValueProduct {
  count: number
  tmcId: number
}

export interface ProductValueCounts {
  [id: number]: number
}

// ------------------- Nomenclature ----------------------//
export interface Nomenclature extends Id, NomenclatureBarcode {
  nameRus: string
  nameKaz: string
  vendorCode: string
  customInfo?: string
  units: string
  unit?: string
  vendorBin?: string
  cnfea?: number
  vendorInfo?: string
  vendorCodeExt?: string
}

export interface Product extends Nomenclature {
  amount: number
  stockBalance: number
  purchasePrice: number
}

// ---------------------- TmcReceipt -----------------------//
export interface StoreCounterpartyIds {
  counterpartyId: number
  storeId: number
}

export interface ProductReceived
  extends OrgId,
    StoreCounterpartyIds,
    Nomenclature {
  amount: number
  purchasePrice: number
}

export interface ProductReceivedRequest extends OrgId, StoreCounterpartyIds {
  nomenclatureId: number
  amount: number
  purchasePrice: number
}

// ----------------------- TmcMove -------------------------//
export interface StoreIdsForMove {
  storeIdSrc: number
  storeIdDest: number
}

export interface ProductMoved {
  storeIds: StoreIdsForMove
  values: ProductValueCounts
}

export interface ProductMoving {
  storeIdSrc: number
  storeIdDest: number
  operationType: number
  values: ValueProduct[]
}

// ------------------- TmcСancellation ---------------------//
export interface Сancellation {
  operationId: number
  operationDate: string
  operationComment: string
  storeIdSrc: number | null
  storeIdDest: number | null
  storeNameDest: string | null
  storeNameSrc: string | null
  userId: number
  userLastName: string
  userFirstName: string
  userPatName: string
}

export interface DataForСancellation {
  storeIdSrc: number
}

export interface ProductСancellationed {
  data: DataForСancellation
  values: ProductValueCounts
}

export interface ProductСancellationing {
  comment: string
  storeId: number
  values: ValueProduct[]
}

export interface СancellationRequest {
  operationType: number
  orgId: number
  storeId: number
  tmcId?: number
  periodEnd?: string
  periodStart?: string
  userId?: number
}

export interface ProductDetailСancellation extends Id {
  productName: string
  amount: number
}

export enum FilterType {
  receiving = 'receiving',
  filtering = 'filtering',
}

export interface FilterValues {
  storeId: number
  tmcId: number
  periodStart: string
  periodEnd: string
}

export enum FilterFields {
  storeId = 'storeId',
  tmcId = 'tmcId',
  periodStart = 'periodStart',
  periodEnd = 'periodEnd',
}

// ---------------------------------------------------------//

export enum LocalStorageName {
  productsReceived = 'LocalReceivedProducts',
  productsForMove = 'LocalMovedProducts',
  productsСancellation = 'localСancellationProducts',
}

export class TmcService {
  list = async (model: StorageId): Promise<Response> => {
    const result = await httpClient.post(TmcAPI.List, model)

    return result
  }

  add = async (model: ProductReceivedRequest[]): Promise<Response> => {
    const result = await httpClient.post(TmcAPI.Add, model)

    return result
  }

  move = async (model: ProductMoving): Promise<Response> => {
    const result = await httpClient.post(TmcAPI.Move, model)

    return result
  }

  get = async (model: NomenclatureBarcode): Promise<Response> => {
    const result = await httpClient.post(TmcAPI.Get, model)

    return result
  }

  Сancellation = async (model: СancellationRequest): Promise<Response> => {
    const result = await httpClient.post(TmcAPI.MovementList, model)

    return result
  }

  writeOff = async (model: ProductСancellationing): Promise<Response> => {
    const result = await httpClient.post(TmcAPI.Move, model)

    return result
  }

  getLocalMovedProducts = (localStorageName: string): any =>
    JSON.parse(localStorage.getItem(localStorageName) || '{}')

  setLocalMovedProducts = (
    localStorageName: string,
    model: ProductMoved,
  ): void => {
    localStorage.setItem(localStorageName, JSON.stringify(model))
  }

  setLocalMovedProduct = (
    localStorageName: string,
    productId: number,
    count: number,
  ): void => {
    const localProductMoved = this.getLocalMovedProducts(localStorageName)

    const { values } = localProductMoved

    const model: ProductMoved = {
      ...localProductMoved,
      values: has(values, productId)
        ? omit(values, [productId])
        : { ...values, [productId]: count },
    }

    this.setLocalMovedProducts(localStorageName, model)
  }

  updateLocalMovedProduct = (
    localStorageName: string,
    productId: number,
    count: number,
  ): void => {
    const localProductMoved = this.getLocalMovedProducts(localStorageName)

    const { values } = localProductMoved

    const model: ProductMoved = {
      ...localProductMoved,
      values: { ...values, [productId]: count },
    }

    this.setLocalMovedProducts(localStorageName, model)
  }

  removeLocalMovedProducts = (localStorageName: string): void => {
    localStorage.removeItem(localStorageName)
  }

  getLocalProductsReceived = (localStorageName: string): any =>
    JSON.parse(localStorage.getItem(localStorageName) || '[]')

  setLocalProductReceived = (
    localStorageName: string,
    payload: ProductReceived,
  ): void => {
    const localProductReceived = this.getLocalProductsReceived(localStorageName)

    const model: ProductReceived[] = [...localProductReceived, payload]

    localStorage.setItem(localStorageName, JSON.stringify(model))
  }

  setLocalProductsReceived = (
    localStorageName: string,
    payload: ProductReceived[],
  ): void => {
    localStorage.setItem(localStorageName, JSON.stringify(payload))
  }

  removeLocalProductsReceived = (localStorageName: string): void => {
    localStorage.removeItem(localStorageName)
  }

  getLocalСancellationProducts = (localStorageName: string): any =>
    JSON.parse(localStorage.getItem(localStorageName) || '{}')

  setLocalСancellationProducts = (
    localStorageName: string,
    model: ProductСancellationed,
  ): void => {
    localStorage.setItem(localStorageName, JSON.stringify(model))
  }

  setLocalСancellationProduct = (
    localStorageName: string,
    productId: number,
    count: number,
  ): void => {
    const localProductMoved =
      this.getLocalСancellationProducts(localStorageName)

    const { values } = localProductMoved

    const model: ProductСancellationed = {
      ...localProductMoved,
      values: has(values, productId)
        ? omit(values, [productId])
        : { ...values, [productId]: count },
    }

    this.setLocalСancellationProducts(localStorageName, model)
  }

  updateLocalСancellationProduct = (
    localStorageName: string,
    productId: number,
    count: number,
  ): void => {
    const localProductMoved =
      this.getLocalСancellationProducts(localStorageName)

    const { values } = localProductMoved

    const model: ProductСancellationed = {
      ...localProductMoved,
      values: { ...values, [productId]: count },
    }

    this.setLocalСancellationProducts(localStorageName, model)
  }

  removeLocalСancellationedProducts = (localStorageName: string): void => {
    localStorage.removeItem(localStorageName)
  }
}

export default new TmcService()
