import { ReactElement, useEffect } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { usePersonsStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Error } from '../../components/ui/Error'

import PersonTableData from './PersonTableData'

interface RouteParams {
  orgId: string
}

function PersonList({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const person = usePersonsStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      person.persons === null ||
      !person.isPersonByOrganizationId(Number(orgId))
    ) {
      person.requestPersons({ orgId: Number(orgId) })
    }

    return () => {
      person.setErrors([])
    }
  }, [orgId])

  const handleRemove = (id: number): void => {
    person.removePerson({ id })
  }

  if (!isEmpty(person.errors)) {
    return <Error errors={person.errors} />
  }

  if (person.persons === null || person.isLoader) {
    return <Loader />
  }

  const linkCreate = getLink(PrivatePaths.PersonCreate, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Heading>{t('PersonList')}</Heading>

      <div className="text-center mb-5">
        <Link to={linkCreate} className="btn btn-primary text-white">
          {t('Person link')}
        </Link>
      </div>

      <PersonTableData
        persons={person.persons}
        onRemove={handleRemove}
        orgId={orgId}
      />
    </>
  )
}

export default withRouter(withAuthRedirect(observer(PersonList)))
