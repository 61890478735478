import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { StaffPositionTableDataProps } from './types'

import TableData from '../../components/ui/TableData'
import isEmpty from '../../utils/is-empty'
import { getDateTimeOfString } from '../../utils/get-date'
import getLink from '../../utils/get-link'
import confirm from '../../utils/confirm-alert'

function StaffPositionTableData({
  staffPositions,
  onClose,
  onOpen,
  orgId,
}: StaffPositionTableDataProps): ReactElement {
  const { t } = useTranslation()

  const columns = [
    {
      name: 'id',
      label: '№',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'departmentName',
      label: t('Department'),
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'positionName',
      label: t('Position'),
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'status',
      label: t('HeaderStatus'),
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'handle',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
  ]

  const data = staffPositions.map((item) => ({
    id: item.id,
    departmentName: item.departmentName,
    positionName: item.positionName,
    status: !item.closed
      ? t('Opened')
      : `${t('Closed')} (${t('Date closed')} ${getDateTimeOfString(
          item.closeDate,
        )})`,
    handle: (
      <>
        <Link
          to={getLink(PrivatePaths.StaffPositionEdit, {
            [orgId]: PrivatePathsRegexp.orgId,
            [item.id]: PrivatePathsRegexp.staffPositionId,
          })}
          className="text-success p-2 me-1"
          style={{ fontSize: '18px' }}
        >
          <i className="bi bi-pencil-fill"></i>
        </Link>

        {!item.closed ? (
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault()
              confirm(
                Number(item.id),
                t('AreYouSure'),
                t('AreYouSureCloseStaffPosition'),
                t('Close'),
                'lg',
                onClose,
              )
            }}
            className="text-danger p-2 me-1"
            style={{ fontSize: '18px' }}
          >
            <i className="bi bi-x-lg"></i>
          </Link>
        ) : (
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault()
              confirm(
                Number(item.id),
                t('AreYouSure'),
                t('AreYouSureOpenStaffPosition'),
                t('Open'),
                'lg',
                onOpen,
              )
            }}
            className="text-success p-2 me-1"
            style={{ fontSize: '18px' }}
          >
            <i className="bi bi-check-lg"></i>
          </Link>
        )}
      </>
    ),
  }))

  return (
    <>
      {!isEmpty(staffPositions) ? (
        <TableData
          data={data}
          columns={columns}
          title={t('StaffPositionList')}
        />
      ) : (
        <p>{t('ListStaffPositionsIsEmpty')}</p>
      )}
    </>
  )
}

export default observer(StaffPositionTableData)
