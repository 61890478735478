import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { ProductMoveFormProps } from './types'

import { Button, ReactSelect } from '../../../components/ui/Forms'
import { useUserStore } from '../../../providers/RootStoreProvider'
import { getDateToHunanString } from '../../../utils/get-date'

function MoveForm({
  initialValues,
  handleSubmitForm,
  loadOptionsStorage,
  isSubmitting,
}: ProductMoveFormProps): ReactElement {
  const { t } = useTranslation()
  const user = useUserStore()
  const currentDate = getDateToHunanString(new Date())

  const schema = Yup.object().shape({
    // Со склада
    storeIdSrc: Yup.string().required(t('InputRequiredValidate')),
    // На склад
    storeIdDest: Yup.string().required(t('InputRequiredValidate')),
  })

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({
        handleSubmit,
        touched,
        values,
        errors,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          <Form noValidate onSubmit={handleSubmit} className="mb-3">
            <Row className="mb-3">
              {user.currentUser && user.currentUser.firstName && (
                <p>
                  {`${user.currentUser.firstName} ${user.currentUser.lastName} ${currentDate}`}
                </p>
              )}
              <Form.Group
                as={Col}
                md="12"
                controlId="storeIdSrc"
                className="pb-4 position-relative"
              >
                <ReactSelect
                  name="storeIdSrc"
                  value={String(values.storeIdSrc)}
                  label={t('SelectStorageFrom')}
                  placeholder={t('SelectStorageFrom')}
                  isInvalid={touched.storeIdSrc && !!errors.storeIdSrc}
                  typeFeedback="invalid"
                  error={errors.storeIdSrc}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  loadOptions={() =>
                    loadOptionsStorage({ orgId: values.orgId })
                  }
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md="12"
                controlId="storeIdDest"
                className="pb-4 position-relative"
              >
                <ReactSelect
                  name="storeIdDest"
                  value={String(values.storeIdDest)}
                  label={t('SelectStorageTo')}
                  placeholder={t('SelectStorageTo')}
                  isInvalid={touched.storeIdDest && !!errors.storeIdDest}
                  typeFeedback="invalid"
                  error={errors.storeIdDest}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  loadOptions={() =>
                    loadOptionsStorage({ orgId: values.orgId })
                  }
                />
              </Form.Group>
            </Row>
            <div className="text-center">
              <Button
                typeField="submit"
                label={t('Continue')}
                disabledField={isSubmitting}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default observer(MoveForm)
