import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { usePositionsStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import PositionForm from './PositionForm'

interface RouteParams {
  orgId: string
}

function PositionCreate({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const position = usePositionsStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      position.positions === null ||
      !position.isPositionByOrganizationId(Number(orgId))
    ) {
      position.requestPositions({ orgId: Number(orgId) })
    }

    return () => {
      position.setErrors([])
    }
  }, [orgId])

  if (!isEmpty(position.errors)) {
    return <Error errors={position.errors} />
  }

  if (position.positions === null || position.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    orgId: Number(orgId),
    id: 0,
    name: '',
  }

  const linkList = getLink(PrivatePaths.PositionList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('PositionList'), link: linkList, active: false },
          {
            id: 2,
            title: t('PositionCreate'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('PositionCreate')}</Heading>

      <div className="container-wrapper">
        <PositionForm
          initialValues={initialValues}
          handleService={position.createPosition}
          buttonlabel={t('Create')}
          link={linkList}
          responseMessage={t('PositionAddedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(PositionCreate)))
