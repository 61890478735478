import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import TmcService, { Product } from '../services/TmcService'

import { StorageId } from '../services/StorageService'

import getErrorsMapping from '../utils/get-errors-mapping'

export class TmcPriceListStore {
  errors: string[] = []

  isLoader = false

  priceList: Product[] = null as unknown as Product[]

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      errors: observable,
      isLoader: observable,

      priceList: observable,

      setErrors: action.bound,

      isLoaderChanged: action.bound,

      requestPriceList: action.bound,

      setPriceList: action.bound,
    })

    this.rootStore = rootStore
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  setPriceList(payload: Product[]): void {
    this.priceList = payload
  }

  async requestPriceList(payload: StorageId): Promise<this> {
    this.isLoaderChanged(true)

    try {
      const response = await TmcService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.setPriceList(json)
      }
    } catch (e: any) {
      console.log(
        `Class TmcPriceListStore, method requestPriceList, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)

    return this
  }
}
