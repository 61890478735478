import { EmployeeAPI } from './ApiUrls'
import httpClient from './httpClient'
import { OrgId } from './OrganizationService'

export interface EmployeeId extends OrgId {
  id: number
}

export interface Employee extends EmployeeId, OrgId {
  contractDate: string
  contractEndDate: string
  contractNumber: string
  contractStartDate: string
  contractType: string
  departmentId: number
  employeeWorkScheduleId: number
  employmentType: number
  orderDate: string
  orderNumber: string
  personId: number
  personnelNumber: string
  positionId: number
  probation: string
  salary: number
  salaryFund: number
  vacationDays: number
}

export interface EmployeeResponse extends Employee {
  positionName: string
  departmentName: string
  personName: string
}

export class EmployeeService {
  list = async (model: OrgId): Promise<Response> => {
    const result = await httpClient.post(EmployeeAPI.List, model)

    return result
  }

  add = async (model: Employee): Promise<Response> => {
    const result = await httpClient.post(EmployeeAPI.Add, model)

    return result
  }

  edit = async (model: Employee): Promise<Response> => {
    const result = await httpClient.post(EmployeeAPI.Edit, model)

    return result
  }

  delete = async (model: EmployeeId): Promise<Response> => {
    const result = await httpClient.post(EmployeeAPI.Delete, model)

    return result
  }

  get = async (model: EmployeeId): Promise<Response> => {
    const result = await httpClient.post(EmployeeAPI.Get, model)

    return result
  }
}

export default new EmployeeService()
