import { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useDepartmentsStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Breadcrumb } from '../../components/ui/Breadcrumb'
import { Error } from '../../components/ui/Error'

import DepartmentForm from './DepartmentForm'

interface RouteParams {
  orgId: string
  departmentId: string
}

function DepartmentEdit({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const department = useDepartmentsStore()

  const { orgId, departmentId } = params as RouteParams

  useEffect(() => {
    if (
      department.departments === null ||
      !department.isDepartmentByOrganizationId(Number(orgId))
    ) {
      department.requestDepartments({ orgId: Number(orgId) })
    }

    department.fillCurrentDepartment({
      id: Number(departmentId),
      orgId: Number(orgId),
    })

    return () => {
      department.setErrors([])
      department.clearCurrentDepartment()
    }
  }, [orgId])

  if (!isEmpty(department.errors)) {
    return <Error errors={department.errors} />
  }

  if (isEmpty(department.currentDepartment) || department.isLoader) {
    return <Loader />
  }

  const initialValues: InitialValues = {
    id: department.currentDepartment.id,
    orgId: department.currentDepartment.orgId,
    formationDate: department.currentDepartment.formationDate,
    name: department.currentDepartment.name,
    parentId: String(department.currentDepartment.parentId),
    workScheduleId: String(department.currentDepartment.workScheduleId),
  }

  const linkList = getLink(PrivatePaths.DepartmentList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('DepartmentList'), link: linkList, active: false },
          {
            id: 2,
            title: t('DepartmentEdit'),
            link: '/',
            active: true,
          },
        ]}
      />
      <Heading>{t('DepartmentEdit')}</Heading>

      <div className="container-wrapper">
        <DepartmentForm
          initialValues={initialValues}
          handleService={department.updateDepartment}
          buttonlabel={t('Edit')}
          responseMessage={t('DepartmentEditedSuccessfully')}
        />
      </div>
    </>
  )
}

export default withRouter(withAuthRedirect(observer(DepartmentEdit)))
