import { omit } from 'lodash'

interface ObjectType {
  [x: string]: any
}

const omitData = <A extends ObjectType, B extends ReadonlyArray<keyof A>>(
  data: A,
  keys: B,
): Omit<A, B[number]> => omit(data, keys) as Omit<A, B[number]>

export default omitData
