import { ReactElement, useEffect } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useDepartmentsStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Error } from '../../components/ui/Error'

import DepartmentTableData from './DepartmentTableData'

interface RouteParams {
  orgId: string
}

function DepartmentList({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const department = useDepartmentsStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      department.departments === null ||
      !department.isDepartmentByOrganizationId(Number(orgId))
    ) {
      department.requestDepartments({ orgId: Number(orgId) })
    }

    return () => {
      department.setErrors([])
    }
  }, [orgId])

  const handleRemove = (id: number): void => {
    department.removeDepartment({
      id,
      orgId: Number(orgId),
    })
  }

  if (!isEmpty(department.errors)) {
    return <Error errors={department.errors} />
  }

  if (department.departments === null || department.isLoader) {
    return <Loader />
  }

  const linkCreate = getLink(PrivatePaths.DepartmentCreate, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Heading>{t('DepartmentList')}</Heading>

      <div className="text-center mb-5">
        <Link to={linkCreate} className="btn btn-primary text-white">
          {t('Department link')}
        </Link>
      </div>

      <DepartmentTableData
        departments={department.departments}
        onRemove={handleRemove}
        orgId={orgId}
      />
    </>
  )
}

export default withRouter(withAuthRedirect(observer(DepartmentList)))
