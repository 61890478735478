import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import StaffPositionService, {
  StaffPositionId,
  StaffPosition,
  StaffPositionResponse,
  StaffPositionOpenClose,
} from '../services/StaffPositionService'

import { OrgId } from '../services/OrganizationService'

import getErrorsMapping from '../utils/get-errors-mapping'
import isEmpty from '../utils/is-empty'

export class StaffPositionsStore {
  staffPositions: StaffPositionResponse[] =
    null as unknown as StaffPositionResponse[]

  currentStaffPosition: StaffPosition = {} as StaffPosition

  errors: string[] = []

  isLoader = false

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      staffPositions: observable,
      currentStaffPosition: observable,
      errors: observable,
      isLoader: observable,
      setErrors: action.bound,

      requestStaffPositions: action.bound,
      getStaffPosition: action.bound,
      createStaffPosition: action.bound,
      updateStaffPosition: action.bound,
      closeStaffPosition: action.bound,
      openStaffPosition: action.bound,

      setStaffPositions: action.bound,
      setStaffPosition: action.bound,
      setCurrentStaffPosition: action.bound,
      editStaffPosition: action.bound,
      setOpenStaffPosition: action.bound,
      setCloseStaffPosition: action.bound,
      isStaffPositionByOrganizationId: action.bound,
      clearCurrentStaffPosition: action.bound,
      fillCurrentStaffPosition: action.bound,
      isLoaderChanged: action.bound,
    })

    this.rootStore = rootStore
  }

  setStaffPositions(payload: StaffPositionResponse[]): void {
    this.staffPositions = payload
  }

  setStaffPosition(payload: StaffPositionResponse): void {
    this.staffPositions = [...this.staffPositions, payload]
  }

  setCurrentStaffPosition(payload: StaffPosition): void {
    this.currentStaffPosition = payload
  }

  editStaffPosition(payload: StaffPositionResponse): void {
    this.staffPositions = this.staffPositions.map((staffPosition) =>
      staffPosition.id === payload.id
        ? { ...staffPosition, ...payload }
        : staffPosition,
    )
  }

  setOpenStaffPosition(payload: StaffPositionOpenClose): void {
    this.staffPositions = this.staffPositions.map((staffPosition) => {
      if (staffPosition.id === payload.id) {
        return {
          ...staffPosition,
          closed: payload.closed,
        }
      }

      return staffPosition
    })
  }

  setCloseStaffPosition(payload: StaffPositionOpenClose): void {
    this.staffPositions = this.staffPositions.map((staffPosition) =>
      staffPosition.id === payload.id
        ? {
            ...staffPosition,
            closed: payload.closed,
            closeDate: payload.closeDate,
          }
        : staffPosition,
    )
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  isStaffPositionByOrganizationId(organizationId: number): boolean {
    const staffPosition = this.staffPositions.filter(
      ({ orgId }: StaffPosition): boolean => orgId === organizationId,
    )
    return !isEmpty(staffPosition)
  }

  clearCurrentStaffPosition(): void {
    this.currentStaffPosition = {} as StaffPosition
  }

  fillCurrentStaffPosition(staffPositionId: StaffPositionId): void {
    if (!isEmpty(this.staffPositions)) {
      const currentStaffPosition = this.staffPositions.filter(
        (item) => item.id === staffPositionId.id,
      )[0]
      this.setCurrentStaffPosition(currentStaffPosition)
    } else {
      this.getStaffPosition(staffPositionId)
    }
  }

  async requestStaffPositions(payload: OrgId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await StaffPositionService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400 || response.status === 404) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({
            id,
            orgId,
            approvalDate,
            departmentId,
            departmentName,
            positionId,
            positionName,
            positionCount,
            salary,
            salaryFund,
            vacantUnitsCount,
            workScheduleId,
            closed,
            closeDate,
          }: StaffPositionResponse): StaffPositionResponse => ({
            id,
            orgId,
            approvalDate,
            departmentId,
            departmentName,
            positionId,
            positionName,
            positionCount,
            salary,
            salaryFund,
            vacantUnitsCount,
            workScheduleId,
            closed,
            closeDate,
          }),
        )
        this.setStaffPositions(result)
      }
    } catch (e: any) {
      console.log(
        `Class StaffPositionsStore, method requestStaffPositions, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async getStaffPosition(payload: StaffPositionId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await StaffPositionService.get(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          orgId,
          approvalDate,
          departmentId,
          positionId,
          positionCount,
          salary,
          salaryFund,
          vacantUnitsCount,
          workScheduleId,
        } = json
        this.setCurrentStaffPosition({
          id,
          orgId,
          approvalDate,
          departmentId,
          positionId,
          positionCount,
          salary,
          salaryFund,
          vacantUnitsCount,
          workScheduleId,
        })
      }
    } catch (e: any) {
      console.log(
        `Class StaffPositionsStore, method getStaffPosition, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async createStaffPosition(payload: StaffPosition): Promise<null | string[]> {
    try {
      const response = await StaffPositionService.add(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          orgId,
          approvalDate,
          departmentId,
          departmentName,
          positionId,
          positionName,
          positionCount,
          salary,
          salaryFund,
          vacantUnitsCount,
          workScheduleId,
          closed,
          closeDate,
        }: StaffPositionResponse = json
        this.setStaffPosition({
          id,
          orgId,
          approvalDate,
          departmentId,
          departmentName,
          positionId,
          positionName,
          positionCount,
          salary,
          salaryFund,
          vacantUnitsCount,
          workScheduleId,
          closed,
          closeDate,
        })
      }
    } catch (e: any) {
      console.log(
        `Class StaffPositionsStore, method createStaffPosition, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async updateStaffPosition(payload: StaffPosition): Promise<null | string[]> {
    try {
      const response = await StaffPositionService.edit(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          orgId,
          approvalDate,
          departmentId,
          departmentName,
          positionId,
          positionName,
          positionCount,
          salary,
          salaryFund,
          vacantUnitsCount,
          workScheduleId,
          closed,
          closeDate,
        }: StaffPositionResponse = json
        this.setCurrentStaffPosition({
          id,
          orgId,
          approvalDate,
          departmentId,
          positionId,
          positionCount,
          salary,
          salaryFund,
          vacantUnitsCount,
          workScheduleId,
        })
        this.editStaffPosition({
          id,
          orgId,
          approvalDate,
          departmentId,
          departmentName,
          positionId,
          positionName,
          positionCount,
          salary,
          salaryFund,
          vacantUnitsCount,
          workScheduleId,
          closed,
          closeDate,
        })
      }
    } catch (e: any) {
      console.log(
        `Class StaffPositionsStore, method updateStaffPosition, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async closeStaffPosition(payload: StaffPositionId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await StaffPositionService.delete(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const { id, closed, closeDate }: StaffPositionOpenClose = json

        this.setCloseStaffPosition({ id, closed, closeDate })
      }
    } catch (e: any) {
      console.log(
        `Class StaffPositionsStore, method closeStaffPosition, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async openStaffPosition(payload: StaffPositionId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await StaffPositionService.open(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const { id, closed, closeDate }: StaffPositionOpenClose = json

        this.setOpenStaffPosition({ id, closed, closeDate })
      }
    } catch (e: any) {
      console.log(
        `Class StaffPositionsStore, method openStaffPosition, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }
}
