import { ReactElement, useEffect } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PrivatePaths, PrivatePathsRegexp } from '../../router/Routes'

import { useCounterpartiesStore } from '../../providers/RootStoreProvider'

import { withAuthRedirect } from '../../hoc/withAuthRedirect'

import isEmpty from '../../utils/is-empty'
import getLink from '../../utils/get-link'

import { Heading } from '../../components/ui/Typography/Typography'
import { Loader } from '../../components/ui/Loader'
import { Error } from '../../components/ui/Error'

import CounterpartyTableData from './CounterpartyTableData'

interface RouteParams {
  orgId: string
}

function CounterpartyList({
  match: { params },
}: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const counterparty = useCounterpartiesStore()

  const { orgId } = params as RouteParams

  useEffect(() => {
    if (
      counterparty.counterparties === null ||
      !counterparty.isCounterpartyByOrganizationId(Number(orgId))
    ) {
      counterparty.requestCounterparties({ orgId: Number(orgId) })
    }

    return () => {
      counterparty.setErrors([])
    }
  }, [orgId])

  const handleRemove = (id: number): void => {
    counterparty.removeCounterparty({ id })
  }

  if (!isEmpty(counterparty.errors)) {
    return <Error errors={counterparty.errors} />
  }

  if (counterparty.counterparties === null || counterparty.isLoader) {
    return <Loader />
  }

  const linkCreate = getLink(PrivatePaths.CounterpartyCreate, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Heading>{t('CounterpartyList')}</Heading>

      <div className="text-center mb-5">
        <Link to={linkCreate} className="btn btn-primary text-white">
          {t('Counterparty link')}
        </Link>
      </div>

      <CounterpartyTableData
        counterparties={counterparty.counterparties}
        onRemove={handleRemove}
        orgId={orgId}
      />
    </>
  )
}

export default withRouter(withAuthRedirect(observer(CounterpartyList)))
