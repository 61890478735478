import { action, makeObservable, observable } from 'mobx'
import RootStore from './rootStore'
import EmployeeService, {
  EmployeeId,
  Employee,
  EmployeeResponse,
} from '../services/EmployeeService'

import { OrgId } from '../services/OrganizationService'

import { ReactSelectValues } from '../types'

import getErrorsMapping from '../utils/get-errors-mapping'
import isEmpty from '../utils/is-empty'

export class EmployeesStore {
  employees: EmployeeResponse[] = null as unknown as EmployeeResponse[]

  currentEmployee: Employee = {} as Employee

  errors: string[] = []

  isLoader = false

  rootStore: typeof RootStore

  constructor(rootStore: typeof RootStore) {
    makeObservable(this, {
      employees: observable,
      currentEmployee: observable,
      errors: observable,
      isLoader: observable,
      setErrors: action.bound,
      requestEmployees: action.bound,
      createEmployee: action.bound,
      getEmployee: action.bound,
      updateEmployee: action.bound,
      removeEmployee: action.bound,
      requestOptionsEmployee: action.bound,
      setEmployees: action.bound,
      setEmployee: action.bound,
      setCurrentEmployee: action.bound,
      editEmployee: action.bound,
      deleteEmployee: action.bound,
      isEmployeeByOrganizationId: action.bound,
      clearCurrentEmployee: action.bound,
      fillCurrentEmployee: action.bound,
      isLoaderChanged: action.bound,
    })

    this.rootStore = rootStore
  }

  setEmployees(payload: EmployeeResponse[]): void {
    this.employees = payload
  }

  setEmployee(payload: EmployeeResponse): void {
    this.employees = [...this.employees, payload]
  }

  setCurrentEmployee(payload: Employee): void {
    this.currentEmployee = payload
  }

  editEmployee(payload: EmployeeResponse): void {
    this.employees = this.employees.map((employee) =>
      employee.id === payload.id ? { ...employee, ...payload } : employee,
    )
  }

  deleteEmployee(id: number): void {
    this.employees = this.employees.filter((employee) => employee.id !== id)
  }

  setErrors(payload: string[]): void {
    this.errors = payload
  }

  isLoaderChanged(payload: boolean): void {
    this.isLoader = payload
  }

  isEmployeeByOrganizationId(organizationId: number): boolean {
    const employee = this.employees.filter(
      ({ orgId }: Employee): boolean => orgId === organizationId,
    )
    return !isEmpty(employee)
  }

  clearCurrentEmployee(): void {
    this.currentEmployee = {} as Employee
  }

  fillCurrentEmployee(employeeId: EmployeeId): void {
    if (!isEmpty(this.employees)) {
      const currentEmployee = this.employees.filter(
        (item) => item.id === employeeId.id,
      )[0]
      this.setCurrentEmployee(currentEmployee)
    } else {
      this.getEmployee(employeeId)
    }
  }

  async requestEmployees(payload: OrgId): Promise<void> {
    this.isLoaderChanged(true)
    try {
      const response = await EmployeeService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({
            id,
            orgId,
            contractDate,
            contractEndDate,
            contractNumber,
            contractStartDate,
            contractType,
            departmentId,
            departmentName,
            employeeWorkScheduleId,
            employmentType,
            orderDate,
            orderNumber,
            personId,
            personName,
            personnelNumber,
            positionId,
            positionName,
            probation,
            salary,
            salaryFund,
            vacationDays,
          }: EmployeeResponse): EmployeeResponse => ({
            id,
            orgId,
            contractDate,
            contractEndDate,
            contractNumber,
            contractStartDate,
            contractType,
            departmentId,
            departmentName,
            employeeWorkScheduleId,
            employmentType,
            orderDate,
            orderNumber,
            personId,
            personName,
            personnelNumber,
            positionId,
            positionName,
            probation,
            salary,
            salaryFund,
            vacationDays,
          }),
        )
        this.setEmployees(result)
      }
    } catch (e: any) {
      console.log(
        `Class EmployeesStore, method requestEmployees, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async getEmployee(payload: EmployeeId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await EmployeeService.get(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          orgId,
          contractDate,
          contractEndDate,
          contractNumber,
          contractStartDate,
          contractType,
          departmentId,
          employeeWorkScheduleId,
          employmentType,
          orderDate,
          orderNumber,
          personId,
          personnelNumber,
          positionId,
          probation,
          salary,
          salaryFund,
          vacationDays,
        }: Employee = json
        this.setCurrentEmployee({
          id,
          orgId,
          contractDate,
          contractEndDate,
          contractNumber,
          contractStartDate,
          contractType,
          departmentId,
          employeeWorkScheduleId,
          employmentType,
          orderDate,
          orderNumber,
          personId,
          personnelNumber,
          positionId,
          probation,
          salary,
          salaryFund,
          vacationDays,
        })
      }
    } catch (e: any) {
      console.log(
        `Class EmployeesStore, method getEmployee, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async createEmployee(payload: Employee): Promise<null | string[]> {
    try {
      const response = await EmployeeService.add(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          orgId,
          contractDate,
          contractEndDate,
          contractNumber,
          contractStartDate,
          contractType,
          departmentId,
          departmentName,
          employeeWorkScheduleId,
          employmentType,
          orderDate,
          orderNumber,
          personId,
          personName,
          personnelNumber,
          positionId,
          positionName,
          probation,
          salary,
          salaryFund,
          vacationDays,
        }: EmployeeResponse = json
        this.setEmployee({
          id,
          orgId,
          contractDate,
          contractEndDate,
          contractNumber,
          contractStartDate,
          contractType,
          departmentId,
          departmentName,
          employeeWorkScheduleId,
          employmentType,
          orderDate,
          orderNumber,
          personId,
          personName,
          personnelNumber,
          positionId,
          positionName,
          probation,
          salary,
          salaryFund,
          vacationDays,
        })
      }
    } catch (e: any) {
      console.log(
        `Class EmployeesStore, method createEmployee, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async updateEmployee(payload: Employee): Promise<null | string[]> {
    try {
      const response = await EmployeeService.edit(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        const errors = getErrorsMapping(['ERROR500'])
        return errors
      }

      const json = await response.json()

      if (response.status === 400) {
        const errors = getErrorsMapping(json.responseDescription)
        return errors
      }

      if (response.status >= 200 && response.status < 300) {
        const {
          id,
          orgId,
          contractDate,
          contractEndDate,
          contractNumber,
          contractStartDate,
          contractType,
          departmentId,
          departmentName,
          employeeWorkScheduleId,
          employmentType,
          orderDate,
          orderNumber,
          personId,
          personName,
          personnelNumber,
          positionId,
          positionName,
          probation,
          salary,
          salaryFund,
          vacationDays,
        }: EmployeeResponse = json
        this.setCurrentEmployee({
          id,
          orgId,
          contractDate,
          contractEndDate,
          contractNumber,
          contractStartDate,
          contractType,
          departmentId,
          employeeWorkScheduleId,
          employmentType,
          orderDate,
          orderNumber,
          personId,
          personnelNumber,
          positionId,
          probation,
          salary,
          salaryFund,
          vacationDays,
        })
        this.editEmployee({
          id,
          orgId,
          contractDate,
          contractEndDate,
          contractNumber,
          contractStartDate,
          contractType,
          departmentId,
          departmentName,
          employeeWorkScheduleId,
          employmentType,
          orderDate,
          orderNumber,
          personId,
          personName,
          personnelNumber,
          positionId,
          positionName,
          probation,
          salary,
          salaryFund,
          vacationDays,
        })
      }
    } catch (e: any) {
      console.log(
        `Class EmployeesStore, method updateEmployee, type error ${e.name}, Message ${e.message}`,
      )
      return [e.message]
    }

    return null
  }

  async removeEmployee(payload: EmployeeId): Promise<void> {
    this.isLoaderChanged(true)

    try {
      const response = await EmployeeService.delete(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      if (response.status === 400) {
        const json = await response.json()
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        this.deleteEmployee(payload.id)
      }
    } catch (e: any) {
      console.log(
        `Class EmployeesStore, method removeEmployee, type error ${e.name}, Message ${e.message}`,
      )
    }

    this.isLoaderChanged(false)
  }

  async requestOptionsEmployee(payload: OrgId): Promise<ReactSelectValues[]> {
    try {
      const response = await EmployeeService.list(payload)

      if (response.status === 403) {
        this.rootStore.userStore.cleanUser()
      }

      if (response.status === 500 || response.status === 502) {
        this.setErrors(getErrorsMapping(['ERROR500']))
      }

      const json = await response.json()

      if (response.status === 400) {
        this.setErrors(getErrorsMapping(json.responseDescription))
      }

      if (response.status >= 200 && response.status < 300) {
        const result = json.map(
          ({ personId, personName }: EmployeeResponse): ReactSelectValues => ({
            value: String(personId),
            label: String(personName),
          }),
        )
        return result
      }
    } catch (e: any) {
      console.log(
        `Class EmployeesStore, method requestOptionsEmployee, type error ${e.name}, Message ${e.message}`,
      )
    }

    return []
  }
}
