import React, { ReactElement, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'

import { PrivatePaths, PrivatePathsRegexp } from '../../../router/Routes'

import {
  useStoragesStore,
  useTmcMoveStore,
} from '../../../providers/RootStoreProvider'

import TmcService, {
  LocalStorageName,
  ProductMoved,
} from '../../../services/TmcService'

import { withAuthRedirect } from '../../../hoc/withAuthRedirect'

import { InitialValues } from './types'

import isEmpty from '../../../utils/is-empty'
import getLink from '../../../utils/get-link'
import has from '../../../utils/has'

import { Heading } from '../../../components/ui/Typography/Typography'
import { Error } from '../../../components/ui/Error'
import { Breadcrumb } from '../../../components/ui/Breadcrumb'

import MoveForm from './MoveForm'
import MoveData from './MoveData'

interface RouteParams {
  orgId: string
}

function Move({ match: { params } }: RouteComponentProps): ReactElement {
  const { t } = useTranslation()

  const storage = useStoragesStore()
  const tmc = useTmcMoveStore()

  const { orgId } = params as RouteParams

  const localStorageName = `${LocalStorageName.productsForMove}-${orgId}`
  const localMovedProducts: ProductMoved =
    TmcService.getLocalMovedProducts(localStorageName)

  useEffect(() => {
    if (!isEmpty(localMovedProducts)) {
      const model: ProductMoved = {
        storeIds: localMovedProducts.storeIds,
        values: localMovedProducts.values,
      }

      tmc.setProductsMoved(model)

      tmc.createInputValueCounts(localMovedProducts.values)

      tmc.requestProducts({
        storeId: localMovedProducts.storeIds.storeIdSrc,
      })
    }

    return () => {
      tmc.setErrors([])
    }
  }, [orgId])

  const handleRequestProductsForMove = (values: InitialValues): void => {
    tmc.clearProductsMoved()

    tmc.clearInputValueCounts()

    const model: ProductMoved = {
      storeIds: {
        storeIdSrc: Number(values.storeIdSrc),
        storeIdDest: Number(values.storeIdDest),
      },
      values: {},
    }

    TmcService.setLocalMovedProducts(localStorageName, model)

    tmc.setStoreIdsForMoved({
      storeIdSrc: Number(values.storeIdSrc),
      storeIdDest: Number(values.storeIdDest),
    })

    tmc.requestProducts({
      storeId: Number(values.storeIdSrc),
    })
  }

  const handleProductMoveChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    count: number,
  ): void => {
    const productId = Number(e.target.name)
    const countFromInputValueCounts = has(tmc.inputValueCounts, productId)
      ? tmc.inputValueCounts[productId]
      : count

    if (has(tmc.productsMoved.values, productId)) {
      tmc.deleteValueCountsForMoved(productId)
    } else {
      tmc.setValueCountsForMoved({ [productId]: countFromInputValueCounts })
    }

    TmcService.setLocalMovedProduct(
      localStorageName,
      productId,
      countFromInputValueCounts,
    )
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const productId = Number(e.target.name)
    const count = Number(e.target.value)

    if (has(tmc.productsMoved.values, productId)) {
      tmc.setInputValueCounts(productId, count)

      tmc.setValueCountsForMoved({ [productId]: count })

      TmcService.updateLocalMovedProduct(localStorageName, productId, count)
    }
  }

  const handleProductsMoved = (): void => {
    const { storeIds, values } = tmc.productsMoved

    if (isEmpty(tmc.errors)) {
      const payload = {
        storeIdSrc: storeIds.storeIdSrc,
        storeIdDest: storeIds.storeIdDest,
        operationType: 2,
        values: Object.entries(values).map(([key, value]) => ({
          tmcId: Number(key),
          count: value,
        })),
      }

      tmc.moveProducts(payload, localStorageName)
    }
  }

  if (!isEmpty(tmc.errors)) {
    return <Error errors={tmc.errors} />
  }

  const { storeIdSrc, storeIdDest } = tmc.productsMoved.storeIds

  const initialValues: InitialValues = {
    orgId: Number(orgId),
    storeIdSrc: storeIdSrc !== 0 ? String(storeIdSrc) : '',
    storeIdDest: storeIdDest !== 0 ? String(storeIdDest) : '',
  }

  const linkList = getLink(PrivatePaths.TmcList, {
    [orgId]: PrivatePathsRegexp.orgId,
  })

  return (
    <>
      <Breadcrumb
        breadcrumbs={[
          { id: 1, title: t('TmcList'), link: linkList, active: false },
          {
            id: 2,
            title: t('TmcMove'),
            link: '/',
            active: true,
          },
        ]}
      />

      <Heading>{t('TmcMove')}</Heading>

      {!isEmpty(tmc.errors) && <Error errors={tmc.errors} />}

      <div className="container-wrapper mb-3">
        <MoveForm
          initialValues={initialValues}
          handleSubmitForm={handleRequestProductsForMove}
          loadOptionsStorage={storage.requestOptionsStorage}
          isSubmitting={tmc.isLoader}
        />
      </div>
      {tmc.isLoader ? (
        <div className="d-flex justify-content-center py-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <MoveData
          products={tmc.productsForMove}
          values={tmc.productsMoved.values}
          inputValueCounts={tmc.inputValueCounts}
          onAmountChange={handleAmountChange}
          onProductMoveChange={handleProductMoveChange}
          onProductsMoved={handleProductsMoved}
        />
      )}
    </>
  )
}

export default withRouter(withAuthRedirect(observer(Move)))
