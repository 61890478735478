import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { NonAuthPaths } from '../../router/Routes'

import styles from './Main.module.scss'

export default function Main(): ReactElement {
  const { t } = useTranslation()

  return (
    <div className="d-flex justify-content-center">
      <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4">
        <h1 className={styles.heading}>{t('Main')}</h1>
        <div className="d-grid gap-2 mb-3">
          <Link
            to={NonAuthPaths.Login}
            className="btn btn-primary text-white text-uppercase"
          >
            {t('Login link')}
          </Link>
        </div>
        <div className="d-grid gap-2">
          <Link
            to={NonAuthPaths.SignUp}
            className="btn btn-primary text-white text-uppercase"
          >
            {t('Sign Up link')}
          </Link>
        </div>
      </div>
    </div>
  )
}
