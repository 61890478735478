interface ContractType {
  key: string
  value: string
  text: string
}

const data: ContractType[] = [
  {
    key: '0',
    value: '0',
    text: 'ГПХ',
  },
  {
    key: '1',
    value: '1',
    text: 'Трудовой договор',
  },
  {
    key: '2',
    value: '2',
    text: 'Другое',
  },
]

export const getContractType = (): ContractType[] => data

export const getCurrentContractType = (
  value: string,
): ContractType | undefined => data.find((item) => item.value === value)
